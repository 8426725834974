export function removeDuplicates(myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
}

//export function groupBy(list, keyGetter) {
//    const map = new Map();
//    list.forEach((item) => {
//        const key = keyGetter(item);
//        const collection = map.get(key);
//        if (!collection) {
//            map.set(key, [item]);
//        } else {
//            collection.push(item);
//        }
//    });
//    return map;
//}

export function groupBy(list, keyGetter, itemKeyGetter, titleGetter, itemTitleGetter) {
    const map = {};
    list.forEach((item) => {
        const key = keyGetter(item);
        const group = map[key];
        if (!group) {
            map[key] = {
                items: {},
            };
            if (titleGetter) {
                map[key].title = titleGetter(item);
            }
        }
        if (itemKeyGetter) {
            var itemKey = itemKeyGetter(item);
            map[key].items[itemKey] = item;
            if (itemTitleGetter) {
                map[key].items[itemKey].title = itemTitleGetter(item);
            }
        }
        
    });
    return map;
}