import React, { Component } from 'react';
import { Grid, Button, Typography } from '@material-ui/core';
import Input from '../../../components/UI/Inputs/Input';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import FileUploadValidatable from '../../../components/UI/FileUpload/FileUploadValidatable';
import OutlinedContainer from '../../../components/UI/Containers/OutlinedContainer';
import { withRouter } from 'react-router-dom';
import { insertValueIntoArrayElement, deleteValueFromArrayElement } from '../../../utility/formHelpers';
import Preloader from '../../../containers/Preloader';

class NewsCreation extends Component {

    constructor(props) {
        super(props);
        this.maxTextSize = 700;
        this.maxTotalFileSize = 5;
        this.state = {
            municipalSettlements: [],
            types: [],
            form: {
                municipalSettlementId: {
                    value: props.isEdit ? props.newsInfo.municipalSettlementId
                        : (this.props.userRole === 'moderator'
                            ? this.props.territory
                            : ""),
                    elementType: "select",
                    config: {
                        label: "Затрагиваемая в новости территория",
                        defaultDisplayValue: "Территория не выбрана",
                        options: []
                    },
                    validation: {
                        required: true
                    },
                    error: "",
                    valid: false
                },
                authority: {
                    value: props.isEdit ? props.newsInfo.authority : "",
                    elementType: "textbox",
                    config: {
                        label: "Орган власти",
                        placeholder: `Орган власти, от лица которого публикуется новость`,
                    },
                    validation: {
                        required: true
                    },
                    error: "",
                    valid: false
                },
                header: {
                    value: props.isEdit ? props.newsInfo.header : "",
                    elementType: "textarea",
                    config: {
                        label: "Заголовок"
                    },
                    validation: {
                        required: true
                    },
                    error: "",
                    valid: false
                },
                text: {
                    value: props.isEdit ? props.newsInfo.text : "",
                    elementType: "textarea",
                    config: {
                        label: "Текст новости",
                        placeholder: `Текст новости (не более ${this.maxTextSize} символов)`,
                        maxLength: this.maxTextSize,
                        rows: 10
                    },
                    validation: {
                        required: true
                    },
                    error: "",
                    valid: false
                },
                images: props.isEdit ? props.newsInfo.images : [],
                documents: props.isEdit ? props.newsInfo.documents : []
            },
            formIsValid: false,
            lettersLeft: this.maxDescriptionSize,
            type: ""
        };
    }

    componentDidMount() {
        this.props.onFetchMunicipalSettlements();
        this.validateForm();
    }

    componentDidUpdate(prevProps) {
        if (this.props.created) {
            console.log("is created: ", this.props.created);
            this.props.history.push(`/news`);
        }
    }

    handleCancel = () => {
        this.props.history.goBack();
    }

    handleFormElementBlur = (element) => { }

    handleDocumentAdd = (document) => {
        var updatedForm = insertValueIntoArrayElement(this.state.form, "documents", document);
        this.setState({
            form: updatedForm
        });
    };

    handleDocumentDelete = (index) => {
        var updatedForm = deleteValueFromArrayElement(this.state.form, "documents", index);
        this.setState({
            form: updatedForm
        });
    };

    handleImageAdd = (image) => {
        var updatedForm = insertValueIntoArrayElement(this.state.form, "images", image);
        this.setState({
            form: updatedForm
        });
    };

    handleImageDelete = (index) => {
        var updatedForm = deleteValueFromArrayElement(this.state.form, "images", index);
        this.setState({
            form: updatedForm
        });
    };

    handleFieldChange = (value, element) => {
        let updatedForm = { ...this.state.form };
        let updatedElement = { ...this.state.form[element] };
        updatedElement.value = value;
        updatedForm[element] = updatedElement;
        this.setState({ form: updatedForm }, this.validateForm);

    }

    handleError(errorText) {
        this.props.onNotify(errorText);
    }

    validateForm = () => {
        var isValid = true;
        let element = null;
        for (var elementName in this.state.form) {
            element = this.state.form[elementName];
            if (element && element.validation && element.validation.required && !element.value) {
                isValid = false;
                break;
            }
        }
        this.setState({
            formIsValid: isValid
        });
    }

    renderImagesInput() {
        return (
            <Grid container direction="column">
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom style={{ display: "block", textAlign: 'left' }}>
                        Изображения
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FileUploadValidatable
                        maxFilesCount={5}
                        fileType="image"
                        files={this.state.form.images}
                        onFileAdd={this.handleImageAdd}
                        onFileDelete={this.handleImageDelete}
                        addFileBtnText="Добавить изображение"
                        accept="image/png,image/jpeg"
                        maxTotalSizeInMegabytes={this.maxTotalFileSize}
                    />
                </Grid>

            </Grid>
        );
    }

    handleFormSubmit = () => {

        console.log(this.state.form);
        var data = {
            municipalSettlementId: this.state.form.municipalSettlementId.value,
            header: this.state.form.header.value,
            text: this.state.form.text.value,
            images: this.state.form.images,
            documents: this.state.form.documents,
            authority: this.state.form.authority.value
        };
        this.props.onPost(data);
        //this.props.history.replace("/votings");
    }

    renderDocumentsInput() {
        return (
            <Grid container direction="column">
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom style={{ display: "block", textAlign: 'left' }}>
                        Документы
                        <Typography variant="caption" gutterBottom style={{ marginLeft: "5px" }}>
                            ( До 5 шт )
                        </Typography>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FileUploadValidatable
                        maxFilesCount={5}
                        fileType="document"
                        files={this.state.form.documents}
                        accept="application/pdf"
                        onFileAdd={this.handleDocumentAdd}
                        onFileDelete={this.handleDocumentDelete}
                        addFileBtnText="Добавить документ"
                        maxTotalSizeInMegabytes={this.maxTotalFileSize}
                    />
                </Grid>
            </Grid>
        );
    }

    render() {
        if (!this.props.municipalSettlements || this.props.municipalSettlements.length == 0) {
            return (
                <Preloader fullScreen open color="primary"/>
            );
        }

        return (
            <Grid container justify="center">
                <Grid item xs={12} sm={8}>
                    <OutlinedContainer>
                        <form >
                            <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12} container justify="space-between" alignItems="center">
                                    <Grid item>
                                        <Typography variant="h5" gutterBottom style={{ textAlign: 'left' }}>
                                            {
                                                this.props.isEdit
                                                    ? 'Изменение новости'
                                                    : 'Добавление новости'
                                            }
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" color="secondary" onClick={this.handleCancel}>
                                            Отмена
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Input
                                        id="municipalSettlementId"
                                        type={this.state.form.municipalSettlementId.elementType}
                                        value={this.state.form.municipalSettlementId.value}
                                        config={this.state.form.municipalSettlementId.config}
                                        disabled={this.props.userRole === 'moderator'}
                                        options={this.props.municipalSettlements}
                                        error={this.state.form.municipalSettlementId.error}
                                        validation={this.state.form.municipalSettlementId.validation}
                                        onChange={event => { this.handleFieldChange(event.target.value, "municipalSettlementId"); }}
                                        onBlur={event => { this.handleFormElementBlur(event, "municipalSettlementId"); }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Input
                                        id="authority"
                                        type={this.state.form.authority.elementType}
                                        value={this.state.form.authority.value}
                                        config={this.state.form.authority.config}
                                        error={this.state.form.authority.error}
                                        validation={this.state.form.authority.validation}
                                        onChange={event => { this.handleFieldChange(event.target.value, "authority"); }}
                                        onBlur={event => { this.handleFormElementBlur(event, "authority"); }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Input
                                        id="header"
                                        type={this.state.form.header.elementType}
                                        value={this.state.form.header.value}
                                        config={this.state.form.header.config}
                                        error={this.state.form.header.error}
                                        validation={this.state.form.header.validation}
                                        onChange={event => { this.handleFieldChange(event.target.value, "header"); }}
                                        onBlur={event => { this.handleFormElementBlur(event, "header"); }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Input
                                        id="text"
                                        type={this.state.form.text.elementType}
                                        value={this.state.form.text.value}
                                        config={this.state.form.text.config}
                                        error={this.state.form.text.error}
                                        validation={this.state.form.text.validation}
                                        onChange={event => { this.handleFieldChange(event.target.value, "text"); }}
                                        onBlur={event => { this.handleFormElementBlur(event, "text"); }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {this.renderImagesInput()}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {this.renderDocumentsInput()}
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" color="primary" onClick={this.handleFormSubmit} disabled={!this.state.formIsValid}>
                                        Сохранить
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </OutlinedContainer>
                </Grid>
            </Grid>

        );
    }
}

const mapStateToProps = state => {
    return {
        municipalSettlements: state.cmn.municipalSettlements,
        created: state.news.created,
        territory: state.user.locale,
        userRole: state.user.role,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchMunicipalSettlements: () => dispatch(actions.fetchMunicipalSettlements()),
        onPost: (data) => dispatch(actions.postNews(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NewsCreation));