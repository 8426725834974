import * as actionTypes from './actionTypes';
import reportApi from '../api/reportApi';
import {addErrorNotification } from './notify';

export const fetchReports = () => {
    return dispatch => {
        reportApi.getReports()
            .then(res => {
                dispatch(fetchReportsSuccess(res.data));
            })
            .catch(err => {
                dispatch(addErrorNotification());
            });
    };
};

export const fetchReportsSuccess = (reportList) => {
    return {
        type: actionTypes.FETCH_REPORTS_SUCCESS,
        reportList
    };
};

export const downloadReport = (reportId, reportParameters) => {
    return (dispatch) => {
        let data = { "id": reportId, "params": [] };
        if (reportParameters.length !== 0) {
            reportParameters.forEach(el => {
                data.params.push({ "id": el.id, "value": JSON.stringify(el.value) });
            })
        }
        reportApi.postReport(data)
            .then((res) => {
                let disposition = res.headers['content-disposition'];
                let filename = 'report.xlsx';
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    let matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) {
                        filename = matches[1].replace(/['"]/g, '');
                    }
                }
                dispatch(fetchReportDocumentSuccess(res.data, filename));
            })
            .catch(err => {
                dispatch(addErrorNotification('При формировании отчёта произошла ошибка'));
            });
    };
}

export const fetchReportDocumentSuccess = (data, filename) => {
    const downloadUrl = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
    return {type: actionTypes.FETCH_REPORT_DOCUMENT_SUCCESS};
};