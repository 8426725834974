import React, { Component } from 'react';
import { Grid, Button} from '@material-ui/core';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import Rejection from './Rejection';
import Voting from '../../../components/Voting/Voting';
import Preloader from '../../../containers/Preloader';

class VotingModerationContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showVotingRejection: false
        };
    }

    componentDidMount() {
        if (this.props.id) {
            this.props.onFetchVoting(this.props.id);
        }
    }

    handleApproveVoting = () => {
        this.props.onApproveVoting(this.props.id);
    }

    renderVotingModeration() {
        let votingRejection = this.state.showVotingRejection
            ? (
                <Grid item key="rejection">
                    <Rejection
                        onReject={(reason) => this.props.onRejectVoting(this.props.id, reason)}
                        onCancel={() => this.toggleVotingRejection(false)} />
                </Grid>
            ) : null;
        return (
            <React.Fragment>
                <Grid container spacing={2}>
                    <Grid item key="actions" xs={12} container spacing={2}>
                        <Grid item key="approve">
                            <Button variant="contained" color="primary" onClick={() => this.handleApproveVoting()}>
                                Опубликовать
                            </Button>
                        </Grid>
                        
                        <Grid item key="reject">
                            <Button variant="contained" color="secondary" onClick={() => this.toggleVotingRejection(true)}>
                                Отклонить
                            </Button>
                        </Grid>
                        
                    </Grid>
                    {votingRejection}
                </Grid>

            </React.Fragment>


        );
    }

    toggleVotingRejection = (show) => {
        this.setState({ showVotingRejection: show });
    };

    render() {
        let voting = (
            <Preloader fullScreen open color="primary"/>
        );
        let moderation = null;
        if (this.props.loading) {
            return voting;
        }
        if (!this.props.loading && this.props.loadedVoting) {
            voting = (
                <Voting {...this.props.loadedVoting}
                    rejectionReason={this.props.loadedVoting.rejectionReason}
                    showResults
                />
            );
            if (this.props.canModerateVoting) {
                moderation = this.renderVotingModeration(this.props.loadedVoting);
            }
        }
        const moderationGrid = moderation
            ? (
                <Grid item xs={12} sm={4}>
                    {moderation}
                </Grid>
            ) : null;
        return (
            <Grid container direction="row" spacing={2} justify="center">
                <Grid item xs={12} sm={8}>
                    {voting}
                </Grid>
                {moderationGrid}
            </Grid>
        );
    }
}

const mapStateToProps = state => {
    return {
        loadedVoting: state.vote.loadedVoting,
        loading: state.vote.loading,
        canModerateVoting: state.vote.loadedVoting && state.vote.loadedVoting.status === "На модерации"
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchVoting: (id) => dispatch(actions.fetchVoting(id)),
        onApproveVoting: (id) => dispatch(actions.approveVoting(id)),
        onRejectVoting: (id, reason) => dispatch(actions.rejectVoting(id, reason))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VotingModerationContainer);