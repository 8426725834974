import React, { Component } from "react";
import AppBar from "../../components/Navigation/AppBar";
import { connect } from 'react-redux';

class MainMenu extends Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {
    }

    getMenuItems = () => {
        if (this.props.user.isAuthenticated) {
            switch (this.props.user.role) {
                case "moderator":
                    return [
                        { to: "/news", title: "Новости" },
                        { to: "/moderation/messages", title: "Cообщения" },
                        { to: "/organizations", title: "Справочник" },
                        { to: "/moderation/votings", title: "Голосования и опросы" },
                        { to: "/moderation/performers", title: "Исполнители" },
                        { to: "/moderation/messagetypes", title: "Типы сообщений" },
                        { to: "/messages/map", title: "Интерактивная карта сообщений" },
                        { to: "/reports", title: "Отчёты" },
                    ];
                case "admin":
                    return [
                        { to: "/news", title: "Новости" },
                        { to: "/votings", title: "Голосования и опросы" },
                        { to: "/organizations", title: "Справочник" },
                        { to: "/reports", title: "Отчёты" },
                        { to: "/users", title: "Пользователи" },
                    ];
                case "performer":
                    return [
                        { to: "/performer/messages", title: "Работа с сообщениями" },
                        { to: "/messages/map", title: "Интерактивная карта сообщений" },
                        { to: "/reports", title: "Отчёты" },
                    ];
            }
        }
        return [
            { to: "/news", title: "Новости" },
            { to: "/messages", title: "Сообщения" },
            { to: "/votings", title: "Голосования и опросы" },
            { to: "/about", title: "О проекте" },
            { to: "/organizations", title: "Справочник" },
            { to: "/messages/map", title: "Интерактивная карта сообщений" },
        ];
    }

    render() {
        let menuItems = this.getMenuItems();
        return (
            <AppBar menuItems={menuItems}/>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps)(MainMenu);