import React from 'react'
import TerritoryParameter from './TerritoryParameter'

let ReportParameter = (props) => {
    switch (props.type) {
        case 'territory':
            return <TerritoryParameter value={props.value} title={props.title} handleParameterValueChange={(reportValue) => props.handleParameterValueChange(reportValue)} />
                break;
        case 'test':
                //return <h1>test</h1> /*<TerritoryParameter value={[2]} handleParameterValueChange={(reportParametrs) => props.handleParameterValueChange([2, 11])} />*/
                break;

            default:
                return null
        }
}

export default ReportParameter;