import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import Preloader from '../Preloader';
import { withRouter } from 'react-router-dom';
import Organization from '../../components/Organizations/Organization';

class OrganizationsContainer extends Component {
    componentDidMount() {
        this.props.fetchOrganizationInfo(this.props.match.params.id);
    }

    componentDidUpdate(prevProps) {
    }

    render() {
        return (
            (this.props.organizationInfo) 
                ? <Organization organizationInfo={this.props.organizationInfo} />
                : <Preloader fullScreen open color="primary"/>
        );
    };
}
const mapStateToProps = state => {
    return {
        organizationInfo: state.org.organizationInfo
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchOrganizationInfo: (organigationId) => dispatch(actions.fetchOrganizationInfo(organigationId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrganizationsContainer));
