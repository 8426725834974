import React from 'react';
import * as actions from '../../store/actions';
import { connect } from 'react-redux';
import IconButtonFavorite from '../../components/UI/IconButtonFavorite';

const VotingFavoriteIconButton = (props) => {
    return (
        <IconButtonFavorite {...props} onClick={event => props.onToggleFavorites(props.votingId, !props.isFavorite, props.votingType)} />
    );
};

const mapDispatchToProps = dispatch => {
    return {
        onToggleFavorites: (id, isFavorite, votingType) => dispatch(actions.setVotingFavoriteStatus(id, isFavorite, votingType))
    };
};

export default connect(mapDispatchToProps)(VotingFavoriteIconButton);