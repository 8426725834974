import React from 'react';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "15px",
        ['@media (min-width:780px)']: { // eslint-disable-line no-useless-computed-key
            padding: "40px"
        }
    },
}));

const OutlinedContainer = (props) => {
    const classes = useStyles();
    return (
        <Paper className={classes.root}>
            { props.children }
        </Paper>
    );
};

export default OutlinedContainer;
