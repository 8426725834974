import axios from 'axios';

axios.defaults.headers.common['X-Frame-Options'] = 'SAMEORIGIN';
axios.defaults.headers.common['X-XSS-Protection'] = '1';
axios.defaults.headers.common['X-Content-Type-Options'] = 'nosniff';

const getDefaultCofig = () => {
    return {};
};

export const endpoints = {
    message: process.env.REACT_APP_MESSAGE_API_URL,
    common: process.env.REACT_APP_COMMON_API_URL,
    voting: process.env.REACT_APP_VOTING_API_URL,
    report: process.env.REACT_APP_REPORT_API_URL,
    user: process.env.REACT_APP_USER_API_URL,
    account: process.env.REACT_APP_ACCOUNT_URL
};

export async function get(url, endpoint, parameters) {
    //let token = await getToken();
    return axios.get(url, {
        baseURL: endpoint,
        params: parameters,
        withCredentials: true
        //headers: {
        //    'Authorization': `Bearer ${token}`
        //}
    });
}

export async function getBlob(url, endpoint) {
    //let token = await getToken();
    return axios.get(url, {
        baseURL: endpoint,
        responseType: 'blob',
        withCredentials: true
        //headers: {
        //    'Authorization': `Bearer ${token}`
        //}
    });
}

export async function post(url, data, endpoint, contentType, responseType) {
    //let token = await getToken();
    if (!contentType) {
        contentType = 'application/json, charset=utf-8';
    }

    return axios.post(url, data, {
        baseURL: endpoint,
        withCredentials: true,
        responseType, 
        headers: {
            'Content-Type': contentType,
            //'Authorization': `Bearer ${token}`
        }
    });
}


export async function put(url, data, endpoint, contentType) {
    //let token = await getToken();
    return axios.put(url, data, {
        baseURL: endpoint,
        withCredentials: true,
        headers: {
            'Content-Type': contentType,
            //'Authorization': `Bearer ${token}`
        }
    });
}

export async function del(url, endpoint) {
    //let token = await getToken();
    return axios.delete(url, {
        baseURL: endpoints.common,
        withCredentials: true,
        //headers: {
        //    'Authorization': `Bearer ${token}`
        //}
    });
}

export const Interceptor = (store) => {
    //Axios.interceptors.request.use(
    //    (conf) => {
    //        // you can add some information before send it.
    //        // conf.headers['Auth'] = 'some token'
    //        return conf;
    //    },
    //    (error) => {
    //        return Promise.reject(error);
    //    }
    //);
    axios.interceptors.response.use(
        (next) => {
            return Promise.resolve(next);
        },
        (error) => {
            if (error.response.status === 401 && store.getState().user.isAuthenticated) {
                store.dispatch({
                    type: 'NEEDS_LOGIN'
                });
            }
            return Promise.reject(error);
        }
    );
};
