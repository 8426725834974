import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import IconButtonFavorite from '../UI/IconButtonFavorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import TextBadge from '../UI/Badges/TextBadge';
import StatusBadge from './MessageStatus';
import { Grid, Box } from '@material-ui/core';
import { shortenString } from '../../utility/stringHelpers';
import { Link, withRouter } from 'react-router-dom';
import PersonBadge from '../UI/Badges/PersonBadge';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        borderRadius: 0,
        height: '100%',
        [theme.breakpoints.up('md')]: {
            width: 400,
            fontSize: '16px'
        },
    },
    media: {
        height: 140
    },
    favorite: {
        marginLeft: 'auto'
    },
    status: {

    },
    header: {
        display: 'flex',
        alignItems: 'center',
        height: '6.4rem',
        padding: '0.5rem',
        [theme.breakpoints.up('sm')]: {
            padding: '1rem',
        },
    },
    content: {
        height: 'auto',
        fontSize: '1.2rem',
        paddingBottom: '0',
        [theme.breakpoints.up('sm')]: {
            height: '5rem',
        },
    },
    fullHeight: {
        height: '100%'
    },
    messageFooter: {
        width: '100%',
    },
    titleContainer: {
        width: '100%',
        padding: '0.5rem',
        [theme.breakpoints.up('sm')]: {
            padding: '1rem',
        },
    },
    titleItem: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row'
        },
    },
    titleItemMsgNumber: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '0.5rem',
        [theme.breakpoints.up('sm')]: {
            marginRight: '0.5rem',
            marginBottom: '0rem',
        },
    },
    messageTittle: {
        color: '#000',
        fontSize: '0.875rem',
        fontWeight: 'bold',
    },
    messageFooterItem: {
        dispaly: 'flex',
    },
    messageFooterImg: {
        padding: 'none'
    }
}));


const MessagePreview = (props) => {
    const classes = useStyles();
    const messageNumber = props.number ? `№${props.number}` : `без номера`;
    const image = props.image ? `data:image/jpg;base64,${props.image}` : "/images/no-photo.jpg";
    let text = !props.text ? "" : props.text;
    text = text.length < 250
        ? text
        : (text.substring(0, 249) + "...");


    return (
        <Card className={classes.root} hoverable="true">

            <Grid container justify="space-between">
                
                <Grid item xs key="cardBody" container > 

                    <Grid container justify="space-between" alignItems="center" className={classes.titleContainer}>
                        <StatusBadge status={props.status} />
                        <Grid item className={classes.titleItem}>
                            <Grid item className={classes.titleItemMsgNumber}>{messageNumber}</Grid>
                            <Typography variant="body2" component="p" color="textSecondary" >
                                {props.date}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs>
                        <Link to={"/messages/" + props.id} >
                            <CardActionArea className={classes.fullHeight} >
                                <Grid container direction="column" justify="space-between" className={classes.fullHeight}>
                                    <Grid item className={classes.header}>
                                        <Typography color="textSecondary">
                                            {props.type}
                                        </Typography>

                                    </Grid>
                                    <Grid item>
                                        <Grid item>
                                            <CardMedia
                                                className={classes.media}
                                                image={image}
                                            />
                                        </Grid>
                                        <Grid item className={classes.contentWrap}>
                                            <CardContent className={classes.content}>
                                                <Typography component="h6" variable="h6" color="textPrimary" >
                                                    {shortenString(text, 120)}
                                                </Typography>
                                            </CardContent>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardActionArea>
                            </Link>
                        </Grid>
                </Grid>

                <Grid item key="cardActions" className={classes.messageFooter}>
                    <CardActions>
                        <Grid container justify="space-between">
                            <Grid item xs container justify="flex-start" alignItems="center">
                                <PersonBadge
                                    name={props.author}
                                />
                            </Grid>
                            <Grid item className={classes.messageFooterItem}>
                                <IconButton onClick={(event) => { event.stopPropagation(); window.location.href = "/messages/map?msg=" + props.gisRoGuid; }}>
                                    <LocationOnIcon  color="action" />
                                </IconButton>
                                <IconButtonFavorite
                                    isFavorite={props.isFavorite}
                                    className={classes.favorite}
                                    filled={props.isFavorite}
                                    onClick={props.onToggleIsFavoriteStatus}
                                />
                            </Grid>
                        </Grid>
                        
                        
                    </CardActions>
                </Grid>

            </Grid>       
        </Card>

    );
};

export default withRouter(MessagePreview);