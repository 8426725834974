import React from 'react';
import TextField from '@material-ui/core/TextField';
import { formatDate } from '../../../utility/formatters';

const input = (props) => {
    var hasError = Boolean(props.error);

    const onChange = (event) => {
        //console.log(event.target.value);
        //console.log(formatDate(new Date(Date.parse(event.target.value))));
        //event.target.value = formatDate(new Date(Date.parse(event.target.value)));
        //console.log(event.target.value);
        props.onChange(event);
    };

    return (
        <TextField
            id={props.id}
            label={props.label}
            value={props.value}
            type="date"
            required={props.validation && props.validation.required}
            error={hasError}
            style={props.style}
            fullWidth
            size={props.size}
            helperText={props.error}
            variant="outlined"
            onChange={props.onChange}
            onBlur={props.onBlur}
            InputLabelProps={{
                shrink: true,
            }}
        />
    );

};

export default input;
//import 'date-fns';
//import React from 'react';
//import DateFnsUtils from '@date-io/date-fns';
//import {
//    MuiPickersUtilsProvider,
//    KeyboardTimePicker,
//    KeyboardDatePicker,
//} from '@material-ui/pickers';

//export default function MaterialUIPickers() {
//    var hasError = Boolean(props.error);
//    return (
//        <MuiPickersUtilsProvider utils={DateFnsUtils}>
//            <KeyboardDatePicker
//                disableToolbar
//                variant="inline"
//                format="dd.MM.yyyy"
//                margin="normal"
//                id={props.id}
//                label={props.label}
//                value={props.value}
//                onChange={props.onChange}
//                KeyboardButtonProps={{
//                    'aria-label': 'изменить дату',
//                }}
//            /> 
//        </MuiPickersUtilsProvider>
//    );
//}