import React from 'react';
import Grid from '@material-ui/core/Grid';
import Comment from './Comment';
import { Typography } from '@material-ui/core';

const commentList = (props) => {
    let comments = null;
    if (props.comments.length) {
        comments = [...props.comments].sort((a, b) => {
            if (a.changeDate > b.changeDate) return -1;
            if (b.changeDate > a.changeDate) return 1;
            return 0;
        }).map((comment, index) => {
            return (
                <Grid item key={index} xs={12}>
                    <Comment
                        author={comment.author}
                        date={comment.changeDate}
                        text={comment.text}
                    />
                </Grid>
            );
        });
    }
    else {
        comments = (<Grid item xs><Typography variant="body2">Комментарии отсутствуют</Typography></Grid>);
    }
    return (
        <Grid container direction="column" justify="flex-start" spacing={1}>
            {comments}
        </Grid>
    );
};

export default commentList;