import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Filter from '../../../../components/UI/Filter';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions';


const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
}));

class MyMessagesFilter extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.statuses.length === 0) {
            this.props.onFetchMessageStatuses();

        }

    }

    componentDidUpdate(prevProps, prevState) {

    }

    handleFilterChange = (event, filterElementId) => {
    }

    render() {
        return (

            <Filter
                show={this.props.showFilter}
                filter={this.props.filter}
                form={this.props.form}
                values={this.props.filter}
                onFilterChange={this.props.onFilterChange}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        statuses: state.my.messageStatuses,
        filter: state.my.messageFilter,
        showFilter: state.my.showMessageFilter,
        form: {
            statusId: {
                value: "",
                elementType: "select",
                config: {
                    label: "Статус",
                    defaultDisplayValue: "Статус не выбран",
                    options: state.my.messageStatuses
                }
            }
        }
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFilterChange: (id, value) => dispatch(actions.setUserMessageFilterValue(id, value)),
        onFetchMessageStatuses: () => dispatch(actions.fetchPersonalAreaMessageStatuses()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyMessagesFilter);
