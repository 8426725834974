import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Filter from '../../../components/UI/Filter';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
}));

class MessageFilter extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.statuses.length === 0) {
            this.props.onFetchMessagePublicStatuses();
            
        }
        if (this.props.categories.length === 0) {
            this.props.onFetchMessageCategories();
        }
        
    }

    componentDidUpdate(prevProps, prevState) {
       
    }

    handleFilterChange = (event, filterElementId) => {
    }

    render() {
        console.log(this.props.show);
        return (
            <Filter
                show={this.props.show}
                filter={this.props.filter}
                form={this.props.form}
                values={this.props.filter}
                onFilterChange={this.props.onFilterChange}
            />
            
        );
    }
}

const mapStateToProps = state => {
    return {
        statuses: state.msg.publicStatuses,
        categories: state.msg.categories,
        filter: state.msg.filter,
        show: state.msg.showFilter,
        form: {
            statusId: {
                value: "",
                elementType: "select",
                config: {
                    label: "Статус",
                    defaultDisplayValue: "Статус не выбран",
                    options: state.msg.publicStatuses
                }
            },
            categoryId: {
                value: "",
                elementType: "select",
                config: {
                    label: "Категория",
                    defaultDisplayValue: "Категория не выбрана",
                    options: state.msg.categories
                }
            },
            beginDate: {
                value: true,
                elementType: "date",
                config: {
                    label: "C"
                }
            },
            endDate: {
                value: true,
                elementType: "date",
                config: {
                    label: "По"
                }
            }
        }
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFilterChange: (id, value) => dispatch(actions.setMessageFilterValue(id, value)),
        onFetchMessagePublicStatuses: () => dispatch(actions.fetchMessagePublicStatuses()),
        onFetchMessageCategories: () => dispatch(actions.fetchMessageCategories()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageFilter);
