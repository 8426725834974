import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import NewsPreview from './NewsPreview';

const newsList = (props) => {
    let news = props.shownNews.map((newsPreview, index) => {
        return (
            
            <Grid item key={index} xs={12}>
                <Link to={"/news/" + newsPreview.id}>
                    <NewsPreview
                        {...newsPreview}
                    />
                </Link>
            </Grid>
            
                
            );
    }); 
    const showMore = (props.enableShowMore && (props.totalFilteredNewsCount > props.maxPossibleNewsCount)) ? (
        <Grid item xs={12}>
            <Button variant="outlined" color="primary" onClick={props.onShowMore} style={{ display: "block", width: "100%" }}>
                Показать больше
            </Button>
        </Grid>) : null;
    return (
        <Grid container spacing={1} style={{width: "100%"}}>
            {news}
            {showMore}
        </Grid>
        
    );
};

export default withRouter(newsList);