import React, { Component } from 'react';
import GroupedList from '../../../components/UI/Containers/GroupedList';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import { groupBy } from '../../../utility/arrayHelpers';
import { Grid, Typography, Button } from '@material-ui/core';
import OutlinedContainer from '../../../components/UI/Containers/OutlinedContainer';
import Preloader from '../../../containers/Preloader';

class MunicipalSettlementMessageTypes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            boundMessageTypes: null,
            loading: true,
            messageTypes: [],
            canSubmit: false
        };
    }

    componentDidMount() {
        this.props.onFetchAllMessageTypes();
        if (!this.props.profileLoaded) {
            this.props.onFetchWorkProfile();
        } else {
            this.props.onFetchMessageTypesByTerritory(this.props.territory);
        }

    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.profileLoaded && this.props.profileLoaded) {
            this.props.onFetchMessageTypesByTerritory(this.props.territory);
        }
        else if (prevState.loading && !this.state.loading) {
            var checkedTypes = this.props.messageTypesByTerritory;
            let allMessageTypesByCategory = groupBy(this.props.allMessageTypes, item => item.categoryId, item => item.id, item => item.categoryTitle, item => item.title);
            console.log(allMessageTypesByCategory);
            Object.entries(allMessageTypesByCategory).forEach(([categoryId, category]) => {
                Object.entries(category.items).forEach(([typeId, type], ind, array) => {
                    allMessageTypesByCategory[categoryId].items[typeId].checked = (checkedTypes[categoryId] !== undefined)
                        && (checkedTypes[categoryId][typeId] !== undefined)
                });
            });
            this.setState({
                boundMessageTypes: allMessageTypesByCategory
            });
        }
        else if (this.state.loading && this.props.messageTypesByTerritory !== undefined && this.props.allMessageTypesLoaded) {
            this.setState({
                loading: false
            });
        }
    }

    getSelectedMessageTypes = (allMessageTypes) => {
        let array = [];
        Object.entries(allMessageTypes).forEach(([categoryId, category]) => {
            Object.entries(category.items).forEach(([typeId, type]) => {
                if (type.checked) {
                    array.push(+typeId);
                }
            });
        });
        return array;
    };

    handleChange = (messageTypes) => {
        this.setState({
            messageTypes: messageTypes,
            canSubmit: true
        });
    };

    handleSubmit = () => {
        this.props.onSetMessageTypes(this.props.territory, this.state.messageTypes);
    };

    changeMessageTypeState = (categoryId, messageTypeId, checked) => {
        var boundMessageTypes = { ...this.state.boundMessageTypes };
        var category = { ...this.state.boundMessageTypes[categoryId] };

        var updatedElement = { ...this.state.boundMessageTypes[categoryId].items[messageTypeId] };
        updatedElement.checked = checked;
        category.items[messageTypeId] = updatedElement;
        boundMessageTypes[categoryId] = category;
        this.setState({
            boundMessageTypes: boundMessageTypes
        }
            , () => {
                this.handleChange(this.getSelectedMessageTypes(this.state.boundMessageTypes));
            }
        );

    };

    render() {
        let messageTypes = (
            <Preloader fullScreen open color="primary"/>
        );
        if (this.state.boundMessageTypes) {
           messageTypes =  (
                <GroupedList
                    editable
                    items={this.state.boundMessageTypes}
                    onChange={this.changeMessageTypeState}
                    enableCheckAll
                />
            );
        }
        return (
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} sm={8}>
                    <OutlinedContainer>
                        <Grid container direction="column" spacing={2}>
                            <Grid item xs>
                                <Typography variant="h6" component="h6">
                                    Типы обрабатываемых сообщений
                            </Typography>
                                {messageTypes}
                            </Grid>
                            <Grid item xs>
                                <Button variant="contained" color="primary"
                                    onClick={() => this.handleSubmit()}
                                    disabled={!this.state.canSubmit}>
                                    Сохранить изменения
                        </Button>
                            </Grid>
                        </Grid>

                    </OutlinedContainer>
                </Grid>
                
            </Grid>
        );;
    }
};


const mapStateToProps = (state) => {
    let territory = state.profile.territories.length !== 0 ? state.profile.territories[0].id : null;
    return {
        territory: territory,
        messageTypesByTerritory: territory ? state.msg.messageTypesByTerritory[territory] : undefined,
        profileLoaded: state.profile.territories.length !== 0,
        allMessageTypes: state.msg.messageTypesWithCategories,
        allMessageTypesLoaded: state.msg.messageTypesWithCategories.length > 0
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchAllMessageTypes: () => dispatch(actions.fetchMessageTypesWithCategories()),
        onFetchMessageTypesByTerritory: (msId) => dispatch(actions.fetchMessageTypesByTerritory(msId)),
        onFetchWorkProfile: () => dispatch(actions.fetchUserWorkProfile()),
        onSetMessageTypes: (msId, messageTypes) => dispatch(actions.setTerritoryMessageTypes(msId, messageTypes))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MunicipalSettlementMessageTypes);