import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        position: "absolute",
        bottom: "0px",
        left: "0px",
        width: "100%",
        backgroundColor: "white",
        padding: theme.spacing(2),
        overflowX: "hidden",
        boxSizing: "border-box"
    },
}));


const MessageInfo = (props) => {
    const classes = useStyles();
    //props.text_of_message
    //props.current_status
    //props.posting_date
    //props.message_id
    return (
        <div key="info" className={classes.root} >
            <Grid container spacing={2}>
                <Grid item xs={12} container direction="row-reverse" alignItems="center">
                    <Grid item>
                        <IconButton aria-label="close" onClick={props.onClose} size="small">
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    </Grid>
                    <Grid item xs>
                        Дата: {props.posting_date}
                    </Grid>

                </Grid>
                <Grid item xs={12}>
                    Статус: {props.current_status}
                </Grid>
                <Grid item xs={12}>
                    Текст сообщения: {props.text_of_message}
                </Grid>
                <Grid item xs={12}>
                    <Link to={`/messages/${props.message_id}`}>подробнее...</Link>
                </Grid>
            </Grid>
            
            
        </div>
    );
};

export default MessageInfo;