import React, { Component } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdropContainer: {
        zIndex: '2'
    } 
}));

let Preloader = (props) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            {(props.fullScreen)
                ? <Backdrop className={classes.backdropContainer} open={props.open}>
                    <CircularProgress color={props.color ? props.color : "primary"} />
                </Backdrop>

                : <CircularProgress color={props.color ? props.color : "primary"} />
            } 
        </React.Fragment>
        )
}

export default Preloader; 