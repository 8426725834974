import React from 'react';
import MessageGrid from '../../../containers/Messages/MessageGrid/MessageGrid';
import MessageFilterToggler from '../../../containers/Messages/MessageFilterToggler';
import MessageFilter from '../../containers/Messages/MessageFilter'; 
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Link, withRouter } from 'react-router-dom';
import Can from '../../../components/Auth/Can';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    addBtn: {
        width: 'max-content'
    }
}));

const MessagesPage = (props) => {
    const classes = useStyles();
    return (
        <Grid container className="Messages" spacing={0} direction="column" justify="center">
            <Grid item key="actions" xs={12} container justify="space-between">
                <Can perform="messages:create" yes={() => (
                        <Grid item key="add" xs={12} sm={3}>
                            <Link to={{pathname: props.match.url + "/new"}}>
                            <Button color="primary" variant="contained" className={classes.addBtn}>
                                        Добавить сообщение
                                 </Button>
                            </Link>
                        </Grid>
                    )}
                />
                <Grid item key="filter" xs={12} sm={8}>
                    <MessageFilter />
                </Grid>
                <Grid item key="toggler" xs={12} sm={1} style={{ textAlign: "right" }}>
                    <MessageFilterToggler />
                </Grid>
                
            </Grid>
            <Grid item xs={12} style={{ marginTop: "20px" }}>
                <MessageGrid/>
            </Grid>

        </Grid>
    );
};

export default withRouter(MessagesPage);

