import React, { Component } from 'react';
import { Grid, Button, Avatar } from '@material-ui/core';
import NewOption from './NewOption';
import Option from './Option';
import FileUploadValidatable from '../../UI/FileUpload/FileUploadValidatable';
import TextArea from '../../UI/Inputs/TextArea';

class Options extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: []
        };
    }

    componentDidMount() {
    }

    handleOptionAdd = (option) => {
        var options = [...this.state.options];
        options.push({
            title: option.title,
            image: option.image
        });
        this.setState({
            showNewOption: false,
            options: options
        });
        this.props.onChange(options);
    }

    handleOptionDelete = (index) => {
        var options = [...this.state.options];
        options.splice(index, 1);
        this.setState({
            options: options
        });
        this.props.onChange(options);
    }

    handleAddOption = () => {
        let options = [...this.state.options];
        options.push({
            number: options.length + 1, 
            title: "",
            image: null
        });
        this.setState({
            options: options
        });
    }

    handleCancelOption = (index) => {
        let options = [...this.state.options];
        options.splice(index, 1);
        console.log(options);
        this.setState({
            options: options
        });
    }

    handleChangeOptionImage = (index, newImage) => {
        let updatedOptions = [...this.state.options];
        let updatedOption = { ...updatedOptions[index] };
        updatedOption.image = newImage;
        updatedOptions[index] = updatedOption;
        this.setState({
            options: updatedOptions
        });
    }

    handleChangeOptionText = (index, text) => {
        let updatedOptions = [...this.state.options];
        let updatedOption = { ...updatedOptions[index] };
        updatedOption.title = text;
        updatedOptions[index] = updatedOption;
        this.setState({
            options: updatedOptions
        });
    }

    handleDeleteOptionImage = (index) => {
        let updatedOptions = [...this.state.options];
        let updatedOption = { ...updatedOptions[index] };
        updatedOption.image = null;
        updatedOptions[index] = updatedOption;
        this.setState({
            options: updatedOptions
        });
    }

    handleSubmit = () => {
        if (this.state.options.length < 2) {
            this.props.onError("В голосовании или опросе должно быть минимум 2 пункта");
        }
        else if (this.state.options.filter(option => {
            return !option.title;
        }).length > 0) {
            this.props.onError("Не у всех пунктов заполнено поле \"Текст\"");
        } else {
            this.props.onSubmit(this.state.options);
        }
    }

    render() {
        let newOption = null;
        let btnAddOption = null;
        let options = null;
        if (this.state.showNewOption) {
            newOption = (
                <Grid item xs={12}>
                    <NewOption onAdd={this.handleOptionAdd} onError={this.props.onError} onCancel={this.handleCancel}/>
                </Grid>

            );
        } else {
            btnAddOption = (
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleAddOption}
                    >
                        Добавить пункт
                    </Button>
                </Grid>
            );
        }
        if (this.state.options.length > 0) {
            options = this.state.options.map((option, index) => {
                return (
                    <Grid item xs={12} key={index} container justify="space-between" alignItems="center" spacing={1}>

                        <Grid item xs={12} sm container spacing={1} alignItems="center">

                            <Avatar>{option.number}</Avatar>
                            <Grid item xs>
                                <TextArea
                                    autoFocus
                                    id="title"
                                    label="Текст"
                                    value={option.title}
                                    required
                                    rows={5}
                                    placeholder="Текст"
                                    onChange={(event) => this.handleChangeOptionText(index, event.target.value)}
                                    />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm>
                            <FileUploadValidatable
                                maxFilesCount={1}
                                fileType="image"
                                files={option.image ? [option.image] : []}
                                onFileAdd={(file) => this.handleChangeOptionImage(index, file)}
                                onFileDelete={() => this.handleDeleteOptionImage(index)}
                                addFileBtnText="Добавить изображение"
                                accept="image/png,image/jpeg"
                                maxTotalSizeInMegabytes={this.maxTotalFileSize}
                            />
                        </Grid>
                        
                        <Grid item style={{ textAlign: "right", maxWidth: 'none' }}>
                            <Button variant="contained" onClick={() => this.handleCancelOption(index)}>
                                отмена
                            </Button>

                        </Grid>
                    </Grid>
                );
            });
        }
        return (
            <Grid container spacing={2}>
                {options}
                {btnAddOption}
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        disabled={this.state.options.length < 2}
                        onClick={this.handleSubmit}
                        >
                            Далее
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

export default Options;