import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';

const textBadge = withStyles({
    root: {
        color: '#3f51b5',
        height: 'auto',
        padding: '5px 5px',
        lineHeight: 'normal',
        borderRadius: '15px',
        border: '1px solid #3f51b5',
        fontWeight: 'bold',
        background: 'none',
    }
})(Chip);

export default textBadge;