import React, { Component } from 'react';
import { Grid, Button, MenuItem, Popper, Grow, Paper, MenuList, ClickAwayListener, Divider } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Icon, InlineIcon } from '@iconify/react';

const CurrentTerritory = (props) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const handleMenuItemClicked = (event, territory) => {
        handleClose(event);
        props.onChange(territory);
    };

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false && anchorRef.current) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    if (!props.territories || !props.show) {
        return null;
    }

    let territory = props.territories.find(el => { return el.id == props.selectedTerritory; });
    let territoryTitle = territory ? territory.title : "Все территории";

    const territories = (
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList id="menu-list-grow">
                                <MenuItem key="all" onClick={(event) => handleMenuItemClicked(event, null)}>
                                    Все территории
                                </MenuItem>
                                {props.territories.map(ter => (
                                    <MenuItem key={ter.id} onClick={(event) => handleMenuItemClicked(event, ter.id)}>
                                        {ter.title}
                                    </MenuItem>

                                ))}
                            </MenuList>

                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );
    return (
        <React.Fragment>
            <Button ref={anchorRef} color="inherit" textDecoration="inherit" onClick={handleToggle}>
                {territoryTitle}
                <ExpandMoreIcon/>
            </Button>

            {territories}
        </React.Fragment>
    );
};

export default CurrentTerritory;