import React, { Component } from 'react';
import Filter from '../../../../components/UI/Filter';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions';

class MyVotingsFilter extends Component {

    componentDidMount() {
        if (this.props.statuses.length === 0) {
            this.props.onFetchVotingStatuses();

        }
    }

    componentDidUpdate(prevProps, prevState) {}

    handleFilterChange = (event, filterElementId) => {}

    render() {
        return (
            <Filter
                show={this.props.showFilter}
                filter={this.props.filter}
                form={this.props.form}
                values={this.props.filter}
                onFilterChange={this.props.onFilterChange}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        statuses: state.my.votingStatuses,
        filter: state.my.votingFilter,
        showFilter: state.my.showVotingFilter,
        form: {
            statusId: {
                value: "",
                elementType: "select",
                config: {
                    label: "Статус",
                    defaultDisplayValue: "Статус не выбран",
                    options: state.my.votingStatuses
                }
            }
        }
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFilterChange: (id, value) => dispatch(actions.setUserVotingFilterValue(id, value)),
        onFetchVotingStatuses: () => dispatch(actions.fetchPersonalAreaVotingStatuses())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyVotingsFilter);
