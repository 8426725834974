import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';

const IconButtonFavorite = (props) => {
    const isFavorite = props.isFavorite !== undefined ? props.isFavorite : false;
    return (
        <IconButton aria-label="В закладки" className={props.className} onClick={props.onClick}>
            {
                isFavorite ? <BookmarkIcon /> : <BookmarkBorderIcon />
            }
        </IconButton>);
};

export default IconButtonFavorite;