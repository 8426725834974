import React from 'react';
import MyMessages from '../../containers/PersonalCabinet/MyMessages/MyMessages';
import MyMessagesFilter from '../../containers/PersonalCabinet/MyMessages/MyMessagesFilter';
import MyMessagesFilterToggler from '../../containers/PersonalCabinet/MyMessages/MyMessagesFilterToggler';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Link, withRouter } from 'react-router-dom';
import Favorites from '../../containers/PersonalCabinet/Favorites';
import { Typography } from '@material-ui/core';
import FavoritesFilter from '../../containers/PersonalCabinet/FavoritesFilter';

const myMessagesPage = (props) => {

    return (
        <Grid container className="Favorites" spacing={0} direction="column" justify="center">
            <Grid item xs={12}>
                <Typography variant="h5" component="h5">
                    Личный кабинет: Избранное
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <FavoritesFilter/>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "20px" }}>
                <Favorites />
            </Grid>

        </Grid>
    );
};

export default withRouter(myMessagesPage);