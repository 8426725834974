import React from 'react';
import Modal from '@material-ui/core/Modal';
import { Container, Grid, makeStyles } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const useStyles = makeStyles((theme) => ({
    img: {
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        outline: "none",
        maxWidth: "90%",
        [theme.breakpoints.up('sm')]: {
            maxWidth: "70%",
            maxHeight: "70%",
        },
    },
    closeBtn: {
        position: 'absolute',
        top: '15px',
        right: '15px',
        color: '#fff',
        cursor: 'pointer',
    },

}));

const ImageModal = (props) => {
    const classes = useStyles();
    
    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
            style={{ outline: 0, border: 0}}
        >
            <Grid>
                <img src={props.image} className={classes.img} />
                <HighlightOffIcon className={classes.closeBtn} onClick={props.onClose}/>
            </Grid>
        </Modal>
    );
};

export default ImageModal;