import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    municipalSettlements: [],
    statistics: {
        loading: false,
        users: 0,
        finishedMessages: 0,
        messagesInWork: 0,
        finishedVotings: 0,
        votingsInProcess: 0
    },
    moderatorStatistics: {
        loading: false,
        deadlineMessages: 0,
        dislikedMessages: 0,
        finishedVotings: 0,
        likedMessages: 0,
        messages: 0,
        messagesInWork: 0,
        users: 0,
        votings: 0
    },
    performerStatistics: {
        loading: false,
        dislikedMessages: 0,
        expiredMessages: 0,
        likedMessages: 0,
        messages: 0,
        messagesInWork: 0
    },
};

const fetchMunicipalSettlementsSuccess = (state, action) => {
    return updateObject(state, {
        municipalSettlements: action.payload.map(munSet => { return { id: munSet.id, title: munSet.fullName, gisROGuid: munSet.gisROGuid};})
    });
};

const fetchPublicStatiticsStart = (state, action) => {
    return updateObject(state, {
        statistics: updateObject(state.statistics, {
            loading: true
        })
    });
};

const fetchPublicStatiticsSuccess = (state, action) => {
    return updateObject(state, {
        statistics: updateObject(state.statistics, {
            loading: false,
            ...action.payload
        })
    });
};

const fetchPublicStatiticsFail = (state, action) => {
    return updateObject(state, {
        statistics: updateObject(state.statistics, {
            loading: false
        })
    });
};

const fetchModeratorStatiticsStart = (state, action) => {
    return updateObject(state, {
        moderatorStatistics: updateObject(state.moderatorStatistics, {
            loading: true
        })
    });
};

const fetchModeratorStatiticsSuccess = (state, action) => {
    return updateObject(state, {
        moderatorStatistics: updateObject(state.moderatorStatistics, {
            loading: false,
            ...action.payload
        })
    });
};

const fetchModeratorStatiticsFail = (state, action) => {
    return updateObject(state, {
        moderatorStatistics: updateObject(state.moderatorStatistics, {
            loading: false
        })
    });
};


const fetchPerformerStatiticsStart = (state, action) => {
    return updateObject(state, {
        performerStatistics: updateObject(state.performerStatistics, {
            loading: true
        })
    });
};

const fetchPerformerStatiticsSuccess = (state, action) => {
    return updateObject(state, {
        performerStatistics: updateObject(state.performerStatistics, {
            loading: false,
            ...action.payload
        })
    });
};

const fetchPerformerStatiticsFail = (state, action) => {
    return updateObject(state, {
        performerStatistics: updateObject(state.performerStatistics, {
            loading: false
        })
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_MUNICIPAL_SETTLEMENTS_SUCCESS: return fetchMunicipalSettlementsSuccess(state, action);
        case actionTypes.FETCH_PUBLIC_STATISTICS_START: return fetchPublicStatiticsStart(state, action);
        case actionTypes.FETCH_PUBLIC_STATISTICS_SUCCESS: return fetchPublicStatiticsSuccess(state, action);
        case actionTypes.FETCH_PUBLIC_STATISTICS_FAIL: return fetchPublicStatiticsFail(state, action);
        case actionTypes.FETCH_MODERATOR_STATISTICS_START: return fetchModeratorStatiticsStart(state, action);
        case actionTypes.FETCH_MODERATOR_STATISTICS_SUCCESS: return fetchModeratorStatiticsSuccess(state, action);
        case actionTypes.FETCH_MODERATOR_STATISTICS_FAIL: return fetchModeratorStatiticsFail(state, action);
        case actionTypes.FETCH_PERFORMER_STATISTICS_START: return fetchPerformerStatiticsStart(state, action);
        case actionTypes.FETCH_PERFORMER_STATISTICS_SUCCESS: return fetchPerformerStatiticsSuccess(state, action);
        case actionTypes.FETCH_PERFORMER_STATISTICS_FAIL: return fetchPerformerStatiticsFail(state, action);
        default: return state;
    }
};

export default reducer;