import * as actionTypes from './actionTypes';
import messageApi from '../api/messageApi';
import { addSuccessNotification, addErrorNotification, addWarningNotification, showErrorPage } from './notify';


export const fetchMessageSuccess = (message) => {
    return {
        type: actionTypes.FETCH_MESSAGE_SUCCESS,
        message: message
    };
};

export const fetchMessageFail = (error) => {
    return {
        type: actionTypes.FETCH_MESSAGE_FAIL,
        error: error
    };
};

export const fetchMessageStart = () => {
    return {
        type: actionTypes.FETCH_MESSAGE_START
    };
};

export const fetchMessage = (id) => {
    return dispatch => {
        dispatch(fetchMessageStart());
        messageApi.get(id)
            .then(res => {
                dispatch(fetchMessageSuccess(res.data));
            })
            .catch(err => {
                if (err.response && err.response.status) {
                    dispatch(showErrorPage(err.response.status));
                }
                dispatch(fetchMessageFail(err));
            });
        };
};

export const fetchMessagePreviewsSuccess = (messages, skip) => {
    return {
        type: actionTypes.FETCH_MESSAGE_PREVIEWS_SUCCESS,
        messages: messages,
        skip: skip
        //favoriteIds: favoriteMessages.map(x => x.id)
    };
};

export const fetchMessagePreviewsFail = (error) => {
    return {
        type: actionTypes.FETCH_MESSAGE_PREVIEWS_FAIL,
        error: error
    };
};

export const fetchMessagePreviewsStart = (skip, take, filter) => {
    return {
        type: actionTypes.FETCH_MESSAGE_PREVIEWS_START,
        skip: skip,
        take: take,
        filter: filter
    };
};

export const resetMessages = () => {
    return {
        type: actionTypes.RESET_MESSAGES
    };
};

export const fetchTotalFilteredMessagesCountSuccess = (count) => {
    return {
        type: actionTypes.FETCH_TOTAL_FILTERED_MESSAGES_COUNT_SUCCES,
        count,
    }
}

export const fetchMessagePreviews = (skip, take) => {
    return (dispatch, getState) => {
        if (skip === 0) {
            dispatch(resetMessages());
        }
        const state = getState();
        const filter = state.msg.filter; 
        dispatch(fetchMessagePreviewsStart(skip, take, filter));
        messageApi.getMessagesPreviewsWithCount(skip, take, { ...filter }, state.my.territory)
            .then(res => {
                dispatch(fetchMessagePreviewsSuccess(res.data.collection, skip));
                dispatch(fetchTotalFilteredMessagesCountSuccess(res.data.count))
            })
            .catch(err => {
                console.log("error: ", err);
                dispatch(fetchMessagePreviewsFail(err));
            });
    };
};

export const fetchMessageDocumentSuccess = (data, title) => {
    const downloadUrl = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', title); //any other extension
    document.body.appendChild(link);
    link.click();
    link.remove();
    return {
        type: actionTypes.FETCH_MESSAGE_DOCUMENT_SUCCESS
    };
};

export const fetchMessageDocumentFail = (error) => {
    return {
        type: actionTypes.FETCH_MESSAGE_DOCUMENT_FAIL,
        error: error
    };
};

export const fetchMessageDocumentStart = () => {
    return {
        type: actionTypes.FETCH_MESSAGE_DOCUMENT_START
    };
};

export const fetchMessageDocument = (id, docId, docTitle) => {
    return dispatch => {
        dispatch(fetchMessageDocumentStart());
        messageApi.getDocument(id, docId)
            .then(res => {
                dispatch(fetchMessageDocumentSuccess(res.data, docTitle));
            })
            .catch(err => {
                dispatch(fetchMessageDocumentFail(err));
            });
    };
};

export const updateMessageFilterValue = (filter, value) => {
    return {
        type: actionTypes.SET_MESSAGE_FILTER_VALUE,
        filter: filter,
        value: value
    };
};

export const setMessageFilterValue = (filter, value) => {
    return (dispatch, getState) => {
        dispatch(updateMessageFilterValue(filter, value));
        var currentState = getState();
        dispatch(fetchMessagePreviews(currentState.msg.skip, currentState.msg.take));
    };
};

export const toggleMessageFilter = () => {
    return {
        type: actionTypes.TOGGLE_MESSAGE_FILTER
    };
};

export const setPrivateMessageFilterValue = (filter, value) => {
    console.log("setPrivateMessageFilterValue");
    return (dispatch, getState) => {
        dispatch(updateMessageFilterValue(filter, value));
        dispatch(fetchMessages());
    };
};


export const fetchMessageStatusesStart = () => {
    return {
        type: actionTypes.FETCH_MESSAGE_STATUSES_START
    };
};

export const fetchMessageStatusesSuccess = (statuses) => {
    return {
        type: actionTypes.FETCH_MESSAGE_STATUSES_SUCCESS,
        statuses: statuses
    };
};

export const fetchMessageStatusesFail = () => {
    return {
        type: actionTypes.FETCH_MESSAGE_STATUSES_FAIL
    };
};

export const fetchMessageStatuses = () => {
    return dispatch => {
        dispatch(fetchMessageStatusesStart());
        messageApi.getStatuses()
            .then(res => {
                dispatch(fetchMessageStatusesSuccess(res.data));
            })
            .catch(err => {
                dispatch(fetchMessageStatusesFail(err));
            });
    };
};

export const fetchMessagePublicStatusesStart = () => {
    return {
        type: actionTypes.FETCH_MESSAGE_PUBLIC_STATUSES_START
    };
};

export const fetchMessagePublicStatusesSuccess = (statuses) => {
    return {
        type: actionTypes.FETCH_MESSAGE_PUBLIC_STATUSES_SUCCESS,
        statuses: statuses
    };
};

export const fetchMessagePublicStatusesFail = () => {
    return {
        type: actionTypes.FETCH_MESSAGE_PUBLIC_STATUSES_FAIL
    };
};

export const fetchMessagePublicStatuses = () => {
    return dispatch => {
        dispatch(fetchMessagePublicStatusesStart());
        messageApi.getPublicStatuses()
            .then(res => {
                dispatch(fetchMessagePublicStatusesSuccess(res.data));
            })
            .catch(err => {
                dispatch(fetchMessagePublicStatusesFail(err));
            });
    };
};

export const fetchMessageCategoriesStart = () => {
    return {
        type: actionTypes.FETCH_MESSAGE_CATEGORIES_START
    };
};

export const fetchMessageCategoriesSuccess = (categories) => {
    return {
        type: actionTypes.FETCH_MESSAGE_CATEGORIES_SUCCESS,
        categories: categories
    };
};

export const fetchMessageCategoriesFail = () => {
    return {
        type: actionTypes.FETCH_MESSAGE_CATEGORIES_FAIL
    };
};

export const fetchMessageCategories = (munSetId) => {
    return dispatch => {
        dispatch(fetchMessageCategoriesStart());
        messageApi.getCategories(munSetId)
            .then(res => {
                dispatch(fetchMessageCategoriesSuccess(res.data));
            })
            .catch(err => {
                dispatch(fetchMessageCategoriesFail(err));
            });
    };
};

export const fetchMessageTypesStart = () => {
    return {
        type: actionTypes.FETCH_MESSAGE_TYPES_START
    };
};

export const fetchMessageTypesSuccess = (messageTypes) => {
    return {
        type: actionTypes.FETCH_MESSAGE_TYPES_SUCCESS,
        messageTypes: messageTypes
    };
};

export const fetchMessageTypesFail = () => {
    return {
        type: actionTypes.FETCH_MESSAGE_TYPES_FAIL
    };
};

export const fetchMessageTypes = (categoryId, munSetId) => {
    return dispatch => {
        dispatch(fetchMessageTypesStart());
        messageApi.getTypes(categoryId, munSetId)
            .then(res => {
                dispatch(fetchMessageTypesSuccess(res.data));
            })
            .catch(err => {
                dispatch(fetchMessageTypesFail(err));
            });
    };
};

export const fetchMessageTypesWithCategoriesStart = () => {
    return {
        type: actionTypes.FETCH_MESSAGE_TYPES_WITH_CATEGORIES_START
    };
};

export const fetchMessageTypesWithCategoriesSuccess = (messageTypes) => {
    return {
        type: actionTypes.FETCH_MESSAGE_TYPES_WITH_CATEGORIES_SUCCESS,
        messageTypes: messageTypes
    };
};

export const fetchMessageTypesWithCategoriesFail = () => {
    return {
        type: actionTypes.FETCH_MESSAGE_TYPES_WITH_CATEGORIES_FAIL
    };
};

export const fetchMessageTypesWithCategories = () => {
    return dispatch => {
        dispatch(fetchMessageTypesWithCategoriesStart());
        messageApi.getTypesWithCategories()
            .then(res => {
                dispatch(fetchMessageTypesWithCategoriesSuccess(res.data));
            })
            .catch(err => {
                dispatch(fetchMessageTypesWithCategoriesFail(err));
            });
    };
};

export const fetchPerformersStart = () => {
    return {
        type: actionTypes.FETCH_CONTRACTORS_START
    };
};

export const fetchPerformersSuccess = (performers) => {
    return {
        type: actionTypes.FETCH_CONTRACTORS_SUCCESS,
        performers: performers
    };
};

export const fetchPerformersFail = () => {
    return {
        type: actionTypes.FETCH_CONTRACTORS_FAIL
    };
};

export const fetchPerformers = (typeId, munSetId) => {
    return dispatch => {
        dispatch(fetchPerformersStart());
        messageApi.getPerformers(typeId, munSetId)
            .then(res => {
                dispatch(fetchPerformersSuccess(res.data));
            })
            .catch(err => {
                dispatch(fetchPerformersFail(err));
            });
    };
};


export const changeMessagesPageStart = (newPage) => {
    return {
        type: actionTypes.CHANGE_MESSAGES_PAGE_START,
        page: newPage
    };
};

export const changeMessagesPageFail = () => {
    return {
        type: actionTypes.CHANGE_MESSAGES_PAGE_FAIL
    };
};

export const changeMessagesPageSuccess = (messages) => {
    return {
        type: actionTypes.CHANGE_MESSAGES_PAGE_SUCCESS,
        messages: messages
    };
};

export const fetchMessageCount = (count) => {
    return {
        type: actionTypes.FETCH_MESSAGE_COUNT,
        count: count
    };
};

const getMessageFilter = (filter) => {

    return {
        categoryId: filter.categoryId,
        typeId: filter.messageTypeId,
        beginDate: filter.sendDate ? filter.sendDate.begin : '',
        endDate: filter.sendDate ? filter.sendDate.end: '',
        beginAnswerDate: filter.answerDate ? filter.answerDate.begin : '',
        endAnswerDate: filter.answerDate ? filter.answerDate.end : '',
        statusId: filter.statusId
    };
};

export const changeMessagesPage = (newPage) => {
    return (dispatch, getState) => {
        dispatch(changeMessagesPageStart(newPage));
        dispatch(fetchMessages());
    };
};

export const fetchMessages = () => {
    return (dispatch, getState) => {
        const currentState = getState();
        const skip = currentState.msg.page * currentState.msg.messagesPerPage;
        const filter = getMessageFilter(currentState.msg.filter);
        Promise.all(
            [messageApi.getCount(filter),
            messageApi.getList(skip, currentState.msg.messagesPerPage, filter)]
        )
            .then(res => {
                dispatch(fetchMessageCount(res[0].data));
                dispatch(changeMessagesPageSuccess(res[1].data));
            })
            .catch(err => {
                dispatch(changeMessagesPageFail(err));
            });
    };
};

export const initMessagePrivateFilter = () => {
    return {
        type: actionTypes.INIT_MESSAGE_PRIVATE_FILTER
    };
};

export const initMessagePublicFilter = () => {
    return {
        type: actionTypes.INIT_MESSAGE_PUBLIC_FILTER
    };
};

export const postCommentStart = () => {
    return {
        type: actionTypes.POST_MESSAGE_COMMENT_START
    };
};

export const postCommentSuccess = () => {
    return {
        type: actionTypes.POST_MESSAGE_COMMENT_SUCCESS
    };
};

export const postCommentFail = (error) => {
    return {
        type: actionTypes.POST_MESSAGE_COMMENT_FAIL,
        error: error
    };
};

export const changeMaxPossibleMessagesCount = () => {
    return {
        type: actionTypes.CHANGE_TOTAL_MESSAGESS_COUNT,
    };
}

export const postComment = (messageId, commentText) => {
    return dispatch => {
        dispatch(postCommentStart());
        messageApi.postComment({
            messageId: messageId,
            text: commentText
        })
            .then(result => {
                console.log(result.data);
                if (result.data.success) { //убрать
                    dispatch(postCommentSuccess());
                    dispatch(fetchMessageComments(messageId));
                    dispatch(addSuccessNotification("Комментарий успешно добавлен"));
                }
                else {
                    
                    dispatch(postCommentFail());
                }
            })
            .catch(err => {
                console.log("fail ", err);
                dispatch(postCommentFail(err));
                dispatch(addErrorNotification("При отправке комментария произошла ошибка"));
            });
    };
};

export const sendToReworkStart = () => {
    return {
        type: actionTypes.SEND_MESSAGE_TO_REWORK_START
    };
};

export const sendToReworkSuccess = () => {
    return {
        type: actionTypes.SEND_MESSAGE_TO_REWORK_SUCCESS
    };
};

export const sendToReworkFail = (error) => {
    return {
        type: actionTypes.SEND_MESSAGE_TO_REWORK_FAIL,
        error: error
    };
};

export const sendToRework = (messageId, commentText) => {
    return dispatch => {
        dispatch(postComment(messageId, commentText));
        dispatch(sendToReworkStart());
        messageApi.sendToRework(messageId)
            .then(result => {
                dispatch(sendToReworkSuccess());
                dispatch(addSuccessNotification("Ответ отправлен на доработку исполнителю"));
            })
            .catch(err => {
                dispatch(sendToReworkFail(err));
                dispatch(addErrorNotification("При отправке сообщения на доработку произошла ошибка"));
            });
    };
};

export const initMessageModerationForm = (message) => {
    return {
        type: actionTypes.INIT_MESSAGE_MODERATION_FORM,
        message: message
    };
};

export const fetchMessageCommentsStart = (messageId) => {
    return {
        type: actionTypes.FETCH_MESSAGE_COMMENTS_START,
        messageId: messageId
    };
};

export const fetchMessageCommentsSuccess = (messageId, comments) => {
    return {
        type: actionTypes.FETCH_MESSAGE_COMMENTS_SUCCESS,
        messageId: messageId,
        comments: comments
    };
};

export const fetchMessageCommentsFail = (messageId, error) => {
    return {
        type: actionTypes.FETCH_MESSAGE_COMMENTS_FAIL,
        messageId: messageId,
        error: error
    };
};

export const fetchMessageComments = (messageId) => {
    return dispatch => {
        dispatch(fetchMessageCommentsStart(messageId));
        messageApi.getComments(messageId)
            .then(result => {
                dispatch(fetchMessageCommentsSuccess(messageId, result.data));
            })
            .catch(err => {
                dispatch(fetchMessageCommentsFail(messageId, err));
            });
    };
};

export const setMessagePerformerSuccess = (messageId) => {
    return {
        type: actionTypes.SET_MESSAGE_CONTRACTOR_SUCCESS,
        messageId: messageId
    };
};

export const setMessagePerformerFail = (messageId, error) => {
    return {
        type: actionTypes.SET_MESSAGE_CONTRACTOR_FAIL,
        messageId: messageId,
        error: "При сохранении возникла ошибка"
    };
};
export const setMessagePerformer = (messageId, performerId, categoryId, messageTypeId) => {
    return dispatch => {
        messageApi.setPerformer(messageId, performerId, categoryId, messageTypeId)
            .then(result => {
                dispatch(setMessagePerformerSuccess(messageId));
                dispatch(addSuccessNotification("Статус сообщения успешно изменён"));
                dispatch(fetchMessage(messageId));
            })
            .catch(err => {
                dispatch(setMessagePerformerFail(messageId, err));
                dispatch(addErrorNotification());
            });
    };
};

export const fetchMessageResponseStart = (messageId) => {
    return {
        type: actionTypes.FETCH_MESSAGE_RESPONSE_START,
        messageId: messageId
    };
}; 


export const fetchMessageResponseSuccess = (response) => {
    return {
        type: actionTypes.FETCH_MESSAGE_RESPONSE_SUCCESS,
        response: response
    };
};

export const fetchMessageResponseFail = (messageId, error) => {
    return {
        type: actionTypes.FETCH_MESSAGE_RESPONSE_FAIL,
        messageId: messageId,
        error: error
    };
};

export const fetchMessageResponse = (messageId) => {
    return (dispatch) => {
        dispatch(fetchMessageResponseStart(messageId));
        messageApi.getResponse(messageId)
            .then(response => {
                dispatch(fetchMessageResponseSuccess(response.data));
                
            })
            .catch(err => {
                dispatch(fetchMessageResponseFail(messageId, err));
                dispatch(addErrorNotification());
            });
    };
};

export const rejectMessageStart = (messageId) => {
    return {
        type: actionTypes.REJECT_MESSAGE,
        messageId: messageId
    };
};

export const rejectMessageSuccess = (messageId) => {
    return {
        type: actionTypes.REJECT_MESSAGE_SUCCESS,
        messageId: messageId
    };
};

export const rejectMessageFail = (messageId, error) => {
    return {
        type: actionTypes.REJECT_MESSAGE_FAIL,
        messageId: messageId,
        error: error
    };
};

export const rejectMessage = (messageId, reason) => {
    return (dispatch) => {
        dispatch(rejectMessageStart(messageId));
        messageApi.reject(messageId, reason)
            .then(response => {
                dispatch(rejectMessageSuccess(messageId));
                dispatch(addSuccessNotification("Статус сообщения успешно изменён"));
                dispatch(fetchMessage(messageId));
            })
            .catch(err => {
                dispatch(rejectMessageFail(messageId, err));
                dispatch(addErrorNotification());
            });
    };
};

export const approveMessageResponseStart = (messageId) => {
    return {
        type: actionTypes.APPROVE_MESSAGE_RESPONSE,
        messageId: messageId
    };
};

export const approveMessageResponseSuccess = (messageId) => {
    return {
        type: actionTypes.APPROVE_MESSAGE_RESPONSE_SUCCESS,
        messageId: messageId
    };
};

export const approveMessageResponseFail = (messageId, error) => {
    return {
        type: actionTypes.APPROVE_MESSAGE_RESPONSE_FAIL,
        messageId: messageId,
        error: error
    };
};

export const approveMessageResponse = (messageId) => {
    return (dispatch) => {
        dispatch(approveMessageResponseStart(messageId));
        messageApi.approve(messageId)
            .then(response => {
                dispatch(approveMessageResponseSuccess(messageId));
                dispatch(addSuccessNotification("Статус сообщения успешно изменён"));
                dispatch(fetchMessage(messageId));
            })
            .catch(err => {
                dispatch(approveMessageResponseFail(messageId, err));
                dispatch(addErrorNotification());
            });
    };
};

export const postMessageResponseStart = (messageId) => {
    return {
        type: actionTypes.POST_MESSAGE_RESPONSE,
        messageId: messageId
    };
};

export const postMessageResponseSuccess = (messageId) => {
    return {
        type: actionTypes.POST_MESSAGE_RESPONSE_SUCCESS,
        messageId: messageId
    };
};

export const postMessageResponseFail = (messageId, err) => {
    return {
        type: actionTypes.POST_MESSAGE_RESPONSE_SUCCESS,
        messageId: messageId,
        error: err
    };
};

export const postMessageResponse = (messageId, data) => {
    return (dispatch) => {
        dispatch(postMessageResponseStart(messageId));
        messageApi.postResponse(messageId, data)
            .then(response => {
                dispatch(postMessageResponseSuccess(messageId));
                dispatch(addSuccessNotification("Ответ успешно отправлен на модерацию"));
                //dispatch(fetchMessage(messageId));
                dispatch(fetchMessageResponse(messageId));
            })
            .catch(err => {
                dispatch(postMessageResponseFail(messageId, err));
                dispatch(addErrorNotification());
            });
    };
};

export const fetchResponseStatusesStart = () => {
    return {
        type: actionTypes.FETCH_RESPONSE_STATUSES_START
    };
};

export const fetchResponseStatusesSuccess = (statuses) => {
    return {
        type: actionTypes.FETCH_RESPONSE_STATUSES_SUCCESS,
        statuses: statuses
    };
};

export const fetchResponseStatusesFail = () => {
    return {
        type: actionTypes.FETCH_RESPONSE_STATUSES_FAIL
    };
};

export const fetchResponseStatuses = () => {
    return dispatch => {
        dispatch(fetchResponseStatusesStart());
        messageApi.getResponseStatuses()
            .then(res => {
                dispatch(fetchResponseStatusesSuccess(res.data));
            })
            .catch(err => {
                dispatch(fetchResponseStatusesFail(err));
            });
    };
};

export const setMessageMapFilterValue = (filter, value) => {
    return {
        type: actionTypes.SET_MESSAGE_MAP_FILTER_VALUE,
        filter: filter,
        value: value
    };
};

export const toggleMessageMapFilter = () => {
    return {
        type: actionTypes.TOGGLE_MESSAGE_MAP_FILTER
    };
};

export const postMessageTypeStart = () => {
    return {
        type: actionTypes.POST_MESSAGE_TYPE_START
    };
};
export const postMessageTypeSuccess = () => {
    return {
        type: actionTypes.POST_MESSAGE_TYPE_SUCCESS
    };
};
export const postMessageTypeFail = ( err) => {
    return {
        type: actionTypes.POST_MESSAGE_TYPE_FAIL,
        error: err
    };
};


export const postMessageType = (categoryId, title) => {
    return (dispatch) => {
        dispatch(postMessageTypeStart());
        messageApi.postMessageType(categoryId, title)
            .then(response => {
                dispatch(postMessageTypeSuccess());
                dispatch(addSuccessNotification("Тип успешно создан"));
            })
            .catch(err => {
                dispatch(postMessageTypeFail(err));
                dispatch(addErrorNotification());
            });
    };
};

export const setMessageIsFavoriteStatusSuccess = (messageId, isFavorite) => {
    return {
        type: actionTypes.SET_MESSAGE_IN_FAVORITES_STATE_SUCCESS,
        messageId,
        isFavorite
    };
};

export const setMessageIsFavoriteStatus = (messageId, isFavorite) => {
    return (dispatch, getState) => {
        const state = getState(); 

        if (state.user.isAuthenticated) {
            messageApi.setIsFavorites(messageId, isFavorite)
                .then(result => {
                    //dispatch(updateMessage(messageId, isFavorite, isMessageDetails));
                    dispatch(setMessageIsFavoriteStatusSuccess(messageId, isFavorite));
                    isFavorite
                        ? dispatch(addSuccessNotification("Сообщение добавлено в избранное"))
                        : dispatch(addWarningNotification("Сообщение удалено из избранного"));
                })
                .catch(err => {
                    dispatch(addErrorNotification());
                });

        } else {
            dispatch(addWarningNotification("необходимо авторизоваться"));
        }
    };
};

export const assessMessageResponseSuccess = (messageId, like) => {
    return {
        type: actionTypes.ASSESS_MESSAGE_RESPONSE_SUCCESS,
        messageId,
        like
    };
};

export const assessMessageResponse = (messageId, like) => {
    return (dispatch) => {
        messageApi.assessResponse(messageId, like)
            .then(result => {
                dispatch(assessMessageResponseSuccess(messageId, like));
                dispatch(fetchMessage(messageId));
                dispatch(addSuccessNotification("Оценка отправлена"));
            })
            .catch(err => {
                dispatch(addErrorNotification("При попытке отправки оценки произошла ошибка"));
            });

    };
};

export const fetchMessageTypesByTerritorySuccess = (msId, msgTypes) => {
    return {
        type: actionTypes.FETCH_MESSAGE_TYPES_BY_TERRITORY,
        messageTypes: msgTypes,
        msId: msId
    };
};

export const fetchMessageTypesByTerritory = (msId) => {
    return (dispatch, getState) => {
        let msgTypesByTer = getState().msg.messageTypesByTerritory[msId];
        if (msgTypesByTer === undefined) {
            messageApi.getGroupedMessageTypes(msId)
                .then(res => {
                    dispatch(fetchMessageTypesByTerritorySuccess(msId, res.data));
                })
                .catch(err => {
                    dispatch(addErrorNotification("При загрузке типов сообщений, активных на выбранной территории, произошла ошибка"));
                });
            
        }
    };
};

const setTerritoryMessageTypesStart = () => {
    return {
        type: actionTypes.SET_PERFORMER_MESSAGE_TYPES_START
    };
};

const setTerritoryMessageTypesSuccess = (msId) => {
    return {
        type: actionTypes.SET_PERFORMER_MESSAGE_TYPES_SUCCESS,
        msId: msId
    };
};

const setTerritoryMessageTypesFail = () => {
    return {
        type: actionTypes.SET_PERFORMER_MESSAGE_TYPES_FAIL
    };
};

export const setTerritoryMessageTypes = (msId, types) => {
    return dispatch => {
        dispatch(setTerritoryMessageTypesStart());
        messageApi.setTypesOnTerritory(msId, types)
            .then(res => {
                dispatch(setTerritoryMessageTypesSuccess(msId));
                dispatch(fetchMessageTypesByTerritory(msId));
                dispatch(addSuccessNotification());
            })
            .catch(err => {
                dispatch(setTerritoryMessageTypesFail());
                dispatch(addErrorNotification("Не удалось привязать один или несколько типов к территории"));
            });
    };
};

export const fetchMessageMapStatusesStart = () => {
    return {
        type: actionTypes.FETCH_MESSAGE_MAP_STATUSES_START
    };
};

export const fetchMessageMapStatusesSuccess = (statuses) => {
    return {
        type: actionTypes.FETCH_MESSAGE_MAP_STATUSES_SUCCESS,
        statuses: statuses
    };
};

export const fetchMessageMapStatusesFail = () => {
    return {
        type: actionTypes.FETCH_MESSAGE_MAP_STATUSES_FAIL
    };
};

export const fetchMessageMapStatuses = () => {
    return dispatch => {
        dispatch(fetchMessageMapStatusesStart());
        messageApi.getMapStatuses()
            .then(res => {
                dispatch(fetchMessageMapStatusesSuccess(res.data));
            })
            .catch(err => {
                dispatch(fetchMessageMapStatusesFail(err));
            });
    };
};

export const sendAllMessages = () => {
    return dispatch => {
        messageApi.sendAllMessages()
            .then(res => {
                console.log(res)
                dispatch(addSuccessNotification("Успешно отправлено"));
            })
            .catch(err => {
                dispatch(addErrorNotification("При отправке в ПОС, произошла ошибка"));
            });
    };
};