import React from "react";
import { GridList, GridListTile } from '@material-ui/core';
import ImageModal from './Modals/ImageModal';
import { withRouter } from 'react-router-dom';
import Image from './Image';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    attachedImgContainer: {
        width: '120px !important',
        height: '120px !important',
        marginRight: '1rem'
    }
}));


const ImageGrid = (props) => {
    const classes = useStyles();
    return (
        <GridList>
            {props.images.map((image, index) => (

                <GridListTile key={index} cols={1} className={classes.attachedImgContainer}>
                    <Image id={image.id}  value={image.value || image.digitalView} />
                </GridListTile>
            ))}
        </GridList>
    );
};

export default ImageGrid;