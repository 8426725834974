import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './AppBar.css';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import { Menu, MenuItem, Grid } from '@material-ui/core';
import { NavLink, withRouter } from 'react-router-dom';
import LoginButton from '../../containers/Account/LoginButton';
import { isMobile } from 'react-device-detect';
import CurrentTerritory from '../../public/containers/PersonalCabinet/CurrentTerritory';

const useStyles = makeStyles((theme) =>({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        //borderBottom: "2px solid white",
        //borderRadius: 0
    },
    title: {
        flexGrow: 0,
    },
    grow: {
        flexGrow: 1,
    },
    link: {
        color: "inherit",
        textDecoration: "inherit"
    },
    linkMobile: {
        color: "inherit",
        textDecoration: "inherit",
        width: "100%"
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

const AppBarComponent = (props) => {
    const classes = useStyles();
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const [userWindowWidth, setUserWindowWidth] = useState(window.innerWidth);

    const mobileMenuId = 'primary-search-account-menu-mobile';

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    window.onresize = () => {
        setUserWindowWidth(window.innerWidth);
    };

    let mobileMenu = (userWindowWidth < 960) ? true : false;

    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleLoginButtonClick = () => {
        window.location.href = process.env.REACT_APP_ACCOUNT_URL + "/login?returnUrl=" + window.location.origin;
    };

    const getSelectedLink = (links) => {
        let selectedLinks = links.filter(link => { return link.to === props.location.pathname; });
        return selectedLinks.length > 0 ? selectedLinks[0] : null;
    };

    const links = props.menuItems;
    const selectedLink = getSelectedLink(links);
    let selectedLinkHeader = !selectedLink ? null
        : (
            <Button className={classes.menuButton} color="inherit">
                {selectedLink.title}
            </Button>
        );

    const renderMobileMenu = (!isMobile && !mobileMenu ) ? null : (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <NavLink exact to="/" className={classes.link}>
                <MenuItem>
                    Главная
                </MenuItem>
            </NavLink>
            {
                links.map(link => (
                    <NavLink key={link.to} to={link.to} className={classes.link} onClick={handleMobileMenuClose}>
                        <MenuItem >
                            {link.title}
                        </MenuItem>
                    </NavLink>
                ))
            }
        </Menu>
    );
    
    return (
        <React.Fragment>
            <AppBar position="static" id="AppBar">
                <Toolbar >
                    <div className={classes.sectionMobile}>

                        <IconButton
                            aria-label="показать больше пунктов"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        {selectedLinkHeader}
                        <div>
                            <LoginButton className={classes.menuButton} onLogin={handleLoginButtonClick} isMobile={isMobile} />
                        </div>
                    </div>
                    <Grid container justify="space-between" alignItems="center" className={classes.sectionDesktop}>
                        <Grid item>
                            <NavLink exact to="/" className={classes.link}>
                                <Button className={classes.menuButton} color="inherit">
                                    Главная
                            </Button>
                            </NavLink>
                            {
                                links.map(link => (
                                    <NavLink key={link.to} to={link.to} className={classes.link}>
                                        <Button className={classes.menuButton} color="inherit">
                                            {link.title}
                                        </Button>
                                    </NavLink>
                                ))
                            }
                        </Grid>
                        <Grid item>
                            <Grid container>
                                <Grid item style={{margin: 'auto'}}>
                                    <CurrentTerritory />
                                </Grid>
                                <Grid item>
                                    <LoginButton className={classes.menuButton} onLogin={handleLoginButtonClick} isMobile={isMobile} />
                                </Grid>
                            </Grid>
                            
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            { renderMobileMenu }
        </React.Fragment>
    );
};

export default withRouter(React.memo(AppBarComponent));