import React, { Component } from 'react';
import { Grid, Button, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Input from '../../components/UI/Inputs/Input';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import FileUploadValidatable from '../../components/UI/FileUpload/FileUploadValidatable';
import { withRouter } from 'react-router-dom';
import { formatDate } from '../../utility/formatters';
import Can from '../../components/Auth/Can';
import { insertValueIntoArrayElement, deleteValueFromArrayElement } from '../../utility/formHelpers';

class NewVoting extends Component {

    constructor(props) {
        super(props);
        this.maxDescriptionSize = 700;
        this.maxTotalFileSize = 5;
        this.maxFilesCount = 5;
        this.state = {
            municipalSettlements: [],
            types: [],
            form: {
                image: null,
                documents: [],
                typeId: {
                    value: "",
                    elementType: "select",
                    config: {
                        label: "Тип",
                        options: []
                    },
                    validation: {
                        required: true
                    },
                    error: "",
                    valid: false
                },
                municipalSettlementId: {
                    value: "",
                    elementType: "select",
                    config: {
                        label: "Территория проведения",
                        defaultDisplayValue: "Территория не выбрана",
                        options: []
                    },
                    validation: {
                        required: true
                    },
                    error: "",
                    valid: false
                },
                authority: {
                    value: "",
                    elementType: "textbox",
                    config: {
                        label: "Орган власти",
                    },
                    validation: {
                        required: false
                    },
                    error: "",
                    valid: true
                },
                header: {
                    value: "",
                    elementType: "textarea",
                    config: {
                        label: "Заголовок",
                    },
                    validation: {
                        required: true
                    },
                    error: "",
                    valid: false
                },
                description: {
                    value: "",
                    elementType: "textarea",
                    config: {
                        label: "Описание",
                        placeholder: `Суть голосования/опроса (не более ${this.maxDescriptionSize} символов)`,
                        maxLength: this.maxDescriptionSize,
                        rows: 10
                    },
                    validation: {
                        required: true
                    },
                    error: "",
                    valid: false
                },
                endDate: {
                    value: "",
                    minDate: "",
                    elementType: "updatedDate",
                    config: {
                        label: "Дата окончания голосования"
                    },
                    validation: {
                        required: true
                    },
                    error: "",
                    valid: false,
                    disablePast: true
                },
                debriefingDate: {
                    value: "",
                    minDate: "",
                    elementType: "updatedDate",
                    config: {
                        label: "Дата подведения итогов"
                    },
                    validation: {
                        required: true
                    },
                    error: "",
                    valid: false,
                    disablePast: true
                },
                startDate: {
                    value: "",
                    minDate: "",
                    elementType: "updatedDate",
                    config: {
                        label: "Дата начала голосования"
                    },
                    validation: {
                        required: true
                    },
                    error: "",
                    valid: false,
                    disablePast: true
                }
                
            },
            formIsValid: false,
            lettersLeft: this.maxDescriptionSize,
            type: ""
        };
    }

    componentDidMount() {
        this.props.onFetchMunicipalSettlements();
        this.props.onFetchTypes();

        let date = new Date();
        let startDate = formatDate(date);

        let fullEndDate = new Date(date.setMonth(date.getMonth() + 1));
        let endDate = formatDate(fullEndDate);

        let fullDebriefingDate = new Date(fullEndDate.setDate(fullEndDate.getDate() + 1));
        let debriefingDate = formatDate(fullDebriefingDate);

        let updatedForm = { ...this.state.form };
        let updatedStartDate = { ...this.state.form["startDate"] };
        let updatedEndDate = { ...this.state.form["endDate"] };
        let updatedDebriefingDate = { ...this.state.form["debriefingDate"] };
        updatedStartDate.value = startDate;
        updatedEndDate.value = endDate;
        updatedDebriefingDate.value = debriefingDate;

        updatedEndDate.minDate = startDate;
        updatedEndDate.minDate = endDate;
        updatedDebriefingDate.minDate = debriefingDate;

        updatedForm["startDate"] = updatedStartDate;
        updatedForm["endDate"] = updatedEndDate;
        updatedForm["debriefingDate"] = updatedDebriefingDate;
        this.setState({ form: updatedForm });
    }

    componentDidUpdate(prevProps) {
        if (this.props.created) {
            this.props.history.push(`/messages`);
        }
    }

    handleStartDateChange = (date, element) => {

        let startDate = formatDate(date);

        this.handleFieldChange(startDate, element);

        if (this.state.form.startDate.value !== startDate) {

   
            let fullEndDate = new Date(date.setMonth(date.getMonth() + 1));
            let endDate = formatDate(fullEndDate);

            let fullDebriefingDate = new Date(fullEndDate.setDate(fullEndDate.getDate() + 1));
            let debriefingDate = formatDate(fullDebriefingDate);

            let updatedForm = { ...this.state.form };
            let updatedStartDate = { ...this.state.form["startDate"] };
            let updatedEndDate = { ...this.state.form["endDate"] };
            let updatedDebriefingDate = { ...this.state.form["debriefingDate"] };
            updatedStartDate.value = startDate;
            updatedEndDate.value = endDate;
            updatedDebriefingDate.value = debriefingDate;

            updatedEndDate.minDate = endDate;
            updatedDebriefingDate.minDate = debriefingDate;

            updatedForm["startDate"] = updatedStartDate;
            updatedForm["endDate"] = updatedEndDate;
            updatedForm["debriefingDate"] = updatedDebriefingDate;
            this.setState({ form: updatedForm });
        }
    }

    handleEndDateChange = (date, element) => {

        let endDate = formatDate(date);

        this.handleFieldChange(endDate, element);

        if (this.state.form.endDate.value !== endDate) {

            let fullDebriefingDate = new Date(date.setDate(date.getDate() + 1));
            let debriefingDate = formatDate(fullDebriefingDate);

            let updatedForm = { ...this.state.form };
            let updatedEndDate = { ...this.state.form["endDate"] };
            let updatedDebriefingDate = { ...this.state.form["debriefingDate"] };
            updatedDebriefingDate.value = debriefingDate;
            updatedEndDate.value = endDate;

            updatedDebriefingDate.minDate = debriefingDate;

            updatedForm["endDate"] = updatedEndDate;
            updatedForm["debriefingDate"] = updatedDebriefingDate;
            this.setState({ form: updatedForm });
        }
    }


    handleCancel = () => {
        this.props.history.goBack();
    }

    handleFormElementBlur = (element) => { }

    handleImageAdd = (image) => {
        let updatedForm = { ...this.state.form };
        updatedForm.image = image;
        this.setState({
            form: updatedForm
        });
    };

    handleImageDelete = () => {
        let updatedForm = { ...this.state.form };
        updatedForm.image = null;
        this.setState({
            form: updatedForm
        });
    };

    handleDocumentAdd = (document) => {
        var updatedForm = insertValueIntoArrayElement(this.state.form, "documents", document);
        this.setState({
            form: updatedForm
        });
    };

    handleDocumentDelete = (index) => {
        var updatedForm = deleteValueFromArrayElement(this.state.form, "documents", index);
        this.setState({
            form: updatedForm
        });
    };

    handleFieldChange = (value, element) => {
        let updatedForm = { ...this.state.form };
        let updatedElement = { ...this.state.form[element] };
        updatedElement.value = value;
        updatedForm[element] = updatedElement;
        this.setState({ form: updatedForm }, this.validateForm);
        
    }

    handleError(errorText) {
        this.props.onNotify(errorText);
    }

    handleOptionsChange = (options) => {
        let updatedForm = { ...this.state.form };
        updatedForm.options = options;
        this.setState({
            form: updatedForm
        });
    }

    validateForm = () => {
        var isValid = true;
        let element = null;
        for (var elementName in this.state.form) {
            element = this.state.form[elementName];
            if (element && element.validation && element.validation.required && !element.value) {
                isValid = false;
                break;
            }
        }
        this.setState({
            formIsValid: isValid
        });
    }

    renderImageInput() {
        return (
            <Grid container direction="column">
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom style={{ display: "block", textAlign: 'left' }}>
                        Изображение
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FileUploadValidatable
                        maxFilesCount={1}
                        fileType="image"
                        files={this.state.form.image ? [this.state.form.image] : []}
                        onFileAdd={this.handleImageAdd}
                        onFileDelete={this.handleImageDelete}
                        addFileBtnText="Добавить изображение"
                        accept="image/png,image/jpeg"
                        maxTotalSizeInMegabytes={this.maxTotalFileSize}
                    />
                </Grid>

            </Grid>
        );
    }

    renderDocumentsInput() {
        return (
            <Grid container direction="column">
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom style={{ display: "block", textAlign: 'left' }}>
                        Документы
                        <Typography variant="caption" gutterBottom style={{ marginLeft: "5px" }}>
                            ( До 5 шт )
                        </Typography>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FileUploadValidatable
                        maxFilesCount={5}
                        fileType="document"
                        files={this.state.form.documents}
                        accept="application/pdf"
                        onFileAdd={this.handleDocumentAdd}
                        onFileDelete={this.handleDocumentDelete}
                        addFileBtnText="Добавить документ"
                        maxTotalSizeInMegabytes={this.maxTotalFileSize}
                    />
                </Grid>
            </Grid>
        );
    }

    getSelectedType = () => {

    }

    handleFormSubmit = () => {
        var data = {
            image: this.state.form.image,
            typeId: this.state.form.typeId.value,
            municipalSettlementId: this.state.form.municipalSettlementId.value,
            header: this.state.form.header.value,
            description: this.state.form.description.value,
            endDate: this.state.form.endDate.value,
            debriefingDate: this.state.form.debriefingDate.value,
            authority: this.state.form.authority.value,
            documents: this.state.form.documents
        };
        this.props.onSubmit(data);
        //this.props.history.replace("/votings");
    }

    render() {
        
        return (

            <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
                <Grid item xs={12}>
                    <Alert variant="outlined" severity="info">
                        Голосование - это выбор одного варианта ответа из предложенного списка.
                        Опрос - это выбор одного или нескольких вариантов ответа из предложенного списка или предложение собственного.
                    </Alert>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Input
                        id="typeId"
                        type={this.state.form.typeId.elementType}
                        value={this.state.form.typeId.value}
                        config={this.state.form.typeId.config}
                        options={this.props.types}
                        error={this.state.form.typeId.error}
                        validation={this.state.form.typeId.validation}
                        onChange={event => { this.handleFieldChange(event.target.value, "typeId"); }}
                        onBlur={event => { this.handleFormElementBlur(event, "typeId"); }}
                    />
                </Grid>
                                
                <Grid item xs={12} sm={6}>
                    <Input
                        id="municipalSettlementId"
                        type={this.state.form.municipalSettlementId.elementType}
                        value={this.state.form.municipalSettlementId.value}
                        config={this.state.form.municipalSettlementId.config}
                        options={this.props.municipalSettlements}
                        error={this.state.form.municipalSettlementId.error}
                        validation={this.state.form.municipalSettlementId.validation}
                        onChange={event => { this.handleFieldChange(event.target.value, "municipalSettlementId"); }}
                        onBlur={event => { this.handleFormElementBlur(event, "municipalSettlementId"); }}
                    />
                </Grid>
                <Can perform="votings:createOnBehalf" yes={() => (
                    <Grid item xs={12}>
                        <Input
                            id="authority"
                            type={this.state.form.authority.elementType}
                            value={this.state.form.authority.value}
                            config={this.state.form.authority.config}
                            error={this.state.form.authority.error}
                            validation={this.state.form.authority.validation}
                            onChange={event => { this.handleFieldChange(event.target.value, "authority"); }}
                            onBlur={event => { this.handleFormElementBlur(event, "authority"); }}
                        />
                    </Grid>
                    
                    )}
                />

                <Grid item xs={12} sm={4}>
                    <Input
                        id="startDate"
                        type={this.state.form.startDate.elementType}
                        value={this.state.form.startDate.value}
                        config={this.state.form.startDate.config}
                        error={this.state.form.startDate.error}
                        minDate={this.state.form.startDate.minDate}
                        disablePast={this.state.form.startDate.disablePast}
                        validation={this.state.form.startDate.validation}
                        onChange={event => { this.handleStartDateChange(event, "startDate"); }}
                        onBlur={event => { this.handleFormElementBlur(event, "startDate"); }}
                    />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Input
                        id="endDate"
                        type={this.state.form.endDate.elementType}
                        value={this.state.form.endDate.value}
                        config={this.state.form.endDate.config}
                        error={this.state.form.endDate.error}
                        minDate={this.state.form.endDate.minDate}
                        disablePast={this.state.form.startDate.disablePast}
                        validation={this.state.form.endDate.validation}
                        onChange={event => { this.handleEndDateChange(event, "endDate"); }}
                        onBlur={event => { this.handleFormElementBlur(event, "endDate"); }}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Input
                        id="debriefingDate"
                        type={this.state.form.debriefingDate.elementType}
                        value={this.state.form.debriefingDate.value}
                        config={this.state.form.debriefingDate.config}
                        error={this.state.form.debriefingDate.error}
                        minDate={this.state.form.debriefingDate.minDate}
                        disablePast={this.state.form.startDate.disablePast}
                        validation={this.state.form.debriefingDate.validation}
                        onChange={event => { this.handleFieldChange(event, "debriefingDate"); }}
                        onBlur={event => { this.handleFormElementBlur(event, "debriefingDate"); }}

                    />
                </Grid>

                                
                <Grid item xs={12}>
                    <Input
                        id="header"
                        type={this.state.form.header.elementType}
                        value={this.state.form.header.value}
                        config={this.state.form.header.config}
                        error={this.state.form.header.error}
                        validation={this.state.form.header.validation}
                        onChange={event => { this.handleFieldChange(event.target.value, "header"); }}
                        onBlur={event => { this.handleFormElementBlur(event, "header"); }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Input
                        id="description"
                        type={this.state.form.description.elementType}
                        value={this.state.form.description.value}
                        config={this.state.form.description.config}
                        error={this.state.form.description.error}
                        validation={this.state.form.description.validation}
                        onChange={event => { this.handleFieldChange(event.target.value, "description"); }}
                        onBlur={event => { this.handleFormElementBlur(event, "description"); }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    {this.renderImageInput()}
                </Grid>
                {
                    this.props.canAddDocument && 
                    (
                        <Grid item xs={12}>
                            {this.renderDocumentsInput()}
                        </Grid>
                    )
                }
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={() => this.handleFormSubmit()} disabled={!this.state.formIsValid}>
                        Далее
                    </Button>
                </Grid>
            </Grid>
                       

        );
    }
}

const mapStateToProps = state => {
    return {
        types: state.vote.types,
        municipalSettlements: state.cmn.municipalSettlements,
        created: state.vote.created,
        canAddDocument: state.user && (state.user.role === 'moderator' || state.user.role === 'admin')
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchMunicipalSettlements: () => dispatch(actions.fetchMunicipalSettlements()),
        onFetchTypes: () => dispatch(actions.fetchVotingTypes()),
        onNotify: (msg) => dispatch(actions.addErrorNotification(msg)),
        onPost: (data) => dispatch(actions.postVoting(data))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NewVoting));