import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReportContainer from './ReportContainer'
import * as actions from '../../../store/actions';
import { Card, Grid, Typography, Box } from '@material-ui/core';
import Preloader from '../../../containers/Preloader';

class ReportListContainer extends Component {

    componentDidMount() {
        this.props.onFetchReports();
    }

    render() {
        if (this.props.reportList == null) {
            return <Preloader fullScreen open color="primary"/>
        };

        return (
            <Grid container justify="center" >
                <Grid item xs={12} sm={8} >
                    <Box mb={2}><Typography variant="h4">Отчёты</Typography></Box>
                    <Card>
                        {
                            this.props.reportList.map(report => {
                                return (<ReportContainer key={report.id} title={report.title} params={report.params} reportId={report.id} />);
                            })
                        }
                    </Card>
                </Grid>
            </Grid>
        );
    }

};

const mapStateToProps = state => {
    return {
        reportList: state.report.reportList
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchReports: () => dispatch(actions.fetchReports())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportListContainer);