import React from 'react';
import VotingsTable from '../containers/VotingTable';
import { Grid, Button } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import Can from '../../../components/Auth/Can';

const votingsPage = (props) => {

    return (
        <Grid container className="Messages" spacing={0} direction="column" justify="center">
            <Grid item xs={12} container>
                <Can perform="votings:create" yes={() => (
                    <Grid item key="add" xs={12}>
                        <Link to={{
                            pathname: "/votings/new"
                        }}>
                            <Button color="primary" variant="contained">
                                Создать голосование/опрос
                            </Button>
                        </Link>
                        </Grid>
                    )}
                />
                <Grid item xs={12}>

                    <VotingsTable messages={props.messages}
                    //filter={<MessageFilter />}
                    />
                </Grid>
            </Grid>
            

        </Grid>

    );
};

export default withRouter(votingsPage);