import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';
const initialState = {
    territories: [],
    position: null,
    organizationId: null,
    organization: "",
    messageTypes: [],
    loading: false
};

const fetchWorkProfileStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const fetchWorkProfileSuccess = (state, action) => {
    if (!action.payload) {
        return updateObject(state, {
            loading: false
        });
    }
    return updateObject(state, {
        territories: action.payload.municipalSettlements,
        position: action.payload.position,
        organization: action.payload.organization ? action.payload.organization.name : "",
        organizationId: action.payload.organization ? action.payload.organization.id : null,
        messageTypes: !action.payload.types ? [] : action.payload.types.map(type => {
            return {
                id: type.typeId,
                name: type.type,
                categoryId: type.categoryId,
                category: type.category
            };
        }),
        loading: false
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_WORK_PROFILE_START: return fetchWorkProfileStart(state, action);
        case actionTypes.FETCH_WORK_PROFILE_SUCCESS: return fetchWorkProfileSuccess(state, action);
        default: return state;
    }
};

export default reducer;