import React from 'react';
import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';

const DocumentList = (props) => {
    const messageId = null;
    
    return (
        <List>
            {
                props.documents.map((document, index) => {
                    return (
                        <ListItem key={index} alignItems="flex-start">
                            <ListItemText style={{ textOverflow: "ellipsis" }} primary={document.value} />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" title="загрузить" onClick={(event) => props.onDownloadDocument(document.id, document.value)}>
                                    <GetAppIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                            
                            
                        </ListItem>
                        
                    );
                })
            }
            
        </List>
    );

};

export default DocumentList;
