import React from 'react';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Grid, Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';


const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    filterTxt: {
        marginLeft: '10px'
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            }

}));

const FilterToolbar = (props) => {
    const classes = useToolbarStyles();
    let filter = props.showFilter ? props.filter : null;
    let toggleFilter = null;
    if (props.filter) {
        toggleFilter = (
            <Grid container direction="row-reverse" justify="flex-start" alignItems="center">
                <Grid item className={classes.filterTxt}>фильтр</Grid>
                <Tooltip title="фильтровать">
                    <IconButton aria-label="фильтровать" onClick={props.onToggleFilter}>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
        );
    }
    return (
        <Toolbar className={classes.root}>
            <Grid container spacing={2} flex-direction="row">
                <Grid item>
                    <Typography className={classes.title} variant="h6" id="tableTitle" component="span">
                        {props.header}
                    </Typography>
                </Grid>
                <Grid item xs container justify="flex-end">
                    <Grid item xs container justify="flex-end">
                        {filter}
                    </Grid>
                    <Grid item>
                        {toggleFilter}
                    </Grid>
                </Grid>

            </Grid>

        </Toolbar>
    );
};

export default FilterToolbar;