import React from 'react';
import {
    TableBody, TableRow, TableCell
} from '@material-ui/core';

const MessageTypesTableBody = (props) => {
    return (

        <TableBody>
            {
                props.messageTypes.map((item, index) => {
                    return (
                        <TableRow
                            hover
                            tabIndex={-1}
                            key={index}
                        >

                            <TableCell align="left" key="cat">{item.category}</TableCell>
                            <TableCell align="left" key="cat">{item.type}</TableCell>
                        </TableRow>
                    );
                })
            }
        </TableBody>


    );

};

export default MessageTypesTableBody;