import { get, post, put, getBlob, endpoints } from './utils';

export default class messageApi {
    static get(id) { return get(`message/${id}`, endpoints.message); }

    static postMesage(formData, isEdit, saveAsDraft) {
        let url = saveAsDraft ? "message/draft" : "message";
        let request = isEdit
            ? put(url, formData, endpoints.message, 'multipart/form-data' )
            : post(url, formData, endpoints.message, 'multipart/form-data');
        return request;
    }

    static getMessagesPreviewsWithCount = (skip, take, filter, territory) => {

        const parameters = { skip: skip, take: take, ...filter };
        if (territory) {
            parameters.msId = territory;
        }
        return get(`message/PreviewWithCount`, endpoints.message, parameters);
    }

    static sendAllMessages() { return get(`message/sendAllMessages`, endpoints.message); }

    static getPhotos(id) { return get(`message/${id}/photos`, endpoints.message); }
    static getDocuments(id) { return get(`message/${id}/documents`, endpoints.message); }
    static getDocument(id, documentId) { return getBlob(`message/${id}/documents/${documentId}`, endpoints.message); }
    static getList = (skip, take, filter) => {
        const parameters = { skip: skip, take: take, ...filter };
        return get(`message`, endpoints.message, parameters);
    }
    static getCount = (filter) => {
        return get(`message/count`, endpoints.message, filter);
    }
    static getPreviews = (skip, take, filter, territory) => {
        const parameters = { skip: skip, take: take, ...filter };
        if (territory) {
            parameters.msId = territory;
        }
        return get(`message/MessagePreview`, endpoints.message, parameters);
    }
    static getCategories = (munSetId) => {
        return munSetId
            ? get(`MessageDictionary/Categories?msid=${munSetId}`, endpoints.message)
            : get(`MessageDictionary/Categories`, endpoints.message);
    }
    static getStatuses = () => { return get(`MessageDictionary/Status`, endpoints.message); }
    static getPublicStatuses = () => { return get(`MessageDictionary/PublicStatus`, endpoints.message); }
    static getMapStatuses = () => { return get(`MessageDictionary/PublicMapStatus`, endpoints.message); }
    static getTypes = (categoryId, munSetId) => {
        if (categoryId && munSetId) {
            return get(`MessageDictionary/MessageTypes?categoryId=${categoryId}&msid=${munSetId}`, endpoints.message);
        }
        return get(`MessageDictionary/MessageTypes`, endpoints.message);
    }

    static getTypesWithCategories = () => {
        return get(`MessageDictionary`, endpoints.message);
    }

    static postMessageType = (categoryId, title) => {
        return post(`MessageDictionary/MessageTypes`, { categoryId: categoryId, typeName: title }, endpoints.message);
    }

    static getPerformers = (typeId, munSetId) => { return get(`MessageDictionary/OrganizationPerformer?typeId=${typeId}&msid=${munSetId}`, endpoints.message); }
    static post = (data) => { return post(`Message`, data, endpoints.message, 'multipart/form-data'); }
    static addToFavorites = (id, state) => { }

    static getComments = (messageId) => { return get(`Comment/ByMessage/${messageId}`, endpoints.message); }
    static postComment = (data) => { return post(`Comment`, data, endpoints.message, 'application/json, charset=utf-8'); }

    static sendToRework = (id) => { return put(`message/${id}/Rework`, null, endpoints.message); }

    static setPerformer = (messageId, performerId, categoryId, messageTypeId) => {
        return put(`Message/${messageId}/Performer`, {
            performerId: performerId,
            categoryId: categoryId,
            typeId: messageTypeId
        }, endpoints.message, 'application/json, charset=utf-8');
    }

    static setIsFavorites = (messageId, isFavorite) => {
        return put(`/message/SetInFavorites?messageId=${messageId}&IsFavorites=${isFavorite}`, null, endpoints.message);
    } 


    static getResponse = (messageId) => { return get(`message/${messageId}/answer`, endpoints.message); }

    static reject = (messageId, reason) => {
        return put(`Message/${messageId}/Reject`, reason, endpoints.message, 'application/json, charset=utf-8');
    }

    static approve = (messageId) => {
        return put(`Message/${messageId}/Finish`, null, endpoints.message, 'application/json, charset=utf-8');
    }

    static postResponse = (messageId, data) => {
        let formData = new FormData();
        let responseObj = {
            answerStatus: data.status,
            answer: data.text
        };
        for (var key in responseObj) {
            formData.append(key, responseObj[key]);
        }
        let savedDocuments = data.documents.filter(x => x.id).map(x => x.id);
        let savedImages = data.images.filter(x => x.id).map(x => x.id);
        let newDocuments = data.documents.filter(x => !x.id);
        let newImages = data.images.filter(x => !x.id);
        for (var i = 0; i < savedDocuments.length; i++) {
            formData.append("savedDocuments[]", savedDocuments[i]);
        }

        for (i = 0; i < savedImages.length; i++) {
            formData.append("savedPhotos[]", savedImages[i]);
        }
        for (i = 0; i < newDocuments.length; i++) {
            formData.append("documents", newDocuments[i]);
        }
        for (var j = 0; j < newImages.length; j++) {
            formData.append("photos", newImages[j]);
        }
        return put(`Message/${messageId}/Answer`,
            formData,
            endpoints.message, 'multipart/form-data');
    }

    static getResponseStatuses = () => { return get(`/Message/AnswerStatus`, endpoints.message); }

    static getMunicipalSettlement = (gisROGuid) => { return get(`/MessageDictionary/MunicipalSettlement?gisROGuid=${gisROGuid}`, endpoints.message); }

    static getGisROSettlement = (municipalSettlementId) => { return get(`/MessageDictionary/MunicipalSettlement?msId=${municipalSettlementId}`, endpoints.message); }

    static getUserMessages = (skip, take, filter) => {
        const parameters = { userId: 0, skip: skip, take: take, ...filter };
        return get(`message/MessagePreviewByUser`, endpoints.message, parameters);
    }

    static getPerformersInfo = () => {
        return get('MessageDictionary/performers', endpoints.message);
    }

    static getPerformerInfo = (id) => {
        return get(`MessageDictionary/performers/${id}`, endpoints.message);
    }

    static getWorkProfile = (userId) => {
        return get(`MessageDictionary/WorkProfile/${userId}`, endpoints.message);
    }

    static setWorkProfile = (userId, profile) => {
        return put(`MessageDictionary/WorkProfile/${userId}`, profile.position, endpoints.message, 'application/json, charset=utf-8');
    }

    static getFavoriteMessages = () => {
        return get(`message/IsFavorites`, endpoints.message);
    }

    static assessResponse = (messageId, like) => {
        return put(`message/like?messageId=${messageId}&like=${like}`, null, endpoints.message);
    }

    static getPersonalAreaStatuses = () => {
        return get(`MessageDictionary/PersonalAreaStatus`, endpoints.message);
    }

    static getGroupedMessageTypes = (msId) => {
        return get(`MessageDictionary/FilteredTypes?msId=${msId}`, endpoints.message);
    }

    static setPerformerMessageTypes = (userId, types) => {
        return put(`MessageDictionary/PerformerTypes/${userId}`, types, endpoints.message);
    }

    static getTypesOnTerritory = (munSetId) => {
        return get(`MessageDictionary/MessageTypes?msid=${munSetId}`, endpoints.message);
    }

    static setTypesOnTerritory = (munSetId, types) => {
        return put(`MessageDictionary/MunsetType/${munSetId}`, types, endpoints.message);
    }
}