import React from 'react';
import { TextField, Typography } from '@material-ui/core';

const textArea = (props) => {
    let field = (
        <TextField
            id={props.id}
            label={props.label}
            value={props.value}
            required={props.required}
            error={props.error}
            style={props.style}
            rows={props.rows}
            placeholder={props.placeholder}
            helperText={props.helperText}
            size={props.size}
            variant="outlined"
            multiline
            fullWidth
            onChange={props.onChange}
            onBlur={props.onBlur}
            autoFocus={props.autoFocus}
        />
    );
    if (props.maxLength) {
        const textLength = props.value ? props.value.length : 0;
        const lettersLeft = props.maxLength - textLength;
        return (
            <React.Fragment>
                {field}
                <div style={{ display: 'block', textAlign: 'right', border: 0 }}>
                    <Typography variant="body2" color="textSecondary" component="span">
                        осталось {lettersLeft} символов
                    </Typography>
                </div>
            </React.Fragment>
        );
    }
    else {
        return field;
    }
};

export default textArea;
