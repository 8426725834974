import React from 'react';
import { Grid, Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: "20px"
    },
    text: {
        marginTop: "20px"
    },
    link: {
        fontWeight: "bold"
    }
}));

const Page403 = (props) => {
    let classes = useStyles();
    let clickBack = () => {
        return props.history.goBack();
    };
    return (

        <Grid item xs container direction="column" justify="center" alignItems="center" spacing={3} className="root">
            <Grid item style={{ textAlign: "center" }}>
                <Typography variant="h3" color="primary" className={classes.text}>
                    403
                </Typography>
                <Typography variant="h3" color="primary" className={classes.text}>
                    Доступ запрещен
                </Typography>
                <Typography variant="body1" color="primary" className={classes.text}>
                    Извините, но у Вас нет прав для посещения данной страницы.
                    <br />
                    Вы можете вернуться на <Link href="#" onClick={clickBack} color="inherit" className={classes.link}>предыдущую страницу</Link>
                </Typography>
                </Grid>

            </Grid>
       
    );
};

export default withRouter(Page403);