const rules = {
    visitor: {
        static: [
            "messages:list",
            "messages:view",
            "votings:list",
            "news:list",
            "home-page:visit",
            "organizations:list",
            "organizations:view",
        ]
    },
    admin: {
        static: [
            "reports:get",
            "messages:list",
            "messages:view",
            "votings:create",
            "votings:list",
            "votings:createOnBehalf",
            "cabinet:visit",
            "performers:list",
            "performers:view",
            "profile:edit",
            "news:create",
            "organizations:list",
            "organizations:view",
            "organizations:create",
            "messagetypes:list",
            "messagetypes:create",
            "organizations:edit",
            "users:list",
            "news:edit"
        ],
    },
    user: {
        static: [
            "messages:create",
            "messages:list",
            "votings:create",
            "votings:list",
            "votings:vote",
            "organizations:list",
            "organizations:view",
            "cabinet:visit",
            "messages:view",
        ],
        dynamic: {
            "message:edit": ({ user, messageAuthorId, messageStatus }) => {
                return messageStatus === "Отклонено" && user.sub === messageAuthorId;
            }
        }
    },
    moderator: {
        static: [
            "messages:list",
            "messages:view",
            "messages:moderate",
            "votings:create",
            "votings:moderate",
            "votings:list",
            "cabinet:visit",
            "performers:list",
            "performers:view",
            "profile:edit",
            "news:create",
            "votings:createOnBehalf",
            "organizations:list",
            "organizations:view",
            "organizations:create",
            "reports:get",
            "messageTypes:edit",
            "news:edit"
        ],
        dynamic: {
            "messages:viewAndModerate": ({ messageStatus }) => {
                return messageStatus !== "Черновик";
            }
        }
    },
    performer: {
        static: [
            "profile:edit",
            "messages:list",
            "messages:perform",
            "reports:get",
            "messages:view",
        ],
    }
};

export default rules;