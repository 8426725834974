import React from 'react';
import MessageTable from '../../../containers/Messages/MessageTable';
import MessageFilter from '../../shared/containers/MessageFilter';
import Grid from '@material-ui/core/Grid';
import { Link, withRouter } from 'react-router-dom';
import { queryString } from 'query-string';

const ModeratorMessagesPage = (props) => {
    const queryString = require('query-string');
    const query = queryString.parse(props.location.search);

    return (
        <Grid container className="Messages" spacing={0} direction="column" justify="center">
            <Grid item xs={12} style={{ marginTop: "20px" }}>
                <MessageTable filter={<MessageFilter />}/>
            </Grid>

        </Grid>

    );
};

export default withRouter(ModeratorMessagesPage);