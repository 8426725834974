import * as actionTypes from './actionTypes';
import commonApi from '../api/commonApi';

export const fetchMunicipalSettlementsStart = () => {
    return {
        type: actionTypes.FETCH_MUNICIPAL_SETTLEMENTS_START
    };
};

export const fetchMunicipalSettlementsSuccess = (munSets) => {
    return {
        type: actionTypes.FETCH_MUNICIPAL_SETTLEMENTS_SUCCESS,
        payload: munSets
    };
};

export const fetchMunicipalSettlementsFail = () => {
    return {
        type: actionTypes.FETCH_MUNICIPAL_SETTLEMENTS_FAIL
    };
};

export const fetchMunicipalSettlements = () => {
    return (dispatch, getState) => {
        let state = getState();
        if (state.cmn.municipalSettlements.length > 0) {
            return;
        }
        dispatch(fetchMunicipalSettlementsStart());
        commonApi.getMunicipalSettlements()
            .then(res => {
                dispatch(fetchMunicipalSettlementsSuccess(res.data));
            })
            .catch(err => {
                dispatch(fetchMunicipalSettlementsFail(err));
            });
    };
};

export const fetchPublicStatisticsStart = () => {
    return {
        type: actionTypes.FETCH_PUBLIC_STATISTICS_START
    };
};

export const fetchPublicStatisticsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_PUBLIC_STATISTICS_SUCCESS,
        payload: payload
    };
};

export const fetchPublicStatisticsFail = () => {
    return {
        type: actionTypes.FETCH_PUBLIC_STATISTICS_FAIL
    };
};

export const fetchPublicStatistics = () => {
    return (dispatch) => {
        dispatch(fetchPublicStatisticsStart());
        Promise.allSettled([
            commonApi.getUsersCount(),
            commonApi.getMessagesInWorkCount(),
            commonApi.getFinishedMessagesCount(),
            commonApi.getVotingsInWorkCount(),
            commonApi.getFinishedVotingsCount()
        ]).then((results) => {
            let payload = {};
            payload.users = _getStatisticsResultValue(results[0]);
            payload.messagesInWork = _getStatisticsResultValue(results[1]);
            payload.finishedMessages = _getStatisticsResultValue(results[2]);
            payload.votingsInProcess = _getStatisticsResultValue(results[3]);
            payload.finishedVotings = _getStatisticsResultValue(results[4]);
            dispatch(fetchPublicStatisticsSuccess(payload));
        }).catch((ex) => {
            dispatch(fetchPublicStatisticsFail());
        });
    };
};

let _getStatisticsResultValue = (result) => {
    return result.status === 'fulfilled' ? result.value?.data : 0;
}

export const fetchModeratorStatisticsStart = () => {
    return {
        type: actionTypes.FETCH_MODERATOR_STATISTICS_START
    };
};

export const fetchModeratorStatisticsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_MODERATOR_STATISTICS_SUCCESS,
        payload: payload
    };
};

export const fetchModeratorStatisticsFail = () => {
    return {
        type: actionTypes.FETCH_MODERATOR_STATISTICS_FAIL
    };
};

export const fetchModeratorStatistics = () => {
    return (dispatch) => {
        dispatch(fetchModeratorStatisticsStart());
        commonApi.getModeratorStatistics()
            .then((results) => {
                dispatch(fetchModeratorStatisticsSuccess(results.data));
            }).catch((ex) => {
                fetchModeratorStatisticsFail();
            });
    };
};

export const fetchPerformerStatisticsStart = () => {
    return {
        type: actionTypes.FETCH_PERFORMER_STATISTICS_START
    };
};

export const fetchPerformerStatisticsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_PERFORMER_STATISTICS_SUCCESS,
        payload: payload
    };
};

export const fetchPerformerStatisticsFail = () => {
    return {
        type: actionTypes.FETCH_PERFORMER_STATISTICS_FAIL
    };
};

export const fetchPerformerStatistics = () => {
    return (dispatch) => {
        dispatch(fetchPerformerStatisticsStart());
        commonApi.getPerformerStatistics()
            .then((results) => {
                dispatch(fetchPerformerStatisticsSuccess(results.data));
            }).catch((ex) => {
                fetchPerformerStatisticsFail();
            });
    };
};