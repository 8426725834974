import React, { Component } from 'react';
import Preloader from '../../../containers/Preloader';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import FullMessage from '../../../components/Messages/FullMessage/FullMessage';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CommentList from '../../../components/Comments/CommentList';
import NewComment from '../../../components/Comments/NewComment';
import MessageModerationPanel from './MessageModerationPanel';
import Rejection from './Rejection';
import PerformerCard from '../../../components/Messages/PerformerCard';
import MessageResponse from '../../../components/Messages/MessageResponse';
import Can from '../../../components/Auth/Can';

class FullMessageContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showMessageRejection: false
        };
    }

    componentDidMount() {
        if (this.props.id) {
            this.props.onFetchMessage(this.props.id);
            this.props.onFetchResponse(this.props.id);
            this.props.onFetchComments(this.props.id);
        }
    }

    handleResponseSubmit = () => {
        this.props.onApproveResponse(this.props.id);
    };

    handleShowNewComment = () => {
        this.setState({
            showNewComment: true
        });
    };

    handleCancelNewComment = () => {
        this.setState({
            showNewComment: false
        });
    };

    renderMessageResponseForm() {
        if (!this.props.loadedMessage || !this.props.response) {
            return null;
        }
        let newComment = null;
        if (this.state.showNewComment) {
            newComment = (
                <Grid item xs={12}>
                    <Typography variant="h6">
                        Новый комментарий
                    </Typography>
                    <NewComment onPostComment={(text) => this.props.onPostComment(this.props.loadedMessage.id, text)}
                        onCancel={this.handleCancelNewComment}
                    />
                </Grid>);
        }
        let messageResponseActions = null;
        if (!this.props.isFinished) {
            messageResponseActions = (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Paper style={{ padding: "10px" }}>
                            <MessageResponse
                                {...this.props.response}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} container spacing={1}>
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={this.handleResponseSubmit}>
                                Опубликовать ответ
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="secondary" onClick={this.handleShowNewComment}>
                                Отправить на доработку...
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                );
        }
        return (
            <React.Fragment>
                {messageResponseActions}
                <Grid container spacing={1}>
                    {newComment}
                    <Grid item xs={12}>
                        <Typography variant="h6">Комментарии</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <CommentList comments={this.props.comments} />          
                    </Grid>
                </Grid>
        </React.Fragment>
        );
    }

    renderResponseActions() {

    }

    renderMessageModeration(message) {
        let rejectionBlock = null;
        if (this.props.canRejectMessage)
        {
            let messageRejection = this.state.showMessageRejection
                ? (
                    <Grid item>
                        <Rejection
                            onReject={(reason) => this.props.onRejectMessage(this.props.id, reason)}
                            onCancel={() => this.toggleMessageRejection(false)}
                            rejectButtonText="Отклонить сообщение"
                        />
                    </Grid>
                ) : null;
            let rejectButton = this.state.showMessageRejection ? null
                : (
                    <Grid item xs={12}>
                        <Button variant="contained" color="secondary" onClick={() => this.toggleMessageRejection(true)}>
                            Отклонить сообщение
                        </Button>
                    </Grid>
                );
            rejectionBlock = (
                <React.Fragment>
                    {rejectButton}
                    {messageRejection}
                </React.Fragment>
            );
        } 
        return (
            <React.Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <MessageModerationPanel message={message} />
                    </Grid>
                    {rejectionBlock}
                </Grid>
                
            </React.Fragment>
        );
    }

    toggleMessageRejection = (show) => {
        this.setState({ showMessageRejection: show });
    };

    render() {
        let message = (
            <Preloader fullScreen open color="primary"/>
        );
        let moderation = null;
        let messageResponse = null;
        if (this.props.loading) {
            return message;
        }
        if (!this.props.loading && this.props.loadedMessage) {
            message = (
                <FullMessage id={this.props.loadedMessage.id}
                    messageType={this.props.loadedMessage.messageType.name}
                    creationDate={this.props.loadedMessage.creationDate}
                    status={this.props.loadedMessage.status.name}
                    geometry={this.props.loadedMessage.geometry}
                    number={this.props.loadedMessage.number}
                    category={this.props.loadedMessage.category.name}
                    text={this.props.loadedMessage.text}
                    images={this.props.loadedMessage.images}
                    documents={this.props.loadedMessage.documents}
                    response={this.props.loadedMessage.response}
                    coordinates={this.props.loadedMessage.coordinates}
                    author={this.props.loadedMessage.author}
                />
            );
            if (this.props.canModerateMessage) {
                moderation = this.renderMessageModeration(this.props.loadedMessage);
            }
            else if (this.props.showPerformerCard) {
                moderation = (
                    <Grid item>
                        <PerformerCard company={this.props.loadedMessage.performer} />
                    </Grid>
                );
            }
            if (this.props.canModerateResponse) {
                messageResponse = this.renderMessageResponseForm();
            }
        } 
        
        return (
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={8}>
                    {message}
                </Grid>
                <Grid item xs={12} sm={4}>
                    {moderation}
                </Grid>
                <Grid item xs={12} sm={8}>
                    {messageResponse}
                </Grid>
            </Grid>  
        );
    }
}

const mapStateToProps = state => {
    const msgStatus = state.msg.loadedMessage ? state.msg.loadedMessage.status.name : null;
    const notFinished = state.msg.loadedMessage && !state.msg.loadedMessage.isFinished;
    const onModeration = state.msg.loadedMessage && (msgStatus === 'На модерации' || msgStatus === 'Новое');
    return {
        loadedMessage: state.msg.loadedMessage,
        loading: state.msg.loading,
        comments: state.msg.comments,
        response: state.msg.response,
        canModerateMessage: notFinished,
        canRejectMessage: onModeration,
        canModerateResponse: notFinished,
        showPerformerCard: msgStatus && msgStatus === "Передано исполнителю",
        isFinished: state.msg.isFinished
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchMessage: (id) => dispatch(actions.fetchMessage(id)),
        onPostComment: (messageId, commentText) => dispatch(actions.sendToRework(messageId, commentText)),
        onFetchComments: (messageId) => dispatch(actions.fetchMessageComments(messageId)),
        onFetchResponse: (messageId) => dispatch(actions.fetchMessageResponse(messageId)),
        onApproveResponse: (messageId) => dispatch(actions.approveMessageResponse(messageId)),
        onRejectMessage: (messageId, reason) => dispatch(actions.rejectMessage(messageId, reason))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FullMessageContainer);