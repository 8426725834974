import React from 'react';
import PublicStatistics from "../public/containers/Statistics/PublicStatistics"
import ModeratorStatistics from "../private/Moderator/containers/ModeratorStatistics";
import PerformerStatistics from "../private/Performer/containers/PerformerStatistics";
import { connect } from 'react-redux';
import Carousel from 'react-material-ui-carousel';
import { Paper, Grid, Typography, Button } from '@material-ui/core';
import CarouselItem from '../components/Main/CarouselItem';
import News from './News/News';
import { makeStyles } from '@material-ui/core/styles';
import { Link} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({

    header: {
        marginBottom: "10px",
        textAlign: "center"
    },
    banner: {
        width: "100%"
    },
    bannerList: {
        marginTop: "25px"
    }

}));

const Home = (props) => {
    const classes = useStyles();
    const carousel = (
        <Carousel autoPlay={false} fullHeightHover>
            <CarouselItem textPrimary="Подай сообщение и получи ответ на него за семь дней"
                imagePosition="left"
                button={
                    <Link to={{
                        pathname: "/messages/new"
                    }}>
                        <Button variant="contained" color="primary" >
                            Написать сообщение
                        </Button>
                    </Link>
                }
            />
            <CarouselItem textPrimary="Вырази своё мнение по наиболее важным вопросам городского развития"
                imagePosition="right"
                button={
                    <Link to={{
                        pathname: "/votings"
                    }}>
                        <Button variant="contained" color="primary" >
                            Перейти к голосованиям и опросам
                        </Button>
                    </Link>
                }
            />
            <CarouselItem textPrimary="Выдвигай инициативы, собирай мнения и влияй на жизнь города"
                imagePosition="left"
                button={
                    <Link to={{
                        pathname: "/votings/new"
                    }}>
                        <Button variant="contained" color="primary" >
                            Предложить голосование или опрос
                        </Button>
                    </Link>
                }
            />
        </Carousel>
    );
    let statistics = null;
    let showFancyStuff = false;
    switch (props.role) {
        case 'moderator':
            statistics = (<ModeratorStatistics />);
            break;
        case 'performer':
            statistics = (<PerformerStatistics />);
            break;
        default:
            statistics = (<PublicStatistics />);
            showFancyStuff = true;
            break;
    }
    return (
        <Grid container justify="center" spacing={4}>
            {showFancyStuff &&
                (<Grid item xs={12}>
                    {carousel}
                </Grid>)
            }
            <Grid item xs={12}>
                {statistics}
            </Grid>

            <Grid item xs={12} className={classes.bannerList}>
                <Grid container justify="center">
                    <Grid item lg={6}>
                        <img src="/images/banner3.png" className={classes.banner} />
                    </Grid>
                    <Grid item lg={6}>
                        <img src="/images/banner4.png" className={classes.banner} />
                        </Grid>
                    </Grid>
                </Grid>
        </Grid >
        );
};

const mapStateToProps = (state) => {
    return {
        role: state.user.isAuthenticated ? state.user.role : null
    };
};

export default connect(mapStateToProps)(Home);