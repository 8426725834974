import React from 'react';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/formControl';
import InputLabel from '@material-ui/core/InputLabel';
import Chip from '@material-ui/core/Chip';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DatePicker from './DatePicker';
import UpdatedDatePicker from './UpdatedDatePicker';
import DateRange from './DateRange';
import TextArea from './TextArea';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import Options from '../../Voting/NewVoting/Options';
//props.id
//props.value
//props.config.label
//props.config.required
//props.error
//props.config.rows - для textarea
//props.config.helperText - для textarea и textbox
//props.config.placeholder - для textarea и textBox
//props.config.tooltip - для чекбокса
//props.config.maxLength - для textarea и textBox
//props.config.options - для select
//props.config.defaultDisplayValue

const input = (props) => {

    let innerInput = null;
    let value = "";
    if (props.value !== null) {
        value = props.value;
    }
    
    var hasError = Boolean(props.error);
    switch (props.type) {
        case 'textbox':
            innerInput = (
                <TextField
                    id={props.id}
                    label={props.config.label}
                    type={props.config.type}
                    value={value}
                    required={props.validation && props.validation.required}
                    error={hasError}
                    style={props.style}
                    fullWidth
                    rows={props.config.rows}
                    placeholder={props.config.placeholder}
                    helperText={props.error}
                    variant="outlined"
                    size={props.size}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    disabled={props.disabled}
                    
                />
            );
            break;
        case 'textarea':
            {
                innerInput = (
                    <TextArea
                        id={props.id}
                        label={props.config.label}
                        value={value}
                        required={props.validation && props.validation.required}
                        error={hasError}
                        style={props.style}
                        rows={props.config.rows}
                        placeholder={props.config.placeholder}
                        helperText={props.error}
                        size={props.size}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        maxLength={props.config.maxLength}
                        disabled={props.disabled}
                    />
                );

                break;
            }

        case 'select': {
            let defaultDisplayValue = null;
            if (props.config.defaultDisplayValue) {
                defaultDisplayValue = (
                    <MenuItem key="0" value="" >
                        <em>{props.config.defaultDisplayValue}</em>
                    </MenuItem>
                );
            }
            var options = props.options ? props.options : props.config.options;
            if (!options) {
                options = [];
            }
            var renderValue = null;
            let onChange = null;
            let optionList = null;
            if (props.config.multiple) {
                optionList = options.map(option => {
                    return (
                        <MenuItem key={option.id} value={option.id}>
                            <Checkbox checked={value.length > 0 && value.includes(option.id)} />
                            <ListItemText primary={option.title} />
                        </MenuItem>
                    );
                });
                onChange = props.onChange;
                renderValue = (selected) => {
                    if (options.length)
                        return selected.map(item => { return options.filter(x => x.id == item)[0].title; }).join(', ');
                };
            } else {
                onChange = props.onChange;
                optionList = options.map(option => {
                    return (
                        <MenuItem key={option.id} value={option.id}>{option.title}</MenuItem>
                    );
                });
            }
            innerInput = (
                <FormControl
                    fullWidth
                    
                    size={props.size}
                    required={props.validation && props.validation.required}
                    error={hasError}
                >
                        <InputLabel htmlFor={props.id}>{props.config.label}</InputLabel>
                        <Select
                            id={props.id}
                            value={value}
                            multiple={props.config.multiple}
                            onBlur={props.onBlur}
                            onChange={onChange}
                            disabled={props.disabled}
                            renderValue={renderValue}
                            MenuProps={{
                                disableScrollLock: true,
                            }}
                        >
                            {defaultDisplayValue}
                            {optionList}
                        </Select>
                        <FormHelperText>{props.error}</FormHelperText>
                </FormControl>

            );
            break;

        }

        case 'checkbox': {
            const checkbox = (
                <FormControlLabel
                    control={<Checkbox id={props.id} name={props.id} checked={value} onClick={props.onClick} color="primary" disabled={props.disabled}/>}
                    label={props.config.label} />
            );
            if (props.tooltip) {
                innerInput = (
                    <Grid container justify="flex-start" alignItems="center">
                        <Grid item>
                            {checkbox}
                        </Grid>
                        <Grid item>
                            <Tooltip title={props.config.tooltip}>
                                <HelpOutlineIcon style={{ fontSize: "small" }} />
                            </Tooltip>
                        </Grid>
                    </Grid>
                );
            } else {
                innerInput = checkbox;
            }
            break;
        }
        case 'date': {
            innerInput = (
                <DatePicker
                    id={props.id}
                    label={props.config.label}
                    value={value}
                    validation={props.validation}
                    error={props.error}
                    style={props.style}
                    size={props.size}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    disabled={props.disabled}
                    fullWidth
                />
            );

            break;
        }
        case 'updatedDate': {
            innerInput = (
                <UpdatedDatePicker
                    id={props.id}
                    label={props.config.label}
                    value={value}
                    validation={props.validation}
                    error={props.error}
                    style={props.style}
                    size={props.size}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    disabled={props.disabled}
                    minDate={props.minDate}
                    disablePast={props.disablePast}
                    fullWidth
                />
            );

            break;
        }
        case 'dateRange': {
            innerInput = (
                <DateRange
                    id={props.id}
                    label={props.config.label}
                    value={value}
                    size={props.size}
                    validation={props.validation}
                    error={props.error}
                    style={props.style}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    disabled={props.disabled}
                    fullWidth
                />
            );
        }
    
    }

    return innerInput;
    
};

export default input;