import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';
import { parseDate } from '../../utility/parsers';
import Cookies from 'universal-cookie';

const initialState = {
    messages: [],
    messagesSkip: 0,
    messagesTake: 9,
    messageFilter: {
        statusId: null
    },
    messageStatuses: [],
    showMessageFilter: false,
    loading: false,
    votings: [],
    votingsSkip: 0,
    votingsTake: 9,
    votingFilter: {
        statusId: null
    },
    votingStatuses: [],
    showVotingFilter: false,
    favorites: [],
    shownFavorites: [],
    favoritesTake: 9,
    showFavoriteMessages: true,
    showFavoriteVotings: true,
    territory: null,
    territoryLoaded: false
};

const fetchUserMessagesStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const fetchUserMessagesSuccess = (state, action) => {
    const updatedMessages = state.messages.concat(action.messages);
    const newSkip = updatedMessages.length;
    return updateObject(state, {
        messages: updatedMessages,
        messagesSkip: newSkip,
        loading: false
    });
};

const fetchUserMessagesFail = (state, action) => {
    return updateObject(state, { loading: false });
};

const setMessageFilterValueReducer = (state, action) => {
    var newState = { ...state };
    var filter = { ...state.messageFilter };
    filter[action.filter] = action.value;
    newState.messages = [];
    newState.loading = true;
    newState.messageFilter = filter;
    return newState;
};

const toggleMessageFilter = (state, action) => {
    return updateObject(state, { showMessageFilter: !state.showMessageFilter });
};

const fetchUserVotingsStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const fetchUserVotingsSuccess = (state, action) => {
    const updatedVotings = state.votings.concat(action.votings);
    const newSkip = updatedVotings.length;
    return updateObject(state, {
        votings: updatedVotings,
        votingsSkip: newSkip,
        loading: false
    });
};

const fetchUserVotingsFail = (state, action) => {
    return updateObject(state, { loading: false });
};

const setVotingFilterValueReducer = (state, action) => {
    var newState = { ...state };
    var filter = { ...state.votingFilter };
    filter[action.filter] = action.value;
    newState.votings = [];
    newState.loading = true;
    newState.votingFilter = filter;
    return newState;
};

const toggleVotingFilter = (state, action) => {
    return updateObject(state, { showVotingFilter: !state.showVotingFilter });
};

const getShownFavorites = (favorites, showMessages, showVotings, skip, take) => {
    console.log(favorites);
    let filter = (item) => {
        return (showMessages ? item.type === "message" : false) || (showVotings ? item.type === "voting" : false);
    };
    let shownFavorites = favorites.filter(filter);
    shownFavorites
        .sort((a, b) => {
            var date1 = parseDate(a.object.createDate);
            var date2 = parseDate(b.object.createDate);
            return date2 - date1;
        });
    return shownFavorites.slice(skip, take);
};

const setFavoritesReducer = (state, action) => {
    let favorites = [];
    favorites = favorites.concat(action.messages.map(x => {
        return {
            type: "message",
            object: x
        };
    }));
    favorites = favorites.concat(action.votings.map(x => {
        return {
            type: "voting",
            object: x
        };
    }));
    let shownFavorites = getShownFavorites(favorites, state.showFavoriteMessages, state.showFavoriteVotings, 0, state.favoritesTake);
    return updateObject(state, {
        favorites: favorites,
        shownFavorites: shownFavorites
    });
};

const showMoreFavoritesReducer = (state, action) => {
    let shownFavorites = getShownFavorites(state.favorites, state.showFavoriteMessages, state.showFavoriteVotings, 0, state.shownFavorites.length + state.favoritesTake);
    return updateObject(state, {
        shownFavorites: shownFavorites
    });
};

const setMessageInFavoritesState = (state, action) => {
    let updatedMessages = state.messages.map(message =>
        message.id === action.messageId
            ? { ...message, isFavorite: action.isFavorite }
            : message
    );
    let favorites = state.favorites;
    let shownFavorites = state.shownFavorites;
    if (!action.isFavorite) {
        let filter = x => !(x.type === "message" && x.object.id === action.messageId);
        favorites = favorites.filter(filter);
        shownFavorites = shownFavorites.filter(filter);
    }
    return updateObject(state, {
        messages: updatedMessages,
        loading: false,
        favorites: favorites,
        shownFavorites: shownFavorites
    });
};

const setVotingInFavoritesState = (state, action) => {
    let updatedVotings = state.votings.map(voting =>
        voting.id === action.votingId
            ? { ...voting, isFavorite: action.isFavorite }
            : voting
    );
    let favorites = state.favorites;
    let shownFavorites = state.shownFavorites;
    if (!action.isFavorite) {
        let filter = x => !(x.type === "voting" && x.object.id === action.votingId);
        favorites = favorites.filter(filter);
        shownFavorites = shownFavorites.filter(filter);
    }
    return updateObject(state, {
        votings: updatedVotings,
        loading: false,
        favorites: favorites,
        shownFavorites: shownFavorites
    });
};

const changeFavoritesFilterReducer = (state, action) => {
    let shownFavorites = getShownFavorites(state.favorites, action.showMessages, action.showVotings, 0, state.favoritesTake);
    return updateObject(state, {
        shownFavorites: shownFavorites,
        showFavoriteMessages: action.showMessages,
        showFavoriteVotings: action.showVotings
    });
};

const getTerritoryReducer = (state, action) => {
    const cookies = new Cookies();
    let territoryCookie = cookies.get('territory');

    let territory = territoryCookie
        ? territoryCookie === 'all' ? null : +territoryCookie
        : action.user.locale;
    return updateObject(state, {
        territory: territory
    });
};

const setTerritoryReducer = (state, action) => {
    const cookies = new Cookies();
    cookies.set('territory', action.territory ? action.territory : 'all');
    return updateObject(state, {
        territory: action.territory
    });
};

const fetchPersonalMessageStatusesSuccess = (state, action) => {
    return updateObject(state, {
        messageStatuses: [...action.statuses].map((st) => { return { id: st.id, title: st.name }; })
    });
};

const fetchPersonalVotingStatusesSuccess = (state, action) => {
    return updateObject(state, {
        votingStatuses: [...action.statuses].map((st) => { return { id: st.id, title: st.name }; })
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_USER_MESSAGES_START: return fetchUserMessagesStart(state, action);
        case actionTypes.FETCH_USER_MESSAGES_SUCCESS: return fetchUserMessagesSuccess(state, action);
        case actionTypes.FETCH_USER_MESSAGES_FAIL: return fetchUserMessagesFail(state, action);
        case actionTypes.SET_USER_MESSAGE_FILTER_VALUE: return setMessageFilterValueReducer(state, action);
        case actionTypes.TOGGLE_USER_MESSAGE_FILTER: return toggleMessageFilter(state, action);
        case actionTypes.FETCH_USER_VOTINGS_START: return fetchUserVotingsStart(state, action);
        case actionTypes.FETCH_USER_VOTINGS_SUCCESS: return fetchUserVotingsSuccess(state, action);
        case actionTypes.FETCH_USER_VOTINGS_FAIL: return fetchUserVotingsFail(state, action);
        case actionTypes.SET_USER_VOTING_FILTER_VALUE: return setVotingFilterValueReducer(state, action);
        case actionTypes.TOGGLE_USER_VOTING_FILTER: return toggleVotingFilter(state, action);
        case actionTypes.SET_FAVORITES: return setFavoritesReducer(state, action);
        case actionTypes.SET_MESSAGE_IN_FAVORITES_STATE_SUCCESS: return setMessageInFavoritesState(state, action);
        case actionTypes.SHOW_MORE_FAVORITES: return showMoreFavoritesReducer(state, action);
        case actionTypes.CHANGE_FAVORITES_FILTER: return changeFavoritesFilterReducer(state, action);
        case actionTypes.UPDATE_VOTING: return setVotingInFavoritesState(state, action);
        case actionTypes.GET_TERRITORY: return getTerritoryReducer(state, action);
        case actionTypes.SET_TERRITORY: return setTerritoryReducer(state, action);
        case actionTypes.FETCH_PERSONAL_AREA_MESSAGE_STATUSES_SUCCESS: return fetchPersonalMessageStatusesSuccess(state, action);
        case actionTypes.FETCH_PERSONAL_AREA_VOTING_STATUSES_SUCCESS: return fetchPersonalVotingStatusesSuccess(state, action);
        default: return state;
    }
};

export default reducer;
