import React, { useState }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardHeader, CardContent, Typography, Grid, Box} from '@material-ui/core';
import StatusBadge from './VotingStatus';
import { withRouter } from 'react-router-dom';
import RejectionReason from '../UI/RejectionReason';
import VotingProcessContainer from '../../containers/Voting/VotingProcess';
import VotingProcess from './VotingProcess';
import { formatDate } from '../../utility/formatters';
import { trimTime, convertVotingStatus } from '../../utility/stringHelpers';
import Property from '../UI/Property';
import Linkify from 'react-linkify';
import VotingDocuments from '../../containers/Voting/VotingDocuments';
import VotingFavoriteIconButton from '../../containers/Voting/VotingFavoriteIconButton';

const useStyles = makeStyles((theme) => ({
    imageContainer: {
        textAlign: "center",
        [theme.breakpoints.up('md')]: {
            textAlign: "right"
        }
    },
    image: {height: '150px',}
}));

const Voting = (props) => {
    const [loadedImage, setLoadedImage] = useState(null);
    const classes = useStyles();
    let votingProcess = null;
    if (props.preview) {
        votingProcess = (<VotingProcess
            preview
            options={props.options}
            multiple={props.type === "Опрос" ? true : false}
            withOwnOption
        />);
    } else {
        
        votingProcess = (props.debriefingDateNotCome && props.closedVote)
            ? (<p> итоги голосования будут доступны {props.debriefingDate}</p>)
            :  (
                <Grid item xs={12}>
                    <VotingProcessContainer disableVote={!props.canVote} onSubmit={(options, ownAnswer) => props.onVote(props.id, options, ownAnswer)} />
                </Grid>
            );
    }

    if (props.preview && props.image) {
        if (props.image instanceof File) {
            var reader = new FileReader();
            reader.onload = function (e) {
                setLoadedImage(e.target.result);
            };
            reader.readAsDataURL(props.image);
        }   
    }

    const imageSrc = loadedImage
            ? loadedImage
        : (props.image ? `data:image/jpg;base64,${props.image}` : null);
    const hasDocuments = props.documents && props.documents.length !== 0;
    let documents = !hasDocuments ? null : (
        <Grid item xs={12}>
            <Typography variant="h6" component="h6">
                Прикрепленные документы
            </Typography>
            <VotingDocuments documents={props.documents} />
        </Grid>
    );
    const rejectionReason = props.rejectionReason
        ? (<RejectionReason textPrimary="Голосование не прошло модерацию:" textSecondary={props.rejectionReason} />)
        : null;
    const statusTitle =  (props.preview)
        ? null
        : convertVotingStatus(props.type, props.status);
    const status = props.preview ? null : (<StatusBadge status={statusTitle} />);
    const image = imageSrc ? (<img src={imageSrc} alt="изображение" className={classes.image} />) : null;
    return (
        <React.Fragment>
            {rejectionReason}
            <Card id="Voting">
                <CardHeader title={props.header}
                    subheader={props.preview ? formatDate(props.createDate) : props.createDate}
                    action={
                        <Box>
                            {status}
                            <VotingFavoriteIconButton
                                votingId={props.id}
                                votingType={props.type}
                                isFavorite={props.isFavorite}
                            />
                        </Box>
                    }
                />
                <CardContent>
                    <Grid container>

                        <Grid item xs={12}>
                            <Grid container direction="row-reverse" spacing={2}>
                                <Grid item key="img" xs={12} sm={5} className={classes.imageContainer}>
                                    {image}
                                </Grid>
                                <Grid item key="body" xs={12} sm={7}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Property label="Номер в системе" value={props.number ? props.number : "Не назначен"} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Property label="Автор" value={props.author} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Property label="Территория проведения" value={props.municipalSettlement} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Property label="Дата окончания голосования" value={props.preview ? formatDate(props.endDate) : trimTime(props.endDate)} />
                                        </Grid>
                                        <Grid item xs={12} style={{ marginTop: "10px" }}>
                                            <Linkify>
                                                <Typography variant="body1" component="p">
                                                    {props.description}
                                                </Typography>
                                            </Linkify>
                                        </Grid>
                                        <Grid item xs={12} container>
                                            {documents}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item key="process" xs={12} >

                                    <Grid item xs={12} >
                                        {votingProcess}
                                    </Grid>
                                   
                                </Grid>
                                
                            </Grid>
                        </Grid>

                    </Grid>

                </CardContent>

            </Card >

        </React.Fragment>
    );

};

export default withRouter(Voting);