import React, { Component } from 'react';
import Filter from '../../../components/UI/Filter';
import { connect } from 'react-redux';
import { Grid, Button } from '@material-ui/core';
import * as actions from '../../../store/actions';
import StatisticsItem from '../../../components/Main/StatisticsItem';
import { Link, withRouter } from 'react-router-dom';
import Can from '../../../components/Auth/Can';

class PerformerStatistics extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

        this.props.onFetchStatistics();
    }

    componentDidUpdate(prevProps, prevState) {
        //if (!prevProps.user && this.props.user) {
        //    console.log('user ', this.props.user);
        //}
    }

    render() {
        return (
            <Grid item xs={12} container justify="center" alignItems="center">
                <Grid item xs container spacing={2}>
                    <Grid item xs={12}>
                        <StatisticsItem count={this.props.messages} label={"сообщений направлено модератором"} loading={this.props.loading} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StatisticsItem count={this.props.messagesInWork} label={"сообщений в работе"} loading={this.props.loading} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StatisticsItem count={this.props.expiredMessages} label={"сообщений с истекшим сроком ответа"} loading={this.props.loading} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StatisticsItem count={this.props.likedMessages} label={"сообщений с положительными отзывами"} loading={this.props.loading} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StatisticsItem count={this.props.dislikedMessages} label={"сообщений с отрицательными отзывами"} loading={this.props.loading} />
                    </Grid>
                </Grid>
            </Grid>

        );
    }
}

const mapStateToProps = state => {
    console.log(state.cmn.performerStatistics);
    return {
        dislikedMessages: state.cmn.performerStatistics.dislikedMessages,
        expiredMessages: state.cmn.performerStatistics.expiredMessages,
        likedMessages: state.cmn.performerStatistics.likedMessages,
        messages: state.cmn.performerStatistics.messages,
        messagesInWork: state.cmn.performerStatistics.messagesInWork,
        loading: state.cmn.performerStatistics.loading,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchStatistics: () => dispatch(actions.fetchPerformerStatistics())

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PerformerStatistics));