import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';
import { setMessageFilterValue, setPrivateFilter, fetchMessageCommentsSuccess, putIsFavorites } from '../actions/messages';
import { groupBy } from '../../utility/arrayHelpers';

const initialState = {
    loadedMessage: null,
    loading: false,
    messages: [],
    skip: 0,
    take: 9,
    filter: {
        categoryId: null,
        statusId: null,
        beginDate: null,
        endDate: null
    },
    //isFilterShown: false,
    categories: [],
    statuses: [],
    publicStatuses: [],
    mapStatuses: [],
    messageTypes: [],
    messageTypesWithCategories: [],
    performers: [],
    showFilter: false,
    page: 0,
    messagesPerPage: 10,
    messageCount: 0,
    comments: [],
    moderationForm: null,
    response: '',
    responseStatuses: [],
    canAddComment: false,
    mapFilter: {
        category: null,
        status: null,
        municipalSettlement: null,
        beginDate: null,
        endDate: null
    },
    messageTypesByTerritory: {},
    totalFilteredMessagesCount: 0,
    maxPossibleMessagesCount: 9,
};

const fetchMessageStart = (state, action) => {
    return updateObject(state, { loading: true, canAddComment: false });
};

const fetchMessageSuccess = (state, action) => {
    return updateObject(state, {
        loadedMessage: {
            id: action.message.id,
            status: action.message.status,
            creationDate: action.message.createDate,
            category: action.message.category,
            messageType: action.message.type,
            number: action.message.number,
            address: action.message.address,
            text: action.message.text,
            images: action.message.photos,
            documents: action.message.documents,
            municipalSettlement: action.message.municipalSettlement,
            response: action.message.answer ? {
                text: action.message.answer.answer,
                date: action.message.answer.answerDate,
                status: action.message.answer.answerStatus.value,
                images: action.message.answer.photos,
                documents: action.message.answer.documents
            } : null,
            performer: action.message.performer,
            performerId: action.message.performerId,
            rejectionReason: action.message.rejectionReason,
            gisGuid: action.message.guidMessageGisRO,
            geometry: action.message.geometry,
            coordinates: action.message.coordinates,
            isFinished: action.message.isFinished,
            isFavorite: action.message.isFavorite,
            author: action.message.userName,
            authorId: action.message.userId,
            responseAssessment: action.message.like,
        },
        loading: false,
        canAddComment: action.message.status.name === "Передано исполнителю",
    });
};

const fetchMessageFail = (state, action) => {
    return updateObject(state, { loading: false });
};

const fetchMessagePreviewsStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const resetMessages = (state, action) => {
    return updateObject(state, { messages: [] });
};

const fetchMessagePreviewsSuccess = (state, action) => {
    let messages = action.messages.map(message => {
        return {
            ...message
        };
    });
    const updatedMessages = action.skip === 0 ? messages : state.messages.concat(messages);
    return updateObject(state, {
        messages: updatedMessages,
        loading: false
    });
};

const fetchMessagePreviewsFail = (state, action) => {
    return updateObject(state, { loading: false });
};

const setMessageFilterValueReducer = (state, action) => {
    var newState = { ...state };
    var filter = { ...state.filter };
    filter[action.filter] = action.value;
    newState.messages = [];
    newState.loading = true;
    newState.filter = filter;
    return newState;
};

const toggleMessageFilter = (state, action) => {
    return updateObject(state, { showFilter: !state.showFilter });
};

const fetchMessageStatusesSuccess = (state, action) => {
    let statuses = [...action.statuses].map((st) => { return { id: st.id, title: st.name }; });
    return updateObject(state, { statuses: statuses });
};

const fetchMessagePublicStatusesSuccess = (state, action) => {
    let statuses = [...action.statuses].map((st) => { return { id: st.id, title: st.name }; });
    return updateObject(state, { publicStatuses: statuses });
};

const fetchMessageMapStatusesSuccess = (state, action) => {
    let statuses = [...action.statuses].map((st) => { return { id: st.id, title: st.name }; });
    return updateObject(state, { mapStatuses: statuses });
};

const fetchMessageCategoriesSuccess = (state, action) => {
    let categories = [...action.categories].map((cat) => { return { id: cat.id, title: cat.name }; });
    return updateObject(state, { categories: categories });
};

const fetchMessageTypesSuccess = (state, action) => {
    let types = [...action.messageTypes].map((type) => { return { id: type.id, title: type.name }; });
    return updateObject(state, { messageTypes: types });
};

const fetchMessageTypesWithCategoriesSuccess = (state, action) => {
    let types = [...action.messageTypes].map((type) => {
        return {
            id: type.typeId,
            title: type.typeTitle,
            categoryId: type.categoryId,
            categoryTitle: type.categoryTitle
        };
    });
    return updateObject(state, { messageTypesWithCategories: types });
};

const fetchPerformersSuccess = (state, action) => {
    let performers = [...action.performers].map((contr) => { return { id: contr.id, title: contr.name }; });
    return updateObject(state, { performers: performers });
};

const changeMessagesPageStart = (state, action) => {
    return updateObject(state, { page: action.page, loading: true });
};

const changeMessagesPageSuccess = (state, action) => {
    return updateObject(state, { messages: action.messages, loading: false});
};

const changeMessagesPageFail = (state, action) => {
    return updateObject(state, { loading: false });
};

const initPrivateFilter = (state, action) => {
    return updateObject(state, {
        filter: {
            categoryId: '',
            statusId: '',
            messageTypeId: '',
            //beginSendDate: null,
            //endSendDate: null,
            //beginAnswerDate: null,
            //endAnswerDate: null
            sendDate: {
                begin: '',
                end: ''
            },
            answerDate: {
                begin: '',
                end: ''
            }
        }
    });
};

const initPublicFilter = (state, action) => {
    return updateObject(state, {
        filter: {
            categoryId: '',
            statusId: '',
            beginDate: '',
            endDate: ''
        }
    });
};

const fetchMessageCount = (state, action) => {
    return updateObject(state, {
        messageCount: action.count
    });
};

const initMessageModerationForm = (state, action) => {
    return updateObject(state, {
        moderationForm: {
            categoryId: action.message.category.id,
            messageTypeId: action.message.messageType.id,
            performerId: ''
        }
    });
};
//const postMessageCommentSuccess = (state, action) => {
//    return 
//};

const fetchMessageCommentsSuccessReducer = (state, action) => {
    return updateObject(state, {
        comments: action.comments
    });
};

const postMessageCommentSuccessReducer = (state, action) => {
    return state;
};

const postMessageCommentFailReducer = (state, action) => {
    return state;
};

const setMessageContactorSuccessReducer = (state, action) => {
    
};

const fetchMessageResponseSuccess = (state, action) => {
    if (!action.response.answer) {
        return updateObject(state, {
            response: null
        });
    }
    return updateObject(state, {
        response: {
            text: action.response.answer,
            status: action.response.answerStatus.id,
            documents: action.response.documents,
            date: action.response.answerDate,
            images: action.response.photos.map((image) => { return { id: image.id, title: image.fileName, value: image.digitalView };})
        }
    });
};

const fetchResponseStatuses = (state, action) => {
    let statuses = [...action.statuses].map((st) => { return { id: st.id, title: st.value }; });
    return updateObject(state, {
        responseStatuses: statuses
    });
};

const setMessageMapFilterValueReducer = (state, action) => {
    var newState = { ...state };
    var filter = { ...state.mapFilter };
    filter[action.filter] = action.value;
    newState.mapFilter = filter;
    return newState;
};

const toggleMessageMapFilter = (state, action) => {
    return updateObject(state, { showFilter: !state.showFilter });
};

const postMessageTypeReducer = (state, action) => {
    return updateObject(state, {created: true});
};

const setMessageInFavoritesState = (state, action) => {
    let stateUpdates = {};
    if (state.loadedMessage && state.loadedMessage.id === action.messageId) {
        let updatedLoadedMessage = { ...state.loadedMessage };
        updatedLoadedMessage.isFavorite = action.isFavorite;
        stateUpdates.loadedMessage = updatedLoadedMessage;
    }

    let updatedMessages = state.messages.map(message =>
        message.id === action.messageId
            ? { ...message, isFavorite: action.isFavorite }
            : message
    );
    stateUpdates.messages = updatedMessages;
    stateUpdates.loading = false;
    return updateObject(state, stateUpdates);

};

const setMessageTypesOnTerritory = (state, action) => {
    let messageTypesByTerritory = { ...state.messageTypesByTerritory };
    let messageTypesOnMSId = {};
    action.messageTypes.forEach(element => {
        messageTypesOnMSId[element.categoryId] = {};
        element.types.forEach(type => {
            messageTypesOnMSId[element.categoryId][type.typeId] = type.type;
        });
    });
    messageTypesByTerritory[action.msId] = { ...messageTypesOnMSId };
    return updateObject(state, {
        messageTypesByTerritory: messageTypesByTerritory
    });
};

const fetchTotalFilteredMessagesCount = (state, action) => {
    return updateObject(state, {
        totalFilteredMessagesCount: action.count
    });
}

const onChangeTotalMessagesCount = (state, action) => {
    return updateObject(state, {
        maxPossibleMessagesCount: state.maxPossibleMessagesCount + state.take
    });
} 

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_MESSAGE_START: return fetchMessageStart(state, action);
        case actionTypes.FETCH_MESSAGE_SUCCESS: return fetchMessageSuccess(state, action);
        case actionTypes.FETCH_MESSAGE_FAIL: return fetchMessageFail(state, action);
        case actionTypes.FETCH_MESSAGE_PREVIEWS_START: return fetchMessagePreviewsStart(state, action);
        case actionTypes.FETCH_MESSAGE_PREVIEWS_SUCCESS: return fetchMessagePreviewsSuccess(state, action);
        case actionTypes.FETCH_MESSAGE_PREVIEWS_FAIL: return fetchMessagePreviewsFail(state, action);
        case actionTypes.SET_MESSAGE_FILTER_VALUE: return setMessageFilterValueReducer(state, action);
        case actionTypes.TOGGLE_MESSAGE_FILTER: return toggleMessageFilter(state, action);
        case actionTypes.FETCH_MESSAGE_STATUSES_SUCCESS: return fetchMessageStatusesSuccess(state, action);
        case actionTypes.FETCH_MESSAGE_PUBLIC_STATUSES_SUCCESS: return fetchMessagePublicStatusesSuccess(state, action);
        case actionTypes.FETCH_MESSAGE_MAP_STATUSES_SUCCESS: return fetchMessageMapStatusesSuccess(state, action);
        case actionTypes.FETCH_MESSAGE_CATEGORIES_SUCCESS: return fetchMessageCategoriesSuccess(state, action);
        case actionTypes.FETCH_MESSAGE_TYPES_SUCCESS: return fetchMessageTypesSuccess(state, action);
        case actionTypes.FETCH_MESSAGE_TYPES_WITH_CATEGORIES_SUCCESS: return fetchMessageTypesWithCategoriesSuccess(state, action);
        case actionTypes.FETCH_CONTRACTORS_SUCCESS: return fetchPerformersSuccess(state, action);
        case actionTypes.CHANGE_MESSAGES_PAGE_START: return changeMessagesPageStart(state, action);
        case actionTypes.CHANGE_MESSAGES_PAGE_SUCCESS: return changeMessagesPageSuccess(state, action);
        case actionTypes.CHANGE_MESSAGES_PAGE_FAIL: return changeMessagesPageFail(state, action);
        case actionTypes.INIT_MESSAGE_PRIVATE_FILTER: return initPrivateFilter(state, action);
        case actionTypes.INIT_MESSAGE_PUBLIC_FILTER: return initPublicFilter(state, action);
        case actionTypes.FETCH_MESSAGE_COUNT: return fetchMessageCount(state, action);
        case actionTypes.INIT_MESSAGE_MODERATION_FORM: return initMessageModerationForm(state, action);
        case actionTypes.FETCH_MESSAGE_COMMENTS_SUCCESS: return fetchMessageCommentsSuccessReducer(state, action);
        case actionTypes.POST_MESSAGE_COMMENT_SUCCESS: return postMessageCommentSuccessReducer(state, action);
        case actionTypes.POST_MESSAGE_COMMENT_FAIL: return postMessageCommentFailReducer(state, action);
        case actionTypes.FETCH_MESSAGE_RESPONSE_SUCCESS: return fetchMessageResponseSuccess(state, action);
        case actionTypes.FETCH_RESPONSE_STATUSES_SUCCESS: return fetchResponseStatuses(state, action);
        case actionTypes.SET_MESSAGE_MAP_FILTER_VALUE: return setMessageMapFilterValueReducer(state, action);
        case actionTypes.TOGGLE_MESSAGE_MAP_FILTER: return toggleMessageMapFilter(state, action);
        case actionTypes.POST_MESSAGE_TYPE_SUCCESS: return postMessageTypeReducer(state, action);
        case actionTypes.SET_MESSAGE_IN_FAVORITES_STATE_SUCCESS: return setMessageInFavoritesState(state, action);
        case actionTypes.RESET_MESSAGES: return resetMessages(state, action);
        case actionTypes.FETCH_MESSAGE_TYPES_BY_TERRITORY: return setMessageTypesOnTerritory(state, action);

        case actionTypes.FETCH_TOTAL_FILTERED_MESSAGES_COUNT_SUCCES: return fetchTotalFilteredMessagesCount(state, action);
        case actionTypes.CHANGE_TOTAL_MESSAGESS_COUNT: return onChangeTotalMessagesCount(state, action);
        default: return state;
    }
};

export default reducer;