import React from 'react';
import { Grid, Button } from '@material-ui/core';

const uploadedImage = (props) => {
    let imageSrc = null;
    let image = null;
    if (props.image) {
        if (props.image instanceof Blob || props.image instanceof File) {
            imageSrc = window.URL.createObjectURL(props.image);
        } else if (props.image.value) {
            imageSrc = `data:image/jpg;base64,${props.image.value}`;
        }
        image = (<img src={imageSrc} style={{ height: "160px" }} alt="изображение"/>);
    }
    
    return (
        <Grid container justify="space-between" alignItems="flex-start" key={props.index} spacing={1}>
            <Grid item xs>
                {image}
            </Grid>
            <Grid item style={{ maxWidth: "none", textAlign: "right" }}>
                <Button variant="contained" onClick={props.onDelete}>
                    удалить
                </Button>

            </Grid>
        </Grid>
    );
};

export default uploadedImage;