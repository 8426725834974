import React, { Component } from 'react';
import { Grid, Button, } from '@material-ui/core';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import NewOrganization from '../../private/shared/containers/Organizations/NewOrganization';
import { withRouter } from 'react-router-dom';
import Preloader from '../Preloader';

class OrganizationEditContainer extends Component {
    componentDidMount() {
        this.props.fetchOrganizationInfo(this.props.match.params.id);
    }

    render() {
        return (
            (this.props.organizationInfo)
                ? <NewOrganization isEdit organizationInfo={this.props.organizationInfo} />
                : <Preloader fullScreen open color="primary"/>
        );
    };
};

const mapStateToProps = (state) => {
    return {
        organizationInfo: state.org.organizationInfo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchOrganizationInfo: (organigationId) => dispatch(actions.fetchOrganizationInfo(organigationId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrganizationEditContainer));