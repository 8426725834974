export const insertValueIntoArrayElement = (object, elementName, value) => {
    var updatedForm = { ...object };
    var updatedArray = [...object[elementName]];
    updatedArray.push(value);
    updatedForm[elementName] = updatedArray;
    return updatedForm;
};

export const deleteValueFromArrayElement = (object, elementName, index) => {
    var updatedForm = { ...object };
    var updatedArray = [...object[elementName]];
    updatedArray.splice(index, 1);
    updatedForm[elementName] = updatedArray;
    return updatedForm;
};
