import React from 'react';
import { Grid, Box } from '@material-ui/core';

const property = (props) => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm="auto">
                <Box fontWeight="fontWeightBold">
                    {props.label}:
                </Box>
            </Grid>
            
            <Grid item xs={12} sm="auto">
                <Box>
                    {props.value}
                </Box>
            </Grid>
            
        </Grid>
    );
};

export default property;