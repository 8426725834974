import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Preloader from '../../containers/Preloader';


const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: "250px", height: "100%"
    }
}));
const StatisticsItem = (props) => {
    const classes = useStyles();
    let count = props.count;
    const progress = (<Preloader color="primary" />);
    if (props.count === null || props.loading) {
        count = (<Preloader color="primary" />);
    }
    return (
        <Card className={classes.root}>
            <CardContent>
                <Typography variant="h4" component="h2" color="primary">
                    {count}
                </Typography>
                <Typography variant="h5" component="h2">
                    {props.label}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default StatisticsItem;