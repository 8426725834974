import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    messageContainer: {
        width: "auto",
        ['@media (max-width:576px)']: {
            width: "100%"
        }
    },
}));

const PreviewGrid = (props) => {
    const classes = useStyles();
    const previewItems = props.shownPreviews.map(item => {
        return (
            <Grid className={classes.messageContainer} item key={props.getPreviewKeyFunction(item)}>
                {props.previewRenderFunction(item)}
            </Grid>
        );
    });

    const showMore = (props.enableShowMore && (props.totalFilteredCount > props.maxPossibleCount) ) ? (
        <Grid item key="actions" xs={12} style={{ marginTop: "10px" }}>
            <Button variant="outlined" color="primary" onClick={props.onShowMore} style={{ display: "block", width: "100%" }}>
                Показать больше
            </Button>
        </Grid>) : null;
    return (
        <Grid container>
            <Grid item key="items" container justify="center" spacing={2}>
                {previewItems}
            </Grid>
            {showMore}
        </Grid>
    );
};

export default withRouter(PreviewGrid);