import React, { Component } from 'react';
import { Card, CardHeader, CardContent, Typography, Grid, Box, IconButton} from '@material-ui/core';
import DocumentList from '../UI/DocumentList/DocumentList';
import { withRouter } from 'react-router-dom';
import ImageGrid from '../UI/ImageGrid';
import PersonBadge from '../UI/Badges/PersonBadge';
import Edit from '@material-ui/icons/Edit';
import Linkify from 'react-linkify';

class NewsDetails extends Component {

    componentDidMount() {
    }

    renderImagesAndDocuments() {
        const hasImages = this.props.images.length !== 0;
        const hasDocuments = this.props.documents.length !== 0;
        if (!hasImages && !hasDocuments) {
            return null;
        }
        let images = !hasImages ? null : (
            <Grid item xs={12} sm={6}>
                <Typography variant="h6" component="h6">
                    Прикрепленные изображения
                                        </Typography>
                {this.renderImages()}
            </Grid>
        );
        let documents = !hasDocuments ? null : (
            <Grid item xs={12} sm={6}>
                <Typography variant="h6" component="h6">
                    Прикрепленные документы
                </Typography>
                {this.renderDocuments()}
            </Grid>
        );
        return (
            <Grid item xs={12}>
                <Grid container>
                    {images}
                    {documents}
                </Grid>
            </Grid>
        );
    }

    handleEditNews = () => {
        this.props.history.push({
            pathname: `edit/${this.props.match.params.id}`
        });
    }

    renderImages() {
        return (<ImageGrid images={this.props.images} />);
    }

    renderDocuments() {
        return (<DocumentList documents={this.props.documents} onDownloadDocument={this.props.onDownloadDocument} />);
    }

    render() {
        const authority = this.props.authority
            ? (
                <Grid item xs={12} className='personalBageContainer'>
                    <PersonBadge
                        name={this.props.authority}
                    />
                </Grid>
            ) : null;

        const editBtn = this.props.showEditBtn
            ? (<Box>
                <IconButton onClick={(event) => { this.handleEditNews(event) }}><Edit /></IconButton>
            </Box>)
            : null;
        return (
                <Card id="NewsDetails">
                    <CardHeader title={this.props.header}
                        subheader={this.props.creationDate}
                        action={editBtn}/>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12} sm={7}>
                                        <Grid container direction="column" alignItems="flex-start">
                                            <Grid item xs={12}>
                                            <Typography variant="body1" component="p" style={{ whiteSpace: 'pre-line'}}>
                                                    <Linkify>
                                                        {this.props.text}
                                                    </Linkify>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {this.renderImagesAndDocuments()}
                            {authority}
                        </Grid>
                    </CardContent>

                </Card >
        );
    }

};

export default withRouter(NewsDetails);