import React from 'react';
import TextBadge from '../UI/Badges/TextBadge';

const getBadgeColor = () => ({
    "На модерации": "default",
    "Передано исполнителю": "default",
    "Отклонено": "default",
    "Исполнено": "default",
    "На доработке": "default"
});

function StatusBadge({ status }) {
    return (<TextBadge color={getBadgeColor[status]} label={status} />) ;
}

export default StatusBadge;