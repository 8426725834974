export const FETCH_MESSAGE_START = "FETCH_MESSAGE_START";
export const FETCH_MESSAGE_SUCCESS = "FETCH_MESSAGE_SUCCESS";
export const FETCH_MESSAGE_FAIL = "FETCH_MESSAGE_FAIL";

export const RESET_MESSAGES = "RESET_MESSAGES";

export const FETCH_TOTAL_FILTERED_MESSAGES_COUNT_SUCCES = "FETCH_TOTAL_FILTERED_MESSAGES_COUNT_SUCCES";
export const CHANGE_TOTAL_MESSAGESS_COUNT = "CHANGE_TOTAL_MESSAGESS_COUNT";


export const FETCH_MESSAGE_PREVIEWS_START = "FETCH_MESSAGE_PREVIEWS_START";
export const FETCH_MESSAGE_PREVIEWS_SUCCESS = "FETCH_MESSAGE_PREVIEWS_SUCCESS";
export const FETCH_MESSAGE_PREVIEWS_FAIL = "FETCH_MESSAGE_PREVIEWS_FAIL";

export const FETCH_MESSAGE_DOCUMENT_START = "FETCH_MESSAGE_DOCUMENT_START";
export const FETCH_MESSAGE_DOCUMENT_SUCCESS = "FETCH_MESSAGE_DOCUMENT_SUCCESS";
export const FETCH_MESSAGE_DOCUMENT_FAIL = "FETCH_MESSAGE_DOCUMENT_FAIL";

export const SET_MESSAGE_FILTER_VALUE = "SET_MESSAGE_FILTER_VALUE";
export const TOGGLE_MESSAGE_FILTER = "TOGGLE_MESSAGE_FILTER";

export const UPDATE_MESSAGE = "UPDATE_MESSAGE";

export const SET_PRIVATE_MESSAGE_FILTER_VALUE = "SET_PRIVATE_MESSAGE_FILTER_VALUE";
export const TOGGLE_PRIVATE_MESSAGE_FILTER = "TOGGLE_PRIVATE_MESSAGE_FILTER";

export const FETCH_MESSAGE_STATUSES_START = "FETCH_MESSAGE_STATUSES_START";
export const FETCH_MESSAGE_STATUSES_SUCCESS = "FETCH_MESSAGE_STATUSES_SUCCESS";
export const FETCH_MESSAGE_STATUSES_FAIL = "FETCH_MESSAGE_STATUSES_FAIL";

export const FETCH_MESSAGE_PUBLIC_STATUSES_START = "FETCH_MESSAGE_PUBLIC_STATUSES_START";
export const FETCH_MESSAGE_PUBLIC_STATUSES_SUCCESS = "FETCH_MESSAGE_PUBLIC_STATUSES_SUCCESS";
export const FETCH_MESSAGE_PUBLIC_STATUSES_FAIL = "FETCH_MESSAGE_PUBLIC_STATUSES_FAIL";

export const FETCH_MESSAGE_CATEGORIES_START = "FETCH_MESSAGE_CATEGORIES_START";
export const FETCH_MESSAGE_CATEGORIES_SUCCESS = "FETCH_MESSAGE_CATEGORIES_SUCCESS";
export const FETCH_MESSAGE_CATEGORIES_FAIL = "FETCH_MESSAGE_CATEGORIES_FAIL";

export const FETCH_MESSAGE_TYPES_START = "FETCH_MESSAGE_TYPES_START";
export const FETCH_MESSAGE_TYPES_SUCCESS = "FETCH_MESSAGE_TYPES_SUCCESS";
export const FETCH_MESSAGE_TYPES_FAIL = "FETCH_MESSAGE_TYPES_FAIL";

export const FETCH_MESSAGE_TYPES_WITH_CATEGORIES_START = "FETCH_MESSAGE_TYPES_WITH_CATEGORIES_START";
export const FETCH_MESSAGE_TYPES_WITH_CATEGORIES_SUCCESS = "FETCH_MESSAGE_TYPES_WITH_CATEGORIES_SUCCESS";
export const FETCH_MESSAGE_TYPES_WITH_CATEGORIES_FAIL = "FETCH_MESSAGE_TYPES_WITH_CATEGORIES_FAIL";

export const FETCH_CONTRACTORS_START = "FETCH_CONTRACTORS_START";
export const FETCH_CONTRACTORS_SUCCESS = "FETCH_CONTRACTORS_SUCCESS";
export const FETCH_CONTRACTORS_FAIL = "FETCH_CONTRACTORS_FAIL";

export const CHANGE_MESSAGES_PAGE_START = "CHANGE_MESSAGES_PAGE_START";
export const CHANGE_MESSAGES_PAGE_SUCCESS = "CHANGE_MESSAGES_PAGE_SUCCESS";
export const CHANGE_MESSAGES_PAGE_FAIL = "CHANGE_MESSAGES_PAGE_FAIL";

export const INIT_MESSAGE_PRIVATE_FILTER = "INIT_MESSAGE_PRIVATE_FILTER";
export const INIT_MESSAGE_PUBLIC_FILTER = "INIT_MESSAGE_PUBLIC_FILTER";

export const FETCH_MESSAGE_COUNT = "FETCH_MESSAGE_COUNT";

export const FETCH_MESSAGE_COMMENTS_START = "FETCH_MESSAGE_COMMENTS_START";
export const FETCH_MESSAGE_COMMENTS_SUCCESS = "FETCH_MESSAGE_COMMENTS_SUCCESS";
export const FETCH_MESSAGE_COMMENTS_FAIL = "FETCH_MESSAGE_COMMENTS_FAIL";

export const POST_MESSAGE_COMMENT_START = "POST_MESSAGE_COMMENT_START";
export const POST_MESSAGE_COMMENT_SUCCESS = "POST_MESSAGE_COMMENT_SUCCESS";
export const POST_MESSAGE_COMMENT_FAIL = "POST_MESSAGE_COMMENT_FAIL";

export const INIT_MESSAGE_MODERATION_FORM = "INIT_MESSAGE_MODERATION_FORM";

export const SET_MESSAGE_CONTRACTOR_SUCCESS = "SET_MESSAGE_CONTRACTOR_SUCCESS";
export const SET_MESSAGE_CONTRACTOR_FAIL = "SET_MESSAGE_CONTRACTOR_FAIL";

export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";

export const FETCH_MESSAGE_RESPONSE_START = "FETCH_MESSAGE_RESPONSE_START";
export const FETCH_MESSAGE_RESPONSE_SUCCESS = "FETCH_MESSAGE_RESPONSE_SUCCESS";
export const FETCH_MESSAGE_RESPONSE_FAIL = "FETCH_MESSAGE_RESPONSE_FAIL";

export const REJECT_MESSAGE = "REJECT_MESSAGE";
export const REJECT_MESSAGE_SUCCESS = "REJECT_MESSAGE_SUCCESS";
export const REJECT_MESSAGE_FAIL = "REJECT_MESSAGE_FAIL";

export const APPROVE_MESSAGE_RESPONSE = "APPROVE_MESSAGE_RESPONSE";
export const APPROVE_MESSAGE_RESPONSE_SUCCESS = "APPROVE_MESSAGE_RESPONSE_SUCCESS";
export const APPROVE_MESSAGE_RESPONSE_FAIL = "APPROVE_MESSAGE_RESPONSE_FAIL";

export const POST_MESSAGE_RESPONSE = "POST_MESSAGE_RESPONSE";
export const POST_MESSAGE_RESPONSE_SUCCESS = "POST_MESSAGE_RESPONSE_SUCCESS";
export const POST_MESSAGE_RESPONSE_FAIL = "POST_MESSAGE_RESPONSE_FAIL";

export const FETCH_RESPONSE_STATUSES_START = "FETCH_RESPONSE_STATUSES_START";
export const FETCH_RESPONSE_STATUSES_SUCCESS = "FETCH_RESPONSE_STATUSES_SUCCESS";
export const FETCH_RESPONSE_STATUSES_FAIL = "FETCH_RESPONSE_STATUSES_FAIL";

export const FETCH_VOTING_PREVIEWS_START = "FETCH_VOTING_PREVIEWS_START";
export const FETCH_VOTING_PREVIEWS_SUCCESS = "FETCH_VOTING_PREVIEWS_SUCCESS";
export const FETCH_VOTING_PREVIEWS_FAIL = "FETCH_VOTING_PREVIEWS_FAIL";

export const FETCH_TOTAL_FILTERED_VOTINGS_COUNT_SUCCES = "FETCH_TOTAL_FILTERED_VOTINGS_COUNT_SUCCESS";
export const CHANGE_TOTAL_VOTINGS_COUNT = "CHANGE_TOTAL_VOTINGS_COUNT";

export const FETCH_VOTING_START = "FETCH_VOTING_START";
export const FETCH_VOTING_SUCCESS = "FETCH_VOTING_SUCCESS";
export const FETCH_VOTING_FAIL = "FETCH_VOTING_FAIL";

export const FETCH_MUNICIPAL_SETTLEMENTS_START = "FETCH_MUNICIPAL_SETTLEMENTS_START";
export const FETCH_MUNICIPAL_SETTLEMENTS_SUCCESS = "FETCH_MUNICIPAL_SETTLEMENTS_SUCCESS";
export const FETCH_MUNICIPAL_SETTLEMENTS_FAIL = "FETCH_MUNICIPAL_SETTLEMENTS_FAIL";

export const FETCH_VOTING_TYPES_START = "FETCH_VOTING_TYPES_START";
export const FETCH_VOTING_TYPES_SUCCESS = "FETCH_VOTING_TYPES_SUCCESS";
export const FETCH_VOTING_TYPES_FAIL = "FETCH_VOTING_TYPES_FAIL";

export const POST_VOTING_START = "POST_VOTING_START";
export const POST_VOTING_SUCCESS = "POST_VOTING_SUCCESS";
export const POST_VOTING_FAIL = "POST_VOTING_FAIL";

export const REJECT_VOTING_START = "REJECT_VOTING_START";
export const REJECT_VOTING_SUCCESS = "REJECT_VOTING_SUCCESS";
export const REJECT_VOTING_FAIL = "REJECT_VOTING_FAIL";

export const APPROVE_VOTING_START = "APPROVE_VOTING_START";
export const APPROVE_VOTING_SUCCESS = "APPROVE_VOTING_SUCCESS";
export const APPROVE_VOTING_FAIL = "APPROVE_VOTING_FAIL";

export const FETCH_VOTINGS_START = "FETCH_VOTINGS_START";
export const FETCH_VOTINGS_SUCCESS = "FETCH_VOTINGS_SUCCESS";
export const FETCH_VOTINGS_FAIL = "FETCH_VOTINGS_FAIL";

export const FETCH_VOTINGS_COUNT_START = "FETCH_VOTING_COUNT_START";
export const FETCH_VOTINGS_COUNT_SUCCESS = "FETCH_VOTINGS_COUNT_SUCCESS";
export const FETCH_VOTINGS_COUNT_FAIL = "FETCH_VOTINGS_COUNT_FAIL";

export const RESET_NEWS = "RESET_NEWS";

export const FETCH_NEWS_START = "FETCH_NEWS_START";
export const FETCH_NEWS_SUCCESS = "FETCH_NEWS_SUCCESS";
export const FETCH_NEWS_FAIL = "FETCH_NEWS_FAIL";

export const FETCH_NEWS_DETAILS_START = "FETCH_NEWS_DETAILS_START";
export const FETCH_NEWS_DETAILS_SUCCESS = "FETCH_NEWS_DETAILS_SUCCESS";
export const FETCH_NEWS_DETAILS_FAIL = "FETCH_NEWS_DETAILS_FAIL";

export const POST_NEWS_START = "POST_NEWS_START";
export const POST_NEWS_SUCCESS = "POST_NEWS_SUCCESS";
export const POST_NEWS_FAIL = "POST_NEWS_FAIL";

export const FETCH_NEWS_DOCUMENT_START = "FETCH_NEWS_DOCUMENT_START";
export const FETCH_NEWS_DOCUMENT_SUCCESS = "FETCH_NEWS_DOCUMENT_SUCCESS";
export const FETCH_NEWS_DOCUMENT_FAIL = "FETCH_NEWS_DOCUMENT_FAIL";

export const CHANGE_TOTAL_NEWS_COUNT = "CHANGE_TOTAL_NEWS_COUNT";
export const FETCH_TOTAL_FILTERED_NEWS_COUNT_SUCCES = "FETCH_TOTAL_FILTERED_NEWS_COUNT_SUCCES";

export const CHANGE_VOTINGS_PAGE_START = "CHANGE_VOTINGS_PAGE_START";
export const CHANGE_VOTINGS_PAGE_SUCCESS = "CHANGE_VOTINGS_PAGE_SUCCESS";
export const CHANGE_VOTINGS_PAGE_FAIL = "CHANGE_VOTINGS_PAGE_FAIL";

export const FETCH_VOTINGS_COUNT = "FETCH_VOTINGS_COUNT";

export const FETCH_VOTING_STATUSES_START = "FETCH_VOTING_STATUSES_START";
export const FETCH_VOTING_STATUSES_SUCCESS = "FETCH_VOTING_STATUSES_SUCCESS";
export const FETCH_VOTING_STATUSES_FAIL = "FETCH_VOTING_STATUSES_FAIL";

export const SET_VOTING_FILTER_VALUE = "SET_VOTING_FILTER_VALUE";
export const TOGGLE_VOTING_FILTER = "TOGGLE_VOTING_FILTER";

export const SET_PRIVATE_VOTING_FILTER_VALUE = "SET_PRIVATE_VOTING_FILTER_VALUE";
export const TOGGLE_PRIVATE_VOTING_FILTER = "TOGGLE_PRIVATE_VOTINGE_FILTER";

export const INIT_VOTING_PRIVATE_FILTER = "INIT_VOTING_PRIVATE_FILTER";
export const INIT_VOTING_PUBLIC_FILTER = "INI_VOTING_PUBLIC_FILTER";

export const FETCH_VOTING_DOCUMENT_START = "FETCH_VOTING_DOCUMENT_START";
export const FETCH_VOTING_DOCUMENT_SUCCESS = "FETCH_VOTING_DOCUMENT_SUCCESS";
export const FETCH_VOTING_DOCUMENT_FAIL = "FETCH_VOTING_DOCUMENT_FAIL";

export const FETCH_PUBLIC_STATISTICS_START = "FETCH_PUBLIC_STATISTICS_START";
export const FETCH_PUBLIC_STATISTICS_SUCCESS = "FETCH_PUBLIC_STATISTICS_SUCCESS";
export const FETCH_PUBLIC_STATISTICS_FAIL = "FETCH_PUBLIC_STATISTICS_FAIL";

export const FETCH_MODERATOR_STATISTICS_START = "FETCH_MODERATOR_STATISTICS_START";
export const FETCH_MODERATOR_STATISTICS_SUCCESS = "FETCH_MODERATOR_STATISTICS_SUCCESS";
export const FETCH_MODERATOR_STATISTICS_FAIL = "FETCH_MODERATOR_STATISTICS_FAIL";

export const FETCH_PERFORMER_STATISTICS_START = "FETCH_PUBLIC_PERFORMER_START";
export const FETCH_PERFORMER_STATISTICS_SUCCESS = "FETCH_PERFORMER_STATISTICS_SUCCESS";
export const FETCH_PERFORMER_STATISTICS_FAIL = "FETCH_PERFORMER_STATISTICS_FAIL";

export const FETCH_USER_MESSAGES_START = "FETCH_USER_MESSAGES_START";
export const FETCH_USER_MESSAGES_SUCCESS = "FETCH_USER_MESSAGES_SUCCESS";
export const FETCH_USER_MESSAGES_FAIL = "FETCH_USER_MESSAGES_FAIL";

export const SET_USER_MESSAGE_FILTER_VALUE = "SET_USER_MESSAGE_FILTER_VALUE";
export const TOGGLE_USER_MESSAGE_FILTER = "TOGGLE_USER_MESSAGE_FILTER";

export const FETCH_USER_VOTINGS_START = "FETCH_USER_VOTINGS_START";
export const FETCH_USER_VOTINGS_SUCCESS = "FETCH_USER_VOTINGS_SUCCESS";
export const FETCH_USER_VOTINGS_FAIL = "FETCH_USER_VOTINGS_FAIL";

export const SET_USER_VOTING_FILTER_VALUE = "SET_USER_VOTING_FILTER_VALUE";
export const TOGGLE_USER_VOTING_FILTER = "TOGGLE_USER_VOTING_FILTER";

export const CHANGE_ORGANIZATIONS_PAGE_START = "CHANGE_ORGANIZATIONS_PAGE_START";
export const CHANGE_ORGANIZATIONS_PAGE_SUCCESS = "CHANGE_ORGANIZATIONS_PAGE_SUCCESS";
export const CHANGE_ORGANIZATIONS_PAGE_FAIL = "CHANGE_ORGANIZATIONS_PAGE_FAIL";

export const INIT_ORGANIZATION_PRIVATE_FILTER = "INIT_ORGANIZATION_PRIVATE_FILTER";
export const INIT_ORGANIZATION_PUBLIC_FILTER = "INIT_ORGANIZATION_PUBLIC_FILTER";

export const SET_ORGANIZATION_FILTER_VALUE = "SET_ORGANIZATION_FILTER_VALUE";
export const TOGGLE_ORGANIZATION_FILTER = "TOGGLEE_ORGANIZATION_FILTER";

export const FETCH_ORGANIZATIONS_COUNT = "FETCH_ORGANIZATIONS_COUNT";
export const FETCH_ORGANIZATION_INFO = "FETCH_ORGANIZATION_INFO";

export const SET_MESSAGE_MAP_FILTER_VALUE = "SET_MESSAGE_MAP_FILTER_VALUE";
export const TOGGLE_MESSAGE_MAP_FILTER = "TOGGLE_MESSAGE_MAP_FILTER";

export const FETCH_SCOPES_START = "FETCH_SCOPES_START";
export const FETCH_SCOPES_SUCCESS = "FETCH_SCOPES_SUCCESS";
export const FETCH_SCOPES_FAIL = "FETCH_SCOPES_FAIL";

export const POST_ORGANIZATION_START = "POST_ORGANIZATION_START";
export const POST_ORGANIZATION_SUCCESS = "POST_ORGANIZATION_SUCCESS";
export const POST_ORGANIZATION_FAIL = "POST_ORGANIZATION_FAIL";

export const DELETE_ORGANIZATION_SUCCESS = "DELETE_ORGANIZATION_SUCCESS";
export const TOGGLE_DISABLED = "TOGGLE_DISABLED";

export const POST_MESSAGE_TYPE_START = "POST_MESSAGE_TYPE_START";
export const POST_MESSAGE_TYPE_SUCCESS = "POST_MESSAGE_TYPE_SUCCESS";
export const POST_MESSAGE_TYPE_FAIL = "POST_MESSAGE_TYPE_FAIL";

export const LOGIN = "LOGIN";

export const VOTE_START = "VOTE_START";
export const VOTE_SUCCESS = "VOTE_SUCCESS";
export const VOTE_FAIL = "VOTE_FAIL";
export const UPDATE_VOTING = "UPDATE_VOTING";

export const FETCH_USER_ANSWER_ON_VOTE_SUCCESS = "FETCH_USER_ANSWER_ON_VOTE_SUCCESS";

export const FETCH_PERFORMERS_START = "FETCH_PERFORMERS_START";
export const FETCH_PERFORMERS_SUCCESS = "FETCH_PERFORMERS_SUCCESS";
export const FETCH_PERFORMERS_FAIL = "FETCH_PERFORMERS_FAIL";

export const FETCH_PERFORMER_INFO_START = "FETCH_PERFORMER_INFO_START";
export const FETCH_PERFORMER_INFO_SUCCESS = "FETCH_PERFORMER_INFO_SUCCESS";
export const FETCH_PERFORMER_INFO_FAIL = "FETCH_PERFORMER_INFO_FAIL";

export const FETCH_WORK_PROFILE_START = "FETCH_WORK_PROFILE_START";
export const FETCH_WORK_PROFILE_SUCCESS = "FETCH_WORK_PROFILE_SUCCESS";
export const FETCH_WORK_PROFILE_FAIL = "FETCH_WORK_PROFILE_FAIL";

export const SET_WORK_PROFILE_SUCCESS = "SET_WORK_PROFILE_SUCCESS";
export const SET_WORK_PROFILE_FAIL = "SET_WORK_PROFILE_FAIL";

export const FETCH_FAVORITES_START = "FETCH_FAVORITES_START";
export const FETCH_FAVORITES_SUCCESS = "FETCH_FAVORITES_SUCCESS";
export const FETCH_FAVORITES_FAIL = "FETCH_FAVORITES_FAIL";

export const SET_FAVORITES = "SET_FAVORITES";

export const SHOW_MORE_FAVORITES = "SHOW_MORE_FAVORITES";
export const CHANGE_FAVORITES_FILTER = "CHANGE_FAVORITES_FILTER";

export const SET_MESSAGE_IN_FAVORITES_STATE_SUCCESS = "SET_MESSAGE_IN_FAVORITES_STATE_SUCCESS";

export const SEND_MESSAGE_TO_REWORK_START = "SEND_MESSAGE_TO_REWORK_START";
export const SEND_MESSAGE_TO_REWORK_SUCCESS = "SEND_MESSAGE_TO_REWORK_SUCCESS";
export const SEND_MESSAGE_TO_REWORK_FAIL = "SEND_MESSAGE_TO_REWORK_FAIL";

export const FETCH_REPORTS_SUCCESS = "FETCH_REPORTS_SUCCESS";
export const FETCH_REPORT_DOCUMENT_SUCCESS = "FETCH_REPORT_DOCUMENT_SUCCESS";

export const GET_TERRITORY = "GET_TERRITORY";
export const SET_TERRITORY = "SET_TERRITORY";

export const ASSESS_MESSAGE_RESPONSE_SUCCESS = "ASSESS_MESSAGE_RESPONSE_SUCCESS";
export const ASSESS_MESSAGE_RESPONSE_FAIL = "ASSESS_MESSAGE_RESPONSE_FAIL";

export const FETCH_PERSONAL_AREA_MESSAGE_STATUSES_SUCCESS = "FETCH_PERSONAL_AREA_MESSAGE_STATUSES_SUCCESS";
export const FETCH_PERSONAL_AREA_MESSAGE_STATUSES_FAIL = "FETCH_PERSONAL_AREA_MESSAGE_STATUSES_FAIL";

export const FETCH_PERSONAL_AREA_VOTING_STATUSES_SUCCESS = "FETCH_PERSONAL_AREA_VOTING_STATUSES_SUCCESS";
export const FETCH_PERSONAL_AREA_VOTING_STATUSES_FAIL = "FETCH_PERSONAL_AREA_VOTING_STATUSES_FAIL";

export const SHOW_ERROR_PAGE = "SHOW_ERROR_PAGE";
export const HIDE_ERROR_PAGE = "HIDE_ERROR_PAGE";

export const RESET_VOTINGS = "RESET_VOTINGS";

export const FETCH_GROUPED_MESSAGE_TYPES_SUCCESS = "FETCH_GROUPED_MESSAGE_TYPES_SUCCESS";

export const SET_PERFORMER_MESSAGE_TYPES_START = "SET_PERFORMER_MESSAGE_TYPES_START";
export const SET_PERFORMER_MESSAGE_TYPES_SUCCESS = "SET_PERFORMER_MESSAGE_TYPES_SUCCESS";
export const SET_PERFORMER_MESSAGE_TYPES_FAIL = "SET_PERFORMER_MESSAGE_TYPES_FAIL";

export const FETCH_MESSAGE_TYPES_BY_TERRITORY = "FETCH_MESSAGE_TYPES_BY_TERRITORY";

export const SET_TERRITORY_MESSAGE_TYPES_START = "SET_TERRITORY_MESSAGE_TYPES_START";
export const SET_TERRITORY_MESSAGE_TYPES_SUCCESS = "SET_TERRITORY_MESSAGE_TYPES_SUCCESS";
export const SET_TERRITORY_MESSAGE_TYPES_FAIL = "SET_TERRITORY_MESSAGE_TYPES_FAIL";

export const FETCH_MESSAGE_MAP_STATUSES_START = "FETCH_MESSAGE_MAP_STATUSES_START";
export const FETCH_MESSAGE_MAP_STATUSES_SUCCESS = "FETCH_MESSAGE_MAP_STATUSES_SUCCESS";
export const FETCH_MESSAGE_MAP_STATUSES_FAIL = "FETCH_MESSAGE_MAP_STATUSES_FAIL";

export const FETCH_USER_START = "FETCH_USER_START";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAIL = "FETCH_USER_FAIL";

export const LOGOUT = "LOGOUT";