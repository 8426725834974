import * as actionTypes from './actionTypes';
import messageApi from '../api/messageApi';
import votingApi from '../api/votingApi';
import { addSuccessNotification, addErrorNotification } from './notify';

export const fetchUserMessagesSuccess = (messages) => {
    return {
        type: actionTypes.FETCH_USER_MESSAGES_SUCCESS,
        messages: messages
    };
};

export const fetchUserMessagesFail = (error) => {
    return {
        type: actionTypes.FETCH_USER_MESSAGES_FAIL,
        error: error
    };
};

export const fetchUserMessagesStart = () => {
    return {
        type: actionTypes.FETCH_USER_MESSAGES_START
    };
};

export const fetchUserMessages = (skip, take) => {
    return (dispatch, getState) => {
        dispatch(fetchUserMessagesStart());
        const state = getState();
        messageApi.getUserMessages(skip, take, { ...state.my.messageFilter })
            .then(res => {
                dispatch(fetchUserMessagesSuccess(res.data));
            })
            .catch(err => {
                dispatch(fetchUserMessagesFail(err));
            });
    };
};


export const updateUserMessageFilterValue = (filter, value) => {
    return {
        type: actionTypes.SET_USER_MESSAGE_FILTER_VALUE,
        filter: filter,
        value: value
    };
};

export const setUserMessageFilterValue = (filter, value) => {
    return (dispatch, getState) => {
        dispatch(updateUserMessageFilterValue(filter, value));
        var currentState = getState();
        dispatch(fetchUserMessages(0, currentState.my.messagesTake));
    };
};

export const toggleUserMessageFilter = () => {
    return {
        type: actionTypes.TOGGLE_USER_MESSAGE_FILTER
    };
};

export const fetchUserVotingsSuccess = (votings) => {
    return {
        type: actionTypes.FETCH_USER_VOTINGS_SUCCESS,
        votings: votings
    };
};

export const fetchUserVotingsFail = (error) => {
    return {
        type: actionTypes.FETCH_USER_VOTINGS_FAIL,
        error: error
    };
};

export const fetchUserVotingsStart = () => {
    return {
        type: actionTypes.FETCH_USER_VOTINGS_START
    };
};

export const fetchUserVotings = (skip, take) => {
    return (dispatch, getState) => {
        dispatch(fetchUserVotingsStart());
        const state = getState();
        votingApi.getUserVotings(skip, take, { ...state.my.votingFilter })
            .then(res => {
                dispatch(fetchUserVotingsSuccess(res.data));
            })
            .catch(err => {
                dispatch(fetchUserVotingsFail(err));
            });
    };
};


export const updateUserVotingFilterValue = (filter, value) => {
    return {
        type: actionTypes.SET_USER_VOTING_FILTER_VALUE,
        filter: filter,
        value: value
    };
};

export const setUserVotingFilterValue = (filter, value) => {
    return (dispatch, getState) => {
        dispatch(updateUserVotingFilterValue(filter, value));
        var currentState = getState();
        dispatch(fetchUserVotings(currentState.my.votingsSkip, currentState.my.votingsTake));
    };
};

export const toggleUserVotingFilter = () => {
    return {
        type: actionTypes.TOGGLE_USER_VOTING_FILTER
    };
};

export const fetchFavoritesStart = () => {
    return {
        type: actionTypes.FETCH_FAVORITES_START,
    };
};

export const fetchFavoritesFail = (err) => {
    return {
        type: actionTypes.FETCH_FAVORITES_FAIL,
        error: err
    };
};

export const setFavorites = (messages, votings) => {
    return {
        type: actionTypes.SET_FAVORITES,
        messages: messages,
        votings: votings
    };
};

export const fetchFavorites = () => {
    return (dispatch) => {
        dispatch(fetchFavoritesStart());
        Promise.all(
            [messageApi.getFavoriteMessages(),
            votingApi.getFavoriteVotings()]
        ).then(results => {
            dispatch(setFavorites(results[0].data, results[1].data));
        }).catch(err => {
            console.log("error: ", err);
            dispatch(fetchFavoritesFail(err));
        });
    };
};

export const showMoreFavorites = () => {
    return {
        type: actionTypes.SHOW_MORE_FAVORITES
    };
};

export const changeFavoritesFilter = (showMessages, showVotings) => {
    return {
        type: actionTypes.CHANGE_FAVORITES_FILTER,
        showMessages: showMessages,
        showVotings: showVotings
    };
};

export const getSelectedTerritory = (user) => {
    return {
        type: actionTypes.GET_TERRITORY,
        user: user
    };
};

export const setSelectedTerritory = (territory) => {
    return {
        type: actionTypes.SET_TERRITORY,
        territory: territory
    };
};

export const fetchPersonalAreaStatusesSuccess = (statuses) => {
    return {
        type: actionTypes.FETCH_PERSONAL_AREA_MESSAGE_STATUSES_SUCCESS,
        statuses: statuses
    };
};

export const fetchPersonalAreaStatusesFail = (err) => {
    return {
        type: actionTypes.FETCH_PERSONAL_AREA_MESSAGE_STATUSES_FAIL,
        error: err
    };
};

export const fetchPersonalAreaMessageStatuses = () => {
    return (dispatch) => {
        messageApi.getPersonalAreaStatuses().then(result => {
            dispatch(fetchPersonalAreaStatusesSuccess(result.data));
        }).catch(err => {
            console.log(err);
            dispatch(fetchPersonalAreaStatusesFail(err));
        });
    };
};


export const fetchPersonalAreaVotingStatusesSuccess = (statuses) => {
    return {
        type: actionTypes.FETCH_PERSONAL_AREA_VOTING_STATUSES_SUCCESS,
        statuses: statuses
    };
};

export const fetchPersonalAreaVotingStatusesFail = () => {
    return {
        type: actionTypes.FETCH_PERSONAL_AREA_VOTING_STATUSES_FAIL
    };
};

export const fetchPersonalAreaVotingStatuses = () => {
    return dispatch => {
        votingApi.getPersonalAreaStatuses()
            .then(res => {
                dispatch(fetchPersonalAreaVotingStatusesSuccess(res.data));
            })
            .catch(err => {
                dispatch(fetchPersonalAreaVotingStatusesFail(err));
            });
    };
};