export function createDraft(message) {
    return {
        id: message.id,
        messageTypeId: message.messageType.id,
        creationDate: message.creationDate,
        statusId: message.status.id,
        number: message.number,
        geometry: message.geometry,
        categoryId: message.category.id,
        messageText: message.text,
        images: message.images,
        documents: message.documents,
        coordinates: message.coordinates,
        gisGuid: message.gisGuid,
        municipalSettlementId: message.municipalSettlement.id,
        isPublic: message.isPublic,
        isFavorite: message.isFavorite
    };
    
}