import React, { Component } from 'react';
import Filter from '../../components/UI/Filter';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';

class MyOrganizationFilter extends Component {
    componentDidMount() {
        this.props.onFetchScopes();
    }

    render() {
        return (
            <Filter
                show={this.props.showFilter}
                filter={this.props.filter}
                form={this.props.form}
                values={this.props.filter}
                onFilterChange={(filter, value) => this.props.onFilterChange(filter, value)}
            />
        );
    };
};

const mapStateToProps = state => {
    return {
        filter: state.org.filter,
        municipalSettlements: state.cmn.municipalSettlements,
        showFilter: state.org.showFilter,
        form: {
            scopeId: {
                value: "",
                elementType: "select",
                config: {
                    label: "Сфера деятельности",
                    defaultDisplayValue: "Сфера деятельности не выбрана",
                    options: state.org.scopes,
                }
            },
            municipalSettlementId: {
                value: "",
                elementType: "select",
                config: {
                    label: "Территория",
                    defaultDisplayValue: "Территория не выбрана",
                    options: state.cmn.municipalSettlements,
                }
            }
        },
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchScopes: () => dispatch(actions.fetchScopes()),
        onFilterChange: (id, value) => dispatch(actions.setOrganizationFilterValue(id, value)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyOrganizationFilter);