import React, { Component } from 'react';
import PreviewGrid from '../../../../components/UI/Containers/PreviewGrid';
import MessagePreview from '../../../../components/Messages/MessagePreview';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions';
import Preloader from '../../../../containers/Preloader';

class MyMessages extends Component {
    componentDidMount() {
        this.props.onFetchMessages(this.props.skip, this.props.take);
    }

    componentDidUpdate(prevProps) {
    }

    render() {
        if (this.props.loading) {
            return (
                <Preloader fullScreen open color="primary"/>
            );
        }
        return (
            <PreviewGrid
                shownPreviews={this.props.messages}
                onShowMore={() => this.props.onFetchMessages(this.props.skip, this.props.take)}
                enableShowMore
                previewRenderFunction={(message) => {
                    return (<MessagePreview image={message.thumbNail}
                        type={message.type}
                        text={message.text}
                        status={message.status}
                        id={message.id}
                        number={message.number}
                        date={message.createDate}
                        isFavorite={message.isFavorite}
                        onToggleIsFavoriteStatus={() => this.props.onSetIsFavoriteStatus(message.id, !message.isFavorite)}
                        author={message.userName}
                    />);
                }}
                getLinkFunction={(message) => { return "/messages/" + message.id; }}
                getPreviewKeyFunction={(message) => { return message.id; }}
            />
        );
    }

};

const mapStateToProps = state => {
    return {
        messages: state.my.messages,
        loading: state.my.loading,
        skip: state.my.messagesSkip,
        take: state.my.messagesTake
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchMessages: (skip, take) => dispatch(actions.fetchUserMessages(skip, take)),
        onSetIsFavoriteStatus: (messageId, isFavorite) => dispatch(actions.setMessageIsFavoriteStatus(messageId, isFavorite))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyMessages);