import React from 'react';
import { TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import SettingsIcon from '@material-ui/icons/Settings';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    headCell: {
        fontWeight: 700
    }
}));

 const PerformersTable = (props) => {
    const classes = useStyles();

    const headCells = [
        { id: 'organization', disablePadding: false, label: 'Организация' },
        { id: 'name', disablePadding: false, label: 'ФИО' },
        { id: 'contacts', disablePadding: false, label: 'Контакты' },
        { id: 'messageTypes', disablePadding: false, label: 'Количество обрабатываемых типов сообщений' },
    ];

    const items =
        props.performers.map(perf => {
            return {
                id: perf.id,
                organization: perf.organization,
                name: perf.name,
                contacts: (
                    <React.Fragment >
                        {perf.email}<br/>
                        {perf.phoneNumber}
                    </React.Fragment>),
                messageTypes: perf.messageTypes.length
            };
        });
    
    return (
        <TableContainer>
            <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size='medium'
            >
                <TableHead>
                    <TableRow>
                        {headCells.map((headCell) => (
                            <TableCell
                                key={headCell.id}
                                align='left'
                                padding={headCell.disablePadding ? 'none' : 'default'}
                                className={classes.headCell}
                            >
                                {headCell.label}
                            </TableCell>
                           
                        ))}
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        items.map((item) => {
                            return (
                                <TableRow
                                    className={`${classes.row}`}
                                    tabIndex={-1}
                                    key={item.id}
                                >
                                    {
                                        headCells.map((headCell) => {
                                            let entry = item[headCell.id];
                                            return (
                                                <TableCell align="left" key={headCell.id}>{entry}</TableCell>
                                            );
                                        })
                                    }
                                    <TableCell align="center" key="view">
                                        <Link to={`performers/${item.id}`}><SettingsIcon/></Link>
                                    </TableCell>
                                </TableRow>
                            );
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default withRouter(PerformersTable);