import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import IconButtonFavorite from '../UI/IconButtonFavorite';
import StatusBadge from './VotingStatus';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import PersonBadge from '../UI/Badges/PersonBadge';
import { convertVotingStatus } from '../../utility/stringHelpers';
import VotingFavoriteIconButton from '../../containers/Voting/VotingFavoriteIconButton';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        borderRadius: 0,
        height: "100%",
        [theme.breakpoints.up('md')]: {
            width: 400,
        },
    },
    media: {
        height: 140
    },
    favorite: {
        marginLeft: 'auto'
    },
    status: {

    },
    header: {
        padding: '5px',
        fontSize: '1.2rem'
    },
    container: {
        height: "100%"
    },
    content: {
        height: '4rem'
    },
    author: {
        marginTop: 'auto',
        marginBottom: "auto",
        border: 'none',
    },
    cardHeader: {
        minHeight: '4rem'
    }
}));


const VotingPreview = ({
        voting }) => {
    const classes = useStyles();
    const image = voting.photo ? `data:image/jpg;base64,${voting.photo}` : "/images/no-photo.jpg";
    const isCreatedTitle = voting.title === "Опрос" ? "Размещён" : "Размещено";
    const statusTitle = convertVotingStatus(voting.type, voting.status);
    return (
        <Card className={classes.root} hoverable="true">

            <Grid className={classes.container} container direction="column" justify="space-between">

                <Grid item xs key="cardBody" container direction="column">

                        <Grid item>
                            <CardHeader
                                avatar={<StatusBadge style={{}} status={statusTitle} />}
                                subheader={`${isCreatedTitle} ${voting.createDate}`}
                                classes={{
                                    root: classes.cardHeader,
                                }}
                            />
                        </Grid>
                    <Grid item xs>
                        <Link to={"/votings/" + voting.id} >
                            <CardActionArea className={classes.fullHeight} >
                                <Grid container direction="column" justify="space-between" className={classes.fullHeight}>
                                    <Grid item>
                                        <Typography variant="h6" component="h6" className={classes.header} color="textPrimary">
                                            {voting.header}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Grid item>
                                            <CardMedia
                                                className={classes.media}
                                                image={image}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <CardContent className={classes.content}>

                                                <Typography variant="body2" component="p" color="textSecondary">
                                                    <strong>Дата подведения итогов:</strong> {voting.debriefingDate}

                                                </Typography>
                                            </CardContent>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardActionArea>
                        </Link> 
                    </Grid>
                </Grid>

                <Grid item key="cardActions">
                    
                    <CardActions disableSpacing>

                        <PersonBadge className={classes.author} name={voting.author} />
                        <VotingFavoriteIconButton
                            votingId={voting.id} votingType={voting.type}
                            isFavorite={voting.isFavorite}
                            className={classes.favorite}
                        />

                    </CardActions>
                </Grid>

            </Grid>
        </Card>

    );
};

export default VotingPreview;