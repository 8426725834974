import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    table: {
        minWidth: 750,
    },
    row: {
        '&:hover': {
            cursor: "pointer"
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

export default function PageableTable(props) {
    const classes = useStyles();

    const handleChangePage = (event, newPage) => {
        props.onChangePage(newPage);
    };

    const rowHeight = 53;
    const emptyRows = props.itemsPerPage - props.rows.length;
    console.log("itemsPerPage: ", props.itemsPerPage);
    console.log("count: ", props.count);
    return (
        <div className={classes.root}>

            <TableContainer>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size='medium'
                >
                    <TableHead>
                        <TableRow>
                            {props.headCells.map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                    align='left'
                                    padding={headCell.disablePadding ? 'none' : 'default'}
                                >
                                    {headCell.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            props.rows.map((row) => {
                                return (
                                    <TableRow
                                        hover
                                        className={`${classes.row} ${row.className}`}
                                        tabIndex={-1}
                                        key={row.fields[props.itemIdProp]}
                                        onClick={() => props.onItemClicked(row.fields[props.itemIdProp])}
                                    >
                                    {
                                            props.headCells.map((headCell) => {
                                                let entry = row.fields[headCell.id];
                                                return (
                                                    <TableCell align="left" key={headCell.id}>
                                                        <Box fontWeight={row.fontWeight}>
                                                            {entry}
                                                        </Box>
                                                        
                                                    </TableCell>
                                                );
                                        })
                                    }
                                    </TableRow>
                                );
                            })
                        }
                        {emptyRows > 0 && (
                            <TableRow style={{ height: rowHeight * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[props.itemsPerPage]}
                component="div"
                count={props.count}
                rowsPerPage={props.itemsPerPage}
                page={props.page}
                onChangePage={handleChangePage}
                labelRowsPerPage={props.labelItemsPerPage}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} из ${count}`}
            />
        </div>
    );
}