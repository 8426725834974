import React, { Component } from 'react';
import MessageTable from '../../components/Messages/MessageTable';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import FilterToolbar from '../../components/UI/FilterToolbar';
import { withRouter } from 'react-router-dom';
import Preloader from '../Preloader';

class MessageTableContainer extends Component {
    componentDidMount() {
        this.props.onChangePage(this.props.page);
    }

    componentDidUpdate(prevProps) {
        //if (prevProps.skip !== ) {
        //    this.props.onFetchMessages(this.props.skip, this.props.take);
        //}

    }

    onMessageClicked = (id) => {
        this.props.history.push({
            pathname: `messages/${id}`
        });
    };

    render() {
        let messages = null;
        if (this.props.loading) {
            messages = (
                <Preloader open fullScreen color="primary"/>
            );
        }
        
        if (!this.props.loading) {
            messages = (
                <Grid container>
                    <MessageTable
                        filter={this.props.filter}
                        showFilter={this.props.showFilter}
                        messages={this.props.messages}
                        page={this.props.page}
                        messagesPerPage={this.props.messagesPerPage}
                        onChangePage={(newPage) => this.props.onChangePage(newPage)}
                        onToggleFilter={this.props.onToggleFilter}
                        onMessageClicked={this.onMessageClicked}
                        count={this.props.count}
                    />
                </Grid>

            );
        }
        return (
            <React.Fragment>
                <FilterToolbar filter={this.props.filter} onToggleFilter={this.props.onToggleFilter} showFilter={this.props.showFilter} header="Сообщения"/>
                {messages}
            </React.Fragment>
        );
        
    }

};

const mapStateToProps = state => {
    return {
        messages: state.msg.messages,
        loading: state.msg.loading,
        skip: state.msg.skip,
        take: state.msg.take,
        showFilter: state.msg.showFilter,
        page: state.msg.page,
        messagesPerPage: state.msg.messagesPerPage,
        count: state.msg.messageCount
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onChangePage: (newPage) => dispatch(actions.changeMessagesPage(newPage)),
        onToggleFilter: () => dispatch(actions.toggleMessageFilter())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MessageTableContainer));