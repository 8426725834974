import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import FullMessage from '../../components/Messages/FullMessage/FullMessage';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import NewMessageStepper from '../../public/containers/Messages/NewMessageStepper';
import { canDo } from '../../components/Auth/Can';
import { createDraft } from '../../utility/messageHelpers';
import Preloader from '../Preloader';

class FullMessageContainer extends Component {

    componentDidMount() {
        if (this.props.id) {
            this.props.onFetchMessage(this.props.id);
        }
    }

    render(){
        let message = (
            <Preloader open fullScreen color="primary"/>
        );
        if (!this.props.loading && this.props.loadedMessage) {
            if (this.props.isDraft) {
                message = (
                    <NewMessageStepper draft={this.props.draft} />
                );
            }
            else {
                message = (
                    <FullMessage
                        editable={canDo(this.props.user, "message:edit", {
                            user: this.props.user,
                            authorId: this.props.loadedMessage.author.id,
                            messageStatus: this.props.loadedMessage.status.name
                        })}
                        id={this.props.loadedMessage.id}
                        author={this.props.loadedMessage.author}
                        messageType={this.props.loadedMessage.messageType.name}
                        creationDate={this.props.loadedMessage.creationDate}
                        status={this.props.loadedMessage.status.name}
                        number={this.props.loadedMessage.number}
                        geometry={this.props.loadedMessage.geometry}
                        category={this.props.loadedMessage.category.name}
                        text={this.props.loadedMessage.text}
                        images={this.props.loadedMessage.images}
                        documents={this.props.loadedMessage.documents}
                        response={this.props.loadedMessage.response}
                        rejectionReason={this.props.loadedMessage.rejectionReason}
                        coordinates={this.props.loadedMessage.coordinates}
                        gisGuid={this.props.loadedMessage.gisGuid}
                        isFavorite={this.props.loadedMessage.isFavorite}
                        toggleIsFavoriteStatus={() => this.props.onSetIsFavoriteStatus(this.props.loadedMessage.id, !this.props.loadedMessage.isFavorite)}
                        canAssessResponse={this.props.canAssessResponse}
                        onAssessResponse={(like) => this.props.onAssessResponse(this.props.loadedMessage.id, like)}
                        responseAssessment={this.props.loadedMessage.responseAssessment}
                    />
                );
            }
            
        }
        return (
            <Grid container justify="center">
                <Grid item xs={12} sm={8}>
                    {message}
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => {
    let isDraft = state.msg.loadedMessage
        && (state.msg.loadedMessage.status.name === "Черновик");
    let isDenied = state.msg.loadedMessage
        && (state.msg.loadedMessage.status.name === "Отклонено");
    return {
        isDraft: isDraft,
        isDenied: isDenied,
        draft: !isDraft ? null : createDraft(state.msg.loadedMessage),
        loadedMessage: state.msg.loadedMessage,
        loading: state.msg.loading,
        user: state.user,
        canAssessResponse: state.user.isAuthenticated && state.msg.loadedMessage && (state.user.id === state.msg.loadedMessage.authorId)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchMessage: (id) => dispatch(actions.fetchMessage(id)),
        onSetIsFavoriteStatus: (messageId, isFavorites) => dispatch(actions.setMessageIsFavoriteStatus(messageId, isFavorites)),
        onAssessResponse: (messageId, like) => dispatch(actions.assessMessageResponse(messageId, like))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FullMessageContainer);