import React, { Component } from 'react';
import { Grid, Button, TextField } from '@material-ui/core';

class Rejecton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reason: ''
        };
    }

    handleReasonTextChange = (reason) => {
        this.setState({
            reason: reason
        });
    };

    handleRejectMessage = () => {
        this.props.onReject(this.state.reason);
    };

    render() {
        const btnText = this.props.rejectButtonText ? this.props.rejectButtonText : "Отклонить";
        return (
            <Grid container spacing={2} >
                <Grid item key="0" xs={12}>
                    <TextField
                        id="reason"
                        multiline
                        variant="outlined"
                        rows={2}
                        fullWidth
                        label="Причина"
                        value={this.state.reason}
                        onChange={(event) => { this.handleReasonTextChange(event.target.value); }} />
                </Grid>
                <Grid item key="1" xs={12} container spacing={1}>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={this.handleRejectMessage} disabled={!this.state.reason}>
                            {btnText}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" onClick={this.props.onCancel}>
                            Отмена
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default Rejecton;