import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Filter from '../../../components/UI/Filter';
import { connect } from 'react-redux';
import { Grid, Button } from '@material-ui/core';
import * as actions from '../../../store/actions';
import StatisticsItem from '../../../components/Main/StatisticsItem';
import { Link, withRouter } from 'react-router-dom';
import Can from '../../../components/Auth/Can';

class PublicStatistics extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        
        this.props.onFetchStatistics();
    }

    componentDidUpdate(prevProps, prevState) {
        //if (!prevProps.user && this.props.user) {
        //    console.log('user ', this.props.user);
        //}
    }

    render() {
        return (
            <Grid container justify="center" alignItems="stretch" spacing={2}>
                <Grid item xs={12} sm>
                    <StatisticsItem count={this.props.usersCount} label={"зарегистрированных пользователей"} loading={this.props.loading} />
                </Grid>
                <Grid item xs={12} sm>
                        <StatisticsItem count={this.props.finishedMessagesCount} label={"сообщений обработано"} loading={this.props.loading} />
                    </Grid> 
                <Grid item xs={12} sm>
                        <StatisticsItem count={this.props.finishedVotingsCount} label={"голоcований и опросов проведено"} loading={this.props.loading} />
                    </Grid>
                <Grid item xs={12} sm>
                        <StatisticsItem count={this.props.messagesInWorkCount} label={"сообщений в работе"} loading={this.props.loading} />
                    </Grid>
                <Grid item xs={12} sm>
                        <StatisticsItem count={this.props.votingsInProcessCount} label={"голосований и опросов в процессе"} loading={this.props.loading} />
                </Grid>
            </Grid>
            
        );
    }
}

const mapStateToProps = state => {
    return {
        usersCount: state.cmn.statistics.users,
        finishedMessagesCount: state.cmn.statistics.finishedMessages,
        messagesInWorkCount: state.cmn.statistics.messagesInWork,
        finishedVotingsCount: state.cmn.statistics.finishedVotings,
        votingsInProcessCount: state.cmn.statistics.votingsInProcess,
        loading: state.cmn.statistics.loading,
        user: state.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchStatistics: () => dispatch(actions.fetchPublicStatistics())
        
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PublicStatistics));
