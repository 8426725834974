import React, { Component } from 'react';
import Preloader from '../../../containers/Preloader';
import Grid from '@material-ui/core/Grid';
import FullMessage from '../../../components/Messages/FullMessage/FullMessage';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
//import MessageModeration from '../components/MessageModeration';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CommentList from '../../../components/Comments/CommentList';
import NewComment from '../../../components/Comments/NewComment';
import NewMessageResponse from './NewMessageResponse';

class Message extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showMessageRejection: false
        };
    }

    componentDidMount() {
        if (this.props.id) {
            this.props.onFetchMessage(this.props.id);
            this.props.onFetchResponse(this.props.id);
            this.props.onFetchComments(this.props.id);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.comments.length > prevProps.comments.length) {
            this.setState({
                showNewComment: false
            });
        }
    }

        
    handleShowNewComment = () => {
        this.setState({
            showNewComment: true
        });
    };

    handleCancelNewComment = () => {
        this.setState({
            showNewComment: false
        });
    };

    renderComments() {
        if (!this.props.loadedMessage) {
            return null;
        }
        let newComment = null;
        if (this.state.showNewComment) {
            newComment = (
                <Grid item xs={12}>
                    <Typography variant="h6">
                        Новый комментарий
                    </Typography>
                    <NewComment onPostComment={(text) => this.props.onPostComment(this.props.loadedMessage.id, text)}
                        onCancel={this.handleCancelNewComment}
                    />
                </Grid>);
        }
        return (
            <React.Fragment>
                <Grid container spacing={1}>
                    {this.props.canAddComment && (
                        <React.Fragment>
                            <Grid item xs>
                                <Button variant="contained" color="primary" onClick={this.handleShowNewComment}>
                                    Написать комментарий
                                </Button>
                            </Grid>
                            {newComment}
                        </React.Fragment>
                    )}
                    
                    <Grid item xs={12}>
                        <Typography variant="h6">Комментарии</Typography>
                    </Grid>
                    <Grid item xs={12} container spacing={2}>

                        <Grid item xs={12}>
                            <CommentList comments={this.props.comments} />
                        </Grid>

                    </Grid>


                </Grid>
            </React.Fragment>
        );
    }

    
    toggleMessageRejection = (show) => {
        this.setState({ showMessageRejection: show });
    };

    render() {
        let message = (
            <Preloader fullScreen open color="primary"/>
        );
        let messageResponse = null;
        if (this.props.loading) {
            return message;
        }
        if (!this.props.loading && this.props.loadedMessage) {

            message = (

                <FullMessage id={this.props.loadedMessage.id}
                    author={this.props.loadedMessage.author}
                    messageType={this.props.loadedMessage.messageType.name}
                    creationDate={this.props.loadedMessage.creationDate}
                    status={this.props.loadedMessage.status.name}
                    geometry={this.props.loadedMessage.geometry}
                    number={this.props.loadedMessage.number}
                    category={this.props.loadedMessage.category.name}
                    text={this.props.loadedMessage.text}
                    images={this.props.loadedMessage.images}
                    documents={this.props.loadedMessage.documents}
                    response={this.props.loadedMessage.response}
                    coordinates={this.props.loadedMessage.coordinates}
                />

            );
            if (this.props.canAddResponse) {
                messageResponse = (
                    <Grid item xs>
                        <NewMessageResponse messageId={this.props.id} response={this.props.response} />
                    </Grid>
                );
            }
            
            
        }

        return (
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={8} container direction="column">
                    <Grid item>
                        {message}
                    </Grid>
                    {messageResponse}
                </Grid>
                <Grid item xs={12} sm={4}>
                    {this.renderComments()}
                    
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => {
    let msgIsNotFinished = state.msg.loadedMessage !== null && !state.msg.loadedMessage.isFinished;
    return {
        loadedMessage: state.msg.loadedMessage,
        loading: state.msg.loading,
        performers: state.msg.performers,
        comments: state.msg.comments,
        response: state.msg.response,
        canAddResponse: msgIsNotFinished,
        canAddComment: msgIsNotFinished
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchMessage: (id) => dispatch(actions.fetchMessage(id)),
        onPostComment: (messageId, commentText) => dispatch(actions.postComment(messageId, commentText)),
        onFetchComments: (messageId) => dispatch(actions.fetchMessageComments(messageId)),
        onFetchResponse: (messageId) => dispatch(actions.fetchMessageResponse(messageId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Message);