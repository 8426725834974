import { get, post, put, getBlob, endpoints } from './utils';

export default class votingApi {
    static getPreviews = (skip, take, filter, territory) => {
        const parameters = { skip: skip, take: take, ...filter };
        if (territory) {
            parameters.territoryId = territory;
        }
        return get(`voting/PublicVotingsPreviews`, endpoints.voting, parameters);
    }

    static getVotingsPreviewsWithCount = (skip, take, filter, territory) => {
        const parameters = { skip: skip, take: take, ...filter };
        if (territory) {
            parameters.territoryId = territory;
        }
        return get(`voting/VotingsPreviews/Count`, endpoints.voting, parameters);
    }

    static get = (id) => { return get(`voting/${id}`, endpoints.voting); }

    static getResults = (id) => { return get(`Voting/AnswerResult/${id}`, endpoints.voting);}

    static getTypes = () => { return get(`VotingDictionary/Type`, endpoints.voting); }

    static post = (data) => {
        var formData = new FormData();
        let votingDto = {
            header: data.header,
            description: data.description,
            municipalSettlement: data.municipalSettlementId,
            type: data.typeId,
            endDate: data.endDate,
            debriefingDate: data.debriefingDate,
            photo: data.image,
            organizationName: data.authority
        };
        let anwerOptions = data.options.map(option => {
            return {
                answerTitle: option.title,
                photo: option.image
            };
        });
        for (var key in votingDto) {
            formData.append(key, votingDto[key]);
        }
        for (var i = 0; i < data.documents.length; i++){
            formData.append("documents", data.documents[i]);
        }
        anwerOptions.forEach(obj => {
            Object.entries(obj).forEach(item => {
                formData.append(item[0], item[1]);
            });
        });
        anwerOptions.forEach((obj,index) => {
            formData.append(`answerOptions[${index}].answerTitle`, obj.answerTitle);
            formData.append(`answerOptions[${index}].photo`, obj.photo);
        });
        return post(`voting`, formData, endpoints.voting, 'multipart/form-data');
    }

    static reject = (id, reason) => {
        return put(`Voting/${id}/Reject`, reason, endpoints.voting, 'application/json, charset=utf-8');
    }

    static approve = (id) => {
        return put(`Voting/${id}/Publish`, null, endpoints.voting, 'application/json, charset=utf-8');
    }

    static setIsFavorite = (votingId, isFavorite) => {
        return put(`/voting/SetInFavorites?votingId=${votingId}&IsFavorites=${isFavorite}`, null, endpoints.voting);
    } 

    static getCount = (filter) => {
        return get(`voting/count`, endpoints.voting, filter);
    }

    static getList = (skip, take, filter) => {
        const parameters = { skip: skip, take: take, ...filter };
        return get(`voting/WithoutPhoto`, endpoints.voting, parameters);
    }

    static getPrivateStatuses = () => {
        return get(`VotingDictionary/Status`, endpoints.voting);
    }

    static getStatuses = () => {
        return get(`VotingDictionary/PublicStatus`, endpoints.voting);
    }

    static getPersonalAreaStatuses = () => {
        return get(`VotingDictionary/PersonalAreaStatus`, endpoints.voting);
    }

    static getDocument(id, documentId) { return getBlob(`voting/${id}/documents/${documentId}`, endpoints.voting); }

    static getUserVotings = (skip, take, filter) => {
        const parameters = { skip: skip, take: take, ...filter };
        return get(`voting/VotingsPreviews/ByUser`, endpoints.voting, parameters);
    }

    static vote = (votingId, checkedOptions, ownAnswer) => {
        let json = {
            votingId: votingId,
            answerOptionIds: checkedOptions
        };
        if (ownAnswer) {
            json.yourAnswer = ownAnswer;
        }
        return post(`voting/answer`, json, endpoints.voting, 'application/json, charset=utf-8');
    }

    static getFavoriteVotings = () => {
        return get(`voting/IsFavorites`, endpoints.voting);
    }

    static getUserAnswersOnVote = (votingId) => {
        return get(`voting/${votingId}/UserAnswers`, endpoints.voting);
    }

    static getDocuments(id) { return get(`voting/${id}/documents`, endpoints.voting); }

    static getDocument(id, documentId) { return getBlob(`voting/${id}/documents/${documentId}`, endpoints.voting); }
}