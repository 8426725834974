import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FileUploadValidatable from '../../../components/UI/FileUpload/FileUploadValidatable';
import Input from '../../../components/UI/Inputs/Input';
import { withSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import messageApi from '../../../store/api/messageApi';
import commonApi from '../../../store/api/commonApi';
import OutlinedContainer from '../../../components/UI/Containers/OutlinedContainer';
import { downloadFile } from '../../../utility/fileHelpers'
import { insertValueIntoArrayElement, deleteValueFromArrayElement } from '../../../utility/formHelpers';

class MessageInfoStep extends Component {
    constructor(props) {
        super(props);
        this.maxMessageSize = 700;
        this.maxFilesCount = 5;
        this.maxTotalFileSize = 5;
        const defaultIsValid = props.draft ? true : false;
        this.state = {
            municipalSettlements: [],
            categories: [],
            messageTypes: [],
            form: {
                images: (props.draft && props.draft.images) ? props.draft.images : [],
                documents: (props.draft && props.draft.documents) ? props.draft.documents : [],
                messageText: {
                    value: (props.draft && props.draft.messageText) ? props.draft.messageText : "",
                    elementType: "textarea",
                    config: {
                        label: "Текст сообщения",
                        placeholder: `Текст сообщения (не более ${this.maxMessageSize} символов)`,
                        maxLength: this.maxMessageSize,
                        rows: 10
                    },
                    validation: {
                        required: true
                    },
                    error: "",
                    valid: defaultIsValid
                },
                categoryId: {
                    value: props.draft && props.draft.categoryId ? props.draft.categoryId : "",
                    elementType: "select",
                    config: {
                        label: "Категория сообщения",
                        //defaultDisplayValue: "Не выбрана",
                        options: []
                    },
                    validation: {
                        required: true
                    },
                    error: "",
                    valid: defaultIsValid
                },
                municipalSettlementId: {
                    value: props.municipalSettlementId ? props.municipalSettlementId : "",
                    elementType: "select",
                    config: {
                        label: "Муниципальное поселение",
                        options: []
                    },
                    validation: {
                        required: true
                    },
                    error: "",
                    valid: defaultIsValid
                },
                messageTypeId: {
                    value: props.draft && props.draft.messageTypeId ? props.draft.messageTypeId : "",
                    elementType: "select",
                    config: {
                        label: "Тип сообщения",
                        options: []
                    },
                    validation: {
                        required: true
                    },
                    error: "",
                    valid: defaultIsValid
                },
                isPublic: {
                    value: (props.draft && (props.draft.isPublic != null)) ? props.draft.isPublic : true,
                    elementType: "checkbox",
                    config: {
                        label: "Это сообщение является публичным",
                        tooltip: "Публичные сообщения могут просматривать все пользователи"
                    },
                    valid: true
                }
            },
            formIsValid: props.draft ? true : false,
            lettersLeft: this.maxMessageSize,
            disableMunicipalSettlement: true
        };
    }

    componentDidMount() {
        commonApi.getMunicipalSettlements().then(result => {
            this.setFormElementOptions("municipalSettlementId", result.data, munSet => {
                return {
                    id: munSet.id,
                    title: munSet.fullName
                };
            });
        });
        messageApi.getCategories().then(result => {
            this.setFormElementOptions("categoryId", result.data, category => {
                return {
                    id: category.id,
                    title: category.name
                };
            });
        });
        if (this.props.draft) {
            messageApi.getTypes(this.props.draft.categoryId, this.props.draft.municipalSettlementId).then(result => {
                this.setFormElementOptions("messageTypeId", result.data, messageType => {
                    return {
                        id: messageType.id,
                        title: messageType.name
                    };
                });
            });
        }
    }
    //Promise.all([
    //    messageApi.getCategories(),
    //    commonApi.getMunicipalSettlements()]).then(results => {
    //        var formCopy = { ...this.state.form };
    //        formCopy = this.updateFormElementOptions(formCopy, "categoryId", results[0].data, category => {
    //            return {
    //                value: category.id,
    //                displayValue: category.name
    //            };
    //        });
    //        formCopy = this.updateFormElementOptions(formCopy, "municipalSettlementId", results[1].data, munSet => {
    //            return {
    //                value: munSet.id,
    //                displayValue: munSet.fullName
    //            };
    //        });
    //        this.setState({
    //            form: formCopy
    //        });
    //    });
    //}

    componentDidUpdate(prevProps) {
        if (prevProps.municipalSettlementId !== this.props.municipalSettlementId) {
            let disableMunicipalSettlement = Boolean(this.props.municipalSettlementId);
            let updatedForm = this.setFormElementValue("municipalSettlementId", this.props.municipalSettlementId);
            this.setState({
                form: updatedForm,
                disableMunicipalSettlement: disableMunicipalSettlement
            });

            let municipalSettlementId = this.props.municipalSettlementId;
            if (municipalSettlementId !== "") {
                let updatedForm = this.setFormElementValue("municipalSettlementId", municipalSettlementId);
                updatedForm = this.updateMessageTypeOptions(updatedForm, []);
                let category = { ...this.state.form.categoryId };
                let messageType = { ...this.state.form.messageTypeId };
                category.value = "";
                messageType.value = "";
                updatedForm.categoryId = category;
                updatedForm.messageTypeId = messageType;

                this.setState({
                    form: updatedForm
                });

                axios.get(`/MessageDictionary/Categories?msid=${municipalSettlementId}`,
                    { baseURL: process.env.REACT_APP_MESSAGE_API_URL })
                    .then(result => {
                        this.setFormElementOptions("categoryId", result.data, category => {
                            return {
                                id: category.id,
                                title: category.name
                            };
                        });
                    });
                }
        }
    }

    showError = (errorMessage) => {
        this.props.enqueueSnackbar(errorMessage);
    }

    setFormElementValue(elementName, newValue, error) {
        let updatedForm = { ...this.state.form };
        let updatedElement = { ... this.state.form[elementName] };
        updatedElement.value = newValue;
        if (error) {
            updatedElement.error = error;
        }
        else if (updatedElement.validation && updatedElement.validation.required && newValue === "") {
            updatedElement.error = "Поле должно быть заполнено";
        } else {
            updatedElement.error = "";
        }
        updatedElement.valid = updatedElement.error ? false : true;
        updatedForm[elementName] = updatedElement;
        return updatedForm;
    }

    checkValidity(element) {
        return element.valid === undefined || element.valid;
    }

    validateForm() {
        let formIsValid = true;
        for (var element in this.state.form) {
            if (!this.checkValidity(this.state.form[element])) {
                formIsValid = false;
            }
        }
        this.setState({
            formIsValid: formIsValid
        });
    }

    handleFormElementBlur = (event, elementName) => {
        let element = { ...this.state.form[elementName] };
        if (!element.error && element.validation.required && !element.value) {
            let updatedForm = { ...this.state.form };
            element.error = "Поле должно быть заполнено";
            updatedForm[elementName] = element;
            this.setState({
                form: updatedForm
            }, this.validateForm);
        }
    }

    //handleFormElementChange = (event, elementName) => {
    //    let value = event.target.value;
    //    let updatedForm = this.setFormElementValue(elementName, value);
    //    if (elementName === "categoryId") {

    //    } else if (elementName === "municipalSettlementId") {

    //    } else if (elementName === "messageText") {
    //    }
    //}

    updateFormElementOptions = (formCopy, elementName, options, mapFunction) => {
        let updatedElement = { ...this.state.form[elementName] };
        let updatedConfig = { ...this.state.form[elementName].config };
        updatedConfig.options = options.map(mapFunction);
        updatedElement.config = updatedConfig;
        formCopy[elementName] = updatedElement;
        return formCopy;
    }

    setFormElementOptions = (elementName, options, mapFunction) => {
        var formCopy = { ...this.state.form };
        formCopy = this.updateFormElementOptions(formCopy, elementName, options, mapFunction);
        this.setState({
            form: formCopy
        });
    }

    updateMessageTypeOptions(formCopy, messageTypes) {
        return this.updateFormElementOptions(formCopy, "messageTypeId", messageTypes, messageType => {
            return {
                id: messageType.id,
                title: messageType.name
            };
        });
    }

    handleCategoryChange = (event) => {
        let categoryId = event.target.value;
        let updatedForm = this.setFormElementValue("categoryId", categoryId);
        const municipalSettlementId = this.state.form.municipalSettlementId.value;
        if (categoryId !== "" && municipalSettlementId !== "") {
            axios.get('MessageDictionary/MessageTypes?categoryId=' + categoryId + "&msid=" + municipalSettlementId,
                { baseURL: process.env.REACT_APP_MESSAGE_API_URL })
                .then(result => {
                    updatedForm = this.updateMessageTypeOptions(updatedForm, result.data);
                    this.setState({
                        form: updatedForm
                    }, this.validateForm);
                });
        } else {
            updatedForm = this.updateMessageTypeOptions(updatedForm, []);
            this.setState({
                form: updatedForm
            }, this.validateForm);
        }
    };

    handleMunicipalSettlementChange = (event) => {
        let munSetId = event.target.value;
        let updatedForm = this.setFormElementValue("municipalSettlementId", munSetId);
        const categoryId = this.state.form.categoryId.value;
        if (munSetId !== "" && categoryId !== "") {
            axios.get('MessageDictionary/MessageTypes?categoryId=' + categoryId + "&msid=" + munSetId,
                { baseURL: process.env.REACT_APP_MESSAGE_API_URL })
                .then(result => {
                    updatedForm = this.updateMessageTypeOptions(updatedForm, result.data);
                    this.setState({
                        form: updatedForm
                    }, this.validateForm);
                });
        } else {
            updatedForm = this.updateMessageTypeOptions(updatedForm, []);
            this.setState({
                form: updatedForm
            }, this.validateForm);
        }
 
    };

    handleMessageTypeChange = (event) => {
        let updatedForm = this.setFormElementValue("messageTypeId", event.target.value);
        this.setState({
            form: updatedForm
        }, this.validateForm);
    };

    handleMessageTextChange = (event) => {
        const messageLength = event.target.value.length;
        const lettersLeft = this.maxMessageSize - messageLength;
        const error = lettersLeft > 0 ? null : `Превышен лимит длины текста сообщения: ${this.maxMessageSize}`;

        let updatedForm = this.setFormElementValue("messageText", event.target.value, error);
        this.setState({
            lettersLeft: lettersLeft,
            form: updatedForm
        }, this.validateForm);
    };

    handleIsPublicClick = (event) => {
        let updatedForm = this.setFormElementValue("isPublic", event.target.checked);
        this.setState({
            form: updatedForm
        }, this.validateForm);
    };

    handleDocumentAdd = (document) => {
        var updatedForm = insertValueIntoArrayElement(this.state.form, "documents", document);
        this.setState({
            form: updatedForm
        });
    };

    handleDocumentDelete = (index) => {
        var updatedForm = deleteValueFromArrayElement(this.state.form, "documents", index);
        this.setState({
            form: updatedForm
        });
    };

    handleDocumentDownload = (id, title) => {
        let self = this;
        let document = messageApi.getDocument(this.props.draft.id, id)
            .then(res => {
                downloadFile(title, res.data);
            })
            .catch(err => {
                self.showError(`Не удалось загрузить документ ${title}`);
            });
    };

    handleImageAdd = (image) => {
        var updatedForm = insertValueIntoArrayElement(this.state.form, "images", image);
        this.setState({
            form: updatedForm
        });
    };

    handleImageDelete = (index) => {
        var updatedForm = deleteValueFromArrayElement(this.state.form, "images", index);
        this.setState({
            form: updatedForm
        });
    };

    handleFormSubmit = (event) => {
        let files = [...this.state.form.documents];
        this.props.onSaveFormData(this.state.form);
    }
    handleCancel = () => {
        this.props.history.goBack();
    }

    renderImagesInput() {
        return (
            <Grid container direction="column">
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom style={{ display: "block", textAlign: 'left' }}>
                        Изображения
                        <Typography variant="caption" gutterBottom style={{ marginLeft: "5px" }}>
                            ( До 5 шт )
                        </Typography>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FileUploadValidatable
                        maxFilesCount={5}
                        fileType="image"
                        files={this.state.form.images}
                        onFileAdd={this.handleImageAdd}
                        onFileDelete={this.handleImageDelete}
                        addFileBtnText="Добавить изображение"
                        accept="image/png,image/jpeg"
                        maxTotalSizeInMegabytes={this.maxTotalFileSize}
                    />
                </Grid>

            </Grid>
        );
    }

    renderDocumentsInput() {
        return (
            <Grid container direction="column">
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom style={{ display: "block", textAlign: 'left' }}>
                        Документы
                        <Typography variant="caption" gutterBottom style={{ marginLeft: "5px" }}>
                            ( До 5 шт )
                        </Typography>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FileUploadValidatable
                        maxFilesCount={5}
                        fileType="document"
                        files={this.state.form.documents}
                        accept="application/pdf"
                        onFileAdd={this.handleDocumentAdd}
                        onFileDelete={this.handleDocumentDelete}
                        onFileDownload={(id, title) => this.handleDocumentDownload(id, title)}
                        addFileBtnText="Добавить документ"
                        maxTotalSizeInMegabytes={this.maxTotalFileSize}
                    />
                </Grid>
            </Grid>
        );
    }

    render() {
        let message = null;
        
        let lettersLeftCaption = null;//todo сделать сообщение красным, если ушли в минус
        message = (

            <OutlinedContainer>
                <form className="messageFormContainer">
                    <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
                        <Grid item xs={12}>
                            <Input
                                id="municipalSettlementId"
                                type={this.state.form.municipalSettlementId.elementType}
                                value={this.state.form.municipalSettlementId.value}
                                config={this.state.form.municipalSettlementId.config}
                                error={this.state.form.municipalSettlementId.error}
                                validation={this.state.form.municipalSettlementId.validation}
                                onChange={event => { this.handleMunicipalSettlementChange(event); }}
                                onBlur={event => { this.handleFormElementBlur(event, "municipalSettlementId"); }}
                                disabled={this.state.disableMunicipalSettlement}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                id="categoryId"
                                type={this.state.form.categoryId.elementType}
                                value={this.state.form.categoryId.value}
                                config={this.state.form.categoryId.config}
                                error={this.state.form.categoryId.error}
                                validation={this.state.form.categoryId.validation}
                                onChange={event => { this.handleCategoryChange(event); }}
                                onBlur={event => { this.handleFormElementBlur(event, "categoryId"); }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                id="messageTypeId"
                                type={this.state.form.messageTypeId.elementType}
                                value={this.state.form.messageTypeId.value}
                                config={this.state.form.messageTypeId.config}
                                error={this.state.form.messageTypeId.error}
                                validation={this.state.form.messageTypeId.validation}
                                onChange={event => { this.handleMessageTypeChange(event); }}
                                onBlur={event => { this.handleFormElementBlur(event, "messageTypeId"); }}
                            />
                        </Grid>
                        
                        <Grid item xs={12}>
                            <Input
                                id="messageText"
                                type={this.state.form.messageText.elementType}
                                value={this.state.form.messageText.value}
                                config={this.state.form.messageText.config}
                                error={this.state.form.messageText.error}
                                validation={this.state.form.messageText.validation}
                                onChange={event => { this.handleMessageTextChange(event); }}
                                onBlur={event => { this.handleFormElementBlur(event, "messageText"); }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {this.renderImagesInput()}
                        </Grid>
                        <Grid item xs={12}>
                            {this.renderDocumentsInput()}
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                id="isPublic"
                                type={this.state.form.isPublic.elementType}
                                value={this.state.form.isPublic.value}
                                config={this.state.form.isPublic.config}
                                onClick={event => { this.handleIsPublicClick(event); }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" onClick={this.handleFormSubmit } disabled={!this.state.formIsValid}>
                                Далее
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </OutlinedContainer>


        );
        return message;
    }

}

export default withSnackbar(withRouter(MessageInfoStep));