import rules from "../../auth/rules";
import { connect } from 'react-redux';

const check = (rules, role, action, data, checkNoUser) => {
    if (!role) {
        role = "visitor";
    }

    const permissions = rules[role];
    if (!permissions) {
        // role is not present in the rules
        return false;
    }

    const staticPermissions = permissions.static;

    if (staticPermissions && staticPermissions.includes(action)) {
        // static rule not provided for action
        return true;
    }

    if (checkNoUser && (role = 'visitor') && action) {
        return null;
    }

    const dynamicPermissions = permissions.dynamic;

    if (dynamicPermissions) {

        const permissionCondition = dynamicPermissions[action];
        if (!permissionCondition) {
            // dynamic rule not provided for action
            return false;
        }

        return permissionCondition({ ...data });
    }

    return false;
};

const Can = props => {
    const checkNoUser = props.noUser ? true : false;
    switch (check(rules, props.role, props.perform, props.data, checkNoUser)) {
        case true:
            return props.yes();

        case false:  
            return props.no();

        case null:
            return props.noUser ? props.noUser() : null;
    }
};

export const canDo = (user, perform, data) => {
    return check(rules, user ? user.role : null, perform, data);
};

Can.defaultProps = {
    yes: () => null,
    no: () => null,
    noUser: null
};

const mapStateToProps = (state) => {
    return {
        role: state.user.isAuthenticated ? state.user.role : null,
        userId: state.user.isAuthenticated ? state.user.id : null
    };
};

export default connect(mapStateToProps)(Can);