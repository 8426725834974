import * as actionTypes from '../actions/actionTypes';
import { showErrorPage } from '../actions';

let defaultState = {
    nextNotification: -1, // used for notifications keys
    notifications: [], // contains the list of notifications,
    showErrorPage: false,
    errorCode: null
};

const reducers = (state = defaultState, action) => {
    switch (action.type) {
        case actionTypes.ADD_NOTIFICATION:
            let key = state.nextNotification + 1; // increment notification key

            return {
                ...state,
                nextNotification: key, // save new notification key in state
                notifications: [{ ...action.notification, key: key }, ...state.notifications] // add notification with incremented key at the start of the list
            };

        case actionTypes.REMOVE_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.filter(notification => notification.key !== action.key) // remove notification from the list for given key
            };

        case actionTypes.SHOW_ERROR_PAGE:
            return {
                ...state,
                showErrorPage: true,
                errorCode: action.errorCode
            };

        case actionTypes.HIDE_ERROR_PAGE:
            if (state.showErrorPage) {
                return {
                    ...state,
                    showErrorPage: false,
                    errorCode: null
                };
            }
            return state;

        default: return state;
    }
};

export default reducers;