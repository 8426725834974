import React, { Component } from 'react';
import { Grid, Button, Typography } from '@material-ui/core';
import { Stepper, Step, StepLabel, StepContent } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Input from '../../components/UI/Inputs/Input';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import FileUploadValidatable from '../../components/UI/FileUpload/FileUploadValidatable';
import OutlinedContainer from '../../components/UI/Containers/OutlinedContainer';
import Options from '../../components/Voting/NewVoting/Options';
import { withRouter } from 'react-router-dom';
import NewVotingMainInfo from './NewVoting';
import Voting from '../../components/Voting/Voting';

class NewVoting extends Component {

    constructor(props) {
        super(props);
        this.state = {
            votingData: null,
            activeStep: 0,
            options: []
        };
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
        if (this.props.created) {
            this.props.history.push(`/votings`);
        }
    }

    handleCancel = () => {
        this.props.history.goBack();
    }

    handleFormElementBlur = (element) => { }

    handleError(errorText) {
        this.props.onNotify(errorText);
    }

    handleOptionsChange = (options) => {
        this.setState({
            options: options
        });
    }

    handleFinishFirstStep = (data) => {
        this.setState({
            activeStep: this.state.activeStep + 1,
            votingData: { ...data }
        });
    }

    handleFinishSecondStep = (options) => {

        this.setState({
            activeStep: this.state.activeStep + 1,
            options: options
        });
        
    }

    handleNext = () => {
        this.setState({
            activeStep: this.state.activeStep + 1
        });
    }

    getSelectedType = () => {

    }

    handleFinish = () => {
        var data = {
            ...this.state.votingData   
        };
        data.options = this.state.options;
        console.log("data to post ",data);
        this.props.onPost(data);
    }


    handleBack = () => {
        this.setState({
            activeStep: this.state.activeStep - 1
        });
    }

    handleReset = () => {
        this.setState({
            activeStep: 0
        });
    }

    render() {
        let preview = null;
        if (this.state.activeStep >= 2 && this.state.votingData) {
            preview = (<Voting {...this.state.votingData}
                options={this.state.options}
                municipalSettlement={this.props.municipalSettlements.filter(x => x.id === this.state.votingData.municipalSettlementId)[0].title}
                preview
                author={this.state.votingData.authority ? this.state.votingData.authority : this.props.userName}
            />);
        }
        return (
            <Grid container justify="center">
                <Grid item xs={12} sm={8}>
                    <OutlinedContainer>
                        <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
                            <Grid item xs={12} container justify="space-between" alignItems="center">
                                <Grid item>
                                    <Typography variant="h5" gutterBottom style={{ textAlign: 'left' }}>
                                        Новое голосование/опрос
                                        </Typography>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" color="secondary" onClick={this.handleCancel}>
                                        Отмена
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Stepper activeStep={this.state.activeStep} orientation="vertical" >
                            <Step key="step1" disabled={this.state.activeStep !== 0}>
                                <StepLabel>Заполните информацию о голосовании</StepLabel>
                                <StepContent TransitionProps={{ in: true }} >    
                                    <NewVotingMainInfo onSubmit={(data) => this.handleFinishFirstStep(data)} />
                                </StepContent>
                            </Step>
                            <Step key="step2" disabled={this.state.activeStep !== 1}>
                                <StepLabel>Укажите пункты для голосования</StepLabel>
                                <StepContent TransitionProps={{ in: true }} >
                                    <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>

                                            <Grid item xs={12}>
                                                <Button
                                                    variant="contained"
                                                    disabled={this.state.activeStep !== 1}
                                                    onClick={this.handleBack}
                                                >
                                                    Назад
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Options onSubmit={options => this.handleFinishSecondStep(options)} onError={error => this.handleError(error)} />
                                            </Grid>
                                            
                                    </Grid>
                                </StepContent>
                            </Step>
                            <Step key="step3" disabled={this.state.activeStep !== 2}>
                                <StepLabel>Предварительный просмотр голосования</StepLabel>
                                <StepContent TransitionProps={{ in: true }} >
                                    <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
                                        <Grid item xs={12}>
                                            <Button
                                                variant="contained"
                                                onClick={this.handleBack}
                                            >
                                                Назад
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {preview}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                variant="contained"
                                                onClick={this.handleNext}
                                            >
                                                Далее
                                            </Button>
                                        </Grid>
                                        
                                    </Grid>
                                    
                                </StepContent>
                            </Step>
                            <Step key="step4" disabled={this.state.activeStep !== 3}>
                                <StepLabel>Отправьте опрос/голосование на модерацию</StepLabel>
                                <StepContent TransitionProps={{ in: true }} >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleFinish}
                                    >
                                        Отправить
                                    </Button>
                                </StepContent>
                            </Step>
                        </Stepper>

                    </OutlinedContainer>
                </Grid>
            </Grid>

        );
    }
}

const mapStateToProps = state => {
    return {
        types: state.vote.types,
        municipalSettlements: state.cmn.municipalSettlements,
        created: state.vote.created,
        userName: state.user.name
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchMunicipalSettlements: () => dispatch(actions.fetchMunicipalSettlements()),
        onFetchTypes: () => dispatch(actions.fetchVotingTypes()),
        onNotify: (msg) => dispatch(actions.addErrorNotification(msg)),
        onPost: (data) => dispatch(actions.postVoting(data))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NewVoting));