import { get, post, getBlob, endpoints, put, del } from './utils';

export default class commonApi {
    static getMunicipalSettlements = () => { return get("Dictionary/Munset", endpoints.common); }

    static getNews = (skip, take, territory) => {
        let filter = { skip: skip, take: take };
        if (territory) {
            filter.msId = territory;
        }
        return get("news", endpoints.common, filter);
    }

    static getNewsWithCount = (skip, take, territory) => {
        let filter = { skip: skip, take: take };
        if (territory) {
            filter.msId = territory;
        }
        return get("news/count", endpoints.common, filter);
    }

    static getNewsDetails = (id) => { return get(`news/${id}`, endpoints.common); }

    static postNews = (news) => {
        var formData = new FormData();
        var messageObj = {
            text: news.text,
            header: news.header,
            municipalSettlementId: news.municipalSettlementId,
            authority: news.authority
        };
        for (var key in messageObj) {
            formData.append(key, messageObj[key]);
        }
        if (news.images) {
            for (var j = 0; j < news.images.length; j++) {
                formData.append("photos", news.images[j]);
            }
        }
        if (news.documents) {
            for (var i = 0; i < news.documents.length; i++) {
                formData.append("documents", news.documents[i]);
            }
        }
        return post("news", formData, endpoints.common, 'multipart/form-data');
    }

    static getDocument(id, documentId) { return getBlob(`news/document/${documentId}`, endpoints.common); }

    static getUsersCount() {
        return get(`statistic/users`, endpoints.common);
    }

    static getFinishedMessagesCount() {//Обработанные сообщения
        return get(`statistic/FinishedMessages`, endpoints.common);
    }

    static getMessagesInWorkCount() {//Сообщения в работе
        return get(`statistic/MessagesInWork`, endpoints.common);
    }

    static getFinishedVotingsCount() {//Завершенные голосования
        return get(`statistic/FinishedVotings`, endpoints.common);
    }
        
    static getVotingsInWorkCount() {//Открытые голосования
        return get(`statistic/VotingsInWork`, endpoints.common);
    }

    static getModeratorStatistics() {
        return get(`Statistic/ModeratorStatistic`, endpoints.common);
    }

    static getPerformerStatistics() {
        return get(`Statistic/PerformerStatistic`, endpoints.common);
    }

    static postOrganization = (data) => {
        var formData = new FormData();
        for (var key in data) {
            if (key !== "photo" && key !== "municipalSettlements") {
                formData.append(key, data[key]);
            }
        }

        if (data.municipalSettlements) {
            for (let i = 0; i < data.municipalSettlements.length; i++) {
                formData.append("municipalSettlements", data.municipalSettlements[i]);
            }
        }

        if (data.photo) {
            formData.append("logo", data.photo);
        }

        return post(`/Dictionary/Organizations`, formData, endpoints.common, 'multipart/form-data');
    }

    static getOrganizations = (filter, territory) => {
        const parameters = { msId: filter.municipalSettlementId, scopeId: filter.scopeId };
        if (territory) {
            parameters.msId = territory;
        }
        return get(`Dictionary/Organizations`, endpoints.common, parameters);
    }

    static getOrganizationInfo = (id) => {
        return get(`Dictionary/Organizations/${id}`, endpoints.common);
    }

    static deleteOrganization = (id) => {
        return del(`Dictionary/Organizations?organizationId=${id}`, endpoints.common); 
    }

    static getScopes = () => {
        return get(`Dictionary/Scopes`, endpoints.common);
    }

    static updateOrganization = (data, id) => {
        let formData = new FormData();
        for (var key in data) {
            if (key === 'municipalSettlements') {
                for (let i = 0; i < data[key].length; i++) {
                    formData.append("municipalSettlements", data[key][i]);
                }
            } else {
                formData.append(key, data[key]);
            }
        }
        return put(`Dictionary/Organizations`, formData, endpoints.common, 'multipart/form-data');
    }

    static updateOraganizationLogo = (id, logo) => {
        let formData = new FormData();
            formData.append("logo", logo)
        return put(`Dictionary/Organizations/${id}/Logo`, formData, endpoints.common, 'multipart/form-data');
    }

    static deleteOrganizationLogo = (id) => {
        return del(`Dictionary/Organizations/${id}/Logo`, endpoints.common);
    }
}