import React from 'react';
import PageableTable from '../UI/Containers/PageableTable';
import { parseDate } from '../../utility/parsers';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

export default function MessageTable(props) {

    const headCells = [
        { id: 'id', disablePadding: false, label: 'ID' },
        { id: 'createDate', disablePadding: false, label: 'Дата отправки' },
        { id: 'category', disablePadding: false, label: 'Категория' },
        { id: 'type', disablePadding: false, label: 'Тип' },
        { id: 'status', disablePadding: false, label: 'Статус' },
        { id: 'userName', disablePadding: false, label: 'Отправитель' },
        { id: 'assessment', disablePadding: false, label: 'Оценка' }
    ];

    const getRowClass = (msg) => {
        if (msg.isFinished) {
            return "";
        }
        let dateToAnswer = parseDate(msg.dateToAnswer);
        let curDate = new Date();
        const diffTime = dateToAnswer - curDate;
        const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
        let rowClass;
        if (diffHours > 48) {
            rowClass = "";
        } else if (diffHours >= 24) {
            rowClass = "low-severity";
        } else if (diffHours >= 12) {
            rowClass = "middle-severity";
        } else {
            rowClass = "high-severity";
        }
        return rowClass;
    };

    return (
        <PageableTable
            itemsPerPage={props.messagesPerPage}
            rows={props.messages.map(msg => {
                return {
                    fields: {
                        id: msg.id,
                        createDate: msg.createDate,
                        category: msg.category ? msg.category.name : "",
                        type: msg.type ? msg.type.name : "",
                        status: msg.status ? msg.status.name : "",
                        userName: msg.userName,
                        assessment: msg.like === true ?
                            (<ThumbUpIcon fontSize="small" color="primary" />)
                            : (msg.like === false ? (<ThumbDownIcon fontSize="small" color="secondary" />) : null)
                    },
                    className: getRowClass(msg),
                    fontWeight: msg.status && msg.status.name === "Новое" ? "fontWeightBold" : null
                };
            })}
            itemIdProp="id"
            headCells={headCells}
            onItemClicked={props.onMessageClicked}
            count={props.count}
            labelItemsPerPage="Сообщений на странице"
            onChangePage={(newPage) => props.onChangePage(newPage)}
            page={props.page}
        />

            
    );
}