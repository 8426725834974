import React from 'react';
import { Paper, Typography, Grid, Button, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

    root: {
        padding: "20px"
    },
    container: {
        height: "100%",
        
    },
    text: {
        fontSize: "24px",
        [theme.breakpoints.up('sm')]: {
            fontSize: "32px",
        },
    },
    content: {
        textAlign: "left",
        minHeight: "240px",
        [theme.breakpoints.up('sm')]: {
            minHeight: "360px",
            paddingTop: "100px",
            paddingBottom: "100px"
        },
        
    },
    contentBody: {
        [theme.breakpoints.down('xs')]: {
            paddingBottom: "20px"
        },
    },
    image: {
        [theme.breakpoints.down('xs')]: {
            height: "200px"
        },
        height: "150px",
        position: "relative",
        float: "right",
        margin: "auto",
        objectFit: "cover"
    },
    imageContainer: {
        maxWidth: "400px",
        [theme.breakpoints.down('xs')]: {
            maxHeight: "360px"
        },
    },
    button: {
        marginTop: "20px"
    }

}));

const CarouselItem = (props) => {
    const classes = useStyles();
    return (
        <Paper className={classes.root}>
            <Grid container direction="row" justify="center" className={classes.container}>
                <Grid item xs={12} sm={8} container direction={props.imagePosition === "left" ? "row" : "row-reverse"} className={classes.container} justify="center" alignItems="stretch">
                    <Grid item xs={12} sm container direction="row-reverse" className={classes.imageContainer}>
                        <img src="/images/main.png" className={classes.image} />
                    </Grid>
                    <Grid item xs={12} sm className={classes.content} container direction="column" justify="flex-end">
                        <Grid item className={classes.contentBody}>
                            <Typography color="primary" className={classes.text} >
                                {props.textPrimary}
                            </Typography>
                            <Box className={classes.button}>
                                {props.button}
                            </Box>
                        </Grid>
                    </Grid>
                    

                </Grid>
            </Grid>
            
            
        </Paper>
    );
};

export default CarouselItem;