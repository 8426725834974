import votingApi from "../api/votingApi";
import * as actionTypes from './actionTypes';
import { addSuccessNotification, addErrorNotification, addWarningNotification, showErrorPage } from './notify';

export const fetchVotingPreviewsSuccess = (votings) => {
    return {
        type: actionTypes.FETCH_VOTING_PREVIEWS_SUCCESS,
        votings: votings
    };
};

export const fetchVotingPreviewsFail = (error) => {
    return {
        type: actionTypes.FETCH_VOTING_PREVIEWS_FAIL,
        error: error
    };
};

export const fetchVotingPreviewsStart = () => {
    return {
        type: actionTypes.FETCH_VOTING_PREVIEWS_START
    };
};

export const resetVotings = () => {
    return {
        type: actionTypes.RESET_VOTINGS
    };
};

export const fetchTotalFilteredVotingsCountSuccess = (count) => {
    return {
        type: actionTypes.FETCH_TOTAL_FILTERED_VOTINGS_COUNT_SUCCES,
        count,
    }
}

export const changeMaxPossibleVotingsCount = () => {
    return {
        type: actionTypes.CHANGE_TOTAL_VOTINGS_COUNT,
    };
}

export const fetchVotingPreviews = (skip, take) => {
    return (dispatch, getState) => {
        if (skip === 0) {
            dispatch(resetVotings());
        }
        dispatch(fetchVotingPreviewsStart());
        const state = getState();

        votingApi.getVotingsPreviewsWithCount(skip, take, { ...state.vote.filter })
            .then(res => {
                dispatch(fetchVotingPreviewsSuccess(res.data.collection));
                dispatch(fetchTotalFilteredVotingsCountSuccess(res.data.count));
            })
            .catch(err => {
                dispatch(fetchVotingPreviewsFail(err));
            });
    };
};

export const fetchVotingSuccess = (voting) => {
    return {
        type: actionTypes.FETCH_VOTING_SUCCESS,
        voting: voting
    };
};

export const fetchVotingFail = (error) => {
    return {
        type: actionTypes.FETCH_VOTING_FAIL,
        error: error
    };
};

export const fetchVotingStart = () => {
    return {
        type: actionTypes.FETCH_VOTING_START
    };
};

export const fetchVoting = (id) => {
    return dispatch => {
        dispatch(fetchVotingStart());
        Promise.all([
            votingApi.get(id),
            votingApi.getResults(id),
            votingApi.getDocuments(id)
        ]).then(results => {
            let payload = {};
            payload = results[0].data;
            payload.results = results[1].data;
            payload.documents = results[2].data;
            dispatch(fetchVotingSuccess(payload));
            
        })
        .catch(err => {
            if (err.response && err.response.status) {
                dispatch(showErrorPage(err.response.status));
            }
            dispatch(fetchVotingFail(err));
        });
    };
};

export const fetchVotingTypesSuccess = (types) => {
    return {
        type: actionTypes.FETCH_VOTING_TYPES_SUCCESS,
        types: types
    };
};

export const fetchVotingTypesFail = (error) => {
    return {
        type: actionTypes.FETCH_VOTING_TYPES_FAIL,
        error: error
    };
};

export const fetchVotingTypesStart = () => {
    return {
        type: actionTypes.FETCH_VOTING_TYPES_START
    };
};

export const fetchVotingTypes = () => {
    return (dispatch, getState) => {
        const state = getState();
        if (!state.vote.types || state.vote.types.length == 0) {
            dispatch(fetchVotingTypesStart());
            votingApi.getTypes()
                .then(res => {
                    dispatch(fetchVotingTypesSuccess(res.data));
                })
                .catch(err => {
                    dispatch(fetchVotingTypesFail(err));
                });
        }
        
    };
};

export const postVotingSuccess = () => {
    return {
        type: actionTypes.POST_VOTING_SUCCESS
    };
};

export const postVotingFail = (error) => {
    return {
        type: actionTypes.POST_VOTING_FAIL,
        error: error
    };
};

export const postVotingStart = () => {
    return {
        type: actionTypes.POST_VOTING_START
    };
};

export const postVoting = (voting) => {
    return (dispatch) => {
        dispatch(postVotingStart());
        votingApi.post(voting)
            .then(res => {
                dispatch(postVotingSuccess());
                dispatch(addSuccessNotification("Успешно"));
            })
            .catch(err => {
                dispatch(postVotingFail(err));
                dispatch(addErrorNotification());
            });

    };
};

export const rejectVotingSuccess = () => {
    return {
        type: actionTypes.REJECT_VOTING_SUCCESS
    };
};

export const rejectVotingFail = (error) => {
    return {
        type: actionTypes.REJECT_VOTING_FAIL,
        error: error
    };
};

export const rejectVotingStart = () => {
    return {
        type: actionTypes.REJECT_VOTING_START
    };
};

export const rejectVoting = (id, reason) => {
    return (dispatch) => {
        dispatch(rejectVotingStart());
        votingApi.reject(id, reason)
            .then(res => {
                dispatch(rejectVotingSuccess());
                dispatch(addSuccessNotification("Успешно"));
            })
            .catch(err => {
                dispatch(rejectVotingFail(err));
                dispatch(addErrorNotification());
            });

    };
};

export const approveVotingSuccess = () => {
    return {
        type: actionTypes.APPROVE_VOTING_SUCCESS
    };
};

export const approveVotingFail = (error) => {
    return {
        type: actionTypes.APPROVE_VOTING_FAIL,
        error: error
    };
};

export const approveVotingStart = () => {
    return {
        type: actionTypes.APPROVE_VOTING_START
    };
};

export const approveVoting = (id) => {
    return (dispatch) => {
        dispatch(approveVotingStart());
        votingApi.approve(id)
            .then(res => {
                dispatch(approveVotingSuccess());
                dispatch(addSuccessNotification("Успешно"));
            })
            .catch(err => {
                dispatch(approveVotingFail(err));
                dispatch(addErrorNotification());
            });

    };
};

export const changeVotingsPageStart = (newPage) => {
    return {
        type: actionTypes.CHANGE_VOTINGS_PAGE_START,
        page: newPage
    };
};

export const changeVotingsPageFail = () => {
    return {
        type: actionTypes.CHANGE_VOTINGS_PAGE_FAIL
    };
};

export const changeVotingsPageSuccess = (votings) => {
    return {
        type: actionTypes.CHANGE_VOTINGS_PAGE_SUCCESS,
        votings: votings
    };
};

export const fetchVotingsCount = (count) => {
    return {
        type: actionTypes.FETCH_VOTINGS_COUNT,
        count: count
    };
};

const getVotingFilter = (filter) => {
    return {
        statusId: filter.statusId
    };
};

export const changeVotingsPage = (newPage) => {
    return (dispatch) => {
        dispatch(changeVotingsPageStart(newPage));
        dispatch(fetchVotings(newPage));
    };
};

export const fetchVotings = (newPage) => {
    return (dispatch, getState) => {
        let page = newPage;
        const currentState = getState();
        if (!page) {
            page = currentState.vote.page;
        }
        const skip = page * currentState.vote.votingsPerPage;
        const filter = getVotingFilter(currentState.vote.filter);
        Promise.all(
            [votingApi.getCount(filter),
                votingApi.getList(skip, currentState.vote.votingsPerPage, filter)]
        )
            .then(res => {
                dispatch(fetchVotingsCount(res[0].data));
                dispatch(changeVotingsPageSuccess(res[1].data));
            })
            .catch(err => {
                dispatch(changeVotingsPageFail(err));
            });
    };
};

export const initVotingPrivateFilter = () => {
    return {
        type: actionTypes.INIT_VOTING_PRIVATE_FILTER
    };
};

export const initVotingPublicFilter = () => {
    return {
        type: actionTypes.INIT_VOTING_PUBLIC_FILTER
    };
};

export const updateVotingFilterValue = (filter, value) => {
    return {
        type: actionTypes.SET_VOTING_FILTER_VALUE,
        filter: filter,
        value: value
    };
};

export const setVotingFilterValue = (filter, value) => {
    return (dispatch, getState) => {
        dispatch(updateVotingFilterValue(filter, value));
        var currentState = getState();
        dispatch(fetchVotingPreviews(currentState.vote.skip, currentState.vote.take));
    };
};

export const toggleVotingFilter = () => {
    return {
        type: actionTypes.TOGGLE_VOTING_FILTER
    };
};

export const setPrivateVotingFilterValue = (filter, value) => {
    return (dispatch, getState) => {
        dispatch(updateVotingFilterValue(filter, value));
        dispatch(fetchVotings());
    };
};

export const fetchVotingStatusesStart = () => {
    return {
        type: actionTypes.FETCH_VOTING_STATUSES_START
    };
};

export const fetchVotingStatusesSuccess = (statuses) => {
    return {
        type: actionTypes.FETCH_VOTING_STATUSES_SUCCESS,
        statuses: statuses
    };
};

export const fetchVotingStatusesFail = () => {
    return {
        type: actionTypes.FETCH_VOTING_STATUSES_FAIL
    };
};

export const fetchVotingStatuses = () => {
    return dispatch => {
        dispatch(fetchVotingStatusesStart());
        votingApi.getStatuses()
            .then(res => {
                dispatch(fetchVotingStatusesSuccess(res.data));
            })
            .catch(err => {
                dispatch(fetchVotingStatusesFail(err));
            });
    };
};

export const fetchVotingDocumentSuccess = (data, title) => {
    const downloadUrl = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', title); //any other extension
    document.body.appendChild(link);
    link.click();
    link.remove();
    return {
        type: actionTypes.FETCH_VOTING_DOCUMENT_SUCCESS
    };
};

export const fetchVotingDocumentFail = (error) => {
    return {
        type: actionTypes.FETCH_VOTING_DOCUMENT_FAIL,
        error: error
    };
};

export const fetchVotingDocumentStart = () => {
    return {
        type: actionTypes.FETCH_VOTING_DOCUMENT_START
    };
};

export const fetchVotingDocument = (id, docId, docTitle) => {
    return dispatch => {
        dispatch(fetchVotingDocumentStart());
        votingApi.getDocument(id, docId)
            .then(res => {
                dispatch(fetchVotingDocumentSuccess(res.data, docTitle));
            })
            .catch(err => {
                dispatch(fetchVotingDocumentFail(err));
            });
    };
};

export const voteSuccess = () => {
    return {
        type: actionTypes.VOTE_SUCCESS
    };
};

export const voteFail = (error) => {
    return {
        type: actionTypes.VOTE_FAIL,
        error: error
    };
};

export const voteStart = () => {
    return {
        type: actionTypes.VOTE_START
    };
};

export const vote = (votingId, chechedOptions, ownAnswer) => {
    return dispatch => {
        dispatch(voteStart());
        votingApi.vote(votingId, chechedOptions, ownAnswer)
            .then(res => {
                dispatch(voteSuccess());
                dispatch(fetchVoting(votingId));
                dispatch(addSuccessNotification("Ваш голос учтён"));
            })
            .catch(err => {
                dispatch(voteFail(err));
                dispatch(addErrorNotification("При отправке формы произошла ошибка"));
            });
    };
};

export const updateVoting = (votingId, isFavorite) => {
    return {
        type: actionTypes.UPDATE_VOTING,
        votingId,
        isFavorite
    };
};

export const setVotingFavoriteStatus = (votingId, isFavorite, votingType) => {
    return (dispatch, getState) => {
        const state = getState();
        if (state.user.isAuthenticated) {
            votingApi.setIsFavorite(votingId, isFavorite)
                .then(result => {
                    let addString = 'добавлен';
                    let deleteString = 'удален';
                    if (votingType === 'Голосование') {
                        addString += 'о';
                        deleteString += 'о';
                    }

                    dispatch(updateVoting(votingId, isFavorite));
                    isFavorite
                        ? dispatch(addSuccessNotification(`${votingType} ${addString} в избранное`))
                        : dispatch(addWarningNotification(`${votingType} ${deleteString} из избранного`));
                })
                .catch(err => {
                    dispatch(addErrorNotification());
                });

        } else {
            dispatch(addWarningNotification("Необходимо авторизоваться"));
        }
    };
};

export const fetchUserAnswersOnVoteSuccess = (userAnswer) => {
    return {
        type: actionTypes.FETCH_USER_ANSWER_ON_VOTE_SUCCESS,
        userAnswer
    };
};

export const fetchUserAnswersOnVote = (votingId) => {
    return dispatch => {
        dispatch(voteStart());
        votingApi.getUserAnswersOnVote(votingId)
            .then(res => {
                dispatch(fetchUserAnswersOnVoteSuccess(res.data));
            })
            .catch(err => {
                dispatch(addErrorNotification());
            });
    };
}