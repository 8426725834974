import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import { queryString } from 'query-string';
import MessageTypes from '../containers/MessageTypes/MessageTypes';

const messageTypesPage = (props) => {
    const queryString = require('query-string');
    const query = queryString.parse(props.location.search);

    return (
        <Grid container className="MessageTypes" spacing={0} direction="row" justify="center">
            <Grid item key="add" xs={12}>
                <Link to={{
                    pathname: props.match.url + "/new"
                }}>
                    <Button color="primary" variant="contained">
                        Добавить тип
                    </Button>
                </Link>
            </Grid>
            <Grid item key="typesTable" xs={8} style={{ marginTop: "20px" }}>
                <MessageTypes />
            </Grid>

        </Grid>

    );
};

export default withRouter(messageTypesPage);