import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import VotingProcess from '../../components/Voting/VotingProcess';

class VotingProcessContainer extends Component {
    componentDidMount() {}

    componentDidUpdate(prevProps) {}

    render() {
        return (
            <VotingProcess
                options={this.props.options}
                multiple={this.props.type === "Опрос" ? true : false}
                disableVote={this.props.disableVote}
                withOwnOption
                hasUserVoted={this.props.hasUserVoted}
                yourAnswersCount={this.props.yourAnswersCount}
                totalNumberOfVoted={this.props.totalNumberOfVoted}
                type={this.props.type}
                user={this.props.user}
                loadedVotingAnswers={this.props.loadedVotingAnswers}
                loadedVotingAlternativeAnswer={this.props.loadedVotingAlternativeAnswer }
                onSubmit={(options, ownAnswer) => this.props.onSubmit(options, ownAnswer)}
            />
        );
    }
};

const mapStateToProps = state => {
    return {
        type: state.vote.loadedVoting.type,
        options: state.vote.loadedVoting.options,
        results: state.vote.loadedVoting.results,
        hasUserVoted: state.vote.loadedVoting.isUserVoted,
        yourAnswersCount: state.vote.loadedVoting.yourAnswersCount,
        totalNumberOfVoted: state.vote.loadedVoting.totalNumberOfVoted,
        loadedVotingAnswers: state.vote.loadedVotingAnswers,
        user: state.user.isAuthenticated ? state.user.id : null,
        loadedVotingAlternativeAnswer: state.vote.loadedVotingAlternativeAnswer
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(VotingProcessContainer);