import React from 'react';
import DocumentList from '../../components/UI/DocumentList/DocumentList';
import * as actions from '../../store/actions';
import { connect } from 'react-redux';

const MessageDocuments = (props) => {
    return (
        <DocumentList documents={props.documents} onDownloadDocument={(docId, docTitle) => props.onFetchDocument(props.id, docId, docTitle)} />
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchDocument: (id, docId, docTitle) => dispatch(actions.fetchMessageDocument(id, docId, docTitle))
    };
};

export default connect(null, mapDispatchToProps)(MessageDocuments);