import React from 'react';
import MessageMap from '../../../containers/Messages/MessageMap/MessageMap';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import MessageMapFilter from '../../../containers/Messages/MessageMap/MessageMapFilter';
import { Toolbar, Typography } from '@material-ui/core';
import MainMenu from '../../../containers/Menu/MainMenu';

const messageMapPage = (props) => {
    const queryString = require('query-string');
    const query = queryString.parse(props.location.search);
    let gisGuid = null;
    console.log(query);
    if (query && query.msg) {
        gisGuid = query.msg;
    }
    return (
        <div style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column"
        }}>
            <MainMenu />
            <Grid container direction="column" justify="center" alignItems="center" style={{ width: "100%", heigth: "100%" }}>
                <Toolbar style={{ padding: "10px", overflow: "hidden" }}>
                    <MessageMapFilter />
                </Toolbar>
            </Grid>
            <div style={{ position: "relative", height: "100%" }}>
                <MessageMap filter={null} selectedGuid={gisGuid}/>
            </div>
            
        </div>
    );
};

export default withRouter(messageMapPage);