import React, { Component } from 'react';
import FileUpload from './FileUpload';
import { withSnackbar } from 'notistack';

class FileUploadValidatable extends Component {
    constructor(props) {
        super(props);
        this.fileSizeOverlimitMsg = props.maxFilesCount === 1
            ? "Превышен допустимый размер файла"
            : "Превышен допустимый общий размер прикрепленных файлов";
    }

    toMegabytes(bytes) {
        return bytes / 1024 / 1024;
    }

    getTotalSize() {
        let total = this.props.files.reduce((sum, file) => {
            return sum += (file.size ? file.size : (file.value ? file.value.length : 0));
        }, 0);
        return this.toMegabytes(total);
    }

    getExtension(filename) {
        var parts = filename.split('.');
        return parts[parts.length - 1];
    }

    showError(errorMessage) {
        this.props.enqueueSnackbar(errorMessage);
    }

    addFileHandler(file){
        var canAddFile = true;
        if (!this.checkFileSize(file)) {
            canAddFile = false;
            this.showError(`${this.fileSizeOverlimitMsg} (${this.props.maxTotalSizeInMegabytes} МБ)`);
        }
        if (!this.checkFileExtension(file)) {
            canAddFile = false;
            this.showError(`Недопустимое расширение файла.`);
        }
        if (canAddFile) {
            this.props.onFileAdd(file);
        }
    }

    checkFileSize = file => {
        if (!this.props.maxTotalSizeInMegabytes) {
            return true;
        }
        var totalFileSize = this.getTotalSize();
        if (totalFileSize + this.toMegabytes(file.size) <= this.props.maxTotalSizeInMegabytes) {
            return true;
        }
        return false;
    }

    checkFileExtension = file => {
        if (!this.props.accept) {
            return true;
        }
        return this.props.accept.replace(/\s/g, '').split(',').filter(accept => {
            return new RegExp(accept.replace('*', '.*')).test(file.type);
        }).length > 0;
    }

    render() {
        return (
            <FileUpload
                maxFilesCount={this.props.maxFilesCount}
                fileType={this.props.fileType}
                files={this.props.files}
                accept={this.props.accept}
                onFileAdd={file => this.addFileHandler(file)}
                onFileDelete={this.props.onFileDelete}
                onFileDownload={(id, name) => this.props.onFileDownload(id, name)}
                addFileBtnText={this.props.addFileBtnText}
            />
        );
    }
}

export default withSnackbar(FileUploadValidatable);