export function shortenString(str, newLength) {
    return (str && newLength && (str.length > newLength)) ? str.substring(0, newLength - 1).concat('...') : str;
}

export function makeFullName(firstName, secondName, lastName){
    return `${lastName} ${firstName} ${secondName}`;
}

export function trimTime(string) {
    return (string.slice(0, 10));
}

const pollStatuses = [];
pollStatuses["завершено"] = "завершен";
pollStatuses["открыто"] = "открыт";
pollStatuses["на модерации"] = "на модерации";
pollStatuses["отклонено"] = "отклонен";

export function convertVotingStatus(type, status) {
    if (type === 'Опрос') {
        return `${type} ${pollStatuses[status.toLowerCase()]}`;
    } else {
        return `${type} ${status.toLowerCase()}`;
    }
}

