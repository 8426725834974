import React, { Component } from 'react';
import PreviewGrid from '../../components/UI/Containers/PreviewGrid';
import VotingPreview from '../../components/Voting/VotingPreview';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import Preloader from '../Preloader';

class VotingGrid extends Component {
    componentDidMount() {
        this.props.onFetchVotings(this.props.skip, this.props.take);
    }

    componentDidUpdate(prevProps) {
    }

    onShowMore = () => {
        this.props.onFetchVotings(this.props.skip, this.props.take);
        this.props.onChangeMaxPossibleVotingsCount();
    }

    render() {
        return (<>
            {this.props.loading ? <Preloader fullScreen open color="primary" /> : null}
            <PreviewGrid
                shownPreviews={this.props.votings}
                enableShowMore
                totalFilteredCount={this.props.totalFilteredVotingsCount}
                maxPossibleCount={this.props.maxPossibleVotingsCount}
                previewRenderFunction={(voting) => {
                    return (<VotingPreview
                        key={voting.id}
                        voting={voting}
                    />);
                }}
                onShowMore={() => this.onShowMore()}
                getLinkFunction={(voting) => {  return "/votings/" + voting.id; }}
                getPreviewKeyFunction={(voting) => { return voting.id; }}
            />
        </>
        );
    }

};

const mapStateToProps = state => {
    return {
        votings: state.vote.votings,
        loading: state.vote.loading,
        skip: state.vote.votings.length,
        take: state.vote.take,
        totalFilteredVotingsCount: state.vote.totalFilteredVotingsCount,
        maxPossibleVotingsCount: state.vote.maxPossibleVotingsCount,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchVotings: (skip, take) => dispatch(actions.fetchVotingPreviews(skip, take)),
        onChangeMaxPossibleVotingsCount: () => dispatch(actions.changeMaxPossibleVotingsCount())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VotingGrid);