import React from 'react';
import { withRouter } from 'react-router-dom';
import { Card, CardHeader, CardContent, Typography, Grid, Box, Button } from '@material-ui/core';
import Property from '../../components/UI/Property.js';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    logoIcon: {
        width: '100%',
        heigth: 'auto'
    },
    logoContainer: {
        width: '100px'
    },
    organizationTitle: {
        padding: '0px'
    },
    organizationDescription: {
        marginTop: '10px'
    },
    comeBackBtn: {
        alignSelf: 'flex-end',
        width: 'max-content',
        margin: '16px 16px 0px 0px',
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    organizationHead: {
        padding: '1rem',
    },
}));

const Organization = (props) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <Box display="flex" justifyContent="center"  >
                <Grid item xs={12} sm={8} >
                    <Card className={classes.wrapper}>
                        <Button className={classes.comeBackBtn} variant="contained" onClick={() => { window.location.assign('/organizations') }} color="primary">к списку организаций</Button>
                        <Grid container justify="space-between" className={classes.organizationHead}>
                            <CardHeader title="Информация по организации" className={classes.organizationTitle} />
                            <Grid container alignItems="center" className={classes.logoContainer}>
                                {(props.organizationInfo.logo)
                                    ? <img src={`data:image/jpg;base64,${props.organizationInfo.logo}`} alt="логотип компании" className={classes.logoIcon} />
                                    : <img src={"/images/no-photo.jpg"} alt="изображение" className={classes.logoIcon} />
                                }
                            </Grid>
                        </Grid>
                        <CardContent>
                            <Grid container >
                                <Grid >
                                    <Grid container >
                                        <Grid item key="body" >
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <Property label="Название организации" value={props.organizationInfo.name} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Property label="Адрес" value={props.organizationInfo.address} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Property label="Контакты" value={props.organizationInfo.contacts} />
                                                </Grid>
                                                <Grid item xs={12} className={classes.organizationDescription}>
                                                    <Typography variant="body1" component="p">
                                                        {props.organizationInfo.description}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card >
                </Grid>
            </Box>
        </React.Fragment>
    );
};

export default withRouter(Organization);