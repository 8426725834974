import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';
const initialState = {
    newsDetails: null,
    loading: false,
    news: [],
    skip: 0,
    take: 9,
    created: false,
    totalFilteredNewsCount: 0,
    maxPossibleNewsCount: 9,
};

const fetchNewsDetailsStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const fetchNewsDetailsSuccess = (state, action) => {
    return updateObject(state, {
        newsDetails: action.payload ? {
            id: action.payload.id,
            creationDate: action.payload.createDate,
            text: action.payload.text,
            header: action.payload.header,
            municipalSettlement: action.payload.municipalSettlement ? action.payload.municipalSettlement.name : "",
            organization: action.payload.organization,
            images: action.payload.photos.map(photo => { return { id: photo.id, value: photo.digitalView }; }),
            documents: action.payload.documents,
            authority: action.payload.authority,
            municipalSettlementId: action.payload.municipalSettlement ? action.payload.municipalSettlement.id : "",
        } : null,
        loading: false
    });
};

const fetchNewsDetailsFail = (state, action) => {
    return updateObject(state, { loading: false });
};

const fetchNewsStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const fetchNewsSuccess = (state, action) => {
    let news = action.skip === 0 ? [] : state.news;
    const updatedNews = news.concat(action.news.map(news => {
        return {
            creationDate: news.createDate,
            header: news.header,
            text: news.text,
            image: news.thumbNail,
            id: news.id,
            authority: news.authority
        };
    }));
    const newSkip = updatedNews.length;
    return updateObject(state, {
        news: updatedNews,
        skip: newSkip,
        loading: false
    });
};

const fetchNewsFail = (state, action) => {
    return updateObject(state, { loading: false });
};

const resetNews = (state, action) => {
    return updateObject(state, { news: [] });
};

const postNewsSuccess = (state, action) => {
    return updateObject(state, {
        created: true
    });
};

const setTerritory = (state, action) => {
    return updateObject(state, {
        news: []
    });
};

const fetchTotalFilteredNewsCount = (state, action) => {
    return updateObject(state, {
        totalFilteredNewsCount: action.count
    });
}

const onChangeTotalNewsCount = (state, action) => {
    return updateObject(state, {
        maxPossibleNewsCount: state.maxPossibleNewsCount + state.take
    });
} 

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_NEWS_START: return fetchNewsStart(state, action);
        case actionTypes.FETCH_NEWS_SUCCESS: return fetchNewsSuccess(state, action);
        case actionTypes.FETCH_NEWS_FAIL: return fetchNewsFail(state, action);
        case actionTypes.FETCH_NEWS_DETAILS_START: return fetchNewsDetailsStart(state, action);
        case actionTypes.FETCH_NEWS_DETAILS_SUCCESS: return fetchNewsDetailsSuccess(state, action);
        case actionTypes.FETCH_NEWS_DETAILS_FAIL: return fetchNewsDetailsFail(state, action);
        case actionTypes.POST_NEWS_SUCCESS: return postNewsSuccess(state, action);
        case actionTypes.SET_TERRITORY: return setTerritory(state, action);
        case actionTypes.RESET_NEWS: return resetNews(state, action);
        case actionTypes.FETCH_TOTAL_FILTERED_NEWS_COUNT_SUCCES: return fetchTotalFilteredNewsCount(state, action);
        case actionTypes.CHANGE_TOTAL_NEWS_COUNT: return onChangeTotalNewsCount(state, action);
        default: return state;
    }
};

export default reducer;