import React, { Component} from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

class NewComment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: ""
        };
    }

    handleCommentTextChange = (text) => {
        this.setState({
            text: text
        });
    };

    handleCommentSend = () => {
        this.props.onPostComment(this.state.text);
    };

    render() {
        let buttonLabel = this.props.sendButtonLabel ? this.props.sendButtonLabel : "Отправить комментарий";
        let fieldLabel = this.props.fieldLabel ? this.props.fieldLabel : "Текст комментария";
        return (
            <Grid container spacing={2} >
                <Grid item key="0" xs={12}>
                    <TextField
                        id="comment"
                        multiline
                        variant="outlined"
                        rows={10}
                        fullWidth
                        label={fieldLabel}
                        value={this.state.text}
                        onChange={(event) => { this.handleCommentTextChange(event.target.value); }} />
                </Grid>
                <Grid item key="1" xs={12} container spacing={1}>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={this.handleCommentSend} disabled={!this.state.text}>
                            {buttonLabel}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" onClick={this.props.onCancel}>
                            Отмена
                    </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
    
};

export default NewComment;