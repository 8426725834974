import React from 'react';
import MyVotings from '../../containers/PersonalCabinet/MyVotings/MyVotings';
import MyVotingsFilter from '../../containers/PersonalCabinet/MyVotings/MyVotingsFilter';
import MyVotingsFilterToggler from '../../containers/PersonalCabinet/MyVotings/MyVotingsFilterToggler';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Link, withRouter } from 'react-router-dom';
import { Typography } from '@material-ui/core';

const myVotingsPage = (props) => {

    return (
        <Grid container className="MyVotings" spacing={2} direction="column" justify="center">
            <Grid item xs={12}>
                <Typography variant="h5" component="h5">
                    Личный кабинет: Мои голосования
                </Typography>
            </Grid>
            <Grid item key="actions" xs={12} container justify="space-between">
                <Grid item key="add" xs={12} sm={2}>
                    <Link to={{
                        pathname: props.match.url + "/new"
                    }}>
                        <Button color="primary" variant="contained">
                            Новое голосование / опрос
                    </Button>
                    </Link>
                </Grid>
                <Grid item key="filter" xs={12} sm={8}>
                    <MyVotingsFilter />
                </Grid>
                <Grid item key="toggler" xs={12} sm={2} style={{ textAlign: "right" }}>
                    <MyVotingsFilterToggler />
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "20px" }}>
                <MyVotings />
            </Grid>

        </Grid>
    );
};

export default withRouter(myVotingsPage);