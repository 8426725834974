import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import Tooltip from '@material-ui/core/Tooltip';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions';
import { Grid } from '@material-ui/core';

class MyVotingsFilterToggler extends Component {
    render() {
        let filterToggler = null;
        if (!this.props.showFilter) {
            filterToggler = (
                <Grid container direction="row-reverse" justify="flex-start" alignItems="center">
                    <Grid item style={{ marginLeft: '10px' }}>фильтр</Grid>
                    <Tooltip title="фильтровать">
                        <IconButton edge="end" aria-label="фильтр" onClick={() => this.props.onToggleFilter()}>
                            <FilterListIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            );
        }
        return filterToggler;
    }
}
const mapStateToProps = state => {
    return {
        showFilter: state.my.showVotingFilter
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onToggleFilter: () => dispatch(actions.toggleUserVotingFilter()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyVotingsFilterToggler);