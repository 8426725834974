import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ImageGrid from '../UI/ImageGrid';
import { Button } from '@material-ui/core';
import { parseDate } from '../../utility/parsers';
import { makeStyles } from '@material-ui/core/styles';
import MessageDocuments from '../../containers/Messages/MessageDocuments';

const useStyles = makeStyles((theme) => ({
    answerPerformerSubTitle: {
        padding: '0.75rem !important',
        paddingBottom: '0.5rem',
        //marginTop: '0.6rem',
        marginTop: '3rem',
        backgroundColor: '#fafafa',
    },
    messageAnswerBody: {
        marginTop: '0.5rem'
    },
    fileContainer: {
        padding: '0.5rem',
        paddingTop: '0'
    },
    attachedDocumentsTitle: {
        paddingBottom: '1rem', 
    }
}));

const MessageResponse = (props) => {
    const classes = useStyles();
    let hasImages = props.images && props.images.length;
    let hasDocuments = props.documents && props.documents.length;
    let imagesAndDocuments = null;
    let assessmentBlock = null;
    if (props.canAssess) {
        let currentDate = new Date().getDate();
        let responseDate = parseDate(props.date);
        responseDate.setDate(responseDate.getDate() + 7);
        let millisecondsLeftForAssessment = responseDate - currentDate;
        let canStillAssessResponse = (props.assessment == null) && props.canAssess && (millisecondsLeftForAssessment > 0);
        if (canStillAssessResponse) {
            assessmentBlock = (
                <Grid item xs={12} container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="body1" color="primary">Удовлетворены ли Вы ответом исполнителя?</Typography>
                    </Grid>
                    <Grid item xs={12} container spacing={2}>
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={() => props.onAssess(true)}>Да</Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="secondary" onClick={() => props.onAssess(false)}>Нет</Button>
                        </Grid>

                    </Grid>

                </Grid>
            );
        }
        
    }
    
    
    if (hasImages || hasDocuments) {
        let images = !hasImages
            ? null
            : (
                <ImageGrid images={props.images}/>
        );
        let documents = !hasDocuments
            ? null :(
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6" component="h6" className={classes.attachedDocumentsTitle}>
                        Прикрепленные документы
                    </Typography>
                    <MessageDocuments documents={props.documents}/>
                </Grid>
        );
        imagesAndDocuments = (
            <Grid item xs={12}>
                <Grid container className={classes.fileContainer}>
                    {images}
                    {documents}
                </Grid>
            </Grid>
        );
    }
    

    return (
        <Grid container spacing={2}>
            {!props.rejectedMessageStatus &&
                <Grid item container spacing={2} className={classes.answerPerformerTitle}>
                    <Grid container justify="space-between" alignItems="center" xs={12} className={classes.answerPerformerSubTitle}>
                        <Typography variant="h6" component="h6">
                            Ответ исполнителя:
                        </Typography>
                        <Typography variant="body1" component="p" color="textSecondary">
                            {props.date}
                        </Typography>
                        <Grid container className={classes.messageAnswerBody}>
                            <Typography variant="body1" component="p">
                                {props.text}
                            </Typography>
                        </Grid>
                    </Grid>
                    {imagesAndDocuments}
                    {assessmentBlock}
                </Grid>
            }
        </Grid>
    );
};

export default MessageResponse;