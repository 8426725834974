import React from 'react';
import PageableTable from '../UI/Containers/PageableTable';


export default function VotingTable(props) {

    const headCells = [
        { id: 'id', disablePadding: false, label: 'ID' },
        { id: 'createDate', disablePadding: false, label: 'Дата публикации' },
        { id: 'header', disablePadding: false, label: 'Заголовок' },
        { id: 'type', disablePadding: false, label: 'Тип' },
        { id: 'status', disablePadding: false, label: 'Статус' },
        { id: 'author', disablePadding: false, label: 'Автор' }
    ];

    return (
        <PageableTable
            itemsPerPage={props.votingsPerPage}
            rows={props.votings.map(vote => {
                return {
                    fields: {
                        id: vote.id,
                        createDate: vote.createDate,
                        header: vote.header,
                        type: vote.type,
                        status: vote.status,
                        author: vote.author
                    },
                    fontWeight: vote.status && vote.status.name === "Новое" ? "fontWeightBold" : null
                };
            })}
            itemIdProp="id"
            headCells={headCells}
            onItemClicked={props.onVotingClicked}
            count={props.count}
            labelItemsPerPage="Голосований/опросов на странице"
            onChangePage={(newPage) => props.onChangePage(newPage)}
            page={props.page}
        />


    );
}