import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';
import { makeFullName } from '../../utility/stringHelpers';

const initialState = {
    loading: false,
    performers: [],
    groupedMessageTypes: []
};

const fetchPerformersInfoStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const fetchPerformersInfoSuccess = (state, action) => {
    let mappedPerformers = action.performers.map(p => {
        return getPerformerPayload(p)
    });
    return updateObject(state, {
        performers: mappedPerformers,
        loading: false
    });
};

const fetchPerformerInfoStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const getPerformerPayload = (performerJson) => {
    return {
        id: performerJson.performer.id,
        email: performerJson.performer.email,
        name: makeFullName(performerJson.performer.firstName, performerJson.performer.patronymic, performerJson.performer.lastName),
        phoneNumber: performerJson.performer.phoneNumber,
        organization: performerJson.organization.name,
        messageTypes: !performerJson.types ? [] : performerJson.types.map(type => {
            return {
                id: type.typeId,
                name: type.type,
                categoryId: type.categoryId,
                category: type.category
            };
        }),
        position: performerJson.position
    };
};

const fetchPerformerInfoSuccess = (state, action) => {
    let p = action.payload;
    return updateObject(state, {
        performerInfo: getPerformerPayload(p),
        loading: false
    });
};

const fetchGroupedMessageTypesSuccess = (state, action) => {
    //let categoriesWithTypes = {};
    //action.messageTypes.forEach(el => {
    //    groupedMessageTypes[el.categoryId].title = el.category;
    //    el.types.forEach(type => {
    //        groupedMessageTypes[el.categoryId].messageTypes[type.typeId] = 
    //    });
    //    groupedMessageTypes[el.categoryId].messageTypes = {
    //        title: el.category,
    //        items: el.types.map(type => {
    //            return {
    //                id: type.typeId,
    //                title: type.type,
    //                checked: checkedTypes.indexOf(type.typeId) !== -1
    //            };
    //        })
    //    };
    //});
    return updateObject(state, {
        groupedMessageTypes: action.messageTypes,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PERFORMERS_START: return fetchPerformersInfoStart(state, action);
        case actionTypes.FETCH_PERFORMERS_SUCCESS: return fetchPerformersInfoSuccess(state, action);
        case actionTypes.FETCH_PERFORMER_INFO_START: return fetchPerformerInfoStart(state, action);
        case actionTypes.FETCH_PERFORMER_INFO_SUCCESS: return fetchPerformerInfoSuccess(state, action);
        case actionTypes.FETCH_GROUPED_MESSAGE_TYPES_SUCCESS: return fetchGroupedMessageTypesSuccess(state, action);
        default: return state;
    }
};

export default reducer;