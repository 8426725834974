import React, { Component } from 'react';
import { Grid, Button, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Input from '../../../../components/UI/Inputs/Input';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions';
import FileUploadValidatable from '../../../../components/UI/FileUpload/FileUploadValidatable';
import OutlinedContainer from '../../../../components/UI/Containers/OutlinedContainer';
import { withRouter } from 'react-router-dom';
import Preloader from '../../../../containers/Preloader';

class NewMessageType extends Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                categoryId: {
                    value: "",
                    elementType: "select",
                    config: {
                        label: "Категория сообщения",
                        defaultDisplayValue: "Категория не выбрана",
                        options: []
                    },
                    validation: {
                        required: true
                    },
                    error: "",
                    valid: false
                },
                title: {
                    value: "",
                    elementType: "textarea",
                    config: {
                        label: "Название"
                    },
                    validation: {
                        required: true
                    },
                    error: "",
                    valid: false
                },
            },
            formIsValid: false
        };
    }

    componentDidMount() {
        this.props.onFetchCategories();
    }

    componentDidUpdate(prevProps) {
        if (this.props.created) {
            this.props.history.push(`/admin/messageTypes`);
        }
    }

    handleCancel = () => {
        this.props.history.goBack();
    }

    handleFormElementBlur = (element) => { }

    handleFieldChange = (value, element) => {
        let updatedForm = { ...this.state.form };
        let updatedElement = { ...this.state.form[element] };
        updatedElement.value = value;
        updatedForm[element] = updatedElement;
        this.setState({ form: updatedForm }, this.validateForm);

    }

    handleError(errorText) {
        this.props.onNotify(errorText);
    }

    validateForm = () => {
        var isValid = true;
        let element = null;
        for (var elementName in this.state.form) {
            element = this.state.form[elementName];
            if (element && element.validation && element.validation.required && !element.value) {
                isValid = false;
                break;
            }
        }
        this.setState({
            formIsValid: isValid
        });
    }

    handleFormSubmit = () => {
        this.props.onPost(this.state.form.categoryId.value, this.state.form.title.value);
    }

    render() {
        if (!this.props.categories) {
            return (
                <Preloader fullScreen open color="primary"/>
            );
        }
        return (
            <Grid container justify="center">
                <Grid item xs={12} sm={8}>
                    <OutlinedContainer>
                        <form >
                            <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12} container justify="space-between" alignItems="center">
                                    <Grid item>
                                        <Typography variant="h5" gutterBottom style={{ textAlign: 'left' }}>
                                            Добавление типа сообщений
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" color="secondary" onClick={this.handleCancel}>
                                            Отмена
                                        </Button>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Input
                                        id="categoryId"
                                        type={this.state.form.categoryId.elementType}
                                        value={this.state.form.categoryId.value}
                                        config={this.state.form.categoryId.config}
                                        options={this.props.categories}
                                        error={this.state.form.categoryId.error}
                                        validation={this.state.form.categoryId.validation}
                                        onChange={event => { this.handleFieldChange(event.target.value, "categoryId"); }}
                                        onBlur={event => { this.handleFormElementBlur(event, "categoryId"); }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Input
                                        id="title"
                                        type={this.state.form.title.elementType}
                                        value={this.state.form.title.value}
                                        config={this.state.form.title.config}
                                        error={this.state.form.title.error}
                                        validation={this.state.form.title.validation}
                                        onChange={event => { this.handleFieldChange(event.target.value, "title"); }}
                                        onBlur={event => { this.handleFormElementBlur(event, "title"); }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" color="primary" onClick={this.handleFormSubmit} disabled={!this.state.formIsValid}>
                                        Сохранить
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </OutlinedContainer>
                </Grid>
            </Grid>

        );
    }
}

const mapStateToProps = state => {
    return {
        categories: state.msg.categories,
        created: state.msg.created
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchCategories: () => dispatch(actions.fetchMessageCategories()),
        onPost: (categoryId, title) => dispatch(actions.postMessageType(categoryId, title))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NewMessageType));