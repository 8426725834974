import React, { Component } from 'react';
import VotingTable from '../../../components/Voting/VotingTable';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import FilterToolbar from '../../../components/UI/FilterToolbar';
import { withRouter } from 'react-router-dom';
import Preloader from '../../../containers/Preloader';

class VotingTableContainer extends Component {
    componentDidMount() {
        this.props.onChangePage(this.props.page);
    }

    componentDidUpdate(prevProps) {
        //if (prevProps.skip !== ) {
        //    this.props.onFetchMessages(this.props.skip, this.props.take);
        //}

    }

    onVotingClicked = (id) => {
        var prefix = "";
        switch (this.props.role) {
            case "moderator":
                prefix = "moderation";
                break;
            default:
                break;
        }
        this.props.history.push({
            pathname: `/${prefix}/votings/${id}`
        });
    };

    render() {
        let votings = null;
        if (this.props.loading) {
            votings = (
                <Preloader fullScreen open color="primary"/>
            );
        }
        
        if (!this.props.loading) {
            votings = (
                <Grid container>
                    <VotingTable
                        filter={this.props.filter}
                        showFilter={this.props.showFilter}
                        votings={this.props.votings}
                        page={this.props.page}
                        votingsPerPage={this.props.votingsPerPage}
                        onChangePage={(newPage) => this.props.onChangePage(newPage)}
                        onToggleFilter={this.props.onToggleFilter}
                        onVotingClicked={this.onVotingClicked}
                        count={this.props.count}
                    />
                </Grid>

            );
        }
        return (
            <React.Fragment>
                <FilterToolbar filter={this.props.filter} onToggleFilter={this.props.onToggleFilter} showFilter={this.props.showFilter} header="Голосования и опросы"/>
                {votings}
            </React.Fragment>
        );

    }

};

const mapStateToProps = state => {
    return {
        votings: state.vote.votings,
        loading: state.vote.loading,
        skip: state.vote.skip,
        take: state.vote.take,
        showFilter: state.vote.showFilter,
        page: state.vote.page,
        votingsPerPage: state.vote.votingsPerPage,
        count: state.vote.votingsCount,
        role: state.user ? state.user.role : null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onChangePage: (newPage) => dispatch(actions.changeVotingsPage(newPage)),
        //onToggleFilter: () => dispatch(actions.toggleVotingsFilter())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VotingTableContainer));