import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    reportsList: []
};

const fetchReportsSuccess = (state, action) => {
    return updateObject(state, {
        reportList: action.reportList
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_REPORTS_SUCCESS: return fetchReportsSuccess(state, action);
        default: return state;
    }
};

export default reducer;