import React, { Component } from 'react';
import Filter from '../../../components/UI/Filter';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';

class MessageFilter extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.onInitPrivateFilter();
        if (this.props.statuses.length === 0) {
            this.props.onFetchMessageStatuses();

        }
        if (this.props.categories.length === 0) {
            this.props.onFetchMessageCategories();
        }
        if (this.props.messageTypes.length === 0) {
            this.props.onFetchMessageTypes();
        }
    }

    componentDidUpdate(prevProps, prevState) {

    }

    handleFilterChange = (event, filterElementId) => {
    }

    render() {
        return (
            <Filter
                show={this.props.show}
                values={this.props.filter}
                form={this.props.form}
                onFilterChange={this.props.onFilterChange}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        statuses: state.msg.statuses,
        categories: state.msg.categories,
        messageTypes: state.msg.messageTypes,
        filter: state.msg.filter,
        show: state.msg.showFilter,
        form: {
            statusId: {
                value: "",
                elementType: "select",
                config: {
                    label: "Статус",
                    defaultDisplayValue: "Статус не выбран",
                    options: state.msg.statuses
                }
            },
            categoryId: {
                value: "",
                elementType: "select",
                config: {
                    label: "Категория",
                    defaultDisplayValue: "Категория не выбрана",
                    options: state.msg.categories
                }
            },
            messageTypeId: {
                value: "",
                elementType: "select",
                config: {
                    label: "Тип",
                    defaultDisplayValue: "Тип не выбран",
                    options: state.msg.messageTypes
                }
            },
            sendDate: {
                value: null,
                elementType: "dateRange",
                config: {
                    label: "Дата отправки"
                }
            },
            answerDate: {
                value: null,
                elementType: "dateRange",
                config: {
                    label: "Дата ответа"
                }
            },
            //beginSendDate: {
            //    value: "",
            //    elementType: "date",
            //    config: {
            //        label: "C"
            //    }
            //},
            //endSendDate: {
            //    value: "",
            //    elementType: "date",
            //    config: {
            //        label: "По"
            //    }
            //},
            //beginAnswerDate: {
            //    value: "",
            //    elementType: "date",
            //    config: {
            //        label: "С"
            //    }
            //},
            //endAnswerDate: {
            //    value: "",
            //    elementType: "date",
            //    config: {
            //        label: "По"
            //    }
            //},
        }
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFilterChange: (id, value) => dispatch(actions.setPrivateMessageFilterValue(id, value)),
        onFetchMessageStatuses: () => dispatch(actions.fetchMessageStatuses()),
        onFetchMessageCategories: () => dispatch(actions.fetchMessageCategories()),
        onFetchMessageTypes: () => dispatch(actions.fetchMessageTypes()),
        onInitPrivateFilter: () => dispatch(actions.initMessagePrivateFilter())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageFilter);
