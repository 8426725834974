import React, { Component } from 'react';
import {Grid} from '@material-ui/core';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import NewMessageStepper from '../../public/containers/Messages/NewMessageStepper';
import Can from '../../components/Auth/Can';
import Page403 from '../../pages/ErrorPages/Page403';
import { withRouter } from 'react-router';
import { createDraft } from '../../utility/messageHelpers';
import Preloader from '../Preloader';

class FullMessageContainer extends Component {

    componentDidMount() {
        if (this.props.match.params.id) {
            this.props.onFetchMessage(this.props.match.params.id);
        }
    }

    render() {
        let message = (
            <Preloader open fullScreen color="primary"/>
        );
        if (!this.props.loading && this.props.loadedMessage) {
            message = (
                <Can
                    perform="message:edit"
                    data={{
                        user: this.props.user,
                        authorId: this.props.loadedMessage.author.id,
                        messageStatus: this.props.loadedMessage.status.name
                    }}
                    yes={() => (
                        <NewMessageStepper draft={this.props.draft} />
                    )}
                    no={() => (<Page403 />)}
                />
            );
        }
        return (
            <Grid container justify="center">
                <Grid item xs={12} sm={8}>
                    {message}
                </Grid>
            </Grid>
            );
        
    }
}

const mapStateToProps = state => {
    return {
        draft: !state.msg.loadedMessage ? null : createDraft(state.msg.loadedMessage),
        loadedMessage: state.msg.loadedMessage,
        loading: state.msg.loading,
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchMessage: (id) => dispatch(actions.fetchMessage(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FullMessageContainer));