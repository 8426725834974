import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
    afterVoteContainer: {
        maxWidth: '450px',
        padding: '0.5rem',
    },
    afterVoteIcon: {
        color: green[500],
        marginRight: '0.6rem'
    },
    afterVoteItem: {
        padding: '0.5rem 0 0.5rem 0.5rem',
    },
    afterVoteTxt: {
        margin: '0.75rem 0px 0px 1.5rem',
    },
    ml: {
        marginLeft: '0.75rem'
    }
}));

const AfterVote = (props) => {
    const classes = useStyles();

    return (
        props.options
            ? <Grid item xs={12} container direction="column" className={classes.afterVoteContainer} >
                    <Grid item xs={12}  container >
                        <Typography variant="h5" gutterBottom>Ваш голос учтён</Typography>
                    </Grid>

                    <Typography variant="subtitle1" gutterBottom>Можете ознакомиться с текущими результатами:</Typography>

                    {props.options.map((option, index) => {
                        let isShow = false;
                        const labelId = `checkbox-label-${index}`;
                        return (
                            <Grid item className={classes.afterVoteItem} key={index} container >
                                <Grid item key={`opt${index}`}
                                    container
                                    alignItems="center"
                                    spacing={2}
                                    xs={12}>

                                    {
                                        props.loadedVotingAnswers.forEach(el => {
                                           ((option.id == el.answerOption.id) && (el.userGiveAnswer == props.user)) && (isShow = true)
                                        })
                                    }

                                    {
                                        (isShow)
                                            ? <CheckBoxOutlinedIcon className={classes.afterVoteIcon} />
                                            : <Grid item className={classes.ml}> </Grid>
                                    }

                                    <Grid item>
                                        {option.title}
                                    </Grid>

                                    <Grid item>
                                        {props.renderImage(option, index)}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} key={`res${index}`} className={classes.afterVoteTxt}>
                                    <Typography variant="body1" color="textSecondary">
                                        Проголосовало: {option.numberOfVotes ? option.numberOfVotes : 0}
                                    </Typography>

                                </Grid>
                            </Grid>
                        )
                    })
                    }
                </Grid>    
            : null
    )
}

export default AfterVote;