import React, { Component } from 'react';
import Filter from '../../../components/UI/Filter';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';

class PublicVotingFilter extends Component {

    componentDidMount() {
        if (this.props.statuses.length === 0) {
            this.props.onFetchVotingStatuses();

        }
        if (this.props.municipalSettlements.length === 0) {
            this.props.onFetchMunicipalSettlements();
        }
    }

    componentDidUpdate(prevProps, prevState) {}

    handleFilterChange = (event, filterElementId) => {}

    render() {
        return (
            <Filter
                show={this.props.show}
                filter={this.props.filter}
                form={this.props.form}
                values={this.props.filter}
                onFilterChange={this.props.onFilterChange}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        statuses: state.vote.statuses,
        municipalSettlements: state.cmn.municipalSettlements,
        filter: state.vote.filter,
        show: state.vote.showFilter,
        form: {
            statusId: {
                value: "",
                elementType: "select",
                config: {
                    label: "Статус",
                    defaultDisplayValue: "Статус не выбран",
                    options: state.vote.statuses
                }
            },
            territoryId: {
                value: "",
                elementType: "select",
                config: {
                    label: "Территория проведения",
                    defaultDisplayValue: "Территория не выбрана",
                    options: state.cmn.municipalSettlements
                }
            }
        }
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFilterChange: (id, value) => dispatch(actions.setVotingFilterValue(id, value)),
        onFetchVotingStatuses: () => dispatch(actions.fetchVotingStatuses()),
        onFetchMunicipalSettlements: () => dispatch(actions.fetchMunicipalSettlements()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicVotingFilter);
