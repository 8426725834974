import React from "react";
import format from "date-fns/format";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import ru from 'date-fns/locale/ru';


class RuLocalizedUtils extends DateFnsUtils {
    getCalendarHeaderText(date) {
        return format(date, "LLLL", { locale: this.locale });
    }

    getDatePickerHeaderText(date) {
        return format(date, "dd MMMM", { locale: this.locale });
    }
}

const Input = (props) => {

    return (
        <MuiPickersUtilsProvider utils={RuLocalizedUtils} locale={ru}>
            <KeyboardDatePicker style={{ width: "100%" }}
                id={props.id}
                label={props.label}
                value={props.value}
                minDate={props.minDate}
                onChange={props.onChange}
                onBlur={props.onBlur}
                InputLabelProps={{
                    shrink: true,
                }}
                invalidDateMessage="недопустимый формат даты"
                disablePast={props.disablePast}
                format="dd.MM.yyyy"
                variant="inline"
                autoOk
            />
        </MuiPickersUtilsProvider>
    );
};

export default Input;