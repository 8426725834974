import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FileUploadValidatable from '../../../components/UI/FileUpload/FileUploadValidatable';
import Input from '../../../components/UI/Inputs/Input';
import * as actions from '../../../store/actions';
import { connect } from 'react-redux';
import { insertValueIntoArrayElement, deleteValueFromArrayElement } from '../../../utility/formHelpers';

class NewMessageResponse extends Component {
    constructor(props) {
        super(props);
        this.maxFilesCount = 5;
        this.maxTotalFileSize = 5;
        this.state = {
            form: props.response ? this.fillFormByExistingResponse(props.response) : {
                text: "",
                status: "",
                images: [],
                documents: []
            },
            formIsValid: false,
            textValid: false,
            textError: "",
            statusValid: false,
            statusError: ""
        };
    }

    componentDidMount(props) {
        this.props.onFetchStatuses();
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.response && this.props.response) {
            this.setState({
                form: this.fillFormByExistingResponse(this.props.response)
            });
        }
    }

    fillFormByExistingResponse(response) {
        return {
            text: response.text,
            status: response.status,
            images: response.images.map(file => { return { id: file.id, name: file.title, value: file.value };}),
            documents: response.documents.map(file => { return { id: file.id, value: file.value }; })
        };
    }

    handleTextChange = (text) => {
        let updatedForm = { ... this.state.form };
        updatedForm.text = text;
        this.setState({
            form: updatedForm,
            textError: text ? "" : "Поле не заполнено"
        }, this.checkFormValidity);
    };

    handleStatusChange = (status) => {
        let updatedForm = { ... this.state.form };
        updatedForm.status = status;
        this.setState({
            form: updatedForm,
            statusError: ""
        }, this.checkFormValidity); 
    };

    handleTextBlur = (text) => {
        this.setState({
            textError: !text ? "Поле не заполнено" : "",
        }, this.checkFormValidity);
    };

    handleStatusBlur = (status) => {
        this.setState({
            statusError: !status ? "Поле не заполнено" : "",
        }, this.checkFormValidity);
    };

    handleFormSubmit = () => {
        this.props.onPostResponse(this.state.form);
    };

    checkFormValidity = () => {
        //for (let item in this.state.form) {
        //    if (!this.state.form[item]) {
        //        return false;
        //    }
        //}
        //return true;
        let formIsValid = true;
        for (var element in this.state.form) {
            if (!this.checkValidity(this.state.form[element])) {
                formIsValid = false;
            }
        }
        this.setState({
            formIsValid: formIsValid
        });
    };

    checkValidity(element) {
        return Boolean(element);
    }

    handleDocumentAdd = (document) => {
        var updatedForm = insertValueIntoArrayElement(this.state.form, "documents", document);
        this.setState({
            form: updatedForm
        });
    };

    handleDocumentDelete = (index) => {
        var updatedForm = deleteValueFromArrayElement(this.state.form, "documents", index);
        this.setState({
            form: updatedForm
        });
    };

    handleImageAdd = (image) => {
        var updatedForm = insertValueIntoArrayElement(this.state.form, "images", image);
        this.setState({
            form: updatedForm
        });
    };

    handleImageDelete = (index) => {
        var updatedForm = deleteValueFromArrayElement(this.state.form, "images", index);
        this.setState({
            form: updatedForm
        });
    };

    renderImagesInput() {
        return (
            <Grid container direction="column">
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom style={{ display: "block", textAlign: 'left' }}>
                        Изображения
                        <Typography variant="caption" gutterBottom style={{ marginLeft: "5px" }}>
                            ( До 5 шт )
                        </Typography>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FileUploadValidatable
                        maxFilesCount={5}
                        fileType="image"
                        files={this.state.form.images}
                        onFileAdd={this.handleImageAdd}
                        onFileDelete={this.handleImageDelete}
                        addFileBtnText="Добавить изображение"
                        accept="image/png,image/jpeg"
                        maxTotalSizeInMegabytes={this.maxTotalFileSize}
                    />
                </Grid>

            </Grid>
        );
    }

    renderDocumentsInput() {
        return (
            <Grid container direction="column">
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom style={{ display: "block", textAlign: 'left' }}>
                        Документы
                        <Typography variant="caption" gutterBottom style={{ marginLeft: "5px" }}>
                            ( До 5 шт )
                        </Typography>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FileUploadValidatable
                        maxFilesCount={5}
                        fileType="document"
                        files={this.state.form.documents}
                        accept="application/pdf"
                        onFileDownload={(id, title) => this.props.onFileDownload(this.props.messageId, id, title)}
                        onFileAdd={this.handleDocumentAdd}
                        onFileDelete={this.handleDocumentDelete}
                        addFileBtnText="Добавить документ"
                        maxTotalSizeInMegabytes={this.maxTotalFileSize}
                    />
                </Grid>
                
            </Grid>
        );
    }

    render() {
        return (
            <Paper style={{ marginTop: "20px", padding: "20px" }}>
                <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            Ответ
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            id="text"
                            type="textarea"
                            value={this.state.form.text}
                            config={{ label: "Текст ответа", rows: 10 }}
                            validation={{ required: true }}
                            error={this.state.textError}
                            onChange={event => { this.handleTextChange(event.target.value); }}
                            onBlur={(event) => { this.handleTextBlur(event.target.value); }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            id="text"
                            type="select"
                            value={this.state.form.status}
                            validation={{ required: true }}
                            error={this.state.statusError}
                            config={{ label: "Статус", options: this.props.statuses }}
                            onChange={event => { this.handleStatusChange(event.target.value); }}
                            onBlur={(event) => { this.handleStatusBlur(event.target.value); }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        {this.renderImagesInput()}
                    </Grid>
                    <Grid item xs={12}>
                        {this.renderDocumentsInput()}
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" onClick={this.handleFormSubmit} disabled={!this.state.formIsValid}>
                            Отправить на модерацию
                    </Button>
                    </Grid>
                </Grid>
                
            </Paper>    
        );

    }
}

const mapStateToProps = state => {
    return {
        statuses: state.msg.responseStatuses
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onFetchStatuses: () => dispatch(actions.fetchResponseStatuses()), 
        onPostResponse: (data) => dispatch(actions.postMessageResponse(ownProps.messageId, data)),
        onFileDownload: (messageId, documentId, documentTitle) => dispatch(actions.fetchMessageDocument(messageId, documentId, documentTitle))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewMessageResponse);