import React, { Component } from 'react';
import {
    TableContainer, Table, TableBody, TableHead, TableRow, TableCell,
    FormControl, InputLabel, Select, MenuItem
} from '@material-ui/core';
import MessageTypesTableBody from '../../components/MessageTypes/MessageTypesTableBody';
import { connect } from 'react-redux';
import * as actions from "../../../../store/actions";
import { removeDuplicates } from "../../../../utility/arrayHelpers";
import Preloader from '../../../../containers/Preloader';

class MessageTypes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            category: ""
        };
    }

    componentDidMount() {
        this.props.onFetchMessageTypes();
    }

    handleCategoryChange = (event) => {
        this.setState({
            category: event.target.value
        });
    };

    render() {
        if (!this.props.messageTypes) {
            return (
                <Preloader fullScreen open color="primary"/>
            );
        }
        let filteredMessageTypes = this.props.messageTypes;
        if (this.state.category) {
            filteredMessageTypes = filteredMessageTypes.filter(type => type.categoryId == this.state.category);
        }
        return (
            <TableContainer>
                <Table
                    aria-labelledby="tableTitle"
                    size='medium'
                >
                    <TableHead>
                        <TableRow>
                            <TableCell
                                key="cat"
                                align='left'
                                padding='default'
                                style={{ width: "50%" }}
                            >
                                <FormControl style={{width: "100%"}}>
                                    <InputLabel>Категория</InputLabel>
                                    <Select
                                        value={this.state.category}
                                        onChange={this.handleCategoryChange}
                                    >
                                        <MenuItem value="">Категория не выбрана</MenuItem>
                                        {
                                            this.props.categories.map((category) => (<MenuItem value={category.id}>{category.title}</MenuItem>))
                                        }
                                    </Select>
                                </FormControl>
                            </TableCell>
                            <TableCell
                                key="type"
                                align='left'
                                padding='default'
                                variant="head"
                                style={{ width: "50%" }}
                            >
                                Тип
                        </TableCell>
                        </TableRow>
                    </TableHead>
                    <MessageTypesTableBody messageTypes={filteredMessageTypes.map(x => { return { type: x.title, category: x.categoryTitle }; })} />
                </Table>
            </TableContainer>

        );
    }
    

}

const mapStateToProps = state => {
    let mesTypes = state.msg.messageTypesWithCategories;
    return {
        categories: removeDuplicates(mesTypes, "categoryTitle").map(item => { return { id: item.categoryId, title: item.categoryTitle };}),
        messageTypes: mesTypes
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchMessageTypes: () => dispatch(actions.fetchMessageTypesWithCategories())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageTypes);