import React, { Component } from 'react';
import { SnackbarProvider } from 'notistack';
import FullMessagePage from './public/pages/Messages/FullMessagePage';
import MessagesPage from './public/pages/Messages/MessagesPage';
import NewMessagePage from './public/pages/Messages/NewMessagePage';
import {Route, Redirect, Switch } from 'react-router-dom';
import MessageModerationPage from './private/Moderator/pages/MessageModerationPage';
import ModeratorMessagesPage from './private/Moderator/pages/MessagesPage';
import PrivateVotingsPage from './private/shared/pages/VotingsPage';
import MessagePerformPage from './private/Performer/pages/MessagePage';
import PerformerMessagesPage from './private/Performer/pages/MessagesPage';
import Notifier from './containers/Notifier';
import VotingsPage from './public/pages/Voting/VotingsPage';
import VotingPage from './public/pages/Voting/VotingPage';
import NewVotingPage from './public/pages/Voting/NewVotingPage';
import NewsPage from './public/pages/News/NewsPage';
import AdminNewsCreationPage from './private/shared/pages/NewsCreationPage';
import NewsDetailsPage from './pages/News/NewsDetailsPage';
import VotingModerationPage from './private/Moderator/pages/VotingModerationPage';
import MyMessagesPage from './public/pages/PersonalCabinet/MyMessagesPage';
import MyVotingsPage from './public/pages/PersonalCabinet/MyVotingsPage';
import OrganizationsPage from './pages/OrganizationsPage';
import NewOrganization from './private/shared/containers/Organizations/NewOrganization';
import MessageTypesPage from './private/Administrator/pages/MessageTypesPage';
import NewMessageType from './private/Administrator/containers/MessageTypes/NewMessageType';
import MainMenu from './containers/Menu/MainMenu';
import Can from './components/Auth/Can';
import AboutPage from './pages/About/AboutPage';
import Home from './containers/Home';
import Performers from './private/Moderator/containers/Performers';
import PerformerInfo from './private/Moderator/containers/PerformerInfo';
import WorkProfile from './private/shared/containers/WorkProfile';
import ReportList from './private/shared/containers/ReportList';
import Oranization from './containers/Organizations/Organization';
import FavoritesPage from './public/pages/PersonalCabinet/FavoritesPage';
import ErrorHandler from './pages/ErrorPages/ErrorHandler';
import { hideErrorPage } from './store/actions';
import { connect } from 'react-redux';
import Page404 from './pages/ErrorPages/Page404';
import Page403 from './pages/ErrorPages/Page403';
import Page401 from './pages/ErrorPages/Page401';
import { getHomeUrl } from './utility/urlHelpers';
import NewsEdit from './components/News/NewsEdit';
import { fetchUser } from './store/actions';
import OrganizationEdit from './containers/Organizations/OrganizationEdit';
import MessageEdit from './containers/Messages/MessageEdit';
import MunicipalSettlementMessageTypes from './private/Moderator/containers/MunicipalSettlementMessageTypes';
class App extends Component {
    state = {
    }

    componentWillMount() {
        
    }

    componentDidMount() {
        const { history } = this.props;
        this.unsubscribeFromHistory = history.listen(this.handleLocationChange);
        this.handleLocationChange(history.location);
        this.props.loadUser();
    }

    componentWillUnmount() {
        if (this.unsubscribeFromHistory) this.unsubscribeFromHistory();
    }

    handleLocationChange = (location) => {
        this.props.hideErrorPage();
        //if (this.props.needsLogin) {
        //    window.location.href = process.env.REACT_APP_ACCOUNT_URL + "/login?returnUrl=" + window.location.origin;
        //}
    }
    
    render() {
        //const url = this.props.location.pathname.substring(0, 9);
        //if (url === '/callback') {
        //    const rest = this.props.location.pathname.substring(9);
        //    return (<CallbackPage {...this.props} signInParams={`${url}#${rest}`} />);
        //}
      return (

        <SnackbarProvider maxSnack={3}>
              <div className="App">
                  <MainMenu />
                  
                  <div className="main-content">
                      <ErrorHandler>
                        <Switch>

                            <Route path="/cabinet/messages" exact>
                                  <Can perform="cabinet:visit"
                                      yes={() => (<MyMessagesPage />)}
                                      no={() => (<Page403 />)}
                                />
                            </Route>
                            <Route path="/cabinet/votings" exact>
                                <Can perform="cabinet:visit" yes={() => (
                                    <MyVotingsPage />
                                  )}
                                    no={() => (<Page403 />)}
                                />
                            </Route>
                            <Route path="/cabinet/favorites" exact>
                                <Can perform="cabinet:visit" yes={() => (
                                    <FavoritesPage />
                                  )}
                                    no={() => (<Page403 />)}
                                />
                            </Route>
                            <Route path="/organizations" exact>
                                <Can perform="organizations:list" yes={() => (
                                    <OrganizationsPage/>
                                  )}
                                    no={() => (<Page403 />)}
                                />
                            </Route>
                            <Route path="/organizations/new" exact>
                                <Can perform="organizations:create" yes={() => (
                                    <NewOrganization />
                                  )}
                                    no={() => (<Page403 />)}
                                />
                            </Route>
                            <Route path="/organizations/:id" exact>
                                <Can perform="organizations:view" yes={() => (
                                    <Oranization />
                                  )}
                                    no={() => (<Page403 />)}
                                />
                            </Route>
                            <Route path="/organizations/edit/:id" exact>
                                <Can perform="organizations:edit" yes={() => (
                                    <OrganizationEdit />
                                  )}
                                    no={() => (<Page403 />)}
                                />
                            </Route>
                            <Route path="/admin/messagetypes/new" exact>
                                <Can perform="messagetypes:create" yes={() => (
                                    <NewMessageType />
                                  )}
                                    no={() => (<Page403 />)}
                                />
                            </Route>

                            <Route path="/admin/messagetypes" exact>
                                <Can perform="messagetypes:list" yes={() => (
                                    <MessageTypesPage />
                                  )}
                                    no={() => (<Page403 />)}
                                />
                            </Route>
                            <Route path="/news/add" exact >
                                <Can perform="news:create" yes={() => (
                                    <AdminNewsCreationPage />
                                  )}
                                    no={() => (<Page403 />)}
                                />
                            </Route>
                          
                            <Route path="/moderation/messages/:id" exact>
                                <Can perform="messages:moderate" yes={() => (
                                    <MessageModerationPage/>
                                  )}
                                    no={() => (<Page403 />)}
                                />
                            </Route>
                            <Route path="/moderation/messages" exact>
                                <Can perform="messages:moderate" yes={() => (
                                    <ModeratorMessagesPage />
                                  )}
                                    no={() => (<Page403 />)}
                                />
                            </Route>
                            <Route path="/reports" exact >
                                <Can perform="reports:get" yes={() => (
                                    <ReportList />
                                )}
                                    no={() => (<Page403 />)}
                                />
                            </Route>
                          
                            <Route path="/moderation/votings/:id" exact >
                                <Can perform="votings:moderate" yes={() => (
                                    <VotingModerationPage />
                                )}
                                    no={() => (<Page403 />)}
                                />
                            </Route>
                            <Route path="/moderation/votings" exact >
                                <Can perform="votings:moderate" yes={() => (
                                    <PrivateVotingsPage />
                                )}
                                    no={() => (<Page403 />)}
                                />
                            </Route>
                            <Route path="/admin/votings" exact >
                                <Can perform="votings:list" yes={() => (
                                    <PrivateVotingsPage />
                                )}
                                    no={() => (<Page403 />)}
                                />
                            </Route>
                            <Route path="/moderation/performers" exact >
                                <Can perform="performers:list" yes={() => (
                                    <Performers />
                                )}
                                    no={() => (<Page403 />)}
                                />
                            </Route>
                            <Route path="/moderation/performers/:id" exact >
                                <Can perform="performers:view" yes={() => (
                                    <PerformerInfo />
                                )}
                                    no={() => (<Page403 />)}
                                />
                              </Route>
                              <Route path="/moderation/messageTypes" exact >
                                  <Can perform="messageTypes:edit" yes={() => (
                                      <MunicipalSettlementMessageTypes />
                                  )}
                                      no={() => (<Page403 />)}
                                  />
                                  
                                  
                              </Route>
                            <Route path="/performer/messages/:id" exact>
                                <Can perform="messages:perform" yes={() => (
                                    <MessagePerformPage />
                                )}
                                    no={() => (<Page403 />)}
                                />
                            </Route>
                            <Route path="/performer/messages" exact >
                                <Can perform="messages:perform" yes={() => (
                                    <PerformerMessagesPage />
                                )}
                                    no={() => (<Page403 />)}
                                />
                            </Route>
                            <Route path="/messages/new" exact >
                                <Can perform="messages:create" yes={() => (
                                    <NewMessagePage />
                                )}
                                    no={() => (<Page403 />)}
                                    noUser={() => (<Page401 />)}
                                />
                              </Route>
                              <Route path="/messages/:id/edit" exact >
                                  <Can perform="messages:view" yes={() => (
                                      <MessageEdit />
                                  )}
                                      no={() => (<Page403 />)}
                                  />
                              </Route>
                            <Route path="/messages/:id" exact >
                                <Can perform="messages:view" yes={() => (
                                    <FullMessagePage />
                                )}
                                    no={() => (<Page403 />)}
                                />
                            </Route>
                            <Route path="/messages" exact component={MessagesPage} />
                            <Route path="/votings/new" exact>
                                  <Can perform="votings:create" yes={() => (
                                      <NewVotingPage />
                                  )}
                                      no={() => (<Page403 />)}
                                      noUser={() => (<Page401 />)}
                                />
                            </Route>
                            <Route path="/votings/:id" exact component={VotingPage} />
                            <Route path="/votings" exact component={VotingsPage} />
                            <Route path="/news/:id" exact component={NewsDetailsPage} />
                            <Route path="/news" exact component={NewsPage} />
                            <Route path="/about" exact component={AboutPage} />
                            <Route path="/users" exact>
                                  <Can perform="users:list" yes={() => {
                                        window.location.href = `${process.env.REACT_APP_IDENTITY_SERVER_URL}/roles/userlist?returnUrl=${getHomeUrl()}`;
                                        return null;
                                  }}
                                    no={() => (<Page403 />)}
                                  />
                            </Route>

                            <Route path="/news/edit/:id" exact>
                                  <Can perform="news:edit" yes={() => (
                                      <NewsEdit />
                                )}
                                    no={() => (<Page403 />)}
                                />
                            </Route>

                            <Route path="/profile" exact >
                                <Can perform="profile:edit" yes={() => (
                                    <WorkProfile />
                                )}
                                    no={() => (<Page403 />)}
                                />
                            </Route>
                              <Route path="/" exact component={Home} />
                              <Route component={Page404} />
                        </Switch>
                      </ErrorHandler>
                      </div>
                      
            <Notifier />  
                      

        </div>
    </SnackbarProvider>
          
    );
  }
}

const mapStateToProps = (state) => {
    return {
        needsLogin: state.user.needsLogin
    };
};

const mapDispatchToProps = dispatch => {
    return {
        hideErrorPage: () => dispatch(hideErrorPage()),
        loadUser: () => dispatch(fetchUser())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
