import React from 'react';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { CardContent } from '@material-ui/core';

const comment = (props) => {
    return (
        <Card>
            <CardContent>
                <Grid container spacing={2} style={{width: "100%"}}>
                    <Grid item key="header" xs={12} container justify="space-between" spacing={1}>
                        <Grid item key="author">
                            <Typography variant="body1">
                                {props.author}
                            </Typography>
                        </Grid>
                        <Grid item key="date">
                            <Typography variant="body1">
                                {props.date}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item key="body" xs={12} zeroMinWidth>
                        <Typography variant="body1">
                            {props.text}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>    
    );
};

export default comment;