import React, { Component } from 'react';
import Filter from '../../../components/UI/Filter';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';

class MessageMapFilter extends Component {

    componentDidMount() {
        if (this.props.statuses.length === 0) {
            this.props.onFetchMessageStatuses();

        }
        if (this.props.categories.length === 0) {
            this.props.onFetchMessageCategories();
        }
        if (this.props.municipalSettlements.length === 0) {
            this.props.onFetchMunicipalSettlements();
        }
    }

    componentDidUpdate(prevProps, prevState) {}

    handleFilterChange = (event, filterElementId) => {}

    render() {
        return (
            <Filter
                show
                filter={this.props.filter}
                form={this.props.form}
                values={this.props.filter}
                onFilterChange={this.props.onFilterChange}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        statuses: state.msg.mapStatuses,
        categories: state.msg.categories,
        municipalSettlements: state.cmn.municipalSettlements,
        filter: state.msg.mapFilter,
        form: {
            status: {
                value: "",
                elementType: "select",
                config: {
                    label: "Статус",
                    defaultDisplayValue: "Статус не выбран",
                    options: state.msg.mapStatuses.map(status => { return { id: status.title, title: status.title };})
                }
            },
            category: {
                value: "",
                elementType: "select",
                config: {
                    label: "Категория",
                    defaultDisplayValue: "Категория не выбрана",
                    options: state.msg.categories.map(category => { return { id: category.title, title: category.title };})
                }
            },
            municipalSettlement: {
                value: "",
                elementType: "select",
                config: {
                    label: "Территория",
                    defaultDisplayValue: "Территория не выбрана",
                    options: state.cmn.municipalSettlements.map(munSet => { return { id: munSet.gisROGuid, title: munSet.title }; })
                }
            },
            beginDate: {
                value: "",
                elementType: "date",
                config: {
                    label: "C"
                }
            },
            endDate: {
                value: "",
                elementType: "date",
                config: {
                    label: "По"
                }
            },
            //municipalSettlementId: {
            //    value: "",
            //    elementType: "select",
            //    config: {
            //        label: "Территория",
            //        defaultDisplayValue: "Не выбрана",
            //        options: state.cmn.municipalSettlements
            //    }
            //}
        }
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFilterChange: (id, value) => dispatch(actions.setMessageMapFilterValue(id, value)),
        onFetchMessageStatuses: () => dispatch(actions.fetchMessageMapStatuses()),
        onFetchMessageCategories: () => dispatch(actions.fetchMessageCategories()),
        onFetchMunicipalSettlements: () => dispatch(actions.fetchMunicipalSettlements()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageMapFilter);
