import React, { Component } from 'react';
import { Checkbox, Radio, Button, Grid, Typography, Box, Divider  } from '@material-ui/core';
import Image from '../UI/Image';
import Can from '../Auth/Can';
import AfterVote from './AfterVote';
import Input from '../../components/UI/Inputs/Input';
import FileUploadValidatable from '../UI/FileUpload/FileUploadValidatable';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import { green } from '@material-ui/core/colors';

class VotingWithMultipleChoices extends Component {
    constructor(props) {
        super(props);
        let options = [];
        props.options.forEach(opt => {
            options.push({
                ...opt,
                checked: false,
            });
        });
        this.state = {
            options: options,
            selectedOption: null,
            canSubmit: false,
            images: [],
            ownOptionImage: null,
            ownOption: {
                value: "",
                elementType: "textbox",
                config: {
                    label: "Свой вариант",
                },
            }
        };
        this.multipleChoice = props.multiple;
        this.withOwnOption = false;//props.multiple && props.withOwnOption ? props.withOwnOption : false;
 
    }

    componentDidMount() {
        if (this.props.preview && this.props.options) {
            this.props.options.forEach((option, index) => {
                if (option.image) {
                    let self = this;
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        let images = { ...self.state.images };
                        images[index] = e.target.result;
                        self.setState({
                            images: images
                        });
                    };
                    reader.readAsDataURL(option.image);
                } else {
                    let images = { ...this.state.images };
                    images[index] = null;
                    this.setState({
                        images: images
                    });
                }
            });
        }
    }

    componentDidUpdate(prevProps) {}

    setChecked = (index, isChecked) => {
        let options = [...this.state.options];
        let checkedElement = { ...options[index] };
        checkedElement.checked = isChecked;
        options[index] = checkedElement;
        this.setState({
            options: options,
            canSubmit: options.filter(option => { return option.checked; }).length > 0
        });
    };

    setChoice = (optionId) => {
        this.setState({
            selectedOption: optionId,
            canSubmit: true
        });
    };

    handleCheckBoxChange = (index, checked) => {
        this.setChecked(index, checked);
    };

    handleRadioChange = (value) => {
        this.setChoice(value);
    }

    handleToggle = (optionId, index) => {
        if (this.multipleChoice) {
            this.setChecked(index, !this.state.options[index].checked);
        } else {
            this.setChoice(optionId);
        }
    };

    handleSubmit = () => {
        this.props.onSubmit((this.state.selectedOption ? [+this.state.selectedOption] : this.state.options.filter(x => x.checked).map(x => x.id)), this.state.ownOption.value.length > 0 && this.state.ownOption.value);
    }

    renderCheckBox = (index, labelId) => {
        return (
            <Checkbox
                checked={this.state.options[index].checked}
                onChange={event => this.handleCheckBoxChange(index, event.target.checked)}
                color="primary"
                inputProps={{ 'aria-labelledby': labelId }}
                disabled={this.props.preview}
            />
        );
    }

    renderRadioButton = (optionId, labelId) => {
        return (
            <Radio color="primary"
                checked={this.state.selectedOption == optionId}
                onChange={event => this.handleRadioChange(event.target.value)}
                value={optionId}
                name="selectedOption"
                inputProps={{ 'aria-labelledby': labelId }}
                disabled={this.props.preview}
            />
        );
    }

    handleOwnOptionChange = (value) => {
        let updatedElement = { ...this.state["ownOption"] };
        updatedElement.value = value;
        this.setState({
            ownOption: updatedElement,
            canSubmit: value.length
        });
    }

    renderInput = (optionId, index, labelId) => {
        if (this.multipleChoice) {
            return this.renderCheckBox(index, labelId);
        } else {
            return this.renderRadioButton(optionId, labelId);
        }
    }

    renderImage = (option, index) => {
        console.log(this.state.images[index]);
        if (option.image) {
            //<img src={`data:image/jpg;base64,${option.image}`} alt="изображение" style={{ height: "160px", objectFit: "scale-down", maxWidth: "100%" }} />
            return (
                <Image id={option.id}
                    value={option.image}
                    url={this.props.preview ? this.state.images[index] : null}
                    style={{ maxWidth: "300px" }} />
            );
        }
        return null;
    }

    changeImage = (newImage) => {
        this.setState({
            ownOptionImage: newImage
        });
    }

    handleImageDelete = () => {
        this.setState({
            ownOptionImage: null
        });
    }

    render() {
        let totalVotes = 0;

        this.state.options.forEach(el => { totalVotes += el.numberOfVotes ? +el.numberOfVotes : 0; });

        let ownOption = null;

        ownOption = (
            <Grid item xs={12} key="own" container spacing={1} >
                <Grid item xs={12} key={`opt_own`}
                    container
                    alignItems="center"
                    spacing={2}>
                    <Grid item style={{minWidth: '278px'}}>
                        <Input
                            id="ownOption"
                            type={this.state.ownOption.elementType}
                            value={this.state.ownOption.value}
                            config={this.state.ownOption.config}
                            disabled={this.props.preview}
                            onChange={event => { this.handleOwnOptionChange(event.target.value); }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        );

        let optionList = (
            this.state.options.map((option, index) => {
                const labelId = `checkbox-label-${index}`;
                return (
                    <Grid item xs={12} key={index} container spacing={1}>
                        <Grid item xs={12} key={`opt${index}`}
                            container
                            //onClick={() => { this.handleToggle(option.id, index); }}
                            alignItems="center"
                            spacing={2}
                            style={{ width: "100%" }}>
                            {!(this.props.disableVote) &&
                                <Can perform="votings:vote" yes={() => (
                                    <Grid item >
                                        {this.renderInput(option.id ? option.id : index, index, labelId)}
                                    </Grid>
                                )}
                                />
                            }      
                            <Grid item>
                                {option.title}
                            </Grid>
                            <Grid item>
                                {this.renderImage(option, index)}
                            </Grid>    
                        </Grid>
                        <Grid item xs={12} key={`res${index}`}
                            style={{ marginLeft: "40px" }}>
                            <Typography variant="body1" color="textSecondary">
                                Проголосовало: {option.numberOfVotes ? option.numberOfVotes : 0}
                            </Typography>
                        </Grid>
                </Grid>
                );
            })
        );

        //let addImgBtn = (
        //    <Grid style={{ maxWidth: 'max-content' }}>
        //        <FileUploadValidatable
        //            maxFilesCount={1}
        //            fileType="image"
        //            files={this.state.ownOptionImage ? [this.state.ownOptionImage] : []}
        //            onFileAdd={this.changeImage}
        //            onFileDelete={this.handleImageDelete}
        //            addFileBtnText="Добавить изображение"
        //            accept="image/png,image/jpeg"
        //            maxTotalSizeInMegabytes={this.maxTotalFileSize}
        //        />
        //    </Grid>
        //);

        let selectedAlternativeAnswer = (
            <React.Fragment>
                {(this.props.loadedVotingAlternativeAnswer && this.props.user != null)
                    ? <CheckBoxOutlinedIcon style={{ color: green[500], marginRight: "1.25rem" }} />
                    : <Box ml={5.75} component="span" > </Box>}

                <Grid item >Альтернативный вариант</Grid>
            </React.Fragment>
        );

        return (
            <React.Fragment>
                <Grid container spacing={2} style={{ width: "100%" }}>
                    {(this.props.hasUserVoted)
                        ? <AfterVote user={this.props.user}
                            loadedVotingAnswers={this.props.loadedVotingAnswers}
                            options={this.state.options}
                            renderImage={(option, index) => this.renderImage(option, index)} />
                        : optionList}
                </Grid>


                {(!this.props.hasUserVoted && !this.props.disableVote && this.multipleChoice)
                    ? <Grid container style={{ marginTop: "16px" }}>
                        {ownOption}
                        {/*addImgBtn*/}
                    </Grid>
                    : null
                }

                {!(this.props.yourAnswersCount === 0 || this.props.type === 'Голосование')
                    ? <Grid container style={{ marginTop: "4px" }}>

                        {(this.props.disableVote || this.props.hasUserVoted) && selectedAlternativeAnswer}
                        <Grid item xs={12} style={{ marginLeft: "35px" }}>
                            {(this.props.disableVote || this.props.hasUserVoted) &&
                                <Typography variant="body1" color="textSecondary" style={{ marginTop: "5px" }}>
                                    Проголосовало: {this.props.yourAnswersCount}
                                </Typography>}
                        </Grid>
                     </Grid>

                    : null
                }

                <Divider style={{ margin: "16px 0px" }} />

                <Box fontWeight="fontWeightBold">
                    Всего проголосовало: <Box component="span">{this.props.preview ? totalVotes : this.props.totalNumberOfVoted}</Box>
                </Box>

                {(!(this.props.disableVote) && !(this.props.hasUserVoted) ) &&
                    <Can perform="votings:vote" yes={() => (
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={!this.state.canSubmit || this.props.preview}
                            onClick={this.handleSubmit}
                            style={{ marginTop: "20px" }}
                        >
                            Проголосовать
                        </Button>
                    )}
                    />
                } 

            </React.Fragment>
        );
    }

};

export default VotingWithMultipleChoices;