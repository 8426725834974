import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import { Grid, FormControl, Select, MenuItem } from '@material-ui/core';

class FavoritesFilter extends Component {
    constructor(props) {
        super(props);
    }

    handleObjectTypeChange = (value) => {
        switch (value) {
            case "all": 
                this.props.onFilterChange(true, true);
                break;
            case "messages":
                this.props.onFilterChange(true, false);
                break;
            case "votings":
                this.props.onFilterChange(false, true);
                break;
            default:
                break;
        }
    }

    render() {
        return (

            <Grid container justify="flex-end" spacing={2}>
                <Grid item>
                    <FormControl
                        fullWidth
                    >
                        <Select
                            id="objectType"
                            value={this.props.objectType}
                            onChange={event => this.handleObjectTypeChange(event.target.value)}
                            MenuProps={{
                                disableScrollLock: true,
                            }}
                        >
                            <MenuItem key="0" value="all">Сообщения и голосования</MenuItem>
                            <MenuItem key="1" value="messages">Сообщения</MenuItem>
                            <MenuItem key="2" value="votings">Голосования</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                
            </Grid >
        );
    }
}

const mapStateToProps = state => {
    return {
        objectType: state.my.showFavoriteMessages && state.my.showFavoriteVotings ? "all"
            : state.my.showFavoriteMessages ? "messages" : "votings"

    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFilterChange: (showMessages, showVotings) => dispatch(actions.changeFavoritesFilter(showMessages, showVotings)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FavoritesFilter);
