import React, { Component } from 'react';
import Preloader from '../Preloader';
import NewsDetails from '../../components/News/NewsDetails';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';

class NewsDetailsContainer extends Component {

    componentDidMount() {
        if (this.props.id) {
            this.props.onFetchNewsDetails(this.props.id);
        }
        this.props.onFetchWorkProfile();
    }

    render() {
        let newsDetails = (
            <Preloader open fullScreen color="primary"/>
        );
        if (!this.props.loading && this.props.newsDetails) {
            let showEditBtn = (this.props.userRole == 'admin' || (this.props.userRole == 'moderator' && this.props.userTerritory == this.props.newsDetails.municipalSettlementId))
            newsDetails = (
                <NewsDetails {...this.props.newsDetails}
                    showEditBtn={showEditBtn}
                    onDownloadDocument={(docId, docTitle) => this.props.onDownloadDocument(this.props.id, docId, docTitle)}
                />
                
            );
        }
        return (
            <Grid container justify="center">
                <Grid item xs={12} sm={8}>
                    {newsDetails}
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => {
    return {
        //loadedNews: state.cmn.loadedNews,
        newsDetails: state.news.newsDetails ? {
            ...state.news.newsDetails
        } : null,
        loading: state.news.loading,
        //loading: state.cmn.loading
        userRole: state.user.isAuthenticated
            ? state.user.role 
            : null,
        userTerritory: (state.profile.territories.length > 0)
            ? state.profile.territories[0].id
            : null,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchNewsDetails: (id) => dispatch(actions.fetchNewsDetails(id)),
        onDownloadDocument: (id, documentId, docTitle) => dispatch(actions.fetchNewsDocument(id, documentId, docTitle)),
        onFetchWorkProfile: () => dispatch(actions.fetchUserWorkProfile())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsDetailsContainer);