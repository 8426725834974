import React, { useState } from 'react';
import './FullMessage.css';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardHeader, CardContent, Button, Typography, Grid, Box } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import StatusBadge from '../MessageStatus';
import { Link, withRouter } from 'react-router-dom';
import MessageResponse from '../MessageResponse';
import RejectionReason from '../../UI/RejectionReason';
import ImageGrid from '../../UI/ImageGrid';
import Property from '../../UI/Property';
import IconButtonFavorite from '../../UI/IconButtonFavorite';
import Map from './Map';
import PersonBadge from '../../UI/Badges/PersonBadge';
import MessageDocuments from '../../../containers/Messages/MessageDocuments';

const useStyles = makeStyles((theme) => ({

    title: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.3rem',
        },
    },
    attachedImgTitle: {
        paddingBottom: '1rem'
    }
}));

const FullMessage = (props) => {
    const [map, setMap] = useState(null);
    const classes = useStyles();
    console.log("response: ", props.response);
    let response = null;
    if (props.response) {
        response = (<MessageResponse
            {...props.response}
            assessment={props.responseAssessment}
            canAssess={props.canAssessResponse}
            onAssess={props.onAssessResponse}
            rejectedMessageStatus={props.status === 'Отклонено'} />);
    }

    const hasImages = props.images.length !== 0;
    const hasDocuments = props.documents.length !== 0;

    let images = !hasImages ? null : (
        <Grid item xs={12}>
            <Typography variant="h6" component="h6" className={classes.attachedImgTitle}>
                Прикрепленные изображения
            </Typography>
            <ImageGrid images={props.images} />
        </Grid>
    );
    let documents = !hasDocuments ? null : (
        <Grid item xs={12}>
            <Typography variant="h6" component="h6">
                Прикрепленные документы
            </Typography>
            <MessageDocuments documents={props.documents} />
        </Grid>
    );
    const imagesAndDocuments = (
        <Grid item xs={12}>
            <Grid container>
                {images}
                {documents}
            </Grid>
        </Grid>
    );
    const rejectionReason = props.rejectionReason ? (
        <RejectionReason
            textPrimary="Сообщение не прошло модерацию:"
            textSecondary={props.rejectionReason}
            canCorrect={props.editable}
            linkToCorrectionPageText="Исправить сообщение"
            linkToCorrectionPage={`/messages/${props.id}/edit`}
        />
    ) : null;
    
    return (
        <React.Fragment>
            {rejectionReason}
            <Card id="FullMessage" className={classes.root}>
                <CardHeader
                    title={props.messageType}
                    subheader={props.number ? `${props.number} ${props.creationDate}` : `без номера ${props.creationDate}`}
                    action={<Box>
                                <StatusBadge status={props.status} />
                                <IconButtonFavorite isFavorite={props.isFavorite} filled={props.isFavorite} onClick={props.toggleIsFavoriteStatus} />
                            </Box>}
                    classes={{ title: classes.title }}
                />
                <CardContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={7}>
                                    <Grid container direction="column" alignItems="flex-start" spacing={1}>
                                        <Grid item xs={12}>
                                            <Property label="Категория" value={props.category} />
                                        </Grid>
                                        <Grid item xs={12} style={{marginTop: "10px"}}>
                                            <Typography variant="body1" component="p">
                                                {props.text}
                                            </Typography>
                                        </Grid>

                                        {imagesAndDocuments}
                                        {response}

                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={5} container direction="column">
                                    <Grid item>
                                        <Map geometry={props.geometry} coordinates={props.coordinates} />
                                    </Grid>
                                    <Grid item style={{textAlign: "center"}}>
                                        <Link to={"/messages/map?msg=" + props.gisGuid}>
                                            <Button color="primary">
                                                <LocationOnIcon fontSize="small" />
                                                показать на интерактивной карте
                                            </Button>
                                        </Link>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <PersonBadge
                                        name={props.author}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card >
        </React.Fragment>
    );

};

export default withRouter(FullMessage);