import * as actionTypes from './actionTypes';
import messageApi from '../api/messageApi';
import { addSuccessNotification, addErrorNotification } from './notify';

export const fetchUserWorkProfileSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_WORK_PROFILE_SUCCESS,
        payload: payload
    };
};

export const fetchUserWorkProfileFail = (error) => {
    return {
        type: actionTypes.FETCH_WORK_PROFILE_FAIL,
        error: error
    };
};

export const fetchUserWorkProfileStart = () => {
    return {
        type: actionTypes.FETCH_WORK_PROFILE_START
    };
};

export const fetchUserWorkProfile = (user) => {
    return (dispatch, getState) => {
        dispatch(fetchUserWorkProfileStart());
        const state = getState();
        messageApi.getWorkProfile(user ? user.id : state.user.id)
            .then(res => {
                dispatch(fetchUserWorkProfileSuccess(res.data));
            })
            .catch(err => {
                dispatch(fetchUserWorkProfileFail(err));
            });
    };
};

export const setUserWorkProfileSuccess = () => {
    return {
        type: actionTypes.SET_WORK_PROFILE_SUCCESS
    };
};

export const setUserWorkProfileFail = (error) => {
    return {
        type: actionTypes.SET_WORK_PROFILE_FAIL,
        error: error
    };
};

export const setUserWorkProfile = (profile) => {
    return (dispatch, getState) => {
        const state = getState();
        messageApi.setWorkProfile(state.user.id, profile)
            .then(res => {
                dispatch(setUserWorkProfileSuccess());
                dispatch(addSuccessNotification("Успешно"));
                dispatch(fetchUserWorkProfile());
            })
            .catch(err => {
                dispatch(setUserWorkProfileFail(err));
                dispatch(addErrorNotification());
            });
    };
};

