import React, {Component} from 'react';
import OrganizationTable from '../containers/Organizations/OrganizationTable';
import { Grid, Button, Typography } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import Can from '../components/Auth/Can';
import { connect } from 'react-redux';
import MyOrganizationFilter from '../containers/Organizations/OrganizationFilter';
import OrganizationFilterToggler from '../containers/Organizations/OrganizationFilterToggler';
import * as actions from '../store/actions';

class organizationsPage extends Component{ 

    render () {
        return (
            <Grid container className="Organizations" spacing={0} direction="column" justify="center">
                
                <Grid item xs={12} sm container justify="space-between">
                    <Grid item xs={12} sm>
                        <Typography variant="h6" component="span">
                            Справочник организаций
                        </Typography>

                        {
                        (this.props.role === "admin") &&
                            <Button 
                                color="primary" 
                                variant="contained" 
                                style={{marginLeft: "24px"}}
                                onClick={() => this.props.sendAllMessages()}
                            >
                                Отправить в ПОС
                            </Button>
                        }
    
                    </Grid>
                    <Can perform="organizations:create" yes={() => (
                        <Grid item key="add" xs={12} sm={3}>
                            <Link to={{
                                pathname: this.props.match.url + "/new"
                            }}>
                                <Button color="primary" variant="contained">
                                    Добавить организацию
                                </Button>
                            </Link>
                        </Grid>
                    )}/>
    
                    <Grid item key="filter" xs={12} sm>
                        <MyOrganizationFilter />
                    </Grid>
                    <Grid item key="toggler" xs={12} sm={1} style={{ textAlign: "right" }}>
                        <OrganizationFilterToggler />
                    </Grid>
                </Grid>
    
                <Grid item xs={12} style={{ marginTop: "20px" }}>
                    <OrganizationTable />
                </Grid>
            </Grid>
        );
    }
};



const mapStateToProps = state => {
    return {
        role: state.user.role
    }
};

const mapDispatchToProps = dispatch => {
    return {
        sendAllMessages: () => dispatch(actions.sendAllMessages()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(organizationsPage));