import React from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import Property from '../../../components/UI/Property';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    root: {
        padding: "20px"
    }
});

const Performer = (props) => {
    const classes = useStyles();
    return (
            <Grid container justify="center" spacing={2}>
                <Grid item xs={12} container spacing={2}>
                    <Grid item xs={12}>
                        <Property label="Организация" value={props.organization} />
                    </Grid>
                    <Grid item xs={12}>
                        <Property label="ФИО" value={props.name} />
                    </Grid>
                    <Grid item xs={12}>
                        <Property label="Должность" value={props.position} />
                    </Grid>
                    <Grid item xs={12}>
                        <Property label="Телефон" value={props.phoneNumber} />
                    </Grid>
                    <Grid item xs={12}>
                        <Property label="Электронная почта" value={props.email} />
                    </Grid>

                </Grid>
                
            </Grid>
        
    );
}

export default Performer;