import React from 'react';
import { Grid, Typography, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import LoginButton from '../../containers/Account/LoginButton';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: "20px"
    },
    text: {
        marginTop: "20px"
    },
    link: {
        fontWeight: "bold",
        cursor: "pointer",
        marginTop: "10px",
    }
}));

const Page401 = (props) => {
    let classes = useStyles();
    const handleLogin = () => {
        window.location.href = process.env.REACT_APP_ACCOUNT_URL + "/login?returnUrl=" + window.location.origin;
    };
    return (

        <Grid item xs container direction="column" justify="center" alignItems="center" spacing={3} className="root">
            <Grid item style={{ textAlign: "center" }}>
                <Typography variant="h3" color="primary" className={classes.text}>
                    401
                </Typography>
                <Typography variant="h3" color="primary" className={classes.text}>
                    Доступ запрещен
                </Typography>
                <Typography variant="body1" color="primary" className={classes.text}>
                    Извините, но для выполнения этого действия Вам необходимо авторизоваться.
                    <br />
                    Вы можете авторизоваться или зарегистрироваться перейдя по данной ссылке
                    <br />
                    <Button className={classes.link} onClick={() => { handleLogin(); }} color="primary" variant="contained" style={{ width: 'max-content' }}>
                        Войти или зарегистрация
                    </Button>
                </Typography>
            </Grid>

        </Grid>

    );
};

export default withRouter(Page401);