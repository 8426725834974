import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';
const initialState = {
    isAuthenticated: false,
    name: null,
    id: null,
    role: null,
    needsLogin: false
};

const fetchUserSuccess = (state, action) => {
    return updateObject(state, {
        isAuthenticated: true,
        name: action.user.name,
        role: action.user.role,
        id: action.user.sub,
        locale: action.user.locale,
        needsLogin: false
    });
};

const needsLogin = (state, action) => {
    return updateObject(state, {
        ...initialState,
        needsLogin: true
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_USER_SUCCESS: return fetchUserSuccess(state, action);
        case 'NEEDS_LOGIN': return needsLogin(state, action);
        default: return state;
    }
};

export default reducer;