import React from 'react';
import MyMessages from '../../containers/PersonalCabinet/MyMessages/MyMessages';
import MyMessagesFilter from '../../containers/PersonalCabinet/MyMessages/MyMessagesFilter';
import MyMessagesFilterToggler from '../../containers/PersonalCabinet/MyMessages/MyMessagesFilterToggler';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Link, withRouter } from 'react-router-dom';
import { Typography } from '@material-ui/core';

const MyMessagesPage = (props) => {

    return (
        <Grid container className="MyMessages" spacing={2} direction="column" justify="center">
            <Grid item xs={12}>
                <Typography variant="h5" component="h5">
                    Личный кабинет: Мои сообщения
                </Typography>
            </Grid>
            <Grid item key="actions" xs={12} container justify="space-between">
                <Grid item key="add" xs={12} sm={2}>
                    <Link to={{
                        pathname: "/messages/new"
                    }}>
                        <Button color="primary" variant="contained">
                            Добавить сообщение
                    </Button>
                    </Link>
                </Grid>
                <Grid item key="filter" xs={12} sm={8}>
                    <MyMessagesFilter />
                </Grid>
                <Grid item key="toggler" xs={12} sm={2} style={{ textAlign: "right" }}>
                    <MyMessagesFilterToggler />
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "20px" }}>
                <MyMessages />
            </Grid>

        </Grid>
    );
};

export default withRouter(MyMessagesPage);