import React from "react";
import { GridList, GridListTile } from '@material-ui/core';
import ImageModal from './Modals/ImageModal';
import { withRouter } from 'react-router-dom';

const image = (props) => {

    let handleImageClick = () => {
        const url = require('url');
        props.history.push({
            pathname: url.parse(window.location.href).pathname,
            search: "?image=" + props.id
        });
    };
    let handleModalClose = () => {
        const url = require('url');
        props.history.replace({
            pathname: url.parse(window.location.href).pathname,
            search: null
        });
    };

    let modal = null;

    const queryString = require('query-string');
    const query = queryString.parse(props.location.search);
    if (props.id && query && query.image && query.image == props.id) {
        modal = <ImageModal open image={`data:image/jpg;base64,${props.value}`} onClose={() => handleModalClose()} />;
    }
    console.log(props);
    return (

        <React.Fragment>
            <img
                className="image-clickable"
                src={props.url ? props.url : `data:image/jpg;base64,${props.value}`}
                alt="изображение"
                onClick={() => handleImageClick()}
                style={props.style}
            />
            {modal}
        </React.Fragment>

    );
};

export default withRouter(image);