import React, { Component } from 'react';
import { Grid, Button, Avatar } from '@material-ui/core';
import FileUploadValidatable from '../../UI/FileUpload/FileUploadValidatable';
import TextArea from '../../UI/Inputs/TextArea';

class NewOption extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: "",
            image: null
        };
    }

    componentDidMount() {
    }

    changeImage = (newImage) => {
        this.setState({
            image: newImage
        });
    }

    changeText = (text) => {
        this.setState({
            text: text
        });
    }

    handleAddBtnClick = () => {
        if (this.state.text.length > 0) {
            this.props.onAdd({
                text: this.state.text,
                image: this.state.image
            });
        } else {
            this.props.onError("Поле \"Текст\" не может быть пустым");
        }
    }

    handleImageDelete = () => {
        this.setState({
            image: null
        });
    }

    render() {
        return (
            <Grid container justify="space-between" alignItems="center" spacing={1}>
                
                <Grid item xs={12} sm>
                    <Avatar>{this.props.number}</Avatar>
                    <TextArea
                        autoFocus
                        id="text"
                        label="Текст"
                        value={this.state.text}
                        required
                        rows={2}
                        placeholder="Текст"
                        onChange={(event) => this.changeText(event.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm>
                    <FileUploadValidatable
                        maxFilesCount={1}
                        fileType="image"
                        files={this.state.image ? [this.state.image] : []}
                        onFileAdd={this.changeImage}
                        onFileDelete={this.handleImageDelete}
                        addFileBtnText="Добавить изображение"
                        accept="image/png,image/jpeg"
                        maxTotalSizeInMegabytes={this.maxTotalFileSize}
                    />
                </Grid>
                <Grid item style={{ textAlign: "right", maxWidth: 'none' }} >
                    <Button color="primary" variant="contained" onClick={() => this.handleAddBtnClick()}>
                        добавить
                    </Button>

                </Grid>
                <Grid item style={{ textAlign: "right", maxWidth: 'none' }}>
                    <Button variant="contained" onClick={() => this.props.onCancel()}>
                        отмена
                    </Button>

                </Grid>
            </Grid>
        );
    }
}

export default NewOption;