import React from 'react';
import Page404 from './Page404';
import Page403 from './Page403';
import { connect } from 'react-redux';
import Page500 from './Page500';

const ErrorHandler = (props) => {


    const renderContent = () => {
        if (!props.showErrorPage) {
            return props.children;
        }
        switch (props.errorStatusCode) {
            case 404:
                return <Page404 />;
            case 403:
                return <Page403 />;
            case 500:
                return <Page500 />;
            default:
                return props.children;
        }
    };


    return renderContent();
      
};

const mapStateToProps = (state) => {
    return {
        showErrorPage: state.notify.showErrorPage,
        errorStatusCode: state.notify.errorCode
    };
};

const mapDispatchToProps = dispatch => {
    return {
        
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ErrorHandler);