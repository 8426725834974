import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    loadedOrganization: null,
    loading: false,
    organizations: [],
    filter: {
        scopeId: '',
        municipalSettlementId: ''
    },
    skip: 0,
    take: 9,
    page: 0,
    created: false,
    organizationsPerPage: 10,
    organizationsCount: 0,
    scopes: [],
    toggleDisabled: false,
    orgId: null,
    showFilter: false,
};

const changeOrganizationsPageStart = (state, action) => {
    return updateObject(state, { page: action.page, loading: true });
};

const changeOrganizationsPageSuccess = (state, action) => {
    return updateObject(state, { organizations: action.organizations, loading: false });
};

const changeOrganizationsPageFail = (state, action) => {
    return updateObject(state, { loading: false });
};

const setOrganizationFilterValueReducer = (state, action) => {
    var newState = { ...state };
    var filter = { ...state.filter };
    filter[action.filter] = action.value;
    newState.organizations = [];
    newState.loading = true;
    newState.filter = filter;
    return newState;
};

const toggleOrganizationFilter = (state, action) => {
    return updateObject(state, { showFilter: !state.showFilter });
};

const initPrivateFilter = (state, action) => {
    return updateObject(state, {
        filter: {
            statusId: '',
            createDate: {
                begin: '',
                end: ''
            }
        }
    });
};

const initPublicFilter = (state, action) => {
    return updateObject(state, {
        filter: {
            categoryId: '',
            municipalSettlementId: '',
        }
    });
};

const fetchOrganizationsCount = (state, action) => {
    return updateObject(state, {
        organizationsCount: action.count
    });
};

const toggleDisabled = (state, action) => {
    return updateObject(state, {
        toggleDisabled: action.disabled
    })
}

const fetchScopes = (state, action) => {
    return updateObject(state, {
        scopes: action.scopes.map(scp => { return { id: scp.id, title: scp.name };})
    });
};

const postOrganizationSuccess = (state, action) => {
    return updateObject(state, { created: true, orgId: action.id });
};

const deleteOrganizationSuccess = (state, action) => {
    return updateObject(state)
}

const fetchOrganizationInfo = (state, action) => {
    return updateObject(state, {organizationInfo: action.organizationInfo});
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CHANGE_ORGANIZATIONS_PAGE_START: return changeOrganizationsPageStart(state, action);
        case actionTypes.CHANGE_ORGANIZATIONS_PAGE_SUCCESS: return changeOrganizationsPageSuccess(state, action);
        case actionTypes.CHANGE_ORGANIZATIONS_PAGE_FAIL: return changeOrganizationsPageFail(state, action);
        case actionTypes.SET_ORGANIZATION_FILTER_VALUE: return setOrganizationFilterValueReducer(state, action);
        case actionTypes.TOGGLE_ORGANIZATION_FILTER: return toggleOrganizationFilter(state, action);
        case actionTypes.INIT_ORGANIZATION_PRIVATE_FILTER: return initPrivateFilter(state, action);
        case actionTypes.INIT_ORGANIZATION_PUBLIC_FILTER: return initPublicFilter(state, action);
        case actionTypes.FETCH_ORGANIZATIONS_COUNT: return fetchOrganizationsCount(state, action);
        case actionTypes.FETCH_SCOPES_SUCCESS: return fetchScopes(state, action);
        case actionTypes.POST_ORGANIZATION_SUCCESS: return postOrganizationSuccess(state, action);
        case actionTypes.DELETE_ORGANIZATION_SUCCESS: return deleteOrganizationSuccess(state, action);
        case actionTypes.TOGGLE_DISABLED: return toggleDisabled(state, action)

        case actionTypes.FETCH_ORGANIZATION_INFO: return fetchOrganizationInfo(state, action);
        
        default: return state;
    }
};

export default reducer;