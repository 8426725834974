import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {},
    textIndent: {textIndent: '24px'},
    fontBold: { fontWeight: 'bold' },
    link: { color: 'primary', fontWeight: '600' },
    highlighted: { fontSize: '24px'}
});

const AboutPage = (props) => {
    const classes = useStyles();
    return (
        <Grid container justify="center" alignItems="center">
            <Grid item xs={12} sm={8} style={{ marginTop: "20px" }}>
                <Typography variant="body" component="article">
                    <Typography variant="body1" component="p" className={classes.textIndent} style={{ marginBottom: "16px" }}>
                        <Box component="span" className={classes.highlighted}>«Активный горожанин»</Box> -  это цифровая платформа вовлечения жителей Ростовской области в решение вопросов городского развития. 
                    </Typography>
                    <Typography variant="body1" component="p" className={classes.fontBold}>
                        Основные цели создания цифровой платформы:
                    </Typography>
                    <Typography component="p">
                        <ul>
                            <li>Выстраивание конструктивного диалога между жителями и властью</li>
                            <li>Улучшение качества жизни граждан и качества управления городом через активное вовлечение жителей </li>
                            <li>Создание механизма оценки деятельности органов местного самоуправления города</li>
                            <li>Сокращение времени до получения обратной связи</li>
                        </ul>
                    </Typography>
                    <Typography variant="body1" component="p" className={classes.fontBold}>
                        Основные задачи цифровой платформы:
                    </Typography>
                    <Typography component="p">
                        <ul>
                            <li>Обеспечить жителям возможность публично сообщить властям о городских проблемах</li>
                            <li>Помочь органам исполнительной власти города своевременно обнаружить, устранить и предупредить возникновение проблем в городе</li>
                            <li>Увеличить скорость реакции и повысить прозрачность принимаемых решений</li>
                            <li>Усовершенствовать деятельность городских служб</li>
                            <li>Вовлечь жителей в процесс совместного управления городом (подача идей, планирование, принятие решений, контроль за исполнением)</li>
                            <li>Обеспечить полноправное эффективное партнерство власти и жителей города</li>
                        </ul>
                    </Typography>
                    <Typography variant="body1" component="p" className={classes.fontBold}>
                        С помощью web-портала или мобильного приложения
                        (<a className={classes.link} href="https://play.google.com/store/apps/details?id=activecitizen.ibzkh.ru">Android</a>,
                        <a className={classes.link} href="https://apps.apple.com/ru/app/%D0%B0%D0%BA%D1%82%D0%B8%D0%B2%D0%BD%D1%8B%D0%B9-%D0%B3%D0%BE%D1%80%D0%BE%D0%B6%D0%B0%D0%BD%D0%B8%D0%BD-%D1%80%D0%BE/id1551144887">IOS</a>) 
                        цифровой платформы, любой житель после короткой регистрации сможет:
                    </Typography>
                    <Typography component="p">
                        <ul>
                            <li>Отправлять сообщения в адрес городских властей и получать ответы на них в короткие сроки</li>
                            <li>Оценивать качество ответов</li>
                            <li>Участвовать в голосованиях и опросах, размещенных городскими властями</li>
                            <li>Выдвигать собственные инициативы</li>
                        </ul>
                    </Typography>
                    <Typography component="p" style={{ marginBottom: "16px" }}>
                        <a href="Общие_характеристики_платформы.pdf" download className={classes.link}>Общие характеристики платформы</a>
                    </Typography>
                    <Typography component="p" className={classes.textIndent} style={{ marginBottom: "16px" }}>
                        Для начала работы с Порталом, жителям необходимо пройти регистрацию и обязательно ознакомиться с:
                    </Typography>

                    <ul>
                        <li><a href="Регламент_приема_и_обработки_сообщений.pdf" download className={classes.link}>Регламентом приема и обработки сообщений</a></li>
                        <li><a href="Инструкция_пользователя.pdf" download className={classes.link}>Инструкцией пользователя</a></li>
                    </ul>

                </Typography>
            </Grid>

        </Grid>
    );
};

export default withRouter(AboutPage);