import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DatePicker from './DatePicker';

const onChange = (beginOrEnd, value) => {
    
};

const dateRange = (props) => {
    var hasError = Boolean(props.error);

    const onChange = (beginOrEnd, value) => {
        console.log("value: ", value);
        let newValue = { ...props.value };
        newValue[beginOrEnd] = value;
        props.onChange(props.id, newValue);
    };

    return (
        <Grid container spacing={2} style={{/*display: "inline-flex"*/ }}>
            <Grid item>
                <Typography variant="body1" component="span">
                    {props.label}
                </Typography>
            </Grid>
            <Grid item xs>
                <DatePicker
                    id={`${props.id}.begin`}
                    label="C"
                    size={props.size}
                    value={props.value.begin}
                    validation={props.validation}
                    error={props.error}
                    style={props.style}
                    onChange={(event) => onChange("begin", event.target.value)}
                    onBlur={props.onBlur}
                    />
            </Grid>
            <Grid item xs>
                <DatePicker
                    id={`${props.id}.begin`}
                    label="По"
                    size={props.size}
                    value={props.value.end}
                    validation={props.validation}
                    error={props.error}
                    style={props.style}
                    onChange={(event) => onChange("end", event.target.value)}
                    onBlur={props.onBlur}
                    />
            </Grid>
        </Grid>
        
    );

};

export default dateRange;