import React from 'react';
import { Grid, Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: "20px"
    },
    text: {
        marginTop: "20px"
    },
    link: {
        fontWeight: "bold"
    }
}));

const Page500 = () => {
    let classes = useStyles();
    return (

        <Grid item xs container direction="column" justify="center" alignItems="center" spacing={3} className="root">
            <Grid item style={{ textAlign: "center" }}>
                <Typography variant="h3" color="primary" className={classes.text}>
                    500
                </Typography>
                <Typography variant="body1" color="primary" className={classes.text}>
                    Кажется, что-то пошло не так.
                    <br />
                    Попробуйте обновить страницу. Если ошибка повторится, свяжитесь с администратором системы.
                </Typography>
            </Grid>

        </Grid>

    );
};

export default Page500;