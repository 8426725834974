import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import CurrentTerritoryComponent from '../../components/PersonalCabinet/CurrentTerritory';

class CurrentTerritory extends Component {
    componentDidMount() {
        this.props.onFetchMunicipalSettlements();
        if (this.props.user.isAuthenticated) {
            this.props.onGetSelectedTerritory(this.props.user);
        }
        
    }

    componentDidUpdate(prevProps) {
        if (this.props.user.isAuthenticated && !prevProps.user.isAuthenticated) {
            this.props.onGetSelectedTerritory(this.props.user);
        }
    }


    render() {
        return (
            <CurrentTerritoryComponent
                show
                selectedTerritory={this.props.currentTerritory}
                territories={this.props.territories}
                onChange={(territory) => this.props.onSetCurrentTerritory(territory)}
            />
        );
    }

};

const mapStateToProps = state => {
    return {
        territories: state.cmn.municipalSettlements,
        currentTerritory: state.my.territory,
        user: state.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchMunicipalSettlements: () => dispatch(actions.fetchMunicipalSettlements()),
        onSetCurrentTerritory: (territory) => dispatch(actions.setSelectedTerritory(territory)),
        onGetSelectedTerritory: (user) => dispatch(actions.getSelectedTerritory(user))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentTerritory);