import * as actionTypes from './actionTypes';
import { addSuccessNotification, addErrorNotification } from './notify';
import commonApi from '../api/commonApi';

export const fetchNewsStart = (skip, take) => {
    return {
        type: actionTypes.FETCH_NEWS_START
    };
};

export const fetchNewsSuccess = (news, skip) => {
    return {
        type: actionTypes.FETCH_NEWS_SUCCESS,
        news: news,
        skip: skip
    };
};

export const fetchNewsFail = (error) => {
    return {
        type: actionTypes.FETCH_NEWS_FAIL,
        error: error
    };
};



export const resetNews = () => {
    return {
        type: actionTypes.RESET_NEWS
    };
};

export const fetchTotalFilteredNewsCountSuccess = (count) => {
    return {
        type: actionTypes.FETCH_TOTAL_FILTERED_NEWS_COUNT_SUCCES,
        count,
    }
}

export const changeMaxPossibleNewsCount = () => {
    return {
        type: actionTypes.CHANGE_TOTAL_NEWS_COUNT,
    }
} 

export const fetchNews = (skip, take, territory) => {
    return (dispatch, getState) => {
        dispatch(fetchNewsStart());
        if (skip === 0) {
            dispatch(resetNews());
        }
        commonApi.getNewsWithCount(skip, take, territory)
            .then(res => {
                dispatch(fetchNewsSuccess(res.data.collection, skip));
                dispatch(fetchTotalFilteredNewsCountSuccess(res.data.count))
            })
            .catch(err => {
                dispatch(fetchNewsFail(err));
            });
    };
};

export const fetchNewsDetailsStart = (id) => {
    return {
        type: actionTypes.FETCH_NEWS_DETAILS_START
    };
};

export const fetchNewsDetailsSuccess = (newsDetails) => {
    console.log(newsDetails);
    return {
        type: actionTypes.FETCH_NEWS_DETAILS_SUCCESS,
        payload: newsDetails
    };
};

export const fetchNewsDetailsFail = (error) => {
    return {
        type: actionTypes.FETCH_NEWS_DETAILS_FAIL,
        error: error
    };
};

export const fetchNewsDetails = (id) => {
    return (dispatch) => {
        dispatch(fetchNewsDetailsStart());
        commonApi.getNewsDetails(id)
            .then(res => {
                dispatch(fetchNewsDetailsSuccess(res.data));
            })
            .catch(err => {
                dispatch(fetchNewsDetailsFail(err));
            });
    };
};

export const postNewsStart = () => {
    return {
        type: actionTypes.POST_NEWS_START
    };
};

export const postNewsSuccess = () => {
    return {
        type: actionTypes.POST_NEWS_SUCCESS
    };
};

export const postNewsFail = (error) => {
    return {
        type: actionTypes.POST_NEWS_FAIL,
        error: error
    };
};

export const postNews = (data) => {
    return (dispatch) => {
        dispatch(postNewsStart());
        commonApi.postNews(data)
            .then(res => {
                dispatch(postNewsSuccess());
                dispatch(addSuccessNotification("Успешно"));
            })
            .catch(err => {
                dispatch(postNewsFail(err));
                dispatch(addErrorNotification());
            });
    };
};

export const fetchNewsDocumentSuccess = (data, title) => {
    const downloadUrl = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', title); //any other extension
    document.body.appendChild(link);
    link.click();
    link.remove();
    return {
        type: actionTypes.FETCH_NEWS_DOCUMENT_SUCCESS
    };
};

export const fetchNewsDocumentFail = (error) => {
    return {
        type: actionTypes.FETCH_NEWS_DOCUMENT_FAIL,
        error: error
    };
};

export const fetchNewsDocumentStart = () => {
    return {
        type: actionTypes.FETCH_NEWS_DOCUMENT_START
    };
};

export const fetchNewsDocument = (id, docId, docTitle) => {
    return dispatch => {
        dispatch(fetchNewsDocumentStart());
        commonApi.getDocument(id, docId)
            .then(res => {
                dispatch(fetchNewsDocumentSuccess(res.data, docTitle));
            })
            .catch(err => {
                dispatch(fetchNewsDocumentFail(err));
            });
    };
};