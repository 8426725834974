import React from 'react';
import { TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Box, IconButton, Typography, Grid } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import Can from '../../components/Auth/Can';

import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import Done from '@material-ui/icons/Done';
import Close from '@material-ui/icons/Close';

import { makeStyles } from '@material-ui/core/styles';

export default function OrganizationTable(props) {
    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
        },
        table: {
            minWidth: 750,
        },
        row: {
            '&:hover': {
                cursor: "pointer"
            }
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        boldText: {
            fontWeight: 'bold'
        },
        btnContainer: {
            display: 'flex'
        },
        successIcon: {
            color: '#4caf50'
        },
        popoverContainer: {
            padding: theme.spacing(2)
        },
        popoverTxt: {
            textAlign: "center"
        }
    }));

    const classes = useStyles();

    const headCells = [
        { id: 'name', disablePadding: false, label: 'Наименование организации' },
        { id: 'address', disablePadding: false, label: 'Адрес' },
        { id: 'contacts', disablePadding: false, label: 'Контакты' }
    ];

    const items =
        props.organizations.map(org => {
            return {
                id: org.id,
                name: org.name,
                address: org.address ? org.address : "не указан",
                contacts: org.contacts ? org.contacts : "отсутсвуют"
            };
        });

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [id, setId] = React.useState(null);

    const open = Boolean(anchorEl);
    const handlePopover = (event, id) => {
        stopPropagation(event);
        setAnchorEl(event.currentTarget);
        setId(id);
    };
    const closePopover = (event) => {
        stopPropagation(event);
        setAnchorEl(null);
    };
    const stopPropagation = (event) => {
        event.stopPropagation();
    }
    const handleEditOrganization = (event, id) => {
        stopPropagation(event);
        props.onEditOrganization(id)
    }

    return (
        <TableContainer>
            <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size='medium'
            >
                <TableHead>
                    <TableRow>
                        {headCells.map((headCell) => (
                            <TableCell className={classes.boldText}
                                key={headCell.id}
                                align='left'
                                padding={headCell.disablePadding ? 'none' : 'default'}
                            >
                                {headCell.label}
                            </TableCell>
                        ))}
                        <Can perform="organizations:edit" yes={() => (
                                <TableCell></TableCell>
                            )}
                        />
                        
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        items.map((item) => {
                            return (
                                <TableRow
                                    hover
                                    className={`${classes.row} ${item.className}`}
                                    tabIndex={-1}
                                    key={item[props.itemIdProp]}
                                    onClick={() => props.onOrganizationClicked(item[props.itemIdProp])}
                                >
                                    {
                                        headCells.map((headCell) => {
                                            let entry = item[headCell.id];
                                            return (
                                                <TableCell align="left" key={headCell.id}>{entry}</TableCell>
                                            );
                                        })
                                    }
                                    <Can perform="organizations:edit" yes={() => (
                                        <TableCell align="left">
                                            <Grid className={classes.btnContainer}>
                                                <IconButton onClick={(event) => { handleEditOrganization(event, item.id) }}><Edit /></IconButton>
                                                <IconButton onClick={(event) => { handlePopover(event, item.id) }}> <Delete /></IconButton>
                                                <Popover
                                                    open={open}
                                                    anchorEl={anchorEl}
                                                    onClose={closePopover}
                                                    anchorOrigin={{
                                                        vertical: "bottom",
                                                        horizontal: "center"
                                                    }}
                                                    transformOrigin={{
                                                        vertical: "top",
                                                        horizontal: "center"
                                                    }}>
                                                    <Grid onClick={(event) => { stopPropagation(event) }} className={classes.popoverContainer}>
                                                        <Typography className={classes.popoverTxt}>Уверенны?</Typography>
                                                        <IconButton
                                                            onClick={(event) => { props.onDeleteOrganization(id); closePopover(event) }}
                                                            className={classes.successIcon}
                                                        >
                                                            <Done />
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={(event) => { closePopover(event) }}
                                                            color="secondary"
                                                        >
                                                            <Close />
                                                        </IconButton>
                                                    </Grid>
                                                </Popover>
                                            </Grid>
                                        </TableCell>
                                        )}
                                    />
                                </TableRow>
                            );
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}