import React, { Component } from 'react';
import { Box, Button } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReportParameter from '../pages/ReportParameter'
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';

class ReportContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            expanded: false,
            isValid: false,
            reportParameters: []
        }
    }

    componentDidMount() {
        let copyState = { ...this.state }
        if (this.props.reportId) {
            if (this.props.params.length) {
                this.props.params.forEach(el => {
                    copyState.reportParameters.push({ id: el.id, type: el.type, title: el.title, value: null });
                })
            } else {
                copyState.reportParameters = [];
                copyState.isValid = true;
            }
            this.setState(copyState);
        }
    }

    handleExpand = panel => (event, isExpanded) => {
        let copyState = { ...this.state };
        if (isExpanded) {
            copyState.expanded = panel;
        }
        else {
            copyState.expanded = false;
        }
        this.setState(copyState);
    };

    handleParameterValueChange = (reportValue, paramId) => {
        let copyState = { ...this.state };
        if (this.state.expanded) {
            if (reportValue.length) {
                copyState.reportParameters.forEach(val => {
                    if (val.id == paramId) {
                        val.value = reportValue;
                    }
                })
                this.setState({ copyState }, this.validation(reportValue));
            }
        }
    };

    validation = (reportParameters) => {
        let copyState = { ...this.state };
        if (this.props.reportId) {
            this.state.reportParameters.forEach(el => {
                if (!el.value) {
                    copyState.isValid = false;
                    return
                } else {
                    copyState.isValid = true;
                }
            })
            this.setState(copyState);
        }
    }

    downloadReport = () => { this.props.downloadReport(this.props.reportId, this.state.reportParameters) };

    render() {
        let reportParameter = null;

        if(this.state.expanded) {
            if (this.props.params.length) {
                reportParameter = this.state.reportParameters.map(param => {
                    return (<ReportParameter type={param.type}
                        value={param.value}
                        title={param.title}
                        handleParameterValueChange={(reportValue) => this.handleParameterValueChange(reportValue, param.id)} />)
                })
            }
        }

        return (<Accordion expanded={this.state.expanded === `panel${this.props.reportId}`} onChange={this.handleExpand(`panel${this.props.reportId}`)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="report-card-header"
                id="report-card-header">
                <Typography style={{ padding: '10px 0px' }} >{this.props.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box>
                    {reportParameter}
                    <Box>
                        <Button disabled={!this.state.isValid}
                            variant="contained" color="primary" 
                            onClick={(event) => this.downloadReport(event)}>Выгрузить</Button>
                    </Box >
                </Box>
            </AccordionDetails>
        </Accordion>)
    }
};

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {
        downloadReport: (reportId, reportParameters) => { dispatch(actions.downloadReport(reportId, reportParameters))}
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportContainer);