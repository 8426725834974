import React, { Component } from 'react';
import { Grid, Button, Typography } from '@material-ui/core';
import Preloader from '../../../../containers/Preloader';
import Input from '../../../../components/UI/Inputs/Input';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions';
import FileUploadValidatable from '../../../../components/UI/FileUpload/FileUploadValidatable';
import OutlinedContainer from '../../../../components/UI/Containers/OutlinedContainer';
import { withRouter } from 'react-router-dom';

class NewOrganization extends Component {

    constructor(props) {
        super(props);
        this.maxTextSize = 700;
        this.maxTotalFileSize = 5;
        this.state = {
            municipalSettlements: [],
            types: [],
            form: {
                municipalSettlements: {
                    value: props.isEdit ? props.organizationInfo.municipalSettlements :[],
                    elementType: "select",
                    config: {
                        label: "Территории, к которым относится организация",
                        defaultDisplayValue: "Территории не выбраны",
                        options: [],
                        multiple: true
                    },
                    validation: {
                        required: true
                    },
                    error: "",
                    valid: false
                },
                name: {
                    value: props.isEdit ? props.organizationInfo.name : "",
                    elementType: "textbox",
                    config: {
                        label: "Название"
                    },
                    validation: {
                        required: true
                    },
                    error: "",
                    valid: false
                },
                activityScope: {
                    value: props.isEdit ? props.organizationInfo.scopeOfTheOrganization.id : "",
                    elementType: "select",
                    config: {
                        label: "Сфера деятельности",
                        defaultDisplayValue: "Сфера деятельности не выбрана",
                        options: []
                    },
                    validation: {
                        required: true
                    },
                    error: "",
                    valid: false
                },
                address: {
                    value: props.isEdit ? props.organizationInfo.address : "",
                    elementType: "textbox",
                    config: {
                        label: "Адрес"
                    },
                    validation: {
                        required: true
                    },
                    error: "",
                    valid: false
                },
                contacts: {
                    value: props.isEdit ? props.organizationInfo.contacts : "",
                    elementType: "textarea",
                    config: {
                        label: "Контакты",
                        placeholder: `Контактная информация, режим работы`,
                        rows: 5
                    },
                    validation: {
                        required: true
                    },
                    error: "",
                    valid: false
                },
                description: {
                    value: props.isEdit ? props.organizationInfo.description : "",
                    elementType: "textarea",
                    config: {
                        label: "Описание",
                        placeholder: `Полномочия организации, сфера деятельности, объекты обслуживания (не более ${this.maxTextSize} символов)`,
                        maxLength: this.maxTextSize,
                        rows: 10
                    },
                    validation: {
                        required: true
                    },
                    error: "",
                    valid: false
                },
                logo: props.isEdit
                        ?(this.props.organizationInfo.logo && this.props.organizationInfo.fileName)
                            ? { value: props.organizationInfo.logo, name: props.organizationInfo.fileName }
                            : null
                        : null  
                        
            },
            formIsValid: false,
            lettersLeft: this.maxDescriptionSize,
            type: ""
        };
    }

    componentDidMount() {
        this.props.onFetchMunicipalSettlements();
        this.props.onFetchScopes();
    }

    componentDidUpdate(prevProps) {
        if (this.props.created) {
            this.props.history.push(`/admin/organizations`);
        }
        if (this.props.createdOrdId) {
            this.props.history.push(`/organizations/${this.props.orgId}`);
        }
    }

    handleCancel = () => {
        this.props.history.goBack();
    }

    handleFormElementBlur = (element) => { }

    handleChangeLogo = (newImage) => {
        let updatedForm = { ...this.state.form };
        updatedForm.logo = newImage;
        this.setState({
            form: updatedForm
        });
    }

    handleDeleteLogo = () => {
        this.handleChangeLogo(null);
        this.props.onDeleteOrganizationLogo(this.props.organizationInfo.id)
    }

    handleFieldChange = (value, element) => {
        let updatedForm = { ...this.state.form };
        let updatedElement = { ...this.state.form[element] };
        updatedElement.value = value;
        updatedForm[element] = updatedElement;
        this.setState({ form: updatedForm }, this.validateForm);
    }

    handleError(errorText) {
        this.props.onNotify(errorText);
    }

    validateForm = () => {
        var isValid = true;
        let element = null;
        for (var elementName in this.state.form) {
            element = this.state.form[elementName];
            if (element && element.validation && element.validation.required && !element.value) {
                isValid = false;
                break;
            }
        }
        this.setState({
            formIsValid: isValid
        });
    }

    renderImagesInput() {
        return (
            <Grid container direction="column">
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom style={{ display: "block", textAlign: 'left' }}>
                        Логотип организации
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FileUploadValidatable
                        maxFilesCount={1}
                        fileType="image"
                        files={this.state.form.logo ? [this.state.form.logo] : []}
                        onFileAdd={(file) => this.handleChangeLogo(file)}
                        onFileDelete={() => this.handleDeleteLogo()}
                        addFileBtnText="Добавить изображение"
                        accept="image/png,image/jpeg"
                        maxTotalSizeInMegabytes={this.maxTotalFileSize}
                    />
                </Grid>

            </Grid>
        );
    }

    handleFormSubmit = () => {
        var data = {
            municipalSettlements: this.state.form.municipalSettlements.value,
            name: this.state.form.name.value,
            scope: this.state.form.activityScope.value,
            description: this.state.form.description.value,
            address: this.state.form.address.value,
            contacts: this.state.form.contacts.value,
            logo: this.state.form.logo,
        };

        if (this.props.isEdit) {
            data['id'] = this.props.organizationInfo.id;
            this.props.onUpdate(data)
        } else {
            this.props.onPost(data)
        }
    }

    render() {
        return (
            ((this.props.organizationInfo && this.props.isEdit) || !this.props.isEdit)
                ?  <Grid container justify="center">
                    <Grid item xs={12} sm={8}>
                        <OutlinedContainer>
                            <form >
                                <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
                                    <Grid item xs={12} container justify="space-between" alignItems="center">
                                        <Grid item>
                                            <Typography variant="h5" gutterBottom style={{ textAlign: 'left' }}>
                                                {this.props.isEdit ? 'Изменение организации' : 'Добавление организации'}
                                            </Typography>
                                        </Grid>
                                        {
                                            !this.props.isEdit
                                            ? (<Grid item>
                                                <Button variant="contained" color="secondary" onClick={this.handleCancel}>
                                                    Отмена
                                                </Button>
                                            </Grid>)
                                            : null
                                        }
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Input
                                            id="name"
                                            type={this.state.form.name.elementType}
                                            value={this.state.form.name.value}
                                            config={this.state.form.name.config}
                                            error={this.state.form.name.error}
                                            validation={this.state.form.name.validation}
                                            onChange={event => { this.handleFieldChange(event.target.value, "name"); }}
                                            onBlur={event => { this.handleFormElementBlur(event, "name"); }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Input
                                            id="municipalSettlementId"
                                            type={this.state.form.municipalSettlements.elementType}
                                            value={this.state.form.municipalSettlements.value}
                                            config={this.state.form.municipalSettlements.config}
                                            options={this.props.municipalSettlements}
                                            error={this.state.form.municipalSettlements.error}
                                            validation={this.state.form.municipalSettlements.validation}
                                            onChange={event => { this.handleFieldChange(event.target.value, "municipalSettlements"); }}
                                            onBlur={event => { this.handleFormElementBlur(event, "municipalSettlements"); }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Input
                                            id="scope"
                                            type={this.state.form.activityScope.elementType}
                                            value={this.state.form.activityScope.value}
                                            config={this.state.form.activityScope.config}
                                            options={this.props.scopes}
                                            error={this.state.form.activityScope.error}
                                            validation={this.state.form.activityScope.validation}
                                            onChange={event => { this.handleFieldChange(event.target.value, "activityScope"); }}
                                            onBlur={event => { this.handleFormElementBlur(event, "activityScope"); }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Input
                                            id="text"
                                            type={this.state.form.address.elementType}
                                            value={this.state.form.address.value}
                                            config={this.state.form.address.config}
                                            error={this.state.form.address.error}
                                            validation={this.state.form.address.validation}
                                            onChange={event => { this.handleFieldChange(event.target.value, "address"); }}
                                            onBlur={event => { this.handleFormElementBlur(event, "address"); }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Input
                                            id="text"
                                            type={this.state.form.contacts.elementType}
                                            value={this.state.form.contacts.value}
                                            config={this.state.form.contacts.config}
                                            error={this.state.form.contacts.error}
                                            validation={this.state.form.contacts.validation}
                                            onChange={event => { this.handleFieldChange(event.target.value, "contacts"); }}
                                            onBlur={event => { this.handleFormElementBlur(event, "contacts"); }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Input
                                            id="text"
                                            type={this.state.form.description.elementType}
                                            value={this.state.form.description.value}
                                            config={this.state.form.description.config}
                                            error={this.state.form.description.error}
                                            validation={this.state.form.description.validation}
                                            onChange={event => { this.handleFieldChange(event.target.value, "description"); }}
                                            onBlur={event => { this.handleFormElementBlur(event, "description"); }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        { this.renderImagesInput()}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button variant="contained"  color="primary" onClick={this.handleFormSubmit} disabled={this.props.disabled || !this.state.formIsValid}>
                                            Сохранить
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </OutlinedContainer>
                    </Grid>
                </Grid>
                : <Preloader fullScreen open color="primary"/>
        );
    }
}

const mapStateToProps = state => {
    return {
        municipalSettlements: state.cmn.municipalSettlements,
        scopes: state.org.scopes,
        created: state.news.created,
        disabled: state.org.toggleDisabled,
        createdOrdId: state.org.created,
        orgId: state.org.orgId
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchMunicipalSettlements: () => dispatch(actions.fetchMunicipalSettlements()),
        onPost: (data) => dispatch(actions.postOrganization(data)),
        onUpdate: (data, logo) => dispatch(actions.updateOrganization(data, logo)),
        onFetchScopes: () => dispatch(actions.fetchScopes()),
        onDeleteOrganizationLogo: (id) => dispatch(actions.deleteOrganizationLogo(id)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NewOrganization));