import React, { Component } from 'react';
import Filter from '../../../components/UI/Filter';
import { connect } from 'react-redux';
import { Grid, Button } from '@material-ui/core';
import * as actions from '../../../store/actions';
import StatisticsItem from '../../../components/Main/StatisticsItem';
import { Link, withRouter } from 'react-router-dom';
import Can from '../../../components/Auth/Can';

class ModeratorStatistics extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

        this.props.onFetchStatistics();
    }

    componentDidUpdate(prevProps, prevState) {
        //if (!prevProps.user && this.props.user) {
        //    console.log('user ', this.props.user);
        //}
    }

    render() {
        return (
            <Grid item xs={12} container justify="center" alignItems="center">
                <Grid item xs container spacing={2}>
                    <Grid item xs={12}>
                        <StatisticsItem count={this.props.users} label={"зарегистрированных пользователей"} loading={this.props.loading} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StatisticsItem count={this.props.deadlineMessages} label={"cообщений с истекающим сроком ответа"} loading={this.props.loading} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StatisticsItem count={this.props.messages} label={"отправленных сообщений"} loading={this.props.loading} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StatisticsItem count={this.props.dislikedMessages} label={"сообщений с отрицательными отзывами "} loading={this.props.loading} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StatisticsItem count={this.props.likedMessages} label={"сообщений с положительными отзывами "} loading={this.props.loading} />
                    </Grid>
                    <Grid item xs={12}>
                        <StatisticsItem count={this.props.messagesInWork} label={"сообщений в работе"} loading={this.props.loading} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StatisticsItem count={this.props.votings} label={"инициатив, вынесенных на голосование"} loading={this.props.loading} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StatisticsItem count={this.props.finishedVotings} label={"голосований и опросов проведено"} loading={this.props.loading} />
                    </Grid>
                    <Can perform="votings:create" yes={() => (
                        <Grid item xs={6}>
                            <Link to="/votings/new">
                                <Button variant="contained" color="primary">Создать голосование или опрос</Button>
                            </Link>
                        </Grid>
                    )}
                    />
                </Grid>
            </Grid>

        );
    }
}

const mapStateToProps = state => {
    return {
        deadlineMessages: state.cmn.moderatorStatistics.deadlineMessages,
        dislikedMessages: state.cmn.moderatorStatistics.dislikedMessages,
        finishedVotings: state.cmn.moderatorStatistics.finishedVotings,
        likedMessages: state.cmn.moderatorStatistics.likedMessages,
        messages: state.cmn.moderatorStatistics.messages,
        messagesInWork: state.cmn.moderatorStatistics.messagesInWork,
        users: state.cmn.moderatorStatistics.users,
        votings: state.cmn.moderatorStatistics.votings,
        loading: state.cmn.moderatorStatistics.loading,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchStatistics: () => dispatch(actions.fetchModeratorStatistics())

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ModeratorStatistics));