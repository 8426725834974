import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Button, IconButton, Menu, MenuItem, Popper, Grow, Paper, MenuList, ClickAwayListener, Divider  } from '@material-ui/core';
import { Icon, InlineIcon } from '@iconify/react';
import PersonIcon from '@material-ui/icons/Person';
import logoutIcon from '@iconify/icons-mdi/logout';
import { NavLink, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import * as actions from '../../store/actions';

const useStyles = makeStyles((theme) => ({
    icon: {
        verticalAlign: "middle"
    },
}));

const LoginButton = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const handleMenuItemClicked = (event, linkTo) => {
        handleClose(event);
        props.history.push(linkTo);
    };

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const redirectToProfile = () => {
        window.location.href = process.env.REACT_APP_IDENTITY_SERVER_URL + '/account/profile?returnUrl=' + window.location.href;
    };

    if (!props.user.isAuthenticated) {
        return (
            <Button className={props.className} color="inherit" onClick={props.onLogin}>
                Войти
            </Button>
        );
    } else {

        let links = [];
        if (props.userRole === "moderator" || props.userRole === "performer") {
            links.push({ to: "/profile", title: "Рабочий профиль" });
        } else if (props.userRole === "user") {
            links.push({ to: "/cabinet/messages", title: "Мои сообщения" });
            links.push({ to: "/cabinet/votings", title: "Мои голосования" });
            links.push({ to: "/cabinet/favorites", title: "Избранное" });
        }
        const personalCabinetMenu = (
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <div>
                                    <MenuList id="menu-list-grow">
                                        <MenuItem key="name">{props.user.name}</MenuItem>
                                        <Divider />
                                        <MenuItem key="cabinet" onClick={redirectToProfile}>
                                            Профиль
                                        </MenuItem>
                                        {links.map(link => (
                                            <MenuItem key={link.to} onClick={(event) => handleMenuItemClicked(event, link.to)}>
                                                {link.title}
                                            </MenuItem>

                                        ))}

                                        <MenuItem onClick={() => {
                                            //console.log("logout"); props.onLogout();
                                            window.location.href = process.env.REACT_APP_ACCOUNT_URL + "/logout?returnUrl=" + window.location.href;
                                        }}>
                                            <Grid container justify="space-between">
                                                <Grid item>Выйти</Grid>
                                                <Grid item><Icon icon={logoutIcon} className={classes.icon} /></Grid>
                                            </Grid>
                                        </MenuItem>
                                    </MenuList>
                                </div>

                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        );
        return (
            <div>

                <IconButton ref={anchorRef} color="inherit" textDecoration="inherit" onClick={handleToggle}>
                    <PersonIcon />
                </IconButton>

                {personalCabinetMenu}
            </div>
        );
    }
};

const mapStateToProps = state => {
    return {
        user: state.user,
        userRole: state.user.isAuthenticated ? state.user.role : null,
        isAuthenticated: state.user.isAuthenticated
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => dispatch(actions.logout()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginButton));