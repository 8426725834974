import * as actionTypes from './actionTypes';
import { addSuccessNotification, addErrorNotification } from './notify';
import userApi from '../api/userApi';

export const fetchUserStart = () => {
    return {
        type: actionTypes.FETCH_USER_START
    };
};

export const fetchUserSuccess = (user) => {
    return {
        type: actionTypes.FETCH_USER_SUCCESS,
        user: user
    };
};

export const fetchUserFail = (err) => {
    return {
        type: actionTypes.FETCH_USER_FAIL,
        error: err
    };
};

export const fetchUser = () => {
    return (dispatch, getState) => {
        dispatch(fetchUserStart());
        const state = getState();
        userApi.get()
            .then(res => {
                dispatch(fetchUserSuccess(res.data));
            })
            .catch(err => {
                dispatch(fetchUserFail(err));
            });
    };
};

export const logoutStart = () => {
    return {
        type: actionTypes.LOGOUT
    };
};

export const logout = () => {
    return (dispatch) => {
        dispatch(logoutStart());
        userApi.logout();
    };
};