import React, { Component } from 'react';
import PreviewGrid from '../../../components/UI/Containers/PreviewGrid';
import MessagePreview from '../../../components/Messages/MessagePreview';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import Preloader from '../../Preloader';

class MessageGridContainer extends Component {
    componentDidMount() {
        if (!this.props.user.isAuthenticated || this.props.user.isAuthenticated && this.props.territory) {
            this.props.onFetchMessages(0, this.props.take, this.props.territory);
        }
        
    }

    componentDidUpdate(prevProps) {
        if (prevProps.territory !== this.props.territory) {
            this.props.onFetchMessages(0, this.props.take, this.props.territory);
        }
    }

    onShowMore = () => {
        this.props.onFetchMessages(this.props.skip, this.props.take);
        this.props.onChangeMaxPossibleMessagesCount();
    }

    render() {
        return (<>
                { this.props.loading ? <Preloader fullScreen open color="primary" /> : null }
                <PreviewGrid
                    shownPreviews={this.props.messages}
                    onShowMore={() => this.onShowMore()}
                    enableShowMore
                    totalFilteredCount={this.props.totalFilteredMessagesCount}
                    maxPossibleCount={this.props.maxPossibleMessagesCount}
                    previewRenderFunction={(message) => {
                        return (<MessagePreview image={message.thumbNail}
                            type={message.type}
                            text={message.text}
                            status={message.status}
                            id={message.id}
                            number={message.number}
                            date={message.createDate}
                            gisRoGuid={message.gisROMessageId}
                            isFavorite={message.isFavorite}
                            onToggleIsFavoriteStatus={event => this.props.onSetIsFavoriteStatus(message.id, !message.isFavorite)}
                            author={message.userName}
                        />);
                    }}
                    getLinkFunction={(message) => { return "/messages/" + message.id; }}
                    getPreviewKeyFunction={(message) => { return message.id; }}
                />
            </>
        );
    }
   
};

const mapStateToProps = state => {
    return {
        messages: state.msg.messages,
        loading: state.msg.loading,
        skip: state.msg.messages.length,
        take: state.msg.take,
        territory: state.my.territory,
        user: state.user,
        totalFilteredMessagesCount: state.msg.totalFilteredMessagesCount,
        maxPossibleMessagesCount: state.msg.maxPossibleMessagesCount,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchMessages: (skip, take, territory) => dispatch(actions.fetchMessagePreviews(skip, take, territory)),
        onSetIsFavoriteStatus: (messageId, isFavorites) => dispatch(actions.setMessageIsFavoriteStatus(messageId, isFavorites)),
        onChangeMaxPossibleMessagesCount: () => dispatch(actions.changeMaxPossibleMessagesCount())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageGridContainer);