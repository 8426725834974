import * as actionTypes from './actionTypes';

export const addNotification = (notification) => ({
    type: actionTypes.ADD_NOTIFICATION,
    notification: notification
});

export const addSuccessNotification = (message) => {
    if (!message) {
        message = "Успешно";
    }
    return {
        type: actionTypes.ADD_NOTIFICATION,
        notification: {
            message: message,
            type: "success"
        }
    };
};

export const addErrorNotification = (message) => {
    if (!message) {
        message = "При обработке запроса произошла ошибка";
    }
    return {
        type: actionTypes.ADD_NOTIFICATION,
        notification: {
            message: message,
            type: "error"
        }
    };
};

export const addWarningNotification = (message) => {
    if (message) {
        return {
            type: actionTypes.ADD_NOTIFICATION,
            notification: {
                message: message,
                type: "warning"
            }
        };
    }

};

export const addInformationNotification = (message) => {
    if (message) {
        return {
            type: actionTypes.ADD_NOTIFICATION,
            notification: {
                message: message,
                type: "info"
            }
        };
    }

};

export const removeNotification = (key) => {
    return {
        type: actionTypes.REMOVE_NOTIFICATION,
        key: key
    };
};

export const showErrorPage = (errorCode) => {
    return {
        type: actionTypes.SHOW_ERROR_PAGE,
        errorCode: errorCode
    };
};

export const hideErrorPage = () => {
    return {
        type: actionTypes.HIDE_ERROR_PAGE
    };
};