import React, { Component } from 'react';
import GroupedList from '../../../components/UI/Containers/GroupedList';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';

class MessageTypeList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            boundMessageTypes: {}
        };
    }

    componentDidMount() {
        if (!this.props.profileLoaded) {
            this.props.onFetchWorkProfile();
        } else {
            this.props.onFetchGroupedMessageTypes(this.props.territory);
        }
        
    }

    componentDidUpdate(prevProps) {

        if (!prevProps.profileLoaded && this.props.profileLoaded) {
            this.props.onFetchGroupedMessageTypes(this.props.territory);
        }
        else if (prevProps.groupedMessageTypes.length === 0 && this.props.groupedMessageTypes.length > 0) {
            var checkedTypes = this.props.messageTypes.map(x => x.id);
            let boundMessageTypes = {};
            this.props.groupedMessageTypes.forEach(cat => {
                boundMessageTypes[cat.categoryId] = {
                    title: cat.category
                };
                boundMessageTypes[cat.categoryId].items = {};
                cat.types.forEach(type => {
                    boundMessageTypes[cat.categoryId].items[type.typeId] = {
                        title: type.type,
                        checked: checkedTypes.indexOf(type.typeId) !== -1
                    };
                });
            });
            this.setState({
                boundMessageTypes: boundMessageTypes,
                messageTypesLoaded: true
            });
        }
            //else {
        //    var curCheckedTypes = this.props.messageTypes.map(x => x.id);
        //    curCheckedTypes.sort();
        //    var prevCheckedTypes = prevProps.messageTypes.map(x => x.id);
        //    prevCheckedTypes.sort();
        //    if (JSON.stringify(curCheckedTypes) !== JSON.stringify(prevCheckedTypes)) {

        //    }
        //}
    }

    getSelectedMessageTypes = (allMessageTypes) => {
        let array = [];
        Object.entries(allMessageTypes).forEach(([categoryId, category]) => {
            Object.entries(category.items).forEach(([typeId, type]) => {
                if (type.checked) {
                    array.push(+typeId);
                }
            });
        });
        return array;
    };

    changeMessageTypeState = (categoryId, messageTypeId, checked) => {
        var boundMessageTypes = {...this.state.boundMessageTypes};
        var category = { ...this.state.boundMessageTypes[categoryId] };

        var updatedElement = { ...this.state.boundMessageTypes[categoryId].items[messageTypeId] };
        updatedElement.checked = checked;
        category.items[messageTypeId] = updatedElement;
        boundMessageTypes[categoryId] = category;
        this.setState({
            boundMessageTypes: boundMessageTypes
        }
            , () => {
                this.props.onChange(this.getSelectedMessageTypes(this.state.boundMessageTypes));
            }
        );
        
    };

    render() {
        if (this.state.messageTypesLoaded) {
            return (
                <GroupedList
                    editable={this.props.editable}
                    items={this.state.boundMessageTypes}
                    onChange={this.changeMessageTypeState}
                    enableCheckAll={this.props.enableCheckAll}
                />
            );
        }
        return null;
    }
};


const mapStateToProps = (state) => {
    return {
        territory: state.profile.territories.length !== 0 ? state.profile.territories[0].id : null,
        groupedMessageTypes: state.perf.groupedMessageTypes,
        profileLoaded: state.profile.territories.length !== 0
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchGroupedMessageTypes: (msId) => dispatch(actions.fetchGroupedMessageTypes(msId)),
        onFetchWorkProfile: () => dispatch(actions.fetchUserWorkProfile()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageTypeList);