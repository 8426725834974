import React, { Component } from 'react';
import './FullMessage.css';

class Map extends Component {

    constructor(props) {
        super(props);
        this.state = {
            newGeometry: null,
            startPointLayer: null
        };
    }

    componentDidMount(props) {
        this.initMap();
    }

    componentDidUpdate(prevProps) {
        if (this.props.editable !== prevProps.editable) {
            if (this.props.editable) {
                this.enableEdit();
            }
        }
    }

    enableEdit() {
        let layer = this.MAP.startDraw();
        let self = this;
        layer.on('dragend', function () {
            self.removeStartPoint();
            if (self.props.onSetPoint) {
                self.props.onSetPoint();
            }
            
        });
    }

    showStartPoint() {
        if (this.props.geometry) {
            let layer = this.MAP.addNewLayer(this.props.geometry);
            this.setState({
                startPointLayer: layer
            });
        }
        if (this.props.coordinates && this.props.coordinates.length === 2) {
            console.log("zoom to user coordinates: ", this.props.coordinates[0], " , ", this.props.coordinates[1]);
            this.MAP.zoomToUserCoordinates(this.props.coordinates, 16);
        }
    }

    removeStartPoint() {
        if (this.state.startPointLayer) {
            this.MAP.removeLayer(this.state.startPointLayer);
            this.setState({
                startPointLayer: null
            });
        }
    }

    createMap() {
        if (!this.MAP) {
            this.MAP = new window.L.GisRoMap('map', {
                center: [48.09470655664555, 40.83330078125],
                zoom: 7,
                BACKEND_URL: process.env.REACT_APP_MAP_URL
            });
        }
        this.showStartPoint();
        
        if (this.props.editable) {
            this.enableEdit();
        }
    }

    getGisSettlementGuid = (munSetInfo) => {
        console.log(munSetInfo);
        try {
            switch (munSetInfo["type"]) {
                case "intersettlement":
                    return munSetInfo["mun_district"]["id"];
                case "settlement":
                    return munSetInfo["settlement"]["id"];
                case "out_of_bounds":
                default:
                    return null;
            }
        }
        catch (exception) {
            return null;
        }

    }

    getEditResult = () => {
        console.log("cancel draw");
        let geometry = this.MAP.cancelDraw();
        let resultObject = {
            geometry: geometry ? geometry.wkt : this.props.geometry,
            coordinates: geometry ? geometry.latlng : this.props.coordinates,
        };
        let getMunicipalSettlementInfo = geometry
            ? this.MAP.getMOInfo(geometry.latlng[0], geometry.latlng[1])
            : this.MAP.getMOInfo(this.props.coordinates[0], this.props.coordinates[1]);
        let self = this;
        return getMunicipalSettlementInfo.then(function (result) {
            let gisSettlementId = self.getGisSettlementGuid(result.data);
            return {
                ...resultObject,
                municipalSettlement: gisSettlementId
            }
        }).catch(ex => {
            return {
                ...resultObject,
                municipalSettlement: null
            }
        });
    }

    initMap() {

        if (!window.L) {
            let aScript = document.createElement('script');
            aScript.type = 'text/javascript';
            aScript.src = "/map/main.js";
            document.head.appendChild(aScript);
            let self = this;
            aScript.onload = function () {
                self.createMap();
            };
        }
        else {
            this.createMap();
        }
    }

    render() {
        return (
            <div id="map" className="map" />
        );
    }
}

export default Map;