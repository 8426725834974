import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Button, Typography, Paper } from '@material-ui/core';
import * as actions from '../../../store/actions';
import { withRouter } from 'react-router-dom';
import Performer from '../components/Performer';
import MessageTypeList from './MessageTypeList';
import OutlinedContainer from '../../../components/UI/Containers/OutlinedContainer';
import Preloader from '../../../containers/Preloader';

class PerformerInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messageTypes: [],
            canSubmit: false
        };
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.props.onFetchPerformerInfo(this.props.match.params.id);
        }
    }

    componentDidUpdate(prevProps, prevState) {
    }

    handleMessageTypesChange = (messageTypes) => {
        this.setState({
            messageTypes: messageTypes,
            canSubmit: true
        });
    };

    handleSubmit = () => {
        this.props.onSetPerformerMessageTypes(this.props.performerInfo.id, this.state.messageTypes);
    };

    render() {
        let performer = (
            <Preloader fullScreen open color="primary"/>
        );
        let messageTypes = null;
        if (!this.props.loading && this.props.performerInfo) {
            performer = (
                <Performer {...this.props.performerInfo} />
            );
            messageTypes = (<MessageTypeList
                messageTypes={this.props.performerInfo.messageTypes}
                onChange={(types) => this.handleMessageTypesChange(types)}
                editable
                enableCheckAll
            />);
        }
        return (
            <Grid container justify="center" alignItems="center">
                <OutlinedContainer>
                    <Grid container direction="column" spacing={2}>
                        <Grid item xs>
                            <Typography variant="h5" component="h5">Информация об исполнителе</Typography>
                        </Grid>
                        <Grid item xs>
                            {performer}
                        </Grid>
                        <Grid item xs>
                            <Typography variant="h6" component="h6">
                                Типы обрабатываемых сообщений
                        </Typography>
                            {messageTypes}
                        </Grid>
                        <Grid item xs>
                            <Button variant="contained" color="primary"
                                onClick={() => this.handleSubmit()}
                                disabled={!this.state.canSubmit}>
                                Сохранить изменения
                        </Button>
                        </Grid>
                    </Grid>
                    
                </OutlinedContainer>
            </Grid>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.perf.loading,
        performerInfo: state.perf.performerInfo
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchPerformerInfo: (id) => dispatch(actions.fetchPerformerInfo(id)),
        onSetPerformerMessageTypes: (id, messageTypes) => dispatch(actions.setPerformerMessageTypes(id, messageTypes))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PerformerInfo));