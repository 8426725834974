import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import NewsCreation from '../../private/shared/containers/NewsCreation';
import * as actions from '../../store/actions/index';
import Preloader from '../../containers/Preloader';

class NewsEdit extends Component {

    componentDidMount() {
        if (this.props.match.params.id) {
            this.props.onFetchNewsDetails(this.props.match.params.id);
        }
    }

    render() {
        return (
            (this.props.newsInfo)
                ? <Grid container className="NewsCreation" justify="center" alignItems="center">
                    <NewsCreation isEdit newsInfo={this.props.newsInfo} />
                </Grid>
                : <Preloader open fullScreen color="primary"/>
        );
    }

};

const mapStateToProps = state => {
    return {
        newsInfo: state.news.newsDetails 
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchNewsDetails: (id) => dispatch(actions.fetchNewsDetails(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NewsEdit));

