import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import OutlinedContainer from '../../../components/UI/Containers/OutlinedContainer';
import MessageInfoStep from './MessageInfoStep';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import messageApi from '../../../store/api/messageApi';
import Preloader from '../../../containers/Preloader';
import { withSnackbar } from 'notistack';
import Map from '../../../components/Messages/FullMessage/Map';
import { withRouter } from 'react-router-dom';

class NewMessageStepper extends Component {

    constructor(props) {
        super(props);
        console.log(props.draft);
        this.steps = ["Шаг 1", "Шаг 2"];
        this.map = React.createRef();
        console.log("draft: ", this.props.draft);
        if (props.draft) {
            this.state = {
                activeStep: 0,
                geometry: props.draft.geometry,
                municipalSettlementId: props.draft.municipalSettlementId,
                form: null,
                loading: false,
                coordinates: props.draft.coordinates,
                locationSet: props.draft.geometry != null,
                canEditMap: true
            };
        } else {
            this.state = {
                activeStep: 0,
                geometry: null,
                municipalSettlementId: null,
                form: null,
                loading: false,
                coordinates: null,
                locationSet: false,
                canEditMap: true
            };
        }
        
    }

    componentDidMount() {

    }


    componentDidUpdate(prevProps) {

    }

    handleSetLocation() {
        this.setState({
            locationSet: true
        });
    }

    handleMunicipalSettlementsNotFound() {
        this.setState({
            geometry: null,
            municipalSettlementId: null,
            loading: false,
            coordinates: null,
            canEditMap: true
        });
        this.props.enqueueSnackbar("Данный функционал не работает на выбранной территории", { variant: "error" });
    }

    handleNext = () => {
        this.setState({
            loading: true,
            canEditMap: false
        });
        let self = this;
        this.map.current.getEditResult()
            .then(result => {
                console.log("mun set info: ", result.data);
                if (!result.municipalSettlement) {
                    self.handleMunicipalSettlementsNotFound();
                } else {
                    messageApi.getMunicipalSettlement(result.municipalSettlement).then(msIdResult => {
                        let munSetId = msIdResult.data;
                        this.setState({
                            geometry: result.geometry,
                            coordinates: result.coordinates,
                            activeStep: 1,
                            municipalSettlementId: munSetId,
                            loading: false
                        });
                    }).catch(ex => {
                        this.handleMunicipalSettlementsNotFound();
                    });
                }
            })
            .catch(ex => {
                this.handleMunicipalSettlementsNotFound();
            });
        
    }

    handleBack = () => {
        this.setState({
            activeStep: this.state.activeStep - 1,
            canEditMap: this.state.activeStep === 1
        });
    }

    handleReset = () => {
        this.setState({
            activeStep: 0
        });
    }

    getStepContent(step) {
        switch (step) {
            case 0:
                return (
                    <OutlinedContainer style={{ width: "100%" }}>
                        <Grid container justify="flex-start" alignItems="flex-start" spacing={2} >
                            <Grid item xs={12}>
                                <Alert variant="outlined" severity="warning">
                                    Для того чтобы продолжить, необходимо указать точку на карте
                                </Alert>
                            </Grid>
                            <Grid item xs={12}>
                                <div style={{ width: "100%", height: "400px", maxWidth: "600px", position: "relative" }} >
                                    <Map
                                        editable={this.state.canEditMap}
                                        geometry={this.props.draft ? this.props.draft.geometry : null} coordinates={this.props.draft ? this.props.draft.coordinates : null}
                                        onSetPoint={() => this.handleSetLocation()}
                                        ref={this.map}
                                    />
                                </div>
                                
                            </Grid>

                        </Grid>

                    </OutlinedContainer>

                );
            case 1:
                return this.renderStepTwo();
            default:
                return "Неизвестный шаг";
        }
    }

    handleSaveFormData = form => {
        this.setState({
            form: form,
            activeStep: 2
        });
    }

    handleCancel = () => {
        this.props.history.goBack();
    }

    handleFinish = (saveAsDraft) => {
        let self = this;
        var formData = new FormData();
        var messageObj = {
            text: this.state.form.messageText.value,
            category: this.state.form.categoryId.value,
            municipalSettlement: this.state.form.municipalSettlementId.value,
            type: this.state.form.messageTypeId.value,
            isPublic: this.state.form.isPublic.value,
        };

        for (var key in messageObj) {
            
            formData.append(key, messageObj[key]);
        }
        let savedDocuments = this.state.form.documents.filter(x => x.id).map(x => x.id);
        let savedImages = this.state.form.images.filter(x => x.id).map(x => x.id);
        let newDocuments = this.state.form.documents.filter(x => !x.id);
        let newImages = this.state.form.images.filter(x => !x.id);
        for (var i = 0; i < savedDocuments.length; i++) {
            formData.append("savedDocuments[]", savedDocuments[i]) ;
        }

        for (var i = 0; i < savedImages.length; i++) {
            formData.append("savedPhotos[]", savedImages[i]);
        }
        for (var i = 0; i < newDocuments.length; i++) {
            formData.append("documents", newDocuments[i]);
        }
        for (var j = 0; j < newImages.length; j++) {
            formData.append("photos", newImages[j]);
        }
        formData.append("Geometry", this.state.geometry);
        formData.append("Coordinates[0]", this.state.coordinates[0]);
        formData.append("Coordinates[1]", this.state.coordinates[1]);
        if (this.props.draft) {
            formData.append("Id", this.props.draft.id);
        }
        messageApi.postMesage(formData, this.props.draft, saveAsDraft)
            .then(function (response) {
                if (response.data.hasError) {
                    self.props.enqueueSnackbar(saveAsDraft
                        ? "При сохранении черновика произошла ошибка"
                        : "При отправке сообщения произошла ошибка", { variant: "error" });
                } else {
                    self.props.enqueueSnackbar(saveAsDraft ? "Черновик сохранен" : "Сообщение отправлено", { variant: "success" });
                    self.props.history.push("/cabinet/messages");
                }
            })
            .catch(ex => {
                console.log(ex);
                self.props.enqueueSnackbar(saveAsDraft
                    ? "При сохранении черновика произошла ошибка"
                    : "При отправке сообщения произошла ошибка", { variant: "error" });
            }
        );
    }

    render() {
        let backDrop = null;
        const header = this.props.draft ? "Редактирование сообщения" : "Новое сообщение";
        if (this.state.loading) {
            backDrop =  (
                <Preloader fullScreen open color="primary"/>
            );
        }
        return (
            <React.Fragment>
                
                <Grid container justify="center">
                    <Grid item xs={12} sm={8} container justify="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="h5" gutterBottom style={{ textAlign: 'left' }}>
                                {header}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="secondary" onClick={this.handleCancel}>
                                Отмена
                    </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={8} >
                        <Stepper activeStep={this.state.activeStep} orientation="vertical" className="paddingXNone">
                            <Step key="step1" disabled={this.state.activeStep !== 0}>
                                <StepLabel>Укажите точку на карте</StepLabel>
                                <StepContent TransitionProps={{ in: true }} >
                                    <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
                                        <Grid item xs>
                                            {this.getStepContent(0)}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={!this.state.locationSet}
                                                onClick={this.handleNext}
                                            >
                                                Далее
                                            </Button>
                                            </Grid>
                                        </Grid>
                                </StepContent>
                            </Step>
                            <Step key="step2" disabled={this.state.activeStep !== 1}>
                                <StepLabel>Заполните поля</StepLabel>
                                <StepContent TransitionProps={{ in: true }} >
                                    <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
                                        <Grid item xs={12}>
                                            <Button
                                                variant="contained"
                                                disabled={this.state.activeStep !== 1}
                                                onClick={this.handleBack}
                                            >
                                                Назад
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <MessageInfoStep
                                                draft={this.props.draft}
                                                municipalSettlementId={this.state.municipalSettlementId}
                                                onSaveFormData={formData => this.handleSaveFormData(formData)} />
                                        </Grid>
                                    </Grid>
                                </StepContent>
                            </Step>
                            <Step key="step3" disabled={this.state.activeStep !== 2}>
                                <StepLabel>Отправьте сообщение</StepLabel>
                                <StepContent TransitionProps={{ in: true }} >
                                    <Button
                                        variant="contained"
                                        color="default"
                                        onClick={() => this.handleFinish(true)}
                                        style={{ marginRight: "5px"}}
                                    >
                                        Сохранить как черновик
                                    </Button>   
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => this.handleFinish(false)}
                                    >
                                        Отправить сообщение
                                    </Button>   
                                </StepContent>
                            </Step>
                        </Stepper>
                    </Grid>
                </Grid>
                {backDrop}
            </React.Fragment>
        );
    }

}

export default withSnackbar(withRouter(NewMessageStepper));