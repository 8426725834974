import React from 'react';
import Input from '../../components/UI/Inputs/Input';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';



const filter = (props) => {
    if (!props.show) {
        return null;
    }
    const filterElementArray = [];
    for (let key in props.values) {
        if (props.form[key]) {
            filterElementArray.push({
                id: key,
                value: props.values[key],
                config: props.form[key]
            });
        }   
    }
    return (
        <Grid container justify="flex-end" spacing={2}>
            {
                filterElementArray.map(filterElement => {
                    return (
                        <Grid item key={filterElement.id} xs style={{ minWidth: "160px", maxWidth: "300px" }}>
                            <Input id={filterElement.id}
                                type={filterElement.config.elementType}
                                value={filterElement.value}
                                config={filterElement.config.config}
                                onChange={(event, value) => { props.onFilterChange(filterElement.id, event.target ? event.target.value: value); }}
                                size="small"
                            />
                        </Grid>
                    );
                })
            }
        </Grid >

    );
};
export default filter;