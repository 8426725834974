import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Preloader from '../Preloader';
import Grid from '@material-ui/core/Grid';
import Voting from '../../components/Voting/Voting';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';

class VotingContainer extends Component {

    componentDidMount() {
        if (this.props.id) {
            this.props.onFetchVoting(this.props.id);
            this.props.onFetchUserAnswersOnVote(this.props.id);
        }
    }

    render() {
        let nowDate = new Date();
        let dateChange = (date) => {
            let cutDate = date.substr(0, 10);
            let deleteSymbol = cutDate.split('.');
            return new Date(deleteSymbol[2], (deleteSymbol[1] - 1), deleteSymbol[0]);
        };

        let voting = (
            <Preloader fullScreen open color="primary"/>
        );
        if (!this.props.loading && this.props.loadedVoting) {
            voting = (
                <Voting {...this.props.loadedVoting}
                    canVote={this.props.loadedVoting.status === "Открыто"}
                    loadedVotingAnswers={this.props.loadedVotingAnswers}
                    onVote={(id, options, ownAnswer) => this.props.onVote(id, options, ownAnswer)}
                    debriefingDateNotCome={(dateChange(this.props.loadedVoting.debriefingDate) > nowDate)}
                    closedVote={this.props.loadedVoting.status === "Завершено"}
                />
            );
        }

        return (
            <Grid container justify="center">
                <Grid item xs={12} sm={8}>
                    {voting}
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => {
    return {
        loadedVoting: state.vote.loadedVoting,
        loading: state.vote.loading,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchVoting: (id) => dispatch(actions.fetchVoting(id)),
        onVote: (id, options, onVote) => dispatch(actions.vote(id, options, onVote)),
        onFetchUserAnswersOnVote: (votingId) => dispatch(actions.fetchUserAnswersOnVote(votingId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VotingContainer);