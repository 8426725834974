import React, { Component } from 'react';
import NewsList from '../../components/News/NewsList';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import Preloader from '../Preloader';
import { Grid, Typography } from '@material-ui/core';

class News extends Component {
    componentDidMount() {
        if (!this.props.user.isAuthenticated || this.props.user.isAuthenticated && this.props.territory) {
            this.props.onFetchNews(0, this.props.take, this.props.territory);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.territory !== this.props.territory) {
            this.props.onFetchNews(0, this.props.take, this.props.territory);
        }
    }
    onShowMore() {
        this.props.onFetchNews(this.props.skip, this.props.take, this.props.territory);
        this.props.onChangeMaxPossibleNewsCount();
    }

    render() {
        return (<>
            {this.props.loading 
                ? ((this.props.embedded) ? <Preloader color="primary" /> : <Preloader open fullScreen color="primary" />)
                : null
            }
            {!this.props.loading && this.props.news.length === 0 && (<Grid item xs><Typography variant="subtitle1">На портале еще нет ни одной новости</Typography></Grid>)}
            <NewsList
                shownNews={this.props.news}
                enableShowMore
                totalFilteredNewsCount={this.props.totalFilteredNewsCount}
                maxPossibleNewsCount={this.props.maxPossibleNewsCount}
                onShowMore={() => this.onShowMore()}
            />
        </>)
    }
};

const mapStateToProps = state => {
    return {
        news: state.news.news,
        loading: state.news.loading,
        skip: state.news.skip,
        take: state.news.take,
        territory: state.my.territory,
        user: state.user,
        totalFilteredNewsCount: state.news.totalFilteredNewsCount,
        maxPossibleNewsCount: state.news.maxPossibleNewsCount
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchNews: (skip, take, territory) => dispatch(actions.fetchNews(skip, take, territory)),
        onChangeMaxPossibleNewsCount: () => dispatch(actions.changeMaxPossibleNewsCount())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(News);