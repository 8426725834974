import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Tooltip, Grid } from '@material-ui/core';

class VotingFilterToggler extends Component {
    render() {
        return (
            <Grid container direction="row-reverse" justify="flex-start" alignItems="center">
                <Grid item style={{ marginLeft:'10px'}}>фильтр</Grid>
                <Tooltip title="фильтровать">
                    <IconButton edge="end" aria-label="фильтр" onClick={() => this.props.onToggleFilter()}>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
       );
    }
}
const mapStateToProps = state => {
    return {
        showFilter: state.vote.showFilter,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onToggleFilter: () => dispatch(actions.toggleVotingFilter()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VotingFilterToggler);