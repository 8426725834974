import React from 'react';
import { Typography, List, ListSubheader, ListItem, ListItemIcon, ListItemText, Checkbox, Box} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    table: {
        minWidth: 750,
    },
    headCell: {
        fontWeight: 700
    },
    checkBox: {
        transform: "scale(0.8)",
        margin: 0
    },
    secondary: {
        fontStyle: "italic"
    },
    subHeader: {
        fontWeight: "bold"
    }
}));

const GroupedList = (props) => {
    const classes = useStyles();
    const selectAllHandler = (category, checked) => {
        let cat = props.items[category];
        Object.entries(cat.items).forEach(([key, group]) => {
            props.onChange(category, key, checked);
        });
    };
    if (!props.items) {
        return null;
    }
    return (
        <List aria-labelledby="message-types" dense >
            {
                Object.entries(props.items).map(([key, group]) => {
                    return (
                        <React.Fragment key={key}>
                            <ListSubheader key={key} className={classes.subHeader} disableSticky>{group.title}</ListSubheader>
                            {props.editable && props.enableCheckAll && (
                                <ListItem key="all">

                                        <ListItemIcon>
                                            <Checkbox
                                                className={classes.checkBox}
                                                edge="start"
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': "label-all" }}
                                                onChange={(event) => selectAllHandler(key, event.target.checked)}
                                            />
                                    </ListItemIcon>
                                    <ListItemText id="label-all" secondary="Выбрать все" className={classes.secondary}/>
                                </ListItem>
                            )}
                            {
                                Object.entries(group.items).map(([k, el]) => {
                                    const labelId = `type-${k}`;
                                    return (
                                        <ListItem key={k} selected={props.editable && el.checked}>
                                            {props.editable && (
                                                <ListItemIcon>
                                                    <Checkbox
                                                        className={classes.checkBox}
                                                        color="primary"
                                                        edge="start"
                                                        checked={el.checked}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                        onChange={(event) => props.onChange(key, k, event.target.checked)}
                                                    />
                                                </ListItemIcon>
                                            )}
                                            <ListItemText id={labelId} primary={el.title} />
                                        </ListItem>
                                    );
                                })
                            }
                        </React.Fragment>
                    );
                })}

        </List>
    );
};

GroupedList.defaultProps = {
    checkedItems: [],
    items: []
};

export default GroupedList;