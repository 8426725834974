const _formatDate = (date) => {
    var dd = date.getDate();
    if (dd < 10) dd = '0' + dd;

    var mm = date.getMonth() + 1;
    if (mm < 10) mm = '0' + mm;

    var yyyy = date.getFullYear();

    return dd + '.' + mm + '.' + yyyy;
};

const _formatDateObject = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = `0${month}`;
    if (day.length < 2)
        day = `0${day}`;

    return [year, month, day].join('-');
}

export function formatDate(date) {
    if (typeof date === Date) {
        return _formatDate(date);
    } else if (typeof date === "string") {
        return _formatDate(new Date(Date.parse(date)));
    } else if (typeof date === "object") {
        return _formatDateObject(date)
    }
    return null;
}