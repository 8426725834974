import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import NewsCreation from '../containers/NewsCreation';

const newsCreationPage = (props) => {

    return (
        <Grid container className="NewsCreation" justify="center" alignItems="center">
            <NewsCreation />
        </Grid>
    );
};

export default withRouter(newsCreationPage);