import React from 'react';
import News from '../../../containers/News/News';
import { Grid, Button } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import Can from '../../../components/Auth/Can';

const newsPage = (props) => {

    return (
        <Grid container className="News" justify="center" alignItems="center">
            <Can perform="news:create" yes={() => (
                <Grid item xs={12} sm={8} style={{ marginTop: "20px" }}>
                    <Link to="/news/add">
                        <Button variant="contained" color="primary">Добавить новость</Button>
                    </Link>

                </Grid>
            )}/>
            <Grid item xs={12} sm={8} style={{ marginTop: "20px" }}>
                <News />
            </Grid>

        </Grid>
    );
};

export default withRouter(newsPage);