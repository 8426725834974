import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import * as actions from '../../../store/actions';
import PerformersTable from '../components/PerformersTable';
import { withRouter } from 'react-router-dom';
import Preloader from '../../../containers/Preloader';

class Performers extends Component {

    componentDidMount() {
        this.props.onFetchPerformersInfo();
    }

    componentDidUpdate(prevProps, prevState) {
    }

    render() {
        let performers = (
            <Preloader fullScreen open color="primary"/>
        );
        if (!this.props.loading) {
            performers = (
                <React.Fragment>
                    <Typography variant="h5" component="h5">Управление исполнителями</Typography>
                    <PerformersTable
                        performers={this.props.performers}
                        />
                </React.Fragment>
            );
        }
        return (
            <Grid item xs={12} container justify="center" alignItems="center">
                <Grid item xs>
                    {performers}
                </Grid>
            </Grid>

        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.perf.loading,
        performers: state.perf.performers
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchPerformersInfo: () => dispatch(actions.fetchPerformersInfo())

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Performers));