import * as actionTypes from './actionTypes';
import messageApi from '../api/messageApi';
import { addSuccessNotification, addErrorNotification } from './notify';

export const fetchPerformersInfo = () => {
    return dispatch => {
        fetchPerformersInfoStart();
        messageApi.getPerformersInfo()
            .then(res => {
                dispatch(fetchPerformersInfoSuccess(res.data));
            })
            .catch(err => {
                dispatch(fetchPerformersInfoFail(err));
            });
    };
};

export const fetchPerformersInfoStart = () => {
    return {
        type: actionTypes.FETCH_PERFORMERS_START
    };
};

export const fetchPerformersInfoSuccess = (data) => {
    return {
        type: actionTypes.FETCH_PERFORMERS_SUCCESS,
        performers: data
    };
};

export const fetchPerformersInfoFail = (error) => {
    return {
        type: actionTypes.FETCH_PERFORMERS_FAIL,
        error: error
    };
};

export const fetchPerformerInfo = (id) => {
    return dispatch => {
        fetchPerformerInfoStart();
        messageApi.getPerformerInfo(id)
            .then(res => {
                dispatch(fetchPerformerInfoSuccess(res.data));
            })
            .catch(err => {
                dispatch(fetchPerformerInfoFail(err));
            });
    };
};

export const fetchPerformerInfoStart = () => {
    return {
        type: actionTypes.FETCH_PERFORMER_INFO_START
    };
};

export const fetchPerformerInfoSuccess = (data) => {
    return {
        type: actionTypes.FETCH_PERFORMER_INFO_SUCCESS,
        payload: data
    };
};

export const fetchPerformerInfoFail = (error) => {
    return {
        type: actionTypes.FETCH_PERFORMER_INFO_FAIL,
        error: error
    };
};

export const fetchGroupedMessageTypes = (msId) => {
    return (dispatch, getState) => {
        messageApi.getGroupedMessageTypes(msId)
            .then(res => {
                dispatch({
                    type: actionTypes.FETCH_GROUPED_MESSAGE_TYPES_SUCCESS,
                    messageTypes: res.data
                });
            })
            .catch(err => {
                dispatch(addErrorNotification("Не удалось загрузить типы сообщений"));
            });
    };
};

const setPerformerMessageTypesStart = () => {
    return {
        type: actionTypes.SET_PERFORMER_MESSAGE_TYPES_START
    };
};

const setPerformerMessageTypesSuccess = (userId) => {
    return {
        type: actionTypes.SET_PERFORMER_MESSAGE_TYPES_SUCCESS
    };
};

const setPerformerMessageTypesFail = () => {
    return {
        type: actionTypes.SET_PERFORMER_MESSAGE_TYPES_FAIL
    };
};

export const setPerformerMessageTypes = (userId, types) => {
    return dispatch => {
        dispatch(setPerformerMessageTypesStart());
        messageApi.setPerformerMessageTypes(userId, types)
            .then(res => {
                dispatch(setPerformerMessageTypesSuccess(userId));
                dispatch(fetchPerformerInfo(userId));
                dispatch(addSuccessNotification());
            })
            .catch(err => {
                dispatch(setPerformerMessageTypesFail());
                dispatch(addErrorNotification("Не удалось привязать один или несколько типов к исполнителю"));
            });
    };
};