import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Link, withRouter } from 'react-router-dom';
import { queryString } from 'query-string';
import Input from '../../../components/UI/Inputs/Input';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';

class MessageModerationPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                categoryId: {
                    value: props.message.category.id,
                    elementType: "select",
                    config: {
                        label: "Категория сообщения"
                    },
                    validation: {
                        required: true
                    },
                    error: "",
                    valid: true
                },
                messageTypeId: {
                    value: props.message.messageType.id,
                    elementType: "select",
                    config: {
                        label: "Тип сообщения"
                    },
                    validation: {
                        required: true
                    },
                    error: "",
                    valid: true
                },
                performerId: {
                    value: props.message.performerId,
                    elementType: "select",
                    config: {
                        //defaultDisplayValue: "Не выбран",
                        label: "Исполнитель"
                    },
                    validation: {
                        required: true
                    },
                    error: "",
                    valid: true
                }
            }
        };
    }

    componentDidMount() {
        this.props.onFetchCategories(this.props.message.municipalSettlement.id);
        this.props.onFetchMessageTypes(this.props.message.category.id, this.props.message.municipalSettlement.id);
        this.props.onFetchPerformers(this.props.message.messageType.id, this.props.message.municipalSettlement.id);
        this.validateForm();
    }

    componentDidUpdate(prevProps) {
        if (this.state.form.messageTypeId.value
            && this.props.messageTypes.length > 0
            && this.props.messageTypes.filter(type => type.id === this.state.form.messageTypeId.value).length === 0) {
            this.setState({
                form: this.setFormElementValue("messageTypeId", this.props.messageTypes[0].id)
            });
            this.props.onFetchPerformers(this.props.messageTypes[0].id, this.props.message.municipalSettlement.id);
        }
        if (this.state.form.performerId.value
            && this.props.performers.length > 0
            && this.props.performers.filter(contr => contr.id === this.state.form.performerId.value).length === 0) {
            this.setState({
                form: this.setFormElementValue("performerId", this.props.performers[0].id)
            });
        }
    }

    setFormElementValue(elementName, newValue) {
        let updatedForm = { ...this.state.form };
        let updatedElement = { ...this.state.form[elementName] };
        if (updatedElement.validation && updatedElement.validation.required && !newValue) {
            updatedElement.error = "Поле должно быть заполнено";
        } else {
            updatedElement.error = "";
        }
        updatedElement.valid = updatedElement.error ? false : true;
        updatedElement.value = newValue;
        updatedForm[elementName] = updatedElement;
        return updatedForm;
    }

    handleFormElementBlur(elementName, newValue) {
        let updatedForm = { ...this.state.form };
        let updatedElement = { ...this.state.form[elementName] };
        if (updatedElement.validation && updatedElement.validation.required && !newValue) {
            updatedElement.error = "Поле должно быть заполнено";
        } else {
            updatedElement.error = "";
        }
        updatedElement.valid = updatedElement.error ? false : true;
        updatedForm[elementName] = updatedElement;
        this.setState({
            form: updatedForm
        }, this.validateForm);
    }

    checkValidity(element) {
        return element.valid === undefined || element.valid;
    }

    validateForm() {
        let formIsValid = true;
        for (var element in this.state.form) {
            if (!this.checkValidity(this.state.form[element])) {
                formIsValid = false;
            }
        }
        this.setState({
            formIsValid: formIsValid
        });
    }

    handleModerationFormSubmit = () => {
        this.props.onSaveModeration(this.props.message.id, this.state.form.performerId.value, this.state.form.categoryId.value, this.state.form.messageTypeId.value);
    }

    handleCategoryChange = (categoryId) => {
        this.setState({
            form: this.setFormElementValue("categoryId", categoryId)
        }, this.validateForm);
        this.props.onFetchMessageTypes(categoryId, this.props.message.municipalSettlement.id);
    }

    handleMessageTypeChange = (messageTypeId) => {
        this.setState({
            form: this.setFormElementValue("messageTypeId", messageTypeId)
        }, this.validateForm);
        this.props.onFetchPerformers(messageTypeId, this.props.message.municipalSettlement.id);
    }

    handlePerformerChange = (performerId) => {
        this.setState({
            form: this.setFormElementValue("performerId", performerId)
        }, this.validateForm);
    }

    handleCancelReasonChange = (cancelReason) => {
        this.setState({
            form: this.setFormElementValue("cancelReason", cancelReason)
        }, this.validateForm);
    }

    render() {
        return (
            <Card id="MessageModeration">
                <CardHeader title="Блок модерации" />
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Input
                                id="categoryId"
                                type={this.state.form.categoryId.elementType}
                                options={this.props.categories}
                                value={this.state.form.categoryId.value}
                                config={this.state.form.categoryId.config}
                                error={this.state.form.categoryId.error}
                                validation={this.state.form.categoryId.validation}
                                onChange={event => { this.handleCategoryChange(event.target.value); }}
                                onBlur={(event) => { this.handleFormElementBlur("categoryId", event.target.value); }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                id="messageTypeId"
                                type={this.state.form.messageTypeId.elementType}
                                value={this.state.form.messageTypeId.value}
                                options={this.props.messageTypes}
                                config={this.state.form.messageTypeId.config}
                                error={this.state.form.messageTypeId.error}
                                validation={this.state.form.messageTypeId.validation}
                                onChange={event => { this.handleMessageTypeChange(event.target.value); }}
                                onBlur={(event) => { this.handleFormElementBlur("messageTypeId", event.target.value); }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                id="performerId"
                                type={this.state.form.performerId.elementType}
                                value={this.state.form.performerId.value}
                                options={this.props.performers}
                                config={this.state.form.performerId.config}
                                error={this.state.form.performerId.error}
                                validation={this.state.form.performerId.validation}
                                onChange={event => { this.handlePerformerChange(event.target.value); }}
                                onBlur={(event) => { this.handleFormElementBlur("performerId", event.target.value); }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" onClick={() => this.handleModerationFormSubmit()} disabled={!this.state.formIsValid}>
                                Сохранить изменения
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            

        );
    }

};

const mapStateToProps = state => {
    return {
        categories: state.msg.categories,
        messageTypes: state.msg.messageTypes,
        performers: state.msg.performers
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onFetchCategories: (munSetId) => dispatch(actions.fetchMessageCategories(munSetId)),
        onFetchMessageTypes: (categoryId, munSetId) => dispatch(actions.fetchMessageTypes(categoryId, munSetId)),
        onFetchPerformers: (typeId, munSetId) => dispatch(actions.fetchPerformers(typeId, munSetId)),
        onChangeCategory: (categoryId) => {
            dispatch(actions.fetchMessageTypes(categoryId, ownProps.message.municipalSettlement.id));
        },
        onChangeMessageType: (messageTypeIdId) => {
            dispatch(actions.fetchPerformers(messageTypeIdId, ownProps.message.municipalSettlement.id));
        },
        onInitForm: (ownProps) => dispatch(actions.initMessageModerationForm(ownProps.message)),
        onSaveModeration: (messageId, performerId, categoryId, messageTypeId) => dispatch(actions.setMessagePerformer(messageId, performerId, categoryId, messageTypeId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MessageModerationPanel));
