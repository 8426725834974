import React from 'react';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { CardContent, CardMedia } from '@material-ui/core';
import PersonBadge from '../UI/Badges/PersonBadge';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    image: {
        height: '100%',
        width: '100%',
        objectFit: 'contain',
        maxHeight: '300px'
    }
}));

const NewsPreview = (props) => {
    const classes = useStyles();
    const image = props.image ? `data:image/jpg;base64,${props.image}` : "/images/no-photo.jpg";
    const authority = props.authority
        ? (
            <Grid item>
                <PersonBadge
                    name={props.authority}
                />
            </Grid>
        ) : null;
    return (
        <Card>
            <CardContent>
                <Grid container spacing={2} style={{ width: "100%" }}>
                    <Grid item xs={12} sm container direction="column" spacing={2} justify="space-between">
                        <Grid item container direction="column" spacing={2}>
                            <Grid item key="date">
                                <Typography variant="body1" color="textSecondary">
                                    {props.creationDate}
                                </Typography>
                            </Grid>
                            <Grid item zeroMinWidth>
                                <Typography variant="body1" noWrap>
                                    {props.header}
                                </Typography>
                            </Grid>
                        </Grid>
                        
                        {authority}
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <img src={image} className={classes.image} />
                    </Grid>
                    
                </Grid>
            </CardContent>
        </Card>
    );
};

export default NewsPreview;