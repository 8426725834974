import React, { Component } from 'react';
import { Box } from '@material-ui/core';
import Input from '../../../components/UI/Inputs/Input';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';

class TerritoryParameter extends Component  {
    state = {
        municipalSettlements: {
            elementType: "select",
            config: {
                value: [],
                label: "Выберите территории",
                defaultDisplayValue: null,
                options: [],
                multiple: true
            },
            validation: {
                required: true
            },
            error: "",
            valid: false
        }
    };

    componentDidMount() {
        if (this.props.territories.length === 0) {
            this.props.onFetchWorkProfile();
        }
        if (this.props.territories.length === 1) {
            let options = this.props.territories.map(el => el.id);
            this.props.handleParameterValueChange(options);
        }
    }

    componentDidUpdate(prevProps) {
        const territoriesWereNotLoaded = !prevProps.territories || !prevProps.territories.length;
        const territoriesAreLoaded = this.props.territories && this.props.territories.length > 0;
        if (territoriesAreLoaded && territoriesWereNotLoaded) {
            let options = this.props.territories.map(el => el.id);
            this.props.handleParameterValueChange(options);
        }
    }

    render() {
        let options = this.props.territories.map(el => {
            return { id: el.id, title: el.name };
        });

        if (this.props.territories.length > 1) {
            return (
                <Box width={270} mb={2}>
                    <Input
                        id="municipalSettlementId"
                        type={this.state.municipalSettlements.elementType}
                        value={this.props.value ? this.props.value : []}
                        config={this.state.municipalSettlements.config}
                        options={options}
                        error={this.state.municipalSettlements.error}
                        onChange={event => { this.props.handleParameterValueChange(event.target.value, "municipalSettlements"); }}
                    />
                </Box >
            );
        }
        return null;
    }
}

const mapStateToProps = state => {
    return { territories: state.profile.territories};
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchWorkProfile: () => dispatch(actions.fetchUserWorkProfile())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TerritoryParameter);

