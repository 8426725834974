import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({

    paper: {
        padding: "10px",
        textAlign: "center",
        marginBottom: "10px"
    },
    bold: {
        fontWeight: "bold" 
    }
}));

const RejectionReason = (props) => {
    const classes = useStyles();
    return (
        <Paper className={classes.paper}>
            <Grid container justify="space-between">
                <Grid item>
                    <Typography variant="body1" color="secondary">
                        <span className={classes.bold}>{props.textPrimary} </span>
                        {props.textSecondary}
                    </Typography>
                </Grid>
                {
                    props.canCorrect && (
                        <Grid item>
                            <Link to={props.linkToCorrectionPage }>
                                <Button color="default" variant="contained" size="small" startIcon={<EditIcon fontSize="small" />}>
                                    {props.linkToCorrectionPageText}
                                </Button>
                            </Link>
                        </Grid>
                    )
                }
                

            </Grid>
            

        </Paper>

    );
};
export default RejectionReason;