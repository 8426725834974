import React from 'react';
import { Typography } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    personBageTxt: {
        verticalAlign: 'middle',
        display: 'flex',
        alignItems: 'center'
    },
    wrapIcon: {
        marginRight: '5px'
    }
}));

const PersonBadge = (props) => {
    const classes = useStyles();
    return (
        <Typography className={classes.personBageTxt} variant="body2" component="span" color="primary">
            <PersonIcon color="primary" className={classes.wrapIcon} />{props.name}
        </Typography>
    );
};

export default PersonBadge;
