import React from 'react';
import { ListItem, ListItemText, ListItemSecondaryAction, IconButton, ListItemIcon } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';

//props.document, props.document.id, props.document.name
const uploadedDocument = (props) => {
    let dowloadDocumentHandler = null;
    let documentName = "";
    if (props.document) {
        if (props.document instanceof Blob || props.document instanceof File) {
            let documentSrc = window.URL.createObjectURL(props.document);
            dowloadDocumentHandler = () => { window.location = documentSrc; };
            documentName = props.document.name;
        } else {
            documentName = props.document.value;
            dowloadDocumentHandler = () => props.onDownload(props.document.id, documentName);
        }
    }
    return (
        <ListItem key={props.index} >
            <ListItemIcon>
                <IconButton edge="end" aria-label="загрузить" title="загрузить" onClick={dowloadDocumentHandler}>
                    <GetAppIcon />
                </IconButton>
            </ListItemIcon>
            <ListItemText style={{ textOverflow: "ellipsis", miWidth: 0, }} primary={documentName} />
            <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="удалить" onClick={props.onDelete}>
                    <DeleteIcon />
                </IconButton>
            </ListItemSecondaryAction>


        </ListItem>
    );
};

export default uploadedDocument;