import React, { Component } from 'react';
import { Fade, Collapse } from '@material-ui/core';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import MessageInfo from './MessageInfo';
import { withRouter } from 'react-router-dom';

class MessageMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMessageInfo: false,
            messageInfo: null
        };
    }

    componentDidMount() {
        this.layerId = 375;
        
        this.initMap();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.showMessageInfo !== prevState.showMessageInfo || this.state.messageInfo !== prevState.messageInfo) {
            return;
        }
        else {
            this.setMapFilter();
        }
    }

    setMapFilter() {
        this.hideAllMessages();
        if (this.isFilterSetToDefault()) {
            this.showDefaultMessages();
        } else {
            this.showFilteredMessages();
        }
    }

    hideAllMessages = ()  => {
        this.MAP.setNonViewLayers([this.layerId]);
    }

    showDefaultMessages = () => {
        this.MAP.setViewLayers([this.layerId]);
    }

    showFilteredMessages() {
        let filter = {};
        let filterString = "";
        if (this.props.filter.category) {
            filter["category__in"] = [this.props.filter.category];
        }
        if (this.props.filter.status) {
            filter["current_status__in"] = [this.props.filter.status];
        }
        if (this.props.filter.beginDate) {
            filter["posting_date__gte"] = this.props.filter.beginDate;
        }
        if (this.props.filter.endDate) {
            filter["posting_date__lte"] = this.props.filter.endDate;
        }
        
        if (this.props.filter.municipalSettlement) {
            filterString += `geom__intersects=${this.props.filter.municipalSettlement}`;
        }
        if (filter) {
            filterString += `&filter=${JSON.stringify(filter)}`;
        }
        console.log(filterString);
        this.MAP.setFilterToViewLayers([{
            id: this.layerId, filter: filterString
        }]);
        
    }

    isFilterSetToDefault = () => {
        return !Object.values(this.props.filter).some(item => Boolean(item));
    }

    createMap = () => {
        if (!this.MAP) {
            this.MAP = new window.L.GisRoMap('map', {
                center: [48.09470655664555, 40.83330078125],
                zoom: 7,
                BACKEND_URL: process.env.REACT_APP_MAP_URL
            });
            let self = this;
            this.MAP.on("load:end", () => {
                let messageLayer = self.MAP.getLayerById(self.layerId);
                messageLayer.on('click', function (ev) {
                    if (isMobile) {
                        self.setState({
                            messageInfo: ev.sourceTarget.feature.properties,
                            showMessageInfo: true
                        });
                    }
                    else {
                        self.props.history.push(`/messages/${ev.sourceTarget.feature.properties.message_id}`);
                    }
                });
            });
            this.MAP.setViewLayers([this.layerId]);
            console.log("guid: ", this.props.selectedGuid);
            if (this.props.selectedGuid) {
                this.MAP.zoomtoUserObj(this.props.selectedGuid, this.layerId)
            }
        }
    }

    closeMessageInfo = () => {
        this.setState({
            //messageInfo: null,
            showMessageInfo: false
        });
    }

    renderSidebar = () => {
        if (isMobile) {
            console.log(this.state.showMessageInfo);
            return (
                <Fade in={this.state.showMessageInfo}>
                    <div>
                        <MessageInfo {...this.state.messageInfo} onClose={() => this.closeMessageInfo()} />
                    </div>
                </Fade>
                
            );
        }
        return null;
    }

    initMap() {

        if (!window.L) {
            let aScript = document.createElement('script');
            aScript.type = 'text/javascript';
            aScript.src = "/map/main.js";
            document.head.appendChild(aScript);
            var self = this;
            aScript.onload = function () {
                self.createMap();

            };
        }
        else {
            this.createMap();
        }

    }

    render() {
        return (
            <React.Fragment>
                <div id="map" style={{ height: "100%" }} key="map">
                    
                </div>
                {this.renderSidebar()}
            </React.Fragment>
        );
        
    }
    
}

const mapStateToProps = state => {
    return {
        filter: state.msg.mapFilter
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MessageMap));