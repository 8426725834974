import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import messageReducer from './store/reducers/message';
import votingReducer from './store/reducers/voting';
import notificationReducer from './store/reducers/notify';
import newsReducer from './store/reducers/news';
import commonReducer from './store/reducers/common';
import cabinetReducer from './store/reducers/cabinet';
import organizationsReducer from './store/reducers/organizations';
import performersReducer from './store/reducers/performers';
import profileReducer from './store/reducers/profile';
import userReducer from './store/reducers/user';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import MessageMapPage from './public/pages/Messages/MessageMapPage';
import * as actions from './store/actions';
import reportReducer from './store/reducers/report';
import { Interceptor } from './store/api/utils';
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
    msg: messageReducer,
    vote: votingReducer,
    notify: notificationReducer,
    cmn: commonReducer,
    news: newsReducer,
    my: cabinetReducer,
    org: organizationsReducer,
    perf: performersReducer,
    profile: profileReducer,
    report: reportReducer,
    user: userReducer
});
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
Interceptor(store);
setTimeout(() => store.dispatch(actions.fetchUser()), process.env.REACT_APP_SESSION_CHECK_INTERVAL);
const app = (

    <Provider store={store}>
        <BrowserRouter>
            <Switch>
                <Route path="/messages/map" exact component={MessageMapPage} />
                <Route path="*" exact component={App} />
            </Switch>
        </BrowserRouter>
    </Provider>
);
ReactDOM.render(app, document.getElementById('root'));
