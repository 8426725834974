import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';
const initialState = {
    loadedVoting: null,
    loading: false,
    votings: [],
    filter: {
        statusId: '',
        territoryId: ''
    },
    skip: 0,
    take: 9,
    page: 0,
    created: false,
    votingsPerPage: 10,
    votingsCount: 0,
    statuses: [],
    isUserVoted: false,

    totalFilteredVotingsCount: 0,
    maxPossibleVotingsCount: 9, //начальное значение такое же как и "take"
    loadedVotingAnswers: null, 
    loadedVotingAlternativeAnswer: false
};

const fetchVotingPreviewsStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const fetchVotingPreviewsSuccess = (state, action) => {
    const updatedVotings = state.votings.concat(action.votings);
    return updateObject(state, {
        votings: updatedVotings,
        loading: false
    });
};

const fetchVotingPreviewsFail = (state, action) => {
    return updateObject(state, { loading: false });
};

const fetchVotingStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const fetchVotingSuccess = (state, action) => {
    console.log("results ", action.voting.results);
    return updateObject(state, {
        loadedVoting: action.voting ? {
            id: action.voting.id,
            number: action.voting.number,
            header: action.voting.header,
            municipalSettlement: action.voting.municipalSettlement.name,
            description: action.voting.description,
            createDate: action.voting.createDate,
            publishDate: action.voting.publicationDate,
            endDate: action.voting.endDate,
            debriefingDate: action.voting.debriefingDate,
            author: action.voting.author,
            status: action.voting.status.value,
            type: action.voting.type.value,
            image: action.voting.photo,
            isFavorite: action.voting.isFavorite,
            yourAnswersCount: action.voting.results.yourAnswersCount,
            isUserVoted: action.voting.isUserVoited,
            totalNumberOfVoted: action.voting.allUsersVoitedCount,
            rejectionReason: action.voting.rejectionReason,
            documents: action.voting.documents,
            options: action.voting.answerOptions.map(opt => {
                return {
                    id: opt.id,
                    title: opt.answerTitle,
                    image: opt.photo,
                    numberOfVotes: action.voting.results.answers.find(res => res.answerOptionId === opt.id).answerCount,
                };
            })
        } : null,
        loading: false
    });
};

const fetchVotingFail = (state, action) => {
    return updateObject(state, { loading: false });
};

const fetchVotingTypesSuccess = (state, action) => {
    return updateObject(state, {
        types: action.types.map(type => { return { id: type.id, title: type.name }; })
    });
};

const postVotingSuccess = (state, action) => {
    return updateObject(state, {
        created: true
    });
};

const changeVotingsPageStart = (state, action) => {
    return updateObject(state, { page: action.page, loading: true });
};

const changeVotingsPageSuccess = (state, action) => {
    return updateObject(state, { votings: action.votings, loading: false });
};

const changeVotingsPageFail = (state, action) => {
    return updateObject(state, { loading: false });
};

const setVotingFilterValueReducer = (state, action) => {
    var newState = { ...state };
    var filter = { ...state.filter };
    filter[action.filter] = action.value;
    newState.votings = [];
    newState.loading = true;
    newState.filter = filter;
    return newState;
};

const toggleVotingFilter = (state, action) => {
    return updateObject(state, { showFilter: !state.showFilter });
};

const initPrivateFilter = (state, action) => {
    return updateObject(state, {
        filter: {
            statusId: '',
            createDate: {
                begin: '',
                end: ''
            }
        }
    });
};

const initPublicFilter = (state, action) => {
    return updateObject(state, {
        filter: {
            categoryId: '',
            territoryId: '',
        }
    });
};

const fetchVotingsCount = (state, action) => {
    return updateObject(state, {
        votingsCount: action.count
    });
};

const fetchVotingStatuses = (state, action) => {
    return updateObject(state, {
        statuses: action.statuses.map(status => { return { id: status.id, title: status.name }; })
    });
};

const updateVoting = (state, action) => {
    let loadedVotingCopy = null;
    if (state.loadedVoting && state.loadedVoting.id === action.votingId) {
        loadedVotingCopy = { ...state.loadedVoting };
        loadedVotingCopy.isFavorite = action.isFavorite;
    }
    let votings = state.votings.map(voting =>
        voting.id === action.votingId
            ? { ...voting, isFavorite: action.isFavorite }
            : voting
    );

    return updateObject(state, {
        votings: votings,
        loadedVoting: loadedVotingCopy,
        loading: false
    });
};

const fetchTotalFilteredVotingsCount = (state, action) => {
    return updateObject(state, {
        totalFilteredVotingsCount: action.count
    });
}

const onChangeTotalVotingsCount = (state, action) => {
    return updateObject(state, {
        maxPossibleVotingsCount: state.maxPossibleVotingsCount + state.take
    });
} 

const resetVotings = (state, action) => {
    return updateObject(state, { votings: [] });
};

const fetchUserAnswersOnVote = (state, action) => {
    return updateObject(state, {
        loadedVotingAnswers: action.userAnswer.answers,
        loadedVotingAlternativeAnswer: (action.userAnswer.yourAnswer.length > 0) ? true : false
    });
};


const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.FETCH_VOTING_PREVIEWS_START: return fetchVotingPreviewsStart(state, action);
        case actionTypes.FETCH_VOTING_PREVIEWS_SUCCESS: return fetchVotingPreviewsSuccess(state, action);
        case actionTypes.FETCH_VOTING_PREVIEWS_FAIL: return fetchVotingPreviewsFail(state, action);
        case actionTypes.FETCH_VOTING_START: return fetchVotingStart(state, action);
        case actionTypes.FETCH_VOTING_SUCCESS: return fetchVotingSuccess(state, action);
        case actionTypes.FETCH_VOTING_FAIL: return fetchVotingFail(state, action);
        case actionTypes.FETCH_VOTING_TYPES_SUCCESS: return fetchVotingTypesSuccess(state, action);
        case actionTypes.POST_VOTING_SUCCESS: return postVotingSuccess(state, action);
        case actionTypes.CHANGE_VOTINGS_PAGE_START: return changeVotingsPageStart(state, action);
        case actionTypes.CHANGE_VOTINGS_PAGE_SUCCESS: return changeVotingsPageSuccess(state, action);
        case actionTypes.CHANGE_VOTINGS_PAGE_FAIL: return changeVotingsPageFail(state, action);
        case actionTypes.SET_VOTING_FILTER_VALUE: return setVotingFilterValueReducer(state, action);
        case actionTypes.TOGGLE_VOTING_FILTER: return toggleVotingFilter(state, action);
        case actionTypes.INIT_VOTING_PRIVATE_FILTER: return initPrivateFilter(state, action);
        case actionTypes.INIT_VOTING_PUBLIC_FILTER: return initPublicFilter(state, action);
        case actionTypes.FETCH_VOTINGS_COUNT: return fetchVotingsCount(state, action);
        case actionTypes.FETCH_VOTING_STATUSES_SUCCESS: return fetchVotingStatuses(state, action);
        case actionTypes.UPDATE_VOTING: return updateVoting(state, action);
        case actionTypes.RESET_VOTINGS: return resetVotings(state, action);
        case actionTypes.FETCH_TOTAL_FILTERED_VOTINGS_COUNT_SUCCES: return fetchTotalFilteredVotingsCount(state, action);
        case actionTypes.CHANGE_TOTAL_VOTINGS_COUNT: return onChangeTotalVotingsCount(state, action);
        case actionTypes.FETCH_USER_ANSWER_ON_VOTE_SUCCESS: return fetchUserAnswersOnVote(state, action);
        default: return state;
    }
};

export default reducer;