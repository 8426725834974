import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import PreviewGrid from '../../../components/UI/Containers/PreviewGrid';
import MessagePreview from '../../../components/Messages/MessagePreview';
import VotingPreview from '../../../components/Voting/VotingPreview';
import Preloader from '../../../containers/Preloader';

class MyMessages extends Component {
    componentDidMount() {
        this.props.onFetchFavorites();
    }

    componentDidUpdate(prevProps) {
    }


    render() {
        if (this.props.loading) {
            return (
                <Preloader fullScreen open color="primary"/>
            );
        }
        //return null;
        return (
            <PreviewGrid
                shownPreviews={this.props.favorites}
                onShowMore={() => this.props.onShowMoreFavorites()}
                enableShowMore
                previewRenderFunction={(fav) => {
                    if (fav.type === "message") {
                        let message = fav.object;
                        return (<MessagePreview image={message.thumbNail}
                            type={message.type}
                            text={message.text}
                            status={message.status}
                            id={message.id}
                            number={message.number}
                            date={message.createDate}
                            gisRoGuid={message.gisROMessageId}
                            isFavorite={message.isFavorite}
                            onToggleIsFavoriteStatus={() => this.props.onSetMessageIsFavoriteStatus(message.id, !message.isFavorite)}
                            author={message.userName}
                        />);
                    } else {
                        let voting = fav.object;
                        return (<VotingPreview
                            key={voting.id}
                            voting={voting}
                        />);
                    }
                     
                }}
                getLinkFunction={(fav) => { return fav.type === "message" ? "/messages/" + fav.object.id : "/votings/" + fav.object.id; }}
                getPreviewKeyFunction={(fav) => { return fav.object.id; }}
            />
        );
    }

};

const mapStateToProps = state => {
    return {
        favorites: state.my.shownFavorites,
        loading: state.my.loading,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchFavorites: () => dispatch(actions.fetchFavorites()),
        onSetMessageIsFavoriteStatus: (messageId, isFavorite) => dispatch(actions.setMessageIsFavoriteStatus(messageId, isFavorite)),
        onShowMoreFavorites: () => dispatch(actions.showMoreFavorites())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyMessages);