import React from 'react';
import VotingGrid from '../../../containers/Voting/VotingGrid';
import PublicVotingFilter from '../../containers/Voting/PublicVotingFilter';
import VotingFilterToggler from '../../../containers/Voting/VotingFilterToggler';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Link, withRouter } from 'react-router-dom';
import Can from '../../../components/Auth/Can';

const messagesPage = (props) => {

    return (
        <Grid container className="Votings" spacing={0} direction="column" justify="center">
            <Grid item key="actions" xs={12} container justify="space-between">
                <Can perform="votings:create" yes={() => (
                    <Grid item key="add" xs={12} sm={2}>
                        <Link to={{
                            pathname: props.match.url + "/new"
                        }}>
                            <Button color="primary" variant="contained">
                                Предложить голосование/опрос
                            </Button>
                        </Link>
                        </Grid>
                    )}
                />
                <Grid item key="filter" xs>
                    <PublicVotingFilter />
                </Grid>
                <Grid item key="toggler" xs={12} sm={1} style={{ textAlign: "right" }}>
                    <VotingFilterToggler />
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "20px" }}>
                <VotingGrid />
            </Grid>

        </Grid>
    );
};

export default withRouter(messagesPage);