import React, { Component } from 'react';
import { Grid, Paper, TextField, Button, Typography } from '@material-ui/core';
import * as actions from '../../../store/actions';
import { connect } from 'react-redux';
import GroupedList from '../../../components/UI/Containers/GroupedList';
import { groupBy } from '../../../utility/arrayHelpers';
import Preloader from '../../../containers/Preloader'

class WorkProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            position: props.position ? props.position : ""
        };
    }

    componentDidMount() {
        this.props.onFetchWorkProfile();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.position !== this.props.position) {
            this.setState({
                position: this.props.position
            });
        }
    }

    handleChangePosition(newValue) {
        this.setState({
            position: newValue
        });
    }

    handleSave() {
        this.props.onSetWorkProfile({
            position: this.state.position
        });
    }

    render() {
        if (this.props.loading) {
            return (
                <Preloader open fullScreen color="primary" />
            );
        } else {
            let messageTypesBlock = !this.props.showMessageTypes ? null :
                (
                    <Grid item key="types" xs={12}>
                        <Typography variant="h6" component="h6">
                            Типы обрабатываемых сообщений
                            </Typography>
                        <GroupedList items={this.props.messageTypes} />
                    </Grid>
                );
            return (
                <Grid container justify="center">
                    <Grid item xs={12} sm={8} container spacing={2}>
                        <Grid item key="profile" xs={12}>
                            <Paper style={{ padding: "20px" }}>
                                <Typography variant="h6" component="h6">
                                    Рабочий профиль (Роль: {this.props.roleTitle})
                            </Typography>
                                <Grid container spacing={2} style={{ marginTop: "20px" }}>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="organization"
                                            label="Организация"
                                            value={this.props.organization}
                                            fullWidth
                                            disabled
                                            variant="outlined"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="position"
                                            label="Должность"
                                            value={this.state.position}
                                            fullWidth
                                            variant="outlined"
                                            onChange={(event) => this.handleChangePosition(event.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="activeTerritory"
                                            label="Активные территории"
                                            value={this.props.territories}
                                            fullWidth
                                            disabled
                                            variant="outlined"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button variant="contained" color="primary" onClick={() => this.handleSave()}>
                                            Сохранить изменения
                                    </Button>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        
                        {messageTypesBlock}

                    </Grid>
                </Grid>
            );
        }
    }
}

const mapStateToProps = state => {
    let roleTitle = "";
    switch (state.user.role) {
        case "performer":
            roleTitle = "Исполнитель";
            break;
        case "moderator":
            roleTitle = "Модератор";
            break;
        case "administrator":
            roleTitle = "Администратор";
            break;
        default:
            break;
    }
    var groupedMessageTypes = groupBy(state.profile.messageTypes, el => el.categoryId, el => el.id, el => el.category, el => el.name);
    return {
        position: state.profile.position,
        territories: state.profile.territories.length > 0 ? state.profile.territories.map(x => x.name).join(", ") : "отсутствуют",
        organization: state.profile.organization ? state.profile.organization : "не указана",
        messageTypes: groupedMessageTypes,
        loading: state.profile.loading,
        showMessageTypes: state.user.role === "performer",
        roleTitle:  roleTitle
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchWorkProfile: () => dispatch(actions.fetchUserWorkProfile()),
        onSetWorkProfile: (profile) => dispatch(actions.setUserWorkProfile(profile))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(WorkProfile);