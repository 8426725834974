import * as actionTypes from './actionTypes';
import { addSuccessNotification, addErrorNotification } from './notify';
import commonApi from '../api/commonApi';
export const updateOrganizationFilterValue = (filter, value) => {
    return {
        type: actionTypes.SET_ORGANIZATION_FILTER_VALUE,
        filter: filter,
        value: value,
    };
};

export const toggleOrganizationFilter = () => {
    return {
        type: actionTypes.TOGGLE_ORGANIZATION_FILTER,
    };
};


export const setOrganizationFilterValue = (filter, value) => {
    return (dispatch, getState) => {
        dispatch(updateOrganizationFilterValue(filter, value));
        dispatch(fetchOrganizations());
    };
};

export const changeOrganizationsPageStart = (newPage) => {
    return {
        type: actionTypes.CHANGE_ORGANIZATIONS_PAGE_START,
        page: newPage,
    };
};

export const changeOrganizationsPageFail = () => {
    return {
        type: actionTypes.CHANGE_ORGANIZATIONS_PAGE_FAIL
    };
};

export const changeOrganizationsPageSuccess = (organizations) => {
    return {
        type: actionTypes.CHANGE_ORGANIZATIONS_PAGE_SUCCESS,
        organizations: organizations,
    };
};

export const getOranizationInfoSuccess = (organizationInfo) => {
    return {
        type: actionTypes.FETCH_ORGANIZATION_INFO,
        organizationInfo: organizationInfo,
    };
};

const getOrganizationFilter = (filter) => {
    return {
        categoryId: filter.categoryId,
        typeId: filter.messageTypeId,
        beginDate: filter.sendDate ? filter.sendDate.begin : '',
        endDate: filter.sendDate ? filter.sendDate.end : '',
        beginAnswerDate: filter.answerDate ? filter.answerDate.begin : '',
        endAnswerDate: filter.answerDate ? filter.answerDate.end : '',
        statusId: filter.statusId,
    };
};

export const changeOrganizationsPage = (newPage, territory) => {
    return (dispatch) => {
        dispatch(changeOrganizationsPageStart(newPage, territory));
        dispatch(fetchOrganizations(territory));
    };
};

export const fetchOrganizations = (territory) => {
    return (dispatch, getState) => {
        const currentState = getState();
        const filter = currentState.org.filter;
        commonApi.getOrganizations(filter, territory)
            .then(res => {
                dispatch(changeOrganizationsPageSuccess(res.data));
            })
            .catch(err => {
                dispatch(changeOrganizationsPageFail(err));
            });
    };
};

export const fetchOrganizationInfo = (organizationId) => {
    return (dispatch, getState) => {
        commonApi.getOrganizationInfo(organizationId)
            .then(res => {
                dispatch(getOranizationInfoSuccess(res.data));
            })
    }
}

export const initOrganizationPrivateFilter = () => {
    return {
        type: actionTypes.INIT_ORGANIZATION_PRIVATE_FILTER,
    };
};

export const initOrganizationPublicFilter = () => {
    return {
        type: actionTypes.INIT_ORGANIZATION_PUBLIC_FILTER,
    };
};

export const fetchScopesStart = () => {
    return {
        type: actionTypes.FETCH_SCOPES_START,
    };
};

export const fetchScopesSuccess = (data) => {
    return {
        type: actionTypes.FETCH_SCOPES_SUCCESS,
        scopes: data
    };
};


export const fetchScopesFail = () => {
    return {
        type: actionTypes.FETCH_SCOPES_FAIL,
    };
};

export const fetchScopes = () => {
    return dispatch => {
        dispatch(fetchScopesStart());
        commonApi.getScopes()
            .then(res => {
                dispatch(fetchScopesSuccess(res.data));
            })
            .catch(err => {
                dispatch(fetchScopesFail(err));
            });
    };
};

export const postOrganizationStart = () => {
    return {
        type: actionTypes.POST_ORGANIZATION_START,
    };
};

export const postOrganizationSuccess = (id) => {
    return {
        type: actionTypes.POST_ORGANIZATION_SUCCESS,
        id: id,
    };
};

export const postOrganizationFail = (error) => {
    return {
        type: actionTypes.POST_ORGANIZATION_FAIL,
        error: error
    };
};

export const deleteOrganizationSuccess = () => {
    return {
        type: actionTypes.DELETE_ORGANIZATION_SUCCESS,
    };
};


export const postOrganization = (data) => {
    return dispatch => {
        dispatch(postOrganizationStart());
        dispatch(toggleDisabled(true));
        commonApi.postOrganization(data)
            .then(result => {
                dispatch(addSuccessNotification("Организация успешно добавлена"));
                dispatch(toggleDisabled(false));
                (result.data.content.headers).forEach(el => {
                    (el['Key'] === 'Content-Disposition') && dispatch(postOrganizationSuccess(el.Value[0]));
                })
            })
            .catch(err => {
                dispatch(postOrganizationFail(err));
                dispatch(addErrorNotification());
                dispatch(toggleDisabled(false));
            });
    };
};

export const toggleDisabled = (disabled) => {
    return {
        type: actionTypes.TOGGLE_DISABLED,
        disabled: disabled,
    };
}

export const deleteOrganizationLogo = (id) => {
    return dispatch => {
        commonApi.deleteOrganizationLogo(id)
            .then(result => {

            }).catch(err => {
                dispatch(addErrorNotification());
            });
    }
}

export const updateOrganization = (data) => {
    return dispatch => {
        dispatch(toggleDisabled(true));
        commonApi.updateOrganization(data)
            .then(result => {
                dispatch(fetchOrganizationInfo(data.id));
                dispatch(addSuccessNotification("Организация обновлена"));
                dispatch(toggleDisabled(false));
            }).catch(err => {
                dispatch(addErrorNotification("Ошибка при обновлении  организации"));
                dispatch(toggleDisabled(false));
            });
        if (data.logo !== null && data.logo.type) {
            commonApi.updateOraganizationLogo(data.id, data.logo)
                .then(result => {
                    dispatch(fetchOrganizationInfo(data.id))
                    dispatch(toggleDisabled(false));
                }).catch(err => {
                    dispatch(addErrorNotification("Ошибка при обновлении  организации"));
                    dispatch(toggleDisabled(false));
                });
        }
    }
}

export const deleteOrganization = (id) => {
    return (dispatch, getState) => {
        let state = getState();
        commonApi.deleteOrganization(id)
            .then(result => {
                dispatch(deleteOrganizationSuccess());
                dispatch(changeOrganizationsPage(state.org.page, state.my.territory));
                dispatch(addSuccessNotification("Организация удалена"));
            })
            .catch(err => {
                dispatch(addErrorNotification('Ошибка при удаление логотипа'));
            });
    }
}
