import React, { useRef } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import ImageIcon from '@material-ui/icons/Image';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import UploadedImage from './UploadedImage';
import UploadedDocument from './UploadedDocument';

const FileUpload = (props) => {
    const maxFilesCount = props.maxFilesCount ? props.maxFilesCount : 1;
    let fileInputRef = useRef(null);
    let fileList = null;
    let addFile = null;
    let acceptedFileTypes = props.accept;

    if (props.files.length < maxFilesCount) {
        let addFileIcon = null;

        switch (props.fileType) {
            case "image":
                addFileIcon = <AddPhotoAlternateIcon />;
                break;
            case "document":
            default:
                addFileIcon = <NoteAddIcon />;
        }
        addFile = (
            <ListItem key="add" button onClick={() => { fileInputRef.click(); }}>
                <ListItemIcon color="primary" aria-label="добавить" >
                    <IconButton >
                        {addFileIcon}
                    </IconButton >
                </ListItemIcon>
                <ListItemText primary={props.addFileBtnText} />
            </ListItem>
        );
    }
    if (props.files.length > 0) {
        let fileIcon = null;
        switch (props.fileType) {
            case "image": {
                fileIcon = <ImageIcon />;
                fileList = (
                    props.files.map((file, index) => (
                        <ListItem key={index}>
                            <UploadedImage image={file} index={index} onDelete={() => props.onFileDelete(index)} />
                        </ListItem>
                    ))
                );
                break;
            }
            case "document":
            default: {
                fileIcon = <InsertDriveFileIcon />;
                fileList = (
                    props.files.map((file, index) => (
                        <UploadedDocument
                            key={index}
                            index={index}
                            document={file}
                            onDownload={(id, name) => props.onFileDownload(id, name)}
                            onDelete={() => props.onFileDelete(index)} />
                    )
                ));
            }
                
        }
    }

    return (
        <div>
            <input type="file" multiple hidden
                accept={acceptedFileTypes}
                ref={(input) => { fileInputRef = input; }}
                onChange={(event) => {
                    const document = event.target.files[0];
                    event.target.value = null;
                    props.onFileAdd(document);
                }}
            />
            <List>
                {addFile}
                {fileList}
            </List>
        </div>

    );

};


export default FileUpload;