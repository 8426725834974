import React, { Component } from 'react';
import OrganizationTable from '../../components/Organizations/OrganizationTable';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import FilterToolbar from '../../components/UI/FilterToolbar';
import { withRouter } from 'react-router-dom';
import Preloader from '../Preloader';

class OrganizationTableContainer extends Component {
    componentDidMount() {
        if (!this.props.user.isAuthenticated || this.props.user.isAuthenticated && this.props.territory) {
            this.props.onChangePage(this.props.page, this.props.territory);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.territory !== prevProps.territory) {
            this.props.onChangePage(1, this.props.territory);
        }
    }

    onOrganizationClicked = (id) => {
        window.open(`/organizations/${id}`);
    };

    handleDeleteOrganization = (id) => {
        this.props.onDeleteOrganization(id);
    }

    handleEditOrganization = (id) => {
        window.open(`/organizations/edit/${id}`);
    }

    render() {
        if (this.props.loading) {
            return (
                <Preloader fullScreen open color="primary"/>
            );
        }
        let organizations = null;
        if (!this.props.loading) {
            organizations = (
                <Grid container>
                    <OrganizationTable
                        filter={this.props.filter}
                        showFilter={this.props.showFilter}
                        organizations={this.props.organizations}
                        page={this.props.page}
                        organizationsPerPage={this.props.organizationsPerPage}
                        onChangePage={(newPage) => this.props.onChangePage(newPage)}
                        onToggleFilter={this.props.onToggleFilter}
                        onOrganizationClicked={this.onOrganizationClicked}
                        count={this.props.count}
                        itemIdProp="id"
                        onDeleteOrganization={(e, id) => { this.handleDeleteOrganization(e, id) }}
                        onEditOrganization={(e, id) => { this.handleEditOrganization(e, id) }}
                    />
                </Grid>

            );
        }
        return (
            <React.Fragment>
                {/*<FilterToolbar filter={this.props.filter} onToggleFilter={this.props.onToggleFilter} showFilter={this.props.showFilter} header="Справочник организаций" />*/}
                {organizations}
            </React.Fragment>
        );
    }
};

const mapStateToProps = state => {
    return {
        organizations: state.org.organizations,
        loading: state.org.loading,
        skip: state.org.skip,
        take: state.org.take,
        showFilter: state.org.showFilter,
        page: state.org.page,
        organizationsPerPage: state.orgorganizationsPerPage,
        count: state.org.organizationsCount,
        territory: state.my.territory,
        user: state.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onChangePage: (newPage, territory) => dispatch(actions.changeOrganizationsPage(newPage, territory)),
        onToggleFilter: () => dispatch(actions.toggleOrganizationFilter()),
        onDeleteOrganization: (id) => dispatch(actions.deleteOrganization(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrganizationTableContainer));